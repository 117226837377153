/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PRPVAddressLocalization { 
    addressId: string;
    addressName: string;
    addressNumber?: string | null;
    addressCity?: string | null;
    addressProvince?: string | null;
    addressZip?: string | null;
    addressCountry?: string | null;
    addressLat?: number | null;
    addressLon?: number | null;
    addressExternalId?: string | null;
    addressTimeZoneId?: string | null;
    addressFull?: string | null;
    addressExtra?: string | null;
    addressUrl?: string | null;
    addressIsValidated: boolean;
    isActive: boolean;
    timeStamp: string;
    languageId: string;
    isTranslated: boolean;
    isActiveLoc?: boolean | null;
    timeStampLoc?: string | null;
}

