<button nz-button class="stella-table-button" [nzTooltipTitle]="toolTip" nzTooltipPlacement="top" nz-tooltip>
  
  @switch (buttonIconType) {
    @default {
      <i class="submenu-icon" [nzRotate]="buttonIconRotate??0" nz-icon [nzType]="buttonIcon"></i>
    }
    @case ("custom-font") {
      <i class="submenu-icon {{buttonIcon}}" [style]="'transform: rotate('+buttonIconRotate+'deg)'"></i>
    }
  }
</button>
