@if (showBroker==true) {
@if (brokerFormFieldGroups!= undefined )
{
<div class="ps-col-12 ps-d-flex ">
  <div class="ps-col-6 ps-d-flex ps-pl-0 ">

    <span class="form-title">
      {{'deal_broker_subtitle'|translation:'Broker '}}
    </span>

    @if(brokerAmount!=undefined && totalVolume!=0)
    {

    <span class="form-title" style="padding-left: 12px;">
      {{'deal_broker_amount_subtitle'|translation:'Amount:
      {0}':[((brokerAmount*totalVolume)|currency:brokerCurrency:'symbol':'1.2-4')!]}}
    </span>

    }

  </div>
  <div class="ps-col-6 ps-d-flex">
    <span class="form-title">
      {{'deal_comment_subtitle'|translation:'Comment'}}
    </span>
  </div>
</div>
}
<div class="ps-col-12">

  @if (brokerFormFieldGroups!= undefined)
  {
  <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
    [purpleFormFieldGroups]="brokerFormFieldGroups">
  </app-purple-form>
  }

</div>
}
<div class="ps-col-12 ps-pl-0 ps-d-flex ps-flex-wrap form-title">
  <div class="ps-col-12">
    <span class="ps-pl-0">
      {{'deal_ticket_deliveries_subtitle'|translation:'Deliveries'}}
    </span>
  </div>
  @if (totalVolume!=0&&totalSum!=0)
  {
  <div class="ps-col-3">

    <span>
      {{'deal_ticket_deliveries_total_amount_volume'|translation:'Volume : {0}' :[totalVolume+''] }}
    </span>

  </div>
  <div class="ps-col-4">

    <span>
      {{'deal_ticket_deliveries_total_amount_price'|translation:'Price : {0}'
      :[((totalSum)/(totalVolume!=0?totalVolume:1)).toFixed(3)] }}
    </span>

  </div>
  <div class="ps-col-5">

    <span>
      {{'deal_ticket_deliveries_total_amount'|translation:'Amount : {0}' :[totalString() ]}}
    </span>
  </div>
  }
</div>
@for (panel of panels; track i;let i = $index)
{
<div class="ps-col-12 ps-d-flex ps-pl-0" style="margin-bottom: 12px;">

  <div class="ps-col-11 ps-justify-content-start" style="padding-right: 0;">
    <nz-collapse [nzBordered]="true" nzExpandIconPosition="right">

      <nz-collapse-panel style="border-bottom: none;" #p
        [nzHeader]="'delivery_panels'|translation: 'Delivery {0} ':[(i+1)+'']" [nzActive]="panel.active"
        [nzDisabled]="panel.disabled" [nzExpandedIcon]="expandedIcon">

        <ng-template #expandedIcon let-active>
          {{ active }}
          <span nz-icon nzType="right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -90"></span>
        </ng-template>

        <div>
          <span class="ps-justify-content-start form-title">
            {{'delivey_details_subtitle'|translation:'Delivery details'}}
          </span>
        </div>


        @if (deliveryFormFieldGroup1!=undefined)
        {
        <app-purple-form [disbledForm]="disableForm" [(formGroup)]="dynamicFromGroups[i]"
          [purpleFormFieldGroups]="deliveryFormFieldGroup1[i]">
        </app-purple-form>
        }

        <button [nzTooltipTitle]="'deal_ticket_select_vintage' | translation: 'Select vintage'"
          (click)="selectVintage(i)" nzTooltipPlacement="top" class="ps-mr-3 select-button vintage {{vintagePosition}}"
          nz-tooltip nz-button nzType="default" nzShape="round"><i nz-icon nzType="calendar"></i>
        </button>

        <button [nzTooltipTitle]="'deal_ticket_select_origin' | translation: 'Select origin'" (click)="selectOrigin(i)"
          nzTooltipPlacement="top" class="ps-mr-3 select-button origin {{originPosition}}" nz-tooltip nz-button
          nzType="default" nzShape="round"><i nz-icon nzType="environment"></i>
        </button>

        <button [nzTooltipTitle]="'deal_ticket_select_origin' | translation: 'Select Technology'"
          (click)="selectTechnology(i)" nzTooltipPlacement="top"
          class="ps-mr-3 select-button technology {{techPosition}}" nz-tooltip nz-button nzType="default"
          nzShape="round"><i nz-icon nzType="global"></i>
        </button>

        @if (this.product.strEq("ukmarket"))
        {
        <button [nzTooltipTitle]="'deal_ticket_select_origin' | translation: 'Select Technology'"
          (click)="selectPath(i)" nzTooltipPlacement="top" class="ps-mr-3 select-button path" nz-tooltip nz-button
          nzType="default" nzShape="round"><i nz-icon nzType="branches"></i>
        </button>
        }


        <div class="ps-d-flex ps-justify-content-start">
          <span class="form-title">
            {{'pricing_information_subtitle'|translation:'Pricing information'}}
          </span>
        </div>

        @if (deliveryFormFieldGroup2!=undefined)
        {
        <app-purple-form [disbledForm]="disableForm" [(formGroup)]="formGroups[i]"
          [purpleFormFieldGroups]="deliveryFormFieldGroup2[i]">
        </app-purple-form>

        }
      </nz-collapse-panel>

    </nz-collapse>

  </div>
  <div class="ps-col-1 ps-justify-content-end">

    <!--  <button class="right-panel-button-bottom " (click)="clonePanel(i)"
      [nzTooltipTitle]='"clone_panel_placeholder" | translation: "Clona Delivery"' nzTooltipPlacement="top" nz-tooltip
      nz-button nzType="default" nzShape="round">
      <span class="icon-duplicate-1 fsp-22-0"></span>

    </button> -->
    <!-- @if (i>0)
    {
     && p.nzActive


    } -->
    <button class="right-panel-button-bottom " (click)="deletePanel(i)"
      [nzTooltipTitle]='"delete_panel_placeholder" | translation: "Delete Delivery"' nzTooltipPlacement="top" nz-tooltip
      nz-button nzType="default" nzShape="round">
      <span class="icon-trash fsp-22-0"></span>

    </button>
  </div>

  @if (i==totalPanelNumber)
  {
  <div class="ps-col-1 ps-d-flex ps-justify-content-end" style="left: -78px;top:75px">
    <!-- [ngStyle]="{'top':(i>0&& p.nzActive)?'145px':'75px'}" -->
    <div class="ps-col-12">
      <button class="right-panel-button-bottom " (click)="addPanel()"
        [nzTooltipTitle]='"add_panel_placeholder" | translation: "Aggiungi Delivery"' nzTooltipPlacement="top"
        nz-tooltip nz-button nzType="default" nzShape="round">
        <i nz-icon class="fsp-22-0" nzType="plus"></i>
      </button>
    </div>
  </div>
  }
</div>
}

<div class="ps-col-12 ps-d-flex">
  <div style="padding-top:0px" class="ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-end">
    <button (click)="save(false)" class="deal-progress-previous-button">
      {{'deal_button_previous_text'|translation:'Torna indietro'}}
    </button>
  </div>
  <div class="ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-start" style="padding-top:0px">
    <button (click)="save(true)" class="deal-progress-next-button">
      {{'delivery_button_previous_text'|translation:'Check and Save'}}
    </button>
  </div>
</div>
