<div class="ps-col-12 ps-pl-0 ps-mb-4 ps-d-flex ps-flex-wrap">
  <div class="ps-col-12">
    <nz-descriptions nzTitle="{{'deal_ticket_deal_details_title'|translation:'Deal Details'}}" class="form-title"
      nzBordered>
      <nz-descriptions-item nzTitle="Company"> {{cpNames[data.dealTicket.companyId!]|titlecase}} </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Trader">{{traderName|titlecase}} </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Portfolio"> {{(data.dealTicket.portfolioId|titlecase) ??'-'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Products"> {{data.dealTicket.productIds??'-'}} </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Portfolio year"> {{(data.dealTicket.portfolioYear|purpleDate)??''}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Type of Deal"> {{(data.dealTicket.dealBuySeller|titlecase) ??'-'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Deal date"> {{(data.dealTicket.dealDate|purpleDate)??'-'}} </nz-descriptions-item>
      <nz-descriptions-item nzTitle="CounterParty"> {{(cpNames[data.dealTicket.counterPartyId??""]|titlecase)??'-'}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Broker">{{brokerName??'-'}} </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Broker Fee">{{data.dealTicket.brokerFee??'-'}} </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Broker Currency">{{data.dealTicket.brokerCurrency??'-'}} </nz-descriptions-item>

      @for (att of dealAttributes; track $index)
      {

      @if (att.strEq("deliveryDate"))
      {
      <nz-descriptions-item nzTitle="Delivery Date">{{(data.dealTicket.fields["deliveryDate"]|purpleDate ) ??'-'}}
      </nz-descriptions-item>
      }
      @else
      {
      <nz-descriptions-item [nzTitle]=(att)|titlecase>{{(data.dealTicket.fields[att]|titlecase ) ??'-'}}
      </nz-descriptions-item>
      }

      }

      <nz-descriptions-item nzTitle="Total Value">{{calculateTotal()}} </nz-descriptions-item>


    </nz-descriptions>
  </div>
</div>

<div class="ps-col-12 ps-pl-0 ps-d-flex ps-flex-wrap form-title deliveries ">
  <div class="ps-col-12">
    <span class="ps-pl-0">
      {{'deal_ticket_deliveries_details_title'|translation:'Deliveries Details'}}
    </span>
  </div>
  <div class="ps-col-12" style="max-height: 40%;">

    <nz-table [nzScroll]="{x: '100%', y: '500px'}" class="purple-table purple-table-2" [nzFrontPagination]="false"
      [nzData]="[1]" nzBordered>
      <thead>
        <tr>
          <th nzWidth="100px">
          </th>
          @for (delivery of data.deliveries; track i;let i= $index; )
          {
          <th nzAlign="center">
            Delivery {{i+1}}
          </th>
          }
        </tr>
      </thead>
      <tbody>

        @if(data.deliveries.at(0)?.deliveryDate!=undefined)
        {
        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_date'|translation:'Date'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.deliveryDate|purpleDate}}</td>
          }
        </tr>
        }



        @if (data.deliveries.at(0)?.vintageFull!=undefined)
        {

        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_vintage'|translation:'Vintage'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.vintageFull?.vintageString??'-'}}</td>
          }
        </tr>
        }

        @if (data.deliveries.at(0)?.origin!=undefined)
        {
        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_origin'|translation:'Origin'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.origin?.originString??'-'}}</td>
          }
        </tr>
        }


        @if (data.deliveries.at(0)?.tech!=undefined)
        {
        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_tech'|translation:'Technology'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.tech?.techString??'-'}}</td>
          }
        </tr>
        }

        @if (data.deliveries.at(0)?.additional!=undefined&&data.deliveries.at(0)!.additional!.length!>0)
        {
        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_additional'|translation:'Additional'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.additional??'-'}}</td>
          }
        </tr>
        }


        @for (attribute of tableRows; track i;let i = $index)
        {
        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{attribute.replace('_',' ')|titlecase}}</b>
          </td>
          @for (delivery of data.deliveries; track d;let d= $index)
          {

          @if (attribute.strEq("beneficiary")||attribute.strEq("power_plant"))
          {
          <td nzAlign="center" [width]="50">
            {{ cpNames[delivery.fields[attribute] ?? '']|titlecase}}</td>
          }
          @else
          {
          <td nzAlign="center" [width]="50">
            {{delivery.fields[attribute] ?? '-'}}</td>
          }
          }
        </tr>
        }

        @if(data.deliveries.at(0)?.counterpartyId!=undefined)
        {

        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_counterparty'|translation:'Counterparty'}}</b>
          </td>
          @for (delivery of data.deliveries; track d;let d= $index)
          {

          <td nzAlign="center" [width]="50">
            {{cpNames[delivery.counterpartyId??""]|titlecase }}</td>

          }
        </tr>
        }

        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_volume'|translation:'Volume'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.volume??'-'}}</td>
          }
        </tr>

        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_price'|translation:'Price'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.price??'-'}}</td>
          }
        </tr>


        <tr>
          <td nzAlign="center" [width]="50">
            <b>{{'delivery_currency'|translation:'Currency'}}</b>
          </td>
          @for (delivery of data.deliveries; track $index)
          {
          <td nzAlign="center" [width]="50">
            {{delivery.currency??'-'}}</td>
          }
        </tr>


      </tbody>
    </nz-table>

  </div>
</div>
<div class="ps-col-12 ps-d-flex ps-justify-content-center">
  <div class="ps-col-2">
    <button (click)="cancel()" [nzTooltipTitle]='"back_button" | translation: "Cancel"' nz-tooltip nz-button
      class="clear-button">
      <ng-container>{{ 'go_back_button' | translation: 'Back': []}}</ng-container>
    </button>
  </div>
  <div class="ps-col-2">
    <button (click)="confirm()" [nzTooltipTitle]='"save_deal_ticket" | translation: "Save"' nz-tooltip nz-button
      class="save-button">
      <ng-container>{{ 'save_deal_ticket_button' | translation: 'Save': []}}</ng-container>
    </button>
  </div>
</div>
