import { Component, OnInit } from '@angular/core';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleTranslationPipe, dashboardButton, dashboardLocalSelect } from 'purple-lib';
import { Subject, lastValueFrom } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ExchangeRateCurrency, ExchangeRateCurrencyRange, StellaService } from 'src/core-modules/sdk/api';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss']
})
export class ExchangeRateComponent implements OnInit {

  constructor(private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private apiProxySvc: PurpleApiProxyService, private tsvc: AppTranslationService, private notificationSvc: NzNotificationService) { }
  sub: Subject<void> = new Subject();

  currentCurrencyFrom = "EUR";
  currentDefaultRange = 365;
  exchanges: Array<ExchangeRateCurrencyExtended> = [];
  overrideCurrencyRanges: ExchangeRateCurrencyRange[] = [];


  currencyColorMap: Map<string, string> = new Map<string, string>([
    ["EUR", "#FF3B304D"],
    ["GBP", "#5856D64D"],
    ["USD", "#3F8B904D"],
    ["CHF", "#007AFF4D"],
    ["SEK", "#FF95004D"]
  ]);

  currencyFromSelectValues: { label: string; value: any; }[] = [
    {
      label: "EUR",
      value: "EUR"
    },
    {
      label: "GBP",
      value: "GBP"
    },
    {
      label: "USD",
      value: "USD"
    },
    {
      label: "CHF",
      value: "CHF"
    },
    {
      label: "SEK",
      value: "SEK"
    }
  ]

  currencyDefaultRangesValues: { label: string; value: any; }[] = [
    {
      label: this.tranPipe.transform('5_day', '5 Days', []),
      value: 5
    },
    {
      label: this.tranPipe.transform('1_mon', '1 Month', []),
      value: 30
    },
    {
      label: this.tranPipe.transform('1_yea', '1 Year', []),
      value: 365
    }
  ]


  async ngOnInit() {
    await this.reloadExchengeValues();
  }

  async reloadExchengeValues() {
    await this.apiProxySvc.makeRequestErrorPage<Array<ExchangeRateCurrency>>(() => this.admSvc.getExchangeRates(this.tsvc.currentLanguage.value, {
      currencyFrom: this.currentCurrencyFrom,
      globalDayRange: this.currentDefaultRange,
      overrideCurrencyRanges: this.overrideCurrencyRanges
    }), true, "internal-loader", 500, "internal-error", async () => { await this.reloadExchengeValues() }, (res: PurpleApiMakeRequestResponse<Array<ExchangeRateCurrency>>) => {

      this.exchanges = (res.data ?? []).map(m => {
        return {
          currencyFrom: m.currencyFrom,
          currencyTo: m.currencyTo,
          dayRange: m.dayRange,
          exchangeRateCurrencyValues: m.exchangeRateCurrencyValues,
          graphData: [
            {
              "name": m.currencyTo,
              "series": m.exchangeRateCurrencyValues.map(m1 => {
                return {
                  "name": m1.dateString,
                  "value": m1.value
                }
              })
            }
          ],
          currentShowNumber: m.exchangeRateCurrencyValues[0]?.value ?? 0,
          rangeValue: this.overrideCurrencyRanges?.find(f => f.currencyTo.strEq(m.currencyTo))?.dayRange ?? this.currentDefaultRange,
          graphColor: {
            name: 'custom',
            selectable: true,
            group: ScaleType.Ordinal,
            domain: [this.currencyColorMap.get(m.currencyTo)!]
          }
        }
      });

      this.dashSvc.dashMainItems = []

      this.dashSvc.dashMainItems.push(
        new dashboardButton("currency-eur", "EUR", async () => {
          this.currentCurrencyFrom = "EUR";

          this.overrideCurrencyRanges = [];
          await this.reloadExchengeValues();

        }, this.currentCurrencyFrom == "EUR" ? 'primary' : 'default', undefined, this.currentCurrencyFrom == "EUR" ? 'test-button' : undefined),
        new dashboardButton("currency-usd", "USD", async () => {
          this.currentCurrencyFrom = "USD";

          this.overrideCurrencyRanges = [];
          await this.reloadExchengeValues();

        }, this.currentCurrencyFrom == "USD" ? 'primary' : 'default', undefined, this.currentCurrencyFrom == "USD" ? 'test-button' : undefined),
        new dashboardButton("currency-gbp", "GBP", async () => {
          this.currentCurrencyFrom = "GBP";

          this.overrideCurrencyRanges = [];
          await this.reloadExchengeValues();

        }, this.currentCurrencyFrom == "GBP" ? 'primary' : 'default', undefined, this.currentCurrencyFrom == "GBP" ? 'test-button' : undefined),
        new dashboardButton("currency-chf", "CHF", async () => {
          this.currentCurrencyFrom = "CHF";

          this.overrideCurrencyRanges = [];
          await this.reloadExchengeValues();

        }, this.currentCurrencyFrom == "CHF" ? 'primary' : 'default', undefined, this.currentCurrencyFrom == "CHF" ? 'test-button' : undefined),

        new dashboardButton("currency-sek", "SEK", async () => {
          this.currentCurrencyFrom = "SEK";

          this.overrideCurrencyRanges = [];
          await this.reloadExchengeValues();

        }, this.currentCurrencyFrom == "SEK" ? 'primary' : 'default', undefined, this.currentCurrencyFrom == "SEK" ? 'test-button' : undefined),
         /*new dashboardLocalSelect("currency-from-select", async (id: string) => {
          this.currentCurrencyFrom = id;

          this.overrideCurrencyRanges = [];
          await this.reloadExchengeValues();
        },
          this.currencyFromSelectValues,
          this.currentCurrencyFrom,
          this.tranPipe.transform('select_currency_from_label', 'Valuta', []),
          this.tranPipe.transform('select_currency_from_placeholder', 'Valuta', []),
          true
        ),

        new dashboardLocalSelect("currency-default-range-select", async (id: number) => {
          this.currentDefaultRange = id;

          await this.reloadExchengeValues();
        },
          this.currencyDefaultRangesValues,
          this.currentDefaultRange,
          this.tranPipe.transform('select_default_range_label', 'Range', []),
          this.tranPipe.transform('select_default_range_placeholder', 'Range', []),
          true
        ),*/
        new dashboardButton(
          "download-excel",
          this.tranPipe.transform('download_exchange_rate_button', 'Esporta', []),
          async () => { await this.exportToExcel() },
          'primary',
          'export',
          undefined,
          'left'
        )
      )
    })


    console.log("Exchanges: ", this.exchanges)
  }

  async setOverrideRange(currencyTo: string, dayRange: number) {
    const idx = this.overrideCurrencyRanges.findIndex(f => f.currencyTo.strEq(currencyTo));

    if (idx === -1) {
      this.overrideCurrencyRanges.push({
        currencyTo: currencyTo,
        dayRange: dayRange
      });
    } else {
      this.overrideCurrencyRanges[idx].dayRange = dayRange;
    }

    this.exchanges.find(f => f.currencyTo.strEq(currencyTo))!.rangeValue = dayRange;

    await this.reloadExchengeValues();
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "stella_exchange_rate";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Exchange Rate', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Monitor the current and past exchange rates for the selected currencies', []))
  }

  async exportToExcel() {

    this.notificationSvc.blank(this.tranPipe.transform('export_excel_activity_download_message_title', 'Download file excel in corso...', []),
      this.tranPipe.transform('export_excel_activity_download_message_subtitle', 'La generazione e il download del file excel protrrebbe richiedere alcuni secondi', []), {
      nzKey: 'download-excel'
    });


    var res = await lastValueFrom(this.admSvc.exportExchangeRatesExcel(
      this.currentCurrencyFrom, this.currentDefaultRange, this.tsvc.currentLanguage.value));

    if (res) {
      const url = window.URL.createObjectURL(res);

      // Create a link
      let link = document.createElement('a');
      link.href = url;

      link.download = "exchange_rates.xlsx";

      // Trigger click event on the link
      document.body.appendChild(link); // Required for Firefox
      link.click();
      console.log("fACCIO LINK CLICK")
      document.body.removeChild(link); // Required for Firefox

      this.notificationSvc.success(this.tranPipe.transform('export_excel_activity_downloaded_message_title', 'Download file excel completato', []),
        this.tranPipe.transform('export_excel_activity_downloaded_message_subtitle', 'Il file excel è stato generato e scaricato correttamente', []), {
        nzKey: 'download-excel'
      });
    } else {
      this.notificationSvc.error(this.tranPipe.transform('export_excel_activity_error_message_title', 'Download file excel non riuscito', []),
        this.tranPipe.transform('export_excel_activity_error_message_subtitle', 'Non è stato possibile generare file excel', []), {
        nzKey: 'download-excel'
      });
    }


  }

}


export interface ExchangeRateCurrencyExtended extends ExchangeRateCurrency {
  graphData: any[];
  currentShowNumber: number;
  rangeValue: number;
  graphColor: Color;
}

