/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TemplatizeTabRequest { 
    tabTemplateId?: string | null;
    tabTemplateName?: string | null;
    tabId?: string | null;
    recipientId?: string | null;
    documentId?: string | null;
    tabLabel?: string | null;
    tabTypeId?: string | null;
    tabInitialValue?: string | null;
    tabIsRequired?: boolean | null;
    tabIsReadOnly?: boolean | null;
    tabPlacementFormFieldName?: string | null;
    tabPlacementAnchor?: string | null;
    tabPlacementPage?: number | null;
    tabPlacementOffsetXPts?: number | null;
    tabPlacementOffsetYPts?: number | null;
    tabPlacementWidth?: number | null;
    tabPlacementHeight?: number | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
    readonly spolloNullIsValue_TabTemplateName?: boolean;
    readonly spolloNullIsValue_TabId?: boolean;
    readonly spolloNullIsValue_RecipientId?: boolean;
    readonly spolloNullIsValue_DocumentId?: boolean;
    readonly spolloNullIsValue_TabLabel?: boolean;
    readonly spolloNullIsValue_TabTypeId?: boolean;
    readonly spolloNullIsValue_TabInitialValue?: boolean;
    readonly spolloNullIsValue_TabIsRequired?: boolean;
    readonly spolloNullIsValue_TabIsReadOnly?: boolean;
    readonly spolloNullIsValue_TabPlacementFormFieldName?: boolean;
    readonly spolloNullIsValue_TabPlacementAnchor?: boolean;
    readonly spolloNullIsValue_TabPlacementPage?: boolean;
    readonly spolloNullIsValue_TabPlacementOffsetXPts?: boolean;
    readonly spolloNullIsValue_TabPlacementOffsetYPts?: boolean;
    readonly spolloNullIsValue_TabPlacementWidth?: boolean;
    readonly spolloNullIsValue_TabPlacementHeight?: boolean;
}

