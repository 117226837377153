import { Component, Inject, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Helper, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeNotificationService, SystemEventPushNotificationTemplateItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/environment';
import { PurpleApiProxyService } from 'purple-lib';

@Component({
  selector: 'app-edit-push-notification-template',
  templateUrl: './edit-push-notification-template.component.html',
  styleUrls: ['./edit-push-notification-template.component.scss']
})
export class EditPushNotificationTemplateComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private data: {systemEventPushNotificationTemplate: SystemEventPushNotificationTemplateItem, systemEventName: string}, private mesSvc: NzMessageService, private backNotSvc: BackOfficeNotificationService, private tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe,
    private apiProxySvc: PurpleApiProxyService, private modalRef: NzModalRef, private fb: FormBuilder) { }
  newObj!: SystemEventPushNotificationTemplateItem;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  ngOnInit() {
    this.newObj = Helper.storeNewObj(this.data.systemEventPushNotificationTemplate);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'title',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_push_notification_template_title', 'Titolo Notifica', []),
            fieldPlaceholder: this.tranPipe.transform('edit_push_notification_template_title_placeholder', 'Inserisci qui il titolo della notifica', []),

            fieldValue: this.newObj.notificationTitle,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'senderName',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_push_notification_template_sender_name', 'Nome mittente Notifica', []),
            fieldPlaceholder: this.tranPipe.transform('edit_push_notification_template_sender_name_placeholder', 'Inserisci qui il nome del mittente della notifica', []),

            fieldValue: this.newObj.senderName,
            fieldIsRequired: true,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'subtitle',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_push_notification_template_subtitle', 'Sottotitolo Notifica', []),
            fieldPlaceholder: this.tranPipe.transform('edit_push_notification_template_subtitle_placeholder', 'Inserisci qui il sottotitolo della notifica', []),
            fieldValue: this.newObj.notificationSubTitle,
            fieldIsRequired: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'body',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_push_notification_template_body', 'Corpo Notifica', []),
            fieldPlaceholder: this.tranPipe.transform('edit_push_notification_template_body_placeholder', 'Inserisci qui il corpo della notifica', []),
            fieldOptions: [{ id: 'rowNumber', value: 5 }],
            fieldValue: this.newObj.notificationBody,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ];
  }


  async editPushNotification() {
    this.isLoading = true; for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.newObj.notificationTitle = this.validateForm.controls["title"].value;
      this.newObj.notificationSubTitle = this.validateForm.controls["subtitle"].value;
      this.newObj.notificationBody = this.validateForm.controls["body"].value;
      this.newObj.senderName = this.validateForm.controls["senderName"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.data.systemEventPushNotificationTemplate)) {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backNotSvc.updateSystemEventPushNotificationTemplate(this.tsvc.translationLanguage.value, {
          notificationBody: this.newObj.notificationBody,
          notificationSubTitle: this.newObj.notificationSubTitle,
          notificationTitle: this.newObj.notificationTitle,
          pk1: this.newObj.pk1,
          pk2: this.newObj.pk2,
          senderName: this.newObj.senderName,
          systemEventId: this.newObj.systemEventId
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success(this.tranPipe.transform('update_success', 'Aggiornato con successo', []), {
            nzDuration: environment.MESSAGE_DURATION,
          });
          this.data.systemEventPushNotificationTemplate = this.newObj;
          this.newObj = Helper.storeNewObj(this.data.systemEventPushNotificationTemplate);
          this.modalRef.close();
        })
      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata'), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }

    }
    this.isLoading = false
  }

  copyToClipboard(event: Event, textToCopy: string) {
    event.stopPropagation();
    this.mesSvc.info(this.tranPipe.transform('edit_mail_template_copy_to_clipboard', 'Replacer copiato negli appunti', []))

    navigator.clipboard.writeText("{{" + textToCopy + "}}");
  }

}

