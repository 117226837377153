import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Helper, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { NotificationModeSystemEventExceptionTableItem, BackOfficeNotificationService } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-notification-exception',
  templateUrl: './edit-notification-exception.component.html',
  styleUrls: ['./edit-notification-exception.component.scss']
})
export class EditNotificationExceptionComponent implements OnInit, AfterViewInit, OnDestroy {
  originalEditItem!: editNotificationExceptionItem;
  newObj!: editNotificationExceptionItem;
  isNew: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  refreshNotModeOpt: number = 0;
  refreshSysEvent: number = 0;
  refreshFormFields: string[] = [];
  sub: Subject<void> = new Subject();

  constructor(@Inject(NZ_MODAL_DATA) public notificationExceptionFull: NotificationModeSystemEventExceptionTableItem | undefined, private modalRef: NzModalRef, 
  private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private backNotSvc: BackOfficeNotificationService, private tsvc: AppTranslationService, private fb: FormBuilder) { }
  
  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
  }

  async ngOnInit(): Promise<void> {
    if (this.notificationExceptionFull == undefined) {
      this.originalEditItem = {
        systemEventId: "",
        notificationModeIds: []
      }

      this.isNew = true;
    } else {
      this.originalEditItem = {
        systemEventId: this.notificationExceptionFull.systemEventId,
        notificationModeIds: this.notificationExceptionFull.notificationMode.map(m => m.notificationModeId)
      }
    }

    this.newObj = Helper.storeNewObj(this.originalEditItem)
    console.log("ORIGINAL: ", this.newObj)

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'systemEvent',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_system_event_notification_exception_system_event', 'Evento di Sistema', []),
            fieldPlaceholder: this.tranPipe.transform('edit_system_event_notification_exception_system_event_placeholder', 'Seleziona un evento di sistema', []),

            fieldOptions: [
              { id: 'scrollFunction', value: this.systemEventsScrollFunction },
              { id: 'searchFunction', value: this.systemEventsSearchFunction },
              { id: 'searchByIdFunction', value: this.systemEventsSearchByIdFunction },
              { id: 'refreshFieldNumber', value: this.refreshSysEvent }

            ],
            fieldValue: this.newObj.systemEventId,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select-multiple',
            fieldControlName: 'notificationModes',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_system_event_notification_exception_notification_modes', 'Modalità Notifica', []),
            fieldPlaceholder: this.tranPipe.transform('edit_system_event_notification_exception_notification_modes_placeholder', 'Seleziona uno o più modalità di notifica', []),
            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.sysEventExceptionModesScrollFunction },
              { id: 'searchFunction', value: this.sysEventExceptionModesSearchFunction },
              { id: 'searchByIdFunction', value: this.sysEventExceptionModesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: this.refreshNotModeOpt }
            ],
            fieldValue: this.newObj.notificationModeIds,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ];

    setTimeout(() => {
      this.validateForm.controls["systemEvent"].valueChanges.pipe(takeUntil(this.sub)).subscribe(s => {
        if(!this.isLoading){
          console.log("not modes ha cambiato valore: ", s)
          this.newObj.systemEventId = s;
          this.refreshFormFields = ["notificationModes"];
        }
      }); 
    }, 100);
  }

  ngAfterViewInit(): void {

  }

  //ScrollFunction
  systemEventsScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "ExcludeAlreadyUsedSysEvent", filterValues: ["true"] }
    ]
    return this.backNotSvc.getActiveSystemEventsSelect(args.culture, args)
  }
  //SearchFunction
  systemEventsSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "ExcludeAlreadyUsedSysEvent", filterValues: ["true"] }
    ]
    return this.backNotSvc.getActiveSystemEventsByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  systemEventsSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "ExcludeAlreadyUsedSysEvent", filterValues: ["true"] }
    ]
    return this.backNotSvc.getActiveSystemEventsByIdSelect(args.culture, args)
  }

  //ScrollFunction
  sysEventExceptionModesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "systemEventId", filterValues: [String.prototype.isNullOrEmpty(this.newObj.systemEventId) ? "V" : this.newObj.systemEventId] }
    ]
    return this.backNotSvc.getSystemEventNotificationModesSelect(args.culture, args)
  }
  //SearchFunction
  sysEventExceptionModesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "systemEventId", filterValues: [String.prototype.isNullOrEmpty(this.newObj.systemEventId) ? "V" : this.newObj.systemEventId] }
    ]
    return this.backNotSvc.getSystemEventNotificationModesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  sysEventExceptionModesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "systemEventId", filterValues: [String.prototype.isNullOrEmpty(this.newObj.systemEventId) ? "V" : this.newObj.systemEventId] }
    ]
    return this.backNotSvc.getSystemEventNotificationModesByIdsSelect(args.culture, args)
  }


  async editNotificationException() {
    this.isLoading = true;

    this.newObj.systemEventId = this.validateForm.controls["systemEvent"].value;
    this.newObj.notificationModeIds = this.validateForm.controls["notificationModes"].value;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      console.log("Check: ", Helper.checkIfDoChanges(this.newObj, this.originalEditItem))
      if (Helper.checkIfDoChanges(this.newObj, this.originalEditItem)) {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backNotSvc.addOrUpdateSystemEventNotificationException(this.tsvc.currentLanguage.value, {
          systemEventId: this.newObj.systemEventId,
          notificationModes: this.newObj.notificationModeIds,
          isNew: this.isNew
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_system_event_notification_exception_add', 'Eccezione aggiunta con successo', [])
          } else {
            text = this.tranPipe.transform('edit_system_event_notification_exception_update', 'Eccezione aggiornata con successo', [])
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(res.data);
        });

      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION
        });
      }
    } 

    this.isLoading = false;
  }

}

export interface editNotificationExceptionItem {
  systemEventId: string;
  notificationModeIds: string[];
}