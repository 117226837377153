/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NotificationModeExceptionStatusEnum = 'enable' | 'systemDisable' | 'adminDisable';

export const NotificationModeExceptionStatusEnum = {
    Enable: 'enable' as NotificationModeExceptionStatusEnum,
    SystemDisable: 'systemDisable' as NotificationModeExceptionStatusEnum,
    AdminDisable: 'adminDisable' as NotificationModeExceptionStatusEnum
};

