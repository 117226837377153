/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventReplacer } from './eventReplacer';


export interface SystemEventPushNotificationTemplateItem { 
    pk1: string;
    pk2: string;
    systemEventId: string;
    notificationTitle: string;
    notificationSubTitle: string;
    notificationBody: string;
    senderName: string;
    isActive: boolean;
    timeStamp: string;
    languageId: string;
    isTranslated: boolean;
    isActiveLoc?: boolean | null;
    timeStampLoc?: string | null;
    systemEventReplacers: Array<EventReplacer>;
}

