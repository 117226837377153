import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-purple-this-or-that',
  templateUrl: './purple-this-or-that.component.html',
  styleUrls: ['./purple-this-or-that.component.scss']
})
export class PurpleThisOrThatComponent implements OnInit {


  constructor() { }

  @Input() text!: string[];
  @Input() buttonNumber!: number;


  @Output() clickBtn = new EventEmitter();

  @Input() disabled = false;
  @Input() isEditable = false;


  @Input() selected = 0;
  ngOnInit() {
  }

  clckBtn(idx: number) {
    if (idx != this.selected) {
      this.selected = idx;

      this.clickBtn.emit(idx)
    }
  }
}
