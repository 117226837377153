/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiAddOrUpdateCounterPartyRequest { 
    isNew: boolean;
    counterPartyId: string;
    counterPartyName?: string | null;
    counterPartyShortName?: string | null;
    contactUserId?: string | null;
    origins?: Array<string> | null;
    originClusters?: Array<string> | null;
    creditLimitFrom?: number | null;
    creditLimitTo?: number | null;
    note?: string | null;
}

