<ng-container *ngIf="exceptionStatus != undefined">
  <ng-container *ngFor="let es of exceptionStatus">
    <container-element [ngSwitch]="es.notificationModeStatus">
      <ng-container *ngSwitchCase="'systemDisable'">
        <nz-alert nzShowIcon class="ps-mb-2" nzCloseable nzType="error"
          [nzMessage]="'notification_mode_exception_admin_disable_error' | translation: '{0} non disponibili': [es.notificationModeName]"></nz-alert>
      </ng-container>
      <ng-container *ngSwitchCase="'adminDisable'">
        <nz-alert nzShowIcon class="ps-mb-2" nzCloseable nzType="warning" [nzAction]="warningTemplate"
          [nzMessage]="'notification_mode_exception_admin_disable_warning' | translation: '{0} disabilitate': [es.notificationModeName]"></nz-alert>
        <ng-template #warningTemplate>
          <button nz-button nzSize="small" nzType="primary" nzGhost
            (click)="enableNotificationMode(es.notificationModeId, es.notificationModeName)">{{
            'notification_mode_exception_admin_enable_button' | translation: 'Abilita': []}}</button>
        </ng-template>
      </ng-container>
    </container-element>
  </ng-container>
</ng-container> 


<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2" 
    [showCustomPagination]="true" >
    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.systemEventName}}</td>
          <td>{{ data.item.systemEventDescription}}</td>
          <td>
            <ng-container *ngFor="let nm of data.item.notificationMode">
              <nz-tag class="ps-mt-2" purpleStringToColor [word]="nm.notificationModeId" [opacity]="10">
                {{nm.notificationModeName}}
              </nz-tag>
            </ng-container>
          </td>

          <td nzAlign="center">
            <button (click)="editSystemEventNotificationModes(data.item!)"
              [nzTooltipTitle]="'notification_exception_table_exception_edit' | translation: 'Modifica Eccezioni': []"
              nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon
                class="fsp-18-0" nzType="edit"></i>
            </button>

            <button (click)="deleteSystemEventNotificationModes(data.item!)" class="ps-mr-3"
              [nzTooltipTitle]='"notification_exception_table_exception_delete" | translation: "Rimuovi Eccezioni"'
              nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon class="fsp-18-0" nzType="delete"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>