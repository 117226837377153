import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Helper, PurpleApiProxyService, PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ClusterMapGrouped, StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormStyle, TranslationWidth, getLocaleMonthNames } from '@angular/common';

@Component({
  selector: 'app-vintage-month-cluster',
  templateUrl: './vintage-month-cluster.component.html',
  styleUrls: ['./vintage-month-cluster.component.scss']
})
export class VintageMonthClusterComponent implements OnInit {
  [x: string]: any;

  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(NZ_MODAL_DATA) private modalVintage: VintageFull | undefined, private apiProxySvc: PurpleApiProxyService, private tranPipe: PurpleTranslationPipe, private loaderSvc: PurpleLoaderService,
    private tsvc: AppTranslationService, private admSvc: StellaService, private msgSvc: NzMessageService, private modalRef: NzModalRef) { }


  clusterMaps: ClusterMapGrouped[] = [];

  monthList = getLocaleMonthNames(this.locale, FormStyle.Standalone, TranslationWidth.Abbreviated);
  selectedMonths: Array<{ [key: number]: boolean }> = [{}];
  selectedCluster: Array<{ [key: string]: boolean }> = [{}];

  y: Array<VintageYearString> = [];

  vintages: Array<VintageObj> = [{
    year: new Date().getFullYear(),
    clusters: [],
    months: []
  }];

  years: Date[] = [new Date()];

  vintageFullString: string | undefined;

  panels = [
    {
      active: true
    }
  ];

  disabledYears: number[] = [];

  totalPanelNumber: number = 0;
  activePanel: number = 0;
  show: boolean = false;

  async ngOnInit() {


    await this.getClusters();

    if (this.modalVintage != undefined) {

      this.vintageFullString = this.modalVintage.vintageString!;

      for (let idx = 0; idx < this.modalVintage.vintages!.length; idx++) {

        if (idx == 0) {
          this.vintages![0] = this.modalVintage.vintages!![0];
        }

        if (idx > 0) {
          this.addVintage(this.modalVintage.vintages![idx]);
        }

        if (this.vintages[idx].months != undefined && this.vintages[idx].months!.length > 0)
          this.vintages[idx].months!.forEach(month => {
            //this.selectedMonths[idx][month - 1] = true;

            this.onRowClickMonth(idx, month - 1);
          });


        if (this.vintages[idx].clusters != undefined && this.vintages[idx].clusters!.length > 0)
          this.vintages[idx].clusters!.forEach(cluster => {
            this.selectedCluster[idx][cluster] = true;
            //this.onRowClickCluster(idx, cluster);
          })

      }

    }

    this.convertVintage()

  }


  async getClusters() {
    await this.apiProxySvc.makeRequestErrorFunction<ClusterMapGrouped[]>(() => this.admSvc.getVintageClusterMap(this.tsvc.currentLanguage.value),
      true,
      "internal-loader",
      100,
      () => this.getClusters(), (res) => {
        this.clusterMaps = res.data!
        this.show = true;
      })
  }


  closeAllPanel(idx: number) {

    if (idx != this.activePanel) {
      this.panels[this.activePanel]!.active = false;

    }
    this.activePanel = idx;
    this.panels[this.activePanel]!.active = true;

  }


  disabledDate = (current: Date): boolean => {

    if (this.vintages.map(m => m.year).some(y => y != undefined && y! == current.getFullYear()))
      return true;

    return false;

  };

  onRowClickMonth(panelidx: number, index: number) {

    var checked = this.selectedMonths[panelidx][index] != undefined ? !this.selectedMonths[panelidx][index] : true;
    this.onChangeMonth(panelidx, index, checked);

  }

  onChangeMonth(panelidx: number, month: number, checked: boolean) {

    this.selectedMonths[panelidx][month] = checked;

    if (checked) {
      this.vintages[panelidx].months != undefined ? this.vintages[panelidx].months!.push(month + 1) : this.vintages[panelidx].months = [month + 1];
    }
    else {

      this.vintages[panelidx].months = this.vintages[panelidx].months!.filter(m => m !== month + 1);
    }

    var m = this.clusterMaps.find(s => s.vintageMonths?.includes(month + 1))!;

    this.updateClustersValues(panelidx, m);

  }


  onRowClickCluster(panelidx: number, index: string) {

    var checked = this.selectedCluster[panelidx][index] != undefined ? !this.selectedCluster[panelidx][index] : true;
    this.onChangeCluster(panelidx, index, checked);


  }

  onChangeCluster(panelidx: number, cluster: string, checked: boolean) {

    this.selectedCluster[panelidx][cluster] = checked;

    if (checked) {
      if (!this.vintages[panelidx].clusters?.includes(cluster))
        this.vintages[panelidx].clusters?.push(cluster);
    }
    else {

      this.vintages[panelidx].clusters = this.vintages[panelidx].clusters!.filter(c =>
        c !== cluster
      );
    }

    var m = this.clusterMaps.find(s => s.vintageMonthClsuterName?.strEq(cluster))!;

    this.updateMonthsValues(panelidx, m, checked);

  }


  updateClustersValues(panelidx: number, clusterMap: ClusterMapGrouped) {

    var allClusterMonthSelected = true;

    clusterMap.vintageMonths!.forEach(n => {
      if (this.selectedMonths[panelidx][n - 1] != true) {
        allClusterMonthSelected = false;
      }
    });

    this.selectedCluster[panelidx][clusterMap.vintageMonthClsuterName!] = allClusterMonthSelected;

    if (allClusterMonthSelected) {

      if (!this.vintages[panelidx].clusters?.includes(clusterMap.vintageMonthClsuterName!))
        this.vintages[panelidx].clusters?.push(clusterMap.vintageMonthClsuterName!);
    }
    else {

      this.vintages[panelidx].clusters = this.vintages[panelidx].clusters!.filter(c =>
        c !== clusterMap.vintageMonthClsuterName
      );
    }
    this.convertVintage();
  }

  updateMonthsValues(panelidx: number, clusterMap: ClusterMapGrouped, checked: boolean) {
    clusterMap.vintageMonths!.forEach(n => {
      this.selectedMonths[panelidx][n - 1] = checked;
      if (checked) {
        if (!this.vintages[panelidx].months!.includes(n))
          this.vintages[panelidx].months?.push(n)
      }
      else {
        this.vintages[panelidx].months = this.vintages[panelidx].months!.filter(month => month !== n);

      }
    });

    this.convertVintage();
  }

  saveVintage() {


    var returnValue: VintageFull =
    {
      vintages: this.vintages,
      vintageString: this.vintageFullString
    }

    this.modalRef.destroy(returnValue);

  }

  addVintage(vintage: VintageObj | undefined) {

    if (this.vintages[this.activePanel].year == undefined) {
      this.msgSvc.error("You need to select year!");
      return;
    }

    this.panels.at(this.activePanel)!.active = false;

    this.totalPanelNumber++;
    this.activePanel = this.totalPanelNumber;
    this.panels.push(
      {
        active: true
      })

    this.selectedMonths.push({});
    this.selectedCluster.push({});

    this.vintages.push(vintage != undefined ? vintage :
      {
        clusters: [],
        months: [],
        year: undefined
      })
  }

  deleteVintage(idx: number) {


    if (this.vintages.length == 1) {
      return;
    }

    this.closeAllPanel(0);
    this.show = false;

    this.panels = this.panels.slice(0, idx).concat(this.panels.slice(idx + 1));
    this.vintages = this.vintages.slice(0, idx).concat(this.vintages.slice(idx + 1));

    this.selectedMonths = [{}];
    this.selectedCluster = [{}];

    this.totalPanelNumber = this.panels.length - 1;

    for (let index = 0; index < this.vintages.length; index++) {

      this.selectedMonths[index] = {}
      if (this.vintages[index].months != undefined && this.vintages[index].months!.length > 0)
        this.vintages[index].months!.forEach(month => {
          this.selectedMonths[index][month - 1] = true;
        });

      this.selectedCluster[index] = {}
      if (this.vintages[index].clusters != undefined && this.vintages[index].clusters!.length > 0)
        this.vintages[index].clusters!.forEach(cluster => {
          this.selectedCluster[index][cluster] = true;
        })


    }

    this.show = true;

    this.convertVintage();
    this.panels[this.activePanel]!.active = false;


  }

  clearPanel(index: number) {

    this.vintages[index].months = [];
    this.vintages[index].clusters = [];

    this.selectedMonths[index] = {}
    /* if (this.vintages[index].months != undefined && this.vintages[index].months!.length > 0)
      this.vintages[index].months!.forEach(month => {
        this.selectedMonths[index][month - 1] = true;
      }); */

    this.selectedCluster[index] = {}
    /* if (this.vintages[index].clusters != undefined && this.vintages[index].clusters!.length > 0)
      this.vintages[index].clusters!.forEach(cluster => {
        this.selectedCluster[index][cluster] = true;
      }) */


    this.convertVintage();
  }

  clearAll() {
    this.closeAllPanel(0);
    this.panels = [
      {
        active: true
      }
    ];
    this.vintages = [{
      year: 0,
      clusters: [],
      months: []
    }];

    this.selectedMonths = [{}];
    this.selectedCluster = [{}];

    this.totalPanelNumber = 0;
    this.convertVintage();
  }


  convertVintage() {

    this.y = [];

    if (this.vintages == undefined) {
      return;
    }


    for (let i = 0; i < this.vintages.length; i++)
      this.vintages[i].year = this.years[i].getFullYear();

    var tempVintages = Helper.storeNewObj(this.vintages);

    tempVintages.sort(this.compareByYear);

    // Ciclo sugli anni
    tempVintages.forEach(vintage => {

      if (vintage.year == undefined)
        return;

      const resTemp: string[] = [];

      const count = vintage.months?.length == 0 || vintage.months?.length == 12;



      // Se ho l'anno intero inserisco solo la chiave senza
      if (count) {
        this.y.push({ year: vintage.year!, months: [] });
        return;
      }

      // Estraggo i mesi in quell'anno
      const temp = vintage!.months!;

      // Ciclo per tutti i cluster per controllare ogni mese
      this.clusterMaps.forEach(c => {
        const containsAll = c.vintageMonths!.every(month => temp.includes(month));
        if (containsAll) {
          resTemp.push(c.vintageMonthClsuterName!);
        } else {
          const contained = temp.filter(s => c.vintageMonths!.includes(s)).sort();
          resTemp.push(...contained.map(i => i.toString()));
        }
      });

      const res: string[] = [];

      // Aggiungo i separatori + e - ai cluster e ai mesi
      resTemp.forEach((toCheck, index) => {
        if (index > 0) {
          const end = res[res.length - 1];
          if (end !== "+") {
            const isCluster = end.includes('Q');
            const m1 = isCluster
              ? this.clusterMaps.filter(w => w.vintageMonthClsuterName === end)
                .map(s => s.vintageMonths)
                .pop()
                ?.reduce((a, b) => Math.max(a, b))
              : parseInt(end);
            const isCluster2 = toCheck.includes('Q');
            const m2 = isCluster2
              ? this.clusterMaps.filter(w => w.vintageMonthClsuterName === toCheck)
                .map(s => s.vintageMonths)
                .shift()
                ?.reduce((a, b) => Math.min(a, b))
              : parseInt(toCheck);

            res.push(m1 === m2! - 1 ? "-" : "+");
          }
        }
        res.push(toCheck);
      });

      // Rimuovo stringhe nel caso siano comprese tra due -
      for (let i = 0; i < res.length; i++) {
        if (res[i] != "-" || i >= res.length - 2) continue;
        if (res[i + 2] != "-") continue;
        res.splice(i + 1, 2);
        i--;

      }

      // Aggiungo l'anno con le stringhe corrispondenti
      this.y.push({ year: vintage.year!, months: res });
    });

    var tempString: string[] = [];

    // Ciclo su tutti gli oggetti anno-stringhe
    for (let i = 0; i < this.y.length; i++) {
      if (i === this.y.length - 1) {
        tempString.push(...this.y[i].months!);
        break;
      }

      if (this.y[i].year + 1 < this.y[i + 1].year) {
        tempString.push(...this.y[i].months!);
        tempString.push(this.y[i].year.toString());
        tempString.push("+");
        continue;
      }

      const last = (this.y[i].months != undefined && this.y[i].months!.length > 0 && this.y[i].months![this.y[i].months!.length - 1] != undefined) ? this.y[i].months![this.y[i].months!.length - 1] : "12";

      const isCluster = last.includes('Q');
      const l = isCluster
        ? this.clusterMaps.filter(w => w.vintageMonthClsuterName === last)
          .map(s => s.vintageMonths)
          .pop()
          ?.reduce((a, b) => Math.max(a, b))
        : parseInt(last);

      if (l == 12) {
        const first = (this.y[i + 1].months != undefined && this.y[i + 1].months!.length > 0 && this.y[i + 1].months![0] != undefined) ? this.y[i + 1].months![0] : "1";
        const isCluster2 = first.includes('Q');
        const l2 = isCluster2
          ? this.clusterMaps.filter(w => w.vintageMonthClsuterName === first)
            .map(s => s.vintageMonths)
            .shift()
            ?.reduce((a, b) => Math.min(a, b))
          : parseInt(first);


        if (l2 == 1) {
          if ((this.y[i].months != undefined && this.y[i].months!.length > 0) && this.y[i].months!.length >= 3) {
            const split = this.y[i].months![this.y[i].months!.length - 2] == "-";
            if (split) {
              this.y[i].months!.pop();
              this.y[i].months!.pop();
            }
          }

          this.y[i].months!.push(this.y[i].year.toString());
          this.y[i].months!.push("-");

          if (this.y[i + 1].months != undefined && this.y[i + 1].months!.length >= 3) {
            const split2 = this.y[i + 1].months![1] === "-";
            if (split2) {
              this.y[i + 1].months!.shift();
              this.y[i + 1].months!.shift();
            }
          }

          tempString.push(...this.y[i].months!);
        }
        else {
          tempString.push(...this.y[i].months!);
          tempString.push(this.y[i].year.toString());
          tempString.push("+");
          continue;
        }
      }
      else {
        tempString.push(...this.y[i].months!);
        tempString.push(this.y[i].year.toString());
        tempString.push("+");
      }

    }

    tempString.push(this.y[this.y.length - 1].year.toString());


    tempString.forEach((value, i) => {
      try {
        const month = parseInt(value);
        if (month >= 1 && month <= 12) {
          tempString[i] = new Date(Date.UTC(new Date().getUTCFullYear(), month - 1, 1))
            .toLocaleString('default', { month: 'short' })
            .toUpperCase();
        }
      } catch (e) {
        // Ignore
      }
    });

    for (let i = 0; i < tempString.length; i++) {

      if (tempString[i] !== "-" || i >= tempString.length - 2) continue;
      if (tempString[i + 2] !== "-") continue;
      var tempString2 = Helper.storeNewObj(tempString);
      var splice1 = tempString.splice(0, i + 1);
      var splice2 = tempString2.slice(i + 3);
      tempString = splice1.concat(splice2);
      i--;

    }


    this.vintageFullString = tempString.join(" ");


  }



  toDate(year: number) {
    new Date(year)

  }

  compareByYear(a: VintageObj, b: VintageObj): number {
    if (a.year && b.year) {
      return a.year - b.year;
    }
    if (!a.year && b.year) {
      return 1;
    }
    if (a.year && !b.year) {
      return -1;
    }
    return 0;
  }

}



export interface VintageObj {
  months: number[] | null | undefined;
  clusters: string[] | undefined;
  year: number | null | undefined;
}

interface VintageYearString {
  year: number;
  months: string[] | undefined;
}

export interface VintageFull {
  vintages: VintageObj[] | undefined;
  vintageString: string | null | undefined;
}
