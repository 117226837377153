import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PurpleTranslationPipe, purpleFormFieldsGroup, PurpleSelectFilter, PurpleApiProxyService, dashboardButton } from 'purple-lib';
import { Subject } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { BackOfficeUsersService, CounterPartyRow, DuplicateDeal, DuplicateDealPurpleApiResponse, StellaService } from 'src/core-modules/sdk/api';
import { Deliveries, Delivery } from './modals/deal-ticket-delivery/deal-ticket-delivery.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DealTicket } from './custom-component/deal-ticket-summary/deal-ticket-summary.component';
import { Step } from './custom-component/deal-ticket-stepper/deal-ticket-stepper.component';
import { OriginClusterComponent } from './modals/origin-cluster/origin-cluster.component';
import { ActivatedRoute, Params } from '@angular/router';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { VintageFull } from './modals/vintage-month-cluster/vintage-month-cluster.component';
import { EditCounterpartComponent } from '../../backoffice/modals/edit-counterpart/edit-counterpart.component';

@Component({
  selector: 'app-deal-ticket',
  templateUrl: './deal-ticket.component.html',
  styleUrls: ['./deal-ticket.component.scss']
})
export class DealTicketComponent implements OnInit, OnDestroy, AfterViewInit {



  constructor(private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private modal: NzModalService, private route: ActivatedRoute, private tsvc: AppTranslationService, private backUserSvc: BackOfficeUsersService, private apiProxySvc: PurpleApiProxyService) {
  }

  sub: Subject<void> = new Subject();

  dealTicket: DealTicket | undefined;

  duplicateDealId: string | undefined;


  stepIdx: number = 0;
  steps: Step[] = [
    {
      title: this.tranPipe.transform('deal_ticket_step_1_title', 'Deal type', []),
      description: this.tranPipe.transform('deal_ticket_step_1_description', 'Enter the type of deal that you want to register', [])
    },
    {
      title: this.tranPipe.transform('deal_ticket_step_2_title', 'Deal ad Deliveries details', []),
      description: this.tranPipe.transform('deal_ticket_step_2_description', 'Compile all the main details of the deal and  deliveries', [])
    },
    /* {
      title: this.tranPipe.transform('deal_ticket_step_3_title', 'Deliveries', []),
      description: this.tranPipe.transform('deal_ticket_step_3_description', 'Insert all the desired deliveries', [])
    }, */
    {
      title: this.tranPipe.transform('deal_ticket_step_3_title', 'Summary', []),
      description: this.tranPipe.transform('deal_ticket_step_3_description', 'Summary of Deal and Deliveries', [])
    }
  ]

  buySellTexts: string[] =
    [
      this.tranPipe.transform("deal_type_buy_lable", "Buy"),
      this.tranPipe.transform("deal_type_sell_lable", "Sell")
    ]

  thisOrThatText: string[] =
    [
      this.tranPipe.transform("this_or_that_yes", "Yes"),
      this.tranPipe.transform("this_or_that_no", "No")
    ]

  disableForm: boolean = false;

  deliveries: Delivery[] = [];


  tradersSearchFilter: PurpleSelectFilter[] = [
    { propertyName: "traders", filterValues: ['true'] }
  ];


  show: boolean = false;

  ngOnInit() {
    this.route.queryParams
      .subscribe(async (params: Params) => {
        this.duplicateDealId = params["transactionId"];

        if (this.duplicateDealId != undefined) {
          this.stepIdx++;
          await this.getFullInfo(this.duplicateDealId);
        }
        else {
          this.show = true;
        }


      });



  }

  ngOnDestroy(): void {

    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "stella_frontoffice_deal_ticket";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Deal ticket', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Start a new pre-deal or create a new deal ticket', []))
  }


  async getFullInfo(transactionId: string) {
    await this.apiProxySvc.makeRequestErrorFunction<DuplicateDeal>(() => this.admSvc.getDealInfoToDuplicate(this.tsvc.currentLanguage.value,
      {
        transactionId: transactionId
      }),
      true,
      "internal-loader",
      100,
      () => this.getFullInfo(transactionId), (res: DuplicateDealPurpleApiResponse | undefined) => {

        if (res != undefined && res.data != undefined) {
          this.dealTicket =
          {
            brokerCurrency: res.data?.brokerCurrency,
            brokerFee: res.data?.brokerFee,
            brokerId: res.data?.brokerId,
            companyId: res.data?.companyId,
            counterPartyId: res.data?.counterPartyId,
            dealBuySeller: res.data?.counterPartyTypeId?.strEq("seller") ? "buy" : "sell",
            dealDate: res.data?.transactionDate,
            fields: res.data?.fields!,
            portfolioId: res.data?.portfolioId,
            portfolioYear: new Date(res.data!.portfolioYear!, 1, 1),
            productIds: [],
            traderId: res.data.traderId
          };


          (res.data.deliveries != undefined ? res.data.deliveries : []).forEach(delivery => {

            var vintages: VintageFull = {
              vintages: [],
              vintageString: delivery?.vintage
            };



            for (const year in delivery?.vintageIncluded) {

              vintages.vintages?.push(
                {
                  clusters: [],
                  months: delivery?.vintageIncluded[year],
                  year: Number.parseInt(year)
                }
              )
            }

            var data: Delivery =
            {
              additional: delivery?.additionals,
              counterpartyId: delivery?.counterPartyId,
              currency: delivery?.currency,
              deliveryDate: delivery?.transactionDate,
              fields: delivery?.dynamicField!,
              origin:
              {
                clusters: delivery?.originClusters?.split("+"),
                originString: delivery?.origins,
                origin: delivery?.originsIncluded?.split("+"),
                toExclude: delivery?.originsExcluded?.split("+")
              },
              price: delivery?.totalPrice,
              tech: {
                clusters: delivery?.technologyClusters?.split("+"),
                technology: delivery?.technologyIncluded?.split("+"),
                techString: delivery?.technologies,
                toExclude: delivery?.technologyExcluded?.split("+")
              },
              vintageFull: vintages,
              volume: delivery?.transactionVolume,
              transactionId: delivery?.transactionId
            }

            this.deliveries?.push(data)
          });


        }

        this.show = true;
      })
  }

  nextStep() {

    if (this.stepIdx < 2)
      this.stepIdx++;

  }


  previousStep() {

    if (this.stepIdx > 0)
      this.stepIdx--;
  }


  saveDeal(event: DealTicket) {

    this.dealTicket = event;
    this.nextStep();

  }

}
