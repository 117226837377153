import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Helper, PurpleApiProxyService, PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ClusterMapGrouped, ClusterMapGroupedString, ContractualPathGrouped, StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-select-path',
  templateUrl: './select-path.component.html',
  styleUrls: ['./select-path.component.scss']
})
export class SelectPathComponent implements OnInit {


  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(NZ_MODAL_DATA) public path: string | undefined, private apiProxySvc: PurpleApiProxyService, private tranPipe: PurpleTranslationPipe, private loaderSvc: PurpleLoaderService,
    private tsvc: AppTranslationService, private admSvc: StellaService, private msgSvc: NzMessageService, private modalRef: NzModalRef) { }



  pathFullString: string | undefined = this.tranPipe.transform("no_path_selected_text", "No Path Selected");


  normalPath: ContractualPathGrouped | undefined;
  specialPath: ContractualPathGrouped | undefined;

  selectedPath: { [Key: string]: boolean } = {}
  selectedSpecials: { [Key: string]: boolean } = {}

  pathSel: string | undefined;
  specialSel: string | undefined;


  show: boolean = false;

  async ngOnInit() {

    await this.getPaths()
  }


  async getPaths() {
    await this.apiProxySvc.makeRequestErrorFunction<ContractualPathGrouped[]>(() => this.admSvc.getContractualPath(this.tsvc.currentLanguage.value),
      true,
      "internal-loader",
      100,
      () => this.getPaths(), (res) => {
        var paths = res.data!



        this.normalPath = paths.find(s => s.pathType?.strEq("Normal"))!;
        this.specialPath = paths.find(s => s.pathType?.strEq("Special"))!;
        this.show = true;
      })
  }


  onRowClickPath(path: string) {




    var checked = this.selectedPath[path] != undefined ? !this.selectedPath[path] : true;


    this.selectedPath[path] = checked;

    if (checked) {


      for (const key in this.selectedPath) {
        if (!key.strEq(path))
          this.selectedPath[key] = false;
      }
      this.pathSel = path
    } else {
      this.pathSel = undefined;


    }



    this.pathString()

  }



  onRowClickSpecial(special: string) {



    var checked = this.selectedSpecials[special] != undefined ? !this.selectedSpecials[special] : true;

    this.selectedSpecials[special] = checked;
    if (checked) {


      for (const key in this.selectedSpecials) {
        if (!key.strEq(special))
          this.selectedSpecials[key] = false;
      }


      this.specialSel = special;
    } else {
      this.specialSel = undefined;
    }



    this.pathString()
  }



  pathString() {
    if (this.pathSel != undefined && this.specialSel != undefined) {
      this.pathFullString = this.pathSel + "|" + this.specialSel
    }
    else if (this.pathSel != undefined) {
      this.pathFullString = this.pathSel
    } else if (this.specialSel != undefined) {
      this.pathFullString = this.specialSel
    }
    else
      this.pathFullString = undefined;
  }

  clearAll() {

    this.pathSel = undefined;
    this.specialSel = undefined;

    for (const key in this.selectedPath) {
      this.selectedPath[key] = false
    }
    for (const key in this.selectedSpecials) {
      this.selectedSpecials[key] = false
    }
  }

  savePath() {
    this.modalRef.destroy(this.pathFullString);
  }


}
