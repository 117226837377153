import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Helper, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormField, purpleFormFieldsGroup } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ApiAddOrUpdateDeliveryRequest, CounterPartyRow, StellaService, Vintages } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { VintageFull, VintageMonthClusterComponent } from '../vintage-month-cluster/vintage-month-cluster.component';
import { OriginClusterComponent, OriginFull } from '../origin-cluster/origin-cluster.component';
import { TechFull, TechnologyClusterComponent } from '../technology-cluster/technology-cluster.component';
import { CurrencyPipe } from '@angular/common';
import { SelectPathComponent } from '../select-path/select-path.component';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EditCounterpartComponent } from 'src/app/dashboard/stella/backoffice/modals/edit-counterpart/edit-counterpart.component';

@Component({
  selector: 'app-deal-ticket-delivery',
  templateUrl: './deal-ticket-delivery.component.html',
  styleUrls: ['./deal-ticket-delivery.component.scss']
})
export class DealTicketDeliveryComponent implements OnInit {



  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(NZ_MODAL_DATA) public data: { product: string, isBuy: boolean, parentTransactionId: string, delivery: Delivery | undefined, currency: string | undefined }, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private fb: FormBuilder, private modalRef: NzModalRef, private mesSvc: NzMessageService, private modal: NzModalService, private tsvc: AppTranslationService, private curr: CurrencyPipe, private apiProxySvc: PurpleApiProxyService) {
  }


  isNew: boolean = false;
  isLoading: boolean = false;
  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups1: purpleFormFieldsGroup[] | undefined;

  purpleFormFieldGroups2: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;


  dynamicFromGroups: FormGroup = this.fb.group({});

  tempDelivery!: Delivery;



  vintagePosition: string = "";
  originPosition: string = "";
  techPosition: string = "";

  totalSum: number = 0;


  show: boolean = false;


  transactionType: string = "Delivery";


  async ngOnInit() {




    if (this.data.delivery == undefined) {

      this.tempDelivery =
      {

        currency: "",
        deliveryDate: undefined,
        origin: {
          clusters: [],
          origin: [],
          toExclude: [],
          originString: ""
        },
        price: undefined,
        tech: {
          clusters: [],
          technology: [],
          techString: "",
          toExclude: []
        },
        vintageFull: {
          vintages: [{
            clusters: [],
            months: [],
            year: undefined
          }],
          vintageString: ""
        },
        volume: undefined,
        fields: {},
        additional: undefined,
        counterpartyId: undefined,
        transactionId: undefined
      }
      this.isNew = true;
    }
    else {
      this.tempDelivery =
      {
        additional: this.data.delivery.additional,
        currency: this.data.delivery.currency,
        deliveryDate: this.data.delivery.deliveryDate,
        origin: this.data.delivery.origin,
        price: this.data.delivery.price,
        tech: this.data.delivery.tech,
        vintageFull: this.data.delivery.vintageFull,
        volume: this.data.delivery.volume,
        fields: this.data.delivery.fields,
        counterpartyId: this.data.delivery.counterpartyId,
        transactionId: this.data.delivery.transactionId
      }

    }

    this.createFormDelivery(this.data.delivery == undefined ? undefined : this.tempDelivery);


    setInterval(() => {
      this.totalSum = 0;

      const price = this.validateForm.controls["price"].value;
      const volume = this.validateForm.controls["volume"].value;

      if (price != undefined && volume != undefined) {
        var currency = this.validateForm.controls["currency"].value;
        this.validateForm.controls["totalAmount"].setValue(this.calculateTotalAmount(price, volume, currency));
      }
    }, 200);

  }


  createFormDelivery(delivery: Delivery | undefined) {

    this.purpleFormFieldGroups1 =
      [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.dynamicFromGroups,
          formFields: this.generateFormDelivery(delivery)
        }
      ];


    this.purpleFormFieldGroups2 =
      [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.validateForm,

          formFields: [
            {
              fieldType: 'input-number',
              fieldControlName: 'volume',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_volume', 'Volume', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_volume_placeholder', 'Volume', []),
              fieldValue: delivery?.volume,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            {
              fieldType: 'input-number',
              fieldControlName: 'price',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_price', 'Price', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_price_placeholder', 'Price', []),
              fieldValue: delivery?.price != undefined ? Math.abs(delivery?.price) : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            {
              fieldType: 'select',
              fieldControlName: 'currency',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_currency', 'Currency', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_currency_placeholder', 'Currency', []),
              fieldValue: delivery?.currency != undefined ? delivery?.currency : this.data.currency,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [
                { id: 'scrollFunction', value: this.currenciesScrollFunction },
                { id: 'searchFunction', value: this.currenciesSearchFunction },
                { id: 'searchByIdFunction', value: this.currenciesSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'totalAmount',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_total_amount', 'Total Amount', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_total_amount_placeholder', 'Total Amount', []),
              fieldValue: this.calculateTotalAmount(delivery?.price, delivery?.volume, delivery?.currency),
              fieldIsRequired: false,
              fieldIsDisabled: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: true
            }
          ]
        }
      ];


    this.show = true
  }


  async save() {


    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    for (const i in this.dynamicFromGroups.controls) {
      this.dynamicFromGroups.controls[i].markAsDirty();
      this.dynamicFromGroups.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid && this.dynamicFromGroups.valid) {

      this.tempDelivery.currency = this.validateForm.controls["currency"].value ?? "";
      this.tempDelivery.price = this.validateForm.controls["price"].value ?? "";
      this.tempDelivery.volume = this.validateForm.controls["volume"].value ?? "";

      const ddate = (this.dynamicFromGroups.controls["deliveryDate"] != undefined && this.dynamicFromGroups.controls["deliveryDate"]?.value != undefined) ? new Date(this.dynamicFromGroups.controls["deliveryDate"]?.value) : undefined;

      this.tempDelivery.deliveryDate = ddate != undefined ? ddate.toIsoStringPurple() : undefined;


      this.tempDelivery.additional = this.dynamicFromGroups.controls["additional"] != undefined ? this.dynamicFromGroups.controls["additional"].value : undefined
      this.tempDelivery.counterpartyId = this.dynamicFromGroups.controls["counterparty"] != undefined ? this.dynamicFromGroups.controls["counterparty"].value : undefined



      var dFields: {
        [key: string]: string | null;
      } = {}

      for (const j in this.dynamicFromGroups.controls) {

        if (j.strEq("technology") && (this.data.product.strEq("cee") || this.data.product.strEq("tee"))) {
          console.log("tech", this.dynamicFromGroups.controls[j].value)
          this.tempDelivery.tech?.technology?.push(this.dynamicFromGroups.controls[j].value);

        }
        if (!j.strEq("deliveryDate") && !j.strEq("technology") && !j.strEq("origin") && !j.strEq("vintage") && !j.strEq("additional"))
          /* this.tempDelivery.fields[j]  */ dFields[j] = this.dynamicFromGroups.controls[j].value;
      }

      this.tempDelivery.fields = dFields;

      //this.modalRef.destroy(this.tempDelivery);

      if (Helper.checkIfDoChanges(this.data.delivery, this.tempDelivery)) {

        console.log("isNew", this.isNew)
        var v: Vintages[] = []
        this.tempDelivery?.vintageFull?.vintages?.forEach(vin => {

          var temp: Vintages =
          {
            vintageClsuters: vin.clusters,
            vintageMonths: vin.months,
            vintageYear: vin.year!
          }
          v.push(temp)
        });

        var ds: ApiAddOrUpdateDeliveryRequest =
        {
          additional: this.tempDelivery.additional,
          currency: this.tempDelivery.currency,
          deliveryDate: this.tempDelivery.deliveryDate!,
          deliveryVintages: v,
          dynamicFields: this.tempDelivery.fields,
          price: this.tempDelivery.price!,
          volume: this.tempDelivery.volume!,
          origins: this.tempDelivery.origin?.origin,
          originsClusters: this.tempDelivery.origin?.clusters,
          originsToExclude: this.tempDelivery.origin?.toExclude,
          technologies: this.tempDelivery.tech?.technology,
          technologiesClusters: this.tempDelivery.tech?.clusters,
          technologiesToExclude: this.tempDelivery.tech?.toExclude,
          counterPartyId: this.tempDelivery.counterpartyId,
          isBuyer: this.data.isBuy,
          isNew: this.isNew,
          parentTransactionId: this.data.parentTransactionId,
          portfolioId: this.data.product,
          transactionId: this.data.delivery?.transactionId,
          transactionType: this.transactionType.toLowerCase()
        }

        await this.apiProxySvc.makeRequestErrorMessage<any>(() => this.admSvc.addOrUpdateDelivery(this.tsvc.currentLanguage.value,
          ds
        ), false, "internal-loader", 500, undefined, environment.MESSAGE_DURATION, () => {
          this.mesSvc.success(this.tranPipe.transform("delivery_ok_insert", "{0} {1} correctly!", [this.transactionType, this.isNew ? "Added" : "Updated"]), {
            nzDuration: environment.MESSAGE_DURATION
          });

          this.modalRef.destroy(true);
        });

      }
    }
    this.isLoading = false;

  }


  selectVintage() {
    var da;


    if (this.tempDelivery != undefined && this.tempDelivery?.vintageFull != undefined) {

      da = this.tempDelivery!.vintageFull;

    }


    this.modal.create<VintageMonthClusterComponent, VintageFull | undefined>({
      nzContent: VintageMonthClusterComponent,
      nzData: da,
      nzTitle: this.tranPipe.transform("edit_delivery_vintage_modal_title", "Select Vintage", []),
      nzWidth: '800px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (res: VintageFull | undefined) => {



      if (res != undefined) {

        this.tempDelivery!.vintageFull = res;
      }


      this.dynamicFromGroups.controls["vintage"].setValue(this.tempDelivery?.vintageFull?.vintageString)

    })
  }



  selectOrigin() {

    var data: OriginFull | undefined = undefined;

    if (this.tempDelivery.origin != undefined) {

      data =
      {
        clusters: this.tempDelivery.origin?.clusters,
        origin: this.tempDelivery.origin?.origin,
        originString: this.tempDelivery.origin?.originString,
        toExclude: this.tempDelivery.origin?.toExclude
      };

    }


    this.modal.create<OriginClusterComponent, { modalOrigin: OriginFull | undefined, isCounterpart: boolean }>({
      nzContent: OriginClusterComponent,
      nzData: { modalOrigin: data, isCounterpart: false },
      nzTitle: this.tranPipe.transform("edit_delivery_origin_modal_title", "Select Origin", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: OriginFull | undefined) => {



      if (res != undefined) {

        this.tempDelivery.origin = res;


        this.dynamicFromGroups.controls["origin"].setValue(this.tempDelivery.origin.originString)
      }

    })

  }

  selectTechnology() {

    var data: TechFull | undefined = undefined;

    if (this.tempDelivery.tech != undefined) {

      data =
      {
        clusters: this.tempDelivery.tech.clusters,
        technology: this.tempDelivery.tech.technology,
        techString: this.tempDelivery.tech.techString,
        toExclude: this.tempDelivery.tech.toExclude
      }

    }

    console.log("product", this.data.product)

    this.modal.create<TechnologyClusterComponent, { productId: string, modalTech: TechFull | undefined }>({
      nzContent: TechnologyClusterComponent,
      nzData: { modalTech: data, productId: this.data.product },
      nzTitle: this.tranPipe.transform("edit_delivery_technology_modal_title", "Select Technologies", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: TechFull | undefined) => {



      if (res != undefined) {

        this.tempDelivery.tech = res;


        this.dynamicFromGroups.controls["technology"].setValue(this.tempDelivery.tech.techString)
      }

    })

  }


  selectPath() {

    var data;

    data = this.dynamicFromGroups.controls["path"].value;




    this.modal.create<SelectPathComponent, string | undefined>({
      nzContent: SelectPathComponent,
      nzData: data,
      nzTitle: this.tranPipe.transform("edit_delivery_path_modal_title", "Select Path", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: string | undefined) => {



      if (res != undefined) {

        this.dynamicFromGroups.controls["path"].setValue(res)
      }

    })

  }

  calculateTotalAmount(price: number | null | undefined, volume: number | null | undefined, currency: string | null | undefined) {

    if (price == undefined || volume == undefined)
      return '0';

    var p: number = +Math.abs(price);
    var v: number = +volume;

    var total = p * v;

    this.totalSum += total;

    return (currency != undefined ? this.curr.transform(total, currency) : total)

  }

  generateFormDelivery(delivery: Delivery | undefined) {

    var fields: purpleFormField[] = []


    console.log("delivery", delivery)


    switch (this.data.product) {
      case "ver":
        {
          fields =
            [

              {
                fieldType: 'date',
                fieldControlName: 'deliveryDate',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
                fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
              },
              {
                fieldType: 'select',
                fieldControlName: 'technology',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_standard', 'Standard', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_standard_placeholder', 'Standard', []),
                fieldValue: delivery?.tech?.techString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.productTechnologyScrollFunction },
                  { id: 'searchFunction', value: this.productTechnologySearchFunction },
                  { id: 'searchByIdFunction', value: this.productTechnologySearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 }
                ]
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'projectId',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_project_id', 'Project ID', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_project_id_placeholder', 'Project ID', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["projectId"] != undefined) ? delivery?.fields["projectId"] : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'projectProponent',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_project_proponent', 'Project Proponent', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_project_proponent_placeholder', 'Project Proponent', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["projectProponent"] != undefined) ? delivery?.fields["projectProponent"] : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: true
              },
              {
                fieldType: 'select',
                fieldControlName: 'additional',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_additional', 'Additional', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_additional_placeholder', 'Additional', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["additional"] != undefined) ? delivery?.fields["additional"] : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.additionalScrollFunction },
                  { id: 'searchFunction', value: this.additionalSearchFunction },
                  { id: 'searchByIdFunction', value: this.additionalSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              },
              {
                fieldType: 'select',
                fieldControlName: 'projectType',
                fieldBootstrapColumn: 8,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_project_type', 'Project Type', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_project_type_placeholder', 'Project Type', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["projectType"] != undefined) ? delivery?.fields["projectType"] : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.projectTypelScrollFunction },
                  { id: 'searchFunction', value: this.projectTypeSearchFunction },
                  { id: 'searchByIdFunction', value: this.projectTypeSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              },
              {
                fieldType: 'textarea',
                fieldControlName: 'comment',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_comment', 'Comment', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_comment_placeholder', 'Comment', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["comment"] != undefined) ? delivery?.fields["comment"] : undefined,
                fieldIsRequired: false,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [{ id: 'rowNumber', value: 4 }]
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'origin',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_location', 'Location', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_location_placeholder', 'Location', []),
                fieldValue: delivery?.origin?.originString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'vintage',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
                fieldValue: delivery?.vintageFull?.vintageString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              }
            ]
        }
        this.vintagePosition = "case-2";
        this.originPosition = "case-1";
        this.techPosition = "case-1";

        break;

      case "cee":
      case "tee":
        {
          fields =
            [

              {
                fieldType: 'date',
                fieldControlName: 'deliveryDate',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
                fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'origin',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_origin', 'Origin', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_origin_placeholder', 'Origin', []),
                fieldValue: delivery?.origin?.originString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'vintage',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
                fieldValue: delivery?.vintageFull?.vintageString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              },
              {
                fieldType: 'select-local',
                fieldControlName: 'quality',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_quality', 'Quality', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_quality_placeholder', 'Quality', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["quality"] != undefined) ? delivery?.fields["quality"] : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'isMultiple', value: false },
                  { id: 'showArrow', value: true },
                  { id: 'enableSearch', value: false },
                  {
                    id: 'options', value: [
                      {
                        label: 'ANY',
                        value: 'ANY'
                      },
                      {
                        label: 'HIG/STANDARD',
                        value: 'HIG/STANDARD'
                      },
                      {
                        label: 'LOW/STANDARD',
                        value: 'LOW/STANDARD'
                      },
                      {
                        label: 'HIGH/NON-STANDARD',
                        value: 'HIGH/NON-STANDARD'
                      },
                      {
                        label: 'LOW/NON-STANDARD',
                        value: 'LOW/NON-STANDARD'
                      }
                    ]
                  }
                ]
              },
              {
                fieldType: 'select',
                fieldControlName: 'beneficiary',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_beneficiary', 'Beneficiary', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_beneficiary_placeholder', 'Beneficiary', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["beneficiary"] != undefined) ? delivery?.fields["beneficiary"] : undefined,
                fieldIsRequired: false,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
                  { id: 'searchFunction', value: this.counterPartiesSearchFunction },
                  { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              },
              {
                fieldType: 'select',
                fieldControlName: 'technology',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_standard', 'Type', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_standard_placeholder', 'Type', []),
                fieldValue: delivery?.tech?.techString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.productTechnologyScrollFunction },
                  { id: 'searchFunction', value: this.productTechnologySearchFunction },
                  { id: 'searchByIdFunction', value: this.productTechnologySearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              }
            ]
        }
        this.techPosition = "case-1";

        break;

      case "hfc":
      case "hfc-uk":
        {
          fields = [

            {
              fieldType: 'date',
              fieldControlName: 'deliveryDate',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
              fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
            },
            {
              fieldType: 'textarea',
              fieldControlName: 'comment',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_comment', 'Comment', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_comment_placeholder', 'Comment', []),
              fieldValue: (delivery?.fields != undefined && delivery?.fields["comment"] != undefined) ? delivery?.fields["comment"] : undefined,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'rowNumber', value: 4 }]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'vintage',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
              fieldValue: delivery?.vintageFull?.vintageString,
              fieldIsRequired: true,
              fieldIsDisabled: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            }
          ]
        }
        this.vintagePosition = "case-2";
        this.originPosition = "case-2";
        this.techPosition = "case-1";

        break;
      case "hfc-brk":
        {
          fields =
            [
              {
                fieldType: 'select',
                fieldControlName: 'counterparty',
                fieldBootstrapColumn: 8,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_counterparty', 'Counterparty', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_counterparty_placeholder', 'Counterparty', []),
                fieldValue: delivery?.counterpartyId,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
                  { id: 'searchFunction', value: this.counterPartiesSearchFunction },
                  { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              },
              {
                fieldType: 'select-local',
                fieldControlName: 'Buyer/Seller',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_buyer_seller', 'Buyer or Seller', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_buyer_seller_placeholder', 'Buyer or Seller', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["buyer/Seller"] != undefined) ? delivery?.fields["buyer/Seller"] : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: !this.isNew,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'isMultiple', value: false },
                  { id: 'showArrow', value: true },
                  { id: 'enableSearch', value: false },
                  {
                    id: 'options', value: [
                      {
                        label: 'Buyer',
                        value: 'Buyer'
                      },
                      {
                        label: 'Seller',
                        value: 'Seller'
                      }
                    ]
                  }
                ]
              },
              {
                fieldType: 'textarea',
                fieldControlName: 'comment',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_comment', 'Comment', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_comment_placeholder', 'Comment', []),
                fieldValue: (delivery?.fields != undefined && delivery?.fields["comment"] != undefined) ? delivery?.fields["comment"] : undefined,
                fieldIsRequired: false,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldOptions: [{ id: 'rowNumber', value: 4 }]
              }
            ]
        }
        this.vintagePosition = "case-3";
        this.originPosition = "case-3";
        this.techPosition = "case-1";


        break;

      case "special":
      case "internal":
      case "consulting":
        {
          fields = [
            {
              fieldType: 'date',
              fieldControlName: 'deliveryDate',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
              fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
            },
            {
              fieldType: 'textarea',
              fieldControlName: 'description',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_description', 'Description', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_description_placeholder', 'Description', []),
              fieldValue: (delivery?.fields != undefined && delivery?.fields["description"] != undefined) ? delivery?.fields["description"] : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'rowNumber', value: 4 }]
            }]
        }
        this.vintagePosition = "case-3";
        this.originPosition = "case-3";
        this.techPosition = "case-1";

        break;

      default:
        {
          fields = [
            {
              fieldType: 'date',
              fieldControlName: 'deliveryDate',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
              fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'technology',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_tech', 'Tech', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_tech_placeholder', 'Tech', []),
              fieldValue: delivery?.tech?.techString,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            },
            {
              fieldType: 'select',
              fieldControlName: 'additional',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_additional', 'Additional', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_additional_placeholder', 'Additional', []),
              fieldValue: delivery?.additional,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [
                { id: 'scrollFunction', value: this.additionalScrollFunction },
                { id: 'searchFunction', value: this.additionalSearchFunction },
                { id: 'searchByIdFunction', value: this.additionalSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'select',
              fieldControlName: 'earmark',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_earmark', 'Earmark', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_earmark_placeholder', 'Earmark', []),
              fieldValue: (delivery?.fields != undefined && delivery?.fields["earmark"] != undefined) ? delivery?.fields["earmark"] : undefined,
              fieldIsRequired: !this.data.product.includes("bme"),
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [
                { id: 'scrollFunction', value: this.earmarklScrollFunction },
                { id: 'searchFunction', value: this.earmarkSearchFunction },
                { id: 'searchByIdFunction', value: this.earmarkSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'origin',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_origin', 'Origin', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_origin_placeholder', 'Origin', []),
              fieldValue: delivery?.origin?.originString,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            },
            {
              fieldType: 'select',
              fieldControlName: 'power_plant',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_power_plant', 'Power Plant', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_power_plant_placeholder', 'Plant', []),
              fieldValue: (delivery?.fields != undefined && delivery?.fields["power_plant"] != undefined) ? delivery?.fields["power_plant"] : undefined,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [
                { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
                { id: 'searchFunction', value: this.counterPartiesSearchFunction },
                { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'vintage',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
              fieldValue: delivery?.vintageFull?.vintageString,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'region',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_region', 'Region', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_region_placeholder', 'Region', []),
              fieldValue: (delivery?.fields != undefined && delivery?.fields["region"] != undefined) ? delivery?.fields["region"] : undefined,
              fieldIsRequired: false,
              fieldIsDisabled: !this.data.product.strEq("gof"),
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldShowInfo: !this.data.product.strEq("gof"),
              fieldInfoText: this.tranPipe.transform("deal_ticket_region_info_text", "This field is available only if the product selected is GoF")
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'path',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_path', 'Path', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_path_placeholder', 'Path', []),
              fieldValue: (delivery?.fields != undefined && delivery?.fields["path"] != undefined) ? delivery?.fields["path"] : undefined,
              fieldIsRequired: false,
              fieldIsDisabled: !this.data.product.strEq("ukmarket"),
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldShowInfo: !this.data.product.strEq("ukmarket"),
              fieldInfoText: this.tranPipe.transform("deal_ticket_path_info_text", "This field is available only if the product selected is Uk Market")
            },
            {
              fieldType: 'select-local',
              fieldControlName: 'physical',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_physical', 'Physical', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_physical_placeholder', 'Choose', []),
              fieldValue: (delivery?.fields != undefined && delivery?.fields["physical"] != undefined) ? delivery?.fields["physical"] : undefined,
              fieldIsRequired: false,
              fieldIsDisabled: !this.data.product.includes("bme"),
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldShowInfo: !this.data.product.includes("bme"),
              fieldInfoText: this.tranPipe.transform("deal_ticket_path_info_text", "This field is available only if the product selected is bme-(uk/eu/ch)"),
              fieldOptions: [
                { id: 'isMultiple', value: false },
                { id: 'showArrow', value: true },
                { id: 'enableSearch', value: false },
                {
                  id: 'options', value: [
                    {
                      label: 'Optional',
                      value: 'Optional'
                    },
                    {
                      label: 'Physical',
                      value: 'Physical'
                    },
                    {
                      label: 'Non-Physical',
                      value: 'Non-Physical'
                    }
                  ]
                }
              ]
            }
          ]
        }
    }


    return fields;

  }




  addCounterpart() {


    var oldO = this.originPosition;




    this.originPosition = "counterpart";


    this.modal.create<EditCounterpartComponent, { counterParty: CounterPartyRow | undefined, isFinanceView: boolean }>({
      nzContent: EditCounterpartComponent,
      nzTitle: this.tranPipe.transform("table_item_edit_modal_title_new", "Add {0}", ["CounterParty"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
      nzData: {
        counterParty: undefined,
        isFinanceView: false
      }
    }).afterClose.subscribe(() => {

      this.originPosition = oldO;

    })
  }

  //ScrollFunction
  currenciesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesSelect(args.culture, args);
  };
  //SearchFunction
  currenciesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  currenciesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByIdSelect(args.culture, args);
  };

  //ScrollFunction
  originScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getOriginSelect(args.culture, args);
  };
  //SearchFunction
  originSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getOriginByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  originSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getOriginByIdsSelect(args.culture, args);
  };




  //ScrollFunction
  technologyScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getTechnologiesSelect(args.culture, args);
  };
  //SearchFunction
  technologySearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getTechnologyByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  technologySearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getTechnologiesByIdsSelect(args.culture, args);
  };



  //ScrollFunction
  productTechnologyScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.data.product],
        filterOperator: "="
      }
    )
    return this.admSvc.getProductTechnologiesSelect(args.culture, args);
  };
  //SearchFunction
  productTechnologySearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.data.product],
        filterOperator: "="
      }
    )
    return this.admSvc.getProductTechnologyByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  productTechnologySearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.data.product],
        filterOperator: "="
      }
    )


    return this.admSvc.getProductTechnologyByIdSelect(args.culture, args);
  };

  //ScrollFunction
  vintageMonthScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthSelectFilter
    return this.admSvc.getVintageMonthsSelect(args.culture, args);
  };
  //SearchFunction
  vintageMonthSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthSelectFilter
    return this.admSvc.getVintageMonthByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  vintageMonthSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthSelectFilter
    return this.admSvc.getVintageMonthsByIdsSelect(args.culture, args);
  };



  vintageMonthClusterScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthClusterSelectFilter

    return this.admSvc.getVintageMonthClustersSelect(args.culture, args);
  };
  //SearchFunction
  vintageMonthClusterSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthClusterSelectFilter

    return this.admSvc.getVintageMonthClusterByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  vintageMonthClusterSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthClusterSelectFilter

    return this.admSvc.getVintageMonthClustersByIdsSelect(args.culture, args);
  };



  //ScrollFunction
  additionalScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.data.product],
        filterOperator: "="
      }
    )

    return this.admSvc.getProductAdditionalsSelect(args.culture, args);
  };
  //SearchFunction
  additionalSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {

    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.data.product],
        filterOperator: "="
      }
    )

    return this.admSvc.getProductAdditionalByIdSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  additionalSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {

    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.data.product],
        filterOperator: "="
      }
    )

    return this.admSvc.getProductAdditionalByIdSelect(args.culture, args);
  };

  //ScrollFunction
  earmarklScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getEarmarkSelect(args.culture, args);
  };
  //SearchFunction
  earmarkSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getEarmarkByIdSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  earmarkSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getEarmarkByIdSelect(args.culture, args);
  };


  //ScrollFunction
  counterPartiesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesSelect(args.culture, args);
  };
  //SearchFunction
  counterPartiesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  counterPartiesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartyByIdSelect(args.culture, args);
  };

  //ScrollFunction
  projectTypelScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProjectTypeSelect(args.culture, args);
  };
  //SearchFunction
  projectTypeSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProjectTypeByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  projectTypeSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProjectTypeByIdSelect(args.culture, args);
  };



}

export interface Delivery {
  deliveryDate: string | null | undefined; //  transactions
  vintageFull: VintageFull | null | undefined; //  transaction vintages
  origin: OriginFull | null | undefined; //  transaction origin
  tech: TechFull | null | undefined; //  transaction technologies
  additional: string | null | undefined;
  counterpartyId: string | null | undefined;

  fields: { [key: string]: string | null }

  volume: number | null | undefined; //  transactions
  price: number | null | undefined; // transaction prices
  currency: string | null | undefined; //  transaction prices

  transactionId: string | null | undefined;

}

export interface Deliveries {
  deliveries: Delivery[] | undefined,
  brokerId: string | undefined,
  brokerFee: number | undefined,
  brokerCurrency: string | undefined,
  dealComment: string | undefined,
  goNext: boolean;
}
