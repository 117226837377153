<nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
  <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" [disbledForm]="disableForm" [(formGroup)]="validateForm"
    [purpleFormFieldGroups]="purpleFormFieldGroups">
  </app-purple-form>
</nz-spin>
<ng-template #purpleModalLoaderTemplate>
  <div class="purple-modal-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>
<div class="purple-button-container ps-w-100 ps-d-flex">
  <button (click)="editEmployee()" nz-button class="purple-modal-button" [disabled]="isLoading" [nzLoading]="isLoading">
    <ng-container *ngIf="!isNew">{{ 'edit_employee_save_button' | translation: 'Salva Modifiche': []}}</ng-container>
    <ng-container *ngIf="isNew">{{ 'edit_employee_add_new_user' | translation: 'Aggiungi Dipendente':
      []}}</ng-container>
  </button>
</div>