<nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
  <app-purple-form *ngIf="purpleFormFieldGroups!= undefined && !data.isFinanceView" [disbledForm]="disableForm"
    [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups">
  </app-purple-form>
  <app-purple-form *ngIf="purpleFormFieldGroups1!= undefined && data.isFinanceView" [disbledForm]="disableForm"
    [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups1">
  </app-purple-form>
</nz-spin>

<button [nzTooltipTitle]="'deal_ticket_select_origin' | translation: 'Select origin'" (click)="selectOrigin()"
  nzTooltipPlacement="top" class="ps-mr-3 select-button cp-origin " nz-tooltip nz-button nzType="default"
  nzShape="round"><i nz-icon nzType="environment"></i>
</button>


<ng-template #purpleModalLoaderTemplate>
  <div class="purple-modal-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>
<div class="purple-button-container ps-w-100 ps-d-flex">
  <button nz-button class="purple-modal-button secondary">
    {{ 'edit_modal_cancel_button' | translation: 'Cancella': []}}
  </button>

  <button nz-button class="purple-modal-button" [disabled]="isLoading" [nzLoading]="isLoading"
    (click)="editCounterParty()">
    {{ 'edit_modal_save_button' | translation: 'Salva {0}': ['modifiche']}}
  </button>
</div>
