import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleLanguageIconPipe, PurpleTableColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardToggle, } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeNotificationService, PRPVSystemEventTemplateTablePurpleTableResponse, SystemEventMailTemplateItem } from 'src/core-modules/sdk/api';
import { EditMailTemplateComponent } from '../../modals/edit-mail-template/edit-mail-template.component';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-base-system-events',
  templateUrl: './base-system-event-mail-templates.component.html',
  styleUrls: ['./base-system-event-mail-templates.component.scss']
})
export class BaseSystemEventMailTemplatesComponent implements OnInit, AfterViewInit {

  constructor(public backNotSvc: BackOfficeNotificationService, public dashSvc: DashboardLayout2Service, public tsvc: AppTranslationService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService, private languageIconPipe: PurpleLanguageIconPipe) {}

  sub: Subject<void> = new Subject();
  isExceptionModeEnable: boolean = true;
  isModeEnable: boolean = true;
  notificationMode: string = "email";

  listOfData: Array<PRPVSystemEventTemplateTablePurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("translation_table_header_translation_language", "Lingua"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: "IsTranslated",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "130px",
      filters: [
        {
          text: "Si",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ]
    },
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "SystemEventName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_description", "Descrizione"),
      dbKey: "SystemEventDescription",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_is_active", "Attivo"),
      dbKey: "IsNotificationModeActive",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "120px",
      filters: [
        {
          text: "Si",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ]
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "150px",
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "NotificationMode",
      filterValue: this.notificationMode
    }];
    args.culture = this.tsvc.translationLanguage.value;
    return this.backNotSvc.getSystemEventTemplateTable(args.culture, args)
  }

  async ngOnInit() {
    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).pipe(takeUntil(this.sub)).subscribe(async () => {
      await this.refreshTableRowsNumber++;
    });

    await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.backNotSvc.checkIfNotificationModeIsEnable(this.tsvc.currentLanguage.value, {
      notificationModeId: this.notificationMode
    }), true, "internal-loader", 350, "internal-error", () => this.ngOnInit(), async (res: PurpleApiMakeRequestResponse<boolean>) => {
      this.isModeEnable = res.data!;

      if (this.isModeEnable) {

        await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.backNotSvc.checkIfNotificationModeExceptionIsEnable(this.tsvc.currentLanguage.value, {
          notificationModeId: this.notificationMode
        }), true, "internal-loader", 350, "internal-error", () => this.ngOnInit(), (res1: PurpleApiMakeRequestResponse<boolean>) => {
          this.isExceptionModeEnable = res1.data!;

          if(this.isExceptionModeEnable){
            this.dashSvc.addTranslationSelect();
          }

          this.dashSvc.dashMainItems?.push(
            new dashboardToggle("tg", async () => {
              await this.enableDisableNotificationModeException();
            }, this.tranPipe.transform('enable_system_event_email_notification_mode', 'Abilita Email', []), this.isExceptionModeEnable));
        })
      }
    })
  }

  ngAfterViewInit(): void {
    const pageName = "base_system_event_mail_template";
    this.dashSvc.setPageHeader(
    this.tranPipe.transform(pageName + '_page_title', 'Template Email', []),
    this.tranPipe.transform(pageName + '_page_subtitle', 'Lista dei template configurabili per le notifiche email', []))
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }
  
  async enableDisableNotificationModeException() {
    await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.backNotSvc.addOrRemoveNotificationModeException(this.tsvc.currentLanguage.value, {
      isCurrentStatusEnable: this.isExceptionModeEnable,
      notificationModeId: this.notificationMode
    }), true, "internal-loader", 100, "internal-error", () => this.enableDisableNotificationModeException, (res: PurpleApiMakeRequestResponse<boolean>) => {
      this.isExceptionModeEnable = res.data!;
    })
  }
  
  async editSystemEventMailTemplate(pk1: string, pk2: string, systemEventId: string, systemEventName: string, showPreview: boolean = false) {
    await this.apiProxySvc.makeRequestErrorMessage<SystemEventMailTemplateItem>(() => this.backNotSvc.getSystemEventMailTemplateDetail(this.tsvc.translationLanguage.value, {
      pk1,
      pk2,
      systemEventId: systemEventId
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SystemEventMailTemplateItem>) => {
      this.modal.create<EditMailTemplateComponent, {systemEventMailTemplate: SystemEventMailTemplateItem, systemEventId: string, systemEventName: string, isPreview: boolean}>({
        nzContent: EditMailTemplateComponent,
        nzData: {
          systemEventMailTemplate: res.data!,
          systemEventId: systemEventId,
          systemEventName: systemEventName,
          isPreview: showPreview
        },
        nzTitle: this.tranPipe.transform('edit_sysem_event_mail_title', 'Email Template: <b>{0}</b> {1}', [systemEventName, '<span class="ps-ml-1 '+this.languageIconPipe.transform(this.tsvc.translationLanguage.value) +'"></span>']),
        nzWidth: '85%',
        nzOkText: "Conferma",
        nzClassName: 'purple-modal ps-modal edit-se-mail-template-modal',
        nzFooter: null,
        nzMaskClosable: false
      }).afterClose.pipe(takeUntil(this.sub)).subscribe(async () => {
        this.refreshTableRowsNumber++;
      });
    });
  }
}
