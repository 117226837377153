@if (dashSvc.dashboardHeight != undefined)
{
<ng-container>

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"
    [isSelectRowActive]="false" [version]="2" [showCustomPagination]="true" tableSearchType="box"
    (filterBox)="dashSvc.setPurpleTablePopover($event)" (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()"
    [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      @for (data of listOfData; track i;let i = $index)
      {
      <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td nzAlign="center">
          <app-stella-table-button buttonIcon="icon-edit"
            toolTip="{{'market_watch_table_button_edit'|translation:'Edit'}}"></app-stella-table-button>
          <app-stella-table-button buttonIcon="icon-duplicate-1"
            toolTip="{{'market_watch_table_button_duplicate'|translation:'Duplicate'}}"></app-stella-table-button>
          <app-stella-table-button buttonIcon="icon-check"
            toolTip="{{'market_watch_table_button_update_traded_price'|translation:'Update Traded Price'}}"></app-stella-table-button>
          <app-stella-table-button buttonIcon="icon-comment"
            toolTip="{{'market_watch_table_button_comment'|translation:'Comment'}}"></app-stella-table-button>
          <app-stella-table-button buttonIcon="icon-x-close"
            toolTip="{{'market_watch_table_button_delete'|translation:'Delete'}}"></app-stella-table-button>
        </td>
      </tr>
      }

    </ng-template>
  </purple-table>
</ng-container>
}
