import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurpleLanguageIconPipe, PurpleTableColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardToggle } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeNotificationService, PRPVSystemEventTemplateTablePurpleTableResponse, SystemEventPushNotificationTemplateItem } from 'src/core-modules/sdk/api';
import { EditPushNotificationTemplateComponent } from '../modals/edit-push-notification-template/edit-push-notification-template.component';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-base-system-events',
  templateUrl: './base-system-event-push-notifications.component.html',
  styleUrls: ['./base-system-event-push-notifications.component.scss']
})
export class BaseSystemEventNotificationsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public backNotSvc: BackOfficeNotificationService, public dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private languageIconPipe: PurpleLanguageIconPipe, private apiProxySvc: PurpleApiProxyService) { }

  sub: Subject<void> = new Subject();
  isExceptionModeEnable: boolean = true;
  isModeEnable: boolean = true;
  notificationMode: string = "push";

  listOfData: Array<PRPVSystemEventTemplateTablePurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("translation_table_header_translation_language", "Lingua"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: "IsTranslated",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "130px",
      filters: [
        {
          text: "Si",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ]
    },
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "SystemEventName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_description", "Descrizione"),
      dbKey: "SystemEventDescription",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_is_active", "Attivo"),
      dbKey: "IsNotificationModeActive",
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      fixedWidth: "120px",
      filters: [
        {
          text: "Si",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ]
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "150px",
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "NotificationMode",
      filterValue: this.notificationMode
    }];
    args.culture = this.tsvc.translationLanguage.value;
    return this.backNotSvc.getSystemEventTemplateTable(args.culture, args)
  }

  async ngOnInit() {
    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(async () => {
      await this.refreshTableRowsNumber++;
    });

    await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.backNotSvc.checkIfNotificationModeIsEnable(this.tsvc.currentLanguage.value, {
      notificationModeId: this.notificationMode
    }), true, "internal-loader", 350, "internal-error", () => this.ngOnInit(), async (res: PurpleApiMakeRequestResponse<boolean>) => {
      this.isModeEnable = res.data!;

      if (this.isModeEnable) {

        await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.backNotSvc.checkIfNotificationModeExceptionIsEnable(this.tsvc.currentLanguage.value, {
          notificationModeId: this.notificationMode
        }), true, "internal-loader", 500, "internal-error", () => this.ngOnInit(), (res1: PurpleApiMakeRequestResponse<boolean>) => {
          this.isExceptionModeEnable = res1.data!;

          if(this.isExceptionModeEnable){
            this.dashSvc.addTranslationSelect();
          }

          this.dashSvc.dashMainItems?.push(
            new dashboardToggle("tg", async () => {
              await this.enableDisableNotificationModeException();
            }, this.tranPipe.transform('enable_push_notification_mode', 'Abilita Notifiche Push', []), this.isExceptionModeEnable));
        })
      }
    })
  }

  ngAfterViewInit(): void {
    const pageName = "base_system_event_notifications";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Template Notifiche Push', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista dei template configurabili per le notifiche push', []))
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next()
    this.sub.complete();
  }

  async enableDisableNotificationModeException() {
    await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.backNotSvc.addOrRemoveNotificationModeException(this.tsvc.currentLanguage.value, {
      isCurrentStatusEnable: this.isExceptionModeEnable,
      notificationModeId: this.notificationMode
    }), true, "internal-loader", 100, "internal-error", () => this.enableDisableNotificationModeException, (res: PurpleApiMakeRequestResponse<boolean>) => {
      this.isExceptionModeEnable = res.data!;
    })
  }

  async editSystemEventMailTemplate(pk1: string, pk2: string, systemEventId: string, systemEventName: string) {
    await this.apiProxySvc.makeRequestErrorMessage<SystemEventPushNotificationTemplateItem>(() => this.backNotSvc.getSystemEventPushNotificationTemplateDetail(this.tsvc.translationLanguage.value, {
      pk1,
      pk2,
      systemEventId: systemEventId
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SystemEventPushNotificationTemplateItem>) => {
      this.modal.create<EditPushNotificationTemplateComponent, {systemEventPushNotificationTemplate: SystemEventPushNotificationTemplateItem, systemEventName: string}>({
        nzContent: EditPushNotificationTemplateComponent,
        nzData: {
          systemEventPushNotificationTemplate: res.data!,
          systemEventName: systemEventName
        },
        nzTitle: this.tranPipe.transform('edit_sysem_event_push_notification_title', 'Template Notifica: <b>{0}</b> {1}', [systemEventName, '<span class="ps-ml-1 ' + this.languageIconPipe.transform(this.tsvc.translationLanguage.value) + '"></span>']),
        nzWidth: '1000px',
        nzOkText: "Conferma",
        nzClassName: 'purple-modal edit-se-push-notification-template-modal',
        nzFooter: null,
        nzMaskClosable: false
      }).afterClose.pipe(takeUntil(this.sub)).subscribe(async () => {
        this.refreshTableRowsNumber++;
      });
    });
  }
}
