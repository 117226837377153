<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"[version]="2" 
    [showCustomPagination]="true" >

    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td nzAlign="center"><span class="{{tsvc.translationLanguage.value| languageIcon}}"></span></td>
          <td nzAlign="center">
            <container-element [ngSwitch]="!data.toTranslate">
              <ng-container *ngSwitchCase="true">
                <span nz-icon nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <span nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></span>
              </ng-container>
            </container-element>
          </td>
          <td>{{ data.item.roleName }}</td>
          <td>{{ data.item.roleDescription }}</td>
          <td nzAlign="center">
            <container-element [ngSwitch]="data.item.isStaffRole">
              <ng-container *ngSwitchCase="true">
                <p class="ps-mb-0 fw-b">{{ 'table_yes' | translation: 'Si': []}}</p>
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <p class="ps-mb-0 fw-b">{{ 'table_no' | translation: 'No': []}}</p>
              </ng-container>
            </container-element>
          </td>
          
          <td>
            <ng-container *ngFor="let role of data.item.roleLevels.slice(0,5)??[]">
              <nz-tag class="ps-mt-2" purpleStringToColor [word]="role.roleLevelId.toString()" [opacity]="10">
                {{role.roleLevelName}} ({{role.roleLevelId}})
              </nz-tag>
            </ng-container>
            <ng-container *ngIf="(data.item.roleLevels.length??0) > 5">
              <nz-tag class="show-more-tag" nz-popover [nzPopoverTitle]="undefined"
                nzPopoverOverlayClassName="show-more-popover" nzPopoverPlacement="bottom"
                [nzPopoverContent]="showMoreContentTemplate">
                {{"show_more_element" | translation: "Mostra altri"}} {{data.item.roleLevels.length - 5}}
                <i class="ps-ml-2" nz-icon nzType="down" nzTheme="outline"></i>
                <ng-template #showMoreContentTemplate>
                  <nz-tag *ngFor="let role of data.item.roleLevels.slice(5)??[]" purpleStringToColor [word]="role.roleLevelId.toString()"
                    [opacity]="10">{{role.roleLevelName}} ({{role.roleLevelId}})</nz-tag>
                </ng-template>
              </nz-tag>
            </ng-container>
          </td>
          


          <td nzAlign="center">
            <button (click)="editItem(data.item!)"
              [nzTooltipTitle]='"edit_button_role_table" | translation: "Modifica Ruolo"' nzTooltipPlacement="top"
              nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon class="fsp-18-0"
                nzType="edit"></i></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>










