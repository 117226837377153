/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiMailTemplateImages } from './apiMailTemplateImages';
import { EventReplacer } from './eventReplacer';


export interface SystemEventMailTemplateItem { 
    pk1: string;
    pk2: string;
    smtpId: string;
    mailContent: string;
    mailSubject: string;
    senderName: string;
    senderEmail: string;
    mailTemplateImages: ApiMailTemplateImages;
    toTranslate: boolean;
    expanded: boolean;
    systemEventReplacers: Array<EventReplacer>;
}

