/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DealPendingFullInfo { 
    transactionId?: string | null;
    transactionStatusId?: string | null;
    counterPartyId?: string;
    counterPartyName?: string | null;
    counterPartyTypeId?: string | null;
    refNumber?: string | null;
    originalCurrency?: string | null;
    transactionDate?: string;
    transactionVolume?: number;
    portfolioId?: string | null;
    portfolioYear?: number;
    totalPrice?: number;
    avgPrice?: number;
    trader?: string | null;
    traderId?: string;
    technologyInfo?: string | null;
    companyId?: string | null;
    company?: string | null;
    brokerId?: string | null;
    brokerCurrency?: string | null;
    brokerFee?: number | null;
    fields?: { [key: string]: string; } | null;
}

