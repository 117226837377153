<div class="exchange-rate-page-container ps-d-flex ps-flex-wrap ps-w-100">
  <div *ngFor="let exc of exchanges" class="exchange-rate-item-external-container ps-d-flex ps-w-100 ps-col-3">
    <div class="exchange-rate-item-container ps-d-flex ps-w-100 ps-flex-wrap ps-align-items-start">
      <div class="ps-d-flex ps-w-100 ps-flex-wrap">
        <div class="ps-w-100 exchange-title-container">
          <p class="exchange-title">{{exc.currencyFrom}} - {{exc.currencyTo}}</p>
          <p class="exchange-value">{{exc.currentShowNumber}}</p>
        </div>
        <div
          class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center exchange-rate-graph-button-container">
          <button class="exchange-rate-graph-button {{exc.rangeValue==1?'selected':''}}" nz-button
            (click)="setOverrideRange(exc.currencyTo, 1)">{{ '1_day' | translation: '1D': []}}</button>
          <button class="exchange-rate-graph-button {{exc.rangeValue==5?'selected':''}}" nz-button
            (click)="setOverrideRange(exc.currencyTo, 5)">{{ '5_day' | translation: '5D': []}}</button>
          <button class="exchange-rate-graph-button {{exc.rangeValue==30?'selected':''}}" nz-button
            (click)="setOverrideRange(exc.currencyTo, 30)">{{ '1_mon' | translation: '1M': []}}</button>
          <button class="exchange-rate-graph-button {{exc.rangeValue==365?'selected':''}}" nz-button
            (click)="setOverrideRange(exc.currencyTo, 365)">{{ '1_yea' | translation: '1Y': []}}</button>
            <button class="exchange-rate-graph-button {{exc.rangeValue==730?'selected':''}}" nz-button
            (click)="setOverrideRange(exc.currencyTo, 730)">{{ 'max' | translation: 'Max': []}}</button>
          <!-- <button class="exchange-rate-graph-button" nz-button (click)="setOverrideRange(exc.currencyTo, -1)">{{ 'max_exc' | translation: 'Max': []}}</button> -->
        </div>
        <div class="exchange-rate-graph-container">
          <ngx-charts-area-chart [legend]="false" [showXAxisLabel]="true" [autoScale]="true" [autoScale]="true"
            [scheme]="exc.graphColor" [xAxis]="true" [yAxis]="true" [results]="exc.graphData">
          </ngx-charts-area-chart>
        </div>
        <div class="exchange-rate-value-list">
          <nz-table [nzPageSize]="15" [nzPaginationType]="'small'" nzShowPagination [nzData]="exc.exchangeRateCurrencyValues" #rowSelectionTable
            class="exchange-rate-value-list-table" [nzBordered]="true">
            <thead>
              <tr>
                <th nzWidth="50%">{{ 'exchange_rate_table_date' | translation: 'Date': []}}</th>
                <th nzWidth="50%">{{ 'exchange_rate_table_rate' | translation: 'Rate': []}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of rowSelectionTable.data">
                <td>{{data.date | date:'shortDate'}}</td>
                <td>{{data.value}}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>
