<div class="main-container ps-d-flex ps-flex-wrap">

    <div class="ps-w-100 ps-d-flex ps-flex-wrap">
        <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center replacer-container">
            <p class="ps-mb-0 replacer-label">
                {{ 'edit_mail_template_available_replacers' | translation: 'Replacer Disponibili': []}}:</p>
            <ng-container *ngFor="let replacer of newObj.systemEventReplacers">
                <nz-tag nzColor="purple" class="replacer-tag"
                    (click)="copyToClipboard($event, replacer.eventReplacerName)" nz-tooltip
                    [nzTooltipTitle]="replacer.eventReplacerDescription">
                    {{replacer.eventReplacerName}}
                </nz-tag>
            </ng-container>
        </div>

        <div class="ps-w-100 form-container">
            <nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
                <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" [disbledForm]="disableForm"
                    [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups">
                </app-purple-form>
            </nz-spin>
            <ng-template #purpleModalLoaderTemplate>
                <div class="purple-modal-custom-spinner">
                    <span nz-icon nzType="loading"></span>
                </div>
            </ng-template>
        </div>

    </div>
</div>
<div class="purple-button-container ps-w-100 ps-d-flex">
    <button (click)="editPushNotification()" nz-button class="purple-modal-button" [disabled]="isLoading"
        [nzLoading]="isLoading">
        {{ 'edit_push_notification_template_save' | translation: 'Salva Modifiche': []}}
    </button>
</div>