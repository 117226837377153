<div class="request-reset-password-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center">
  <form class="request-reset-password-form" nz-form [formGroup]="validateForm" class="request-reset-password-form" (ngSubmit)="submitForm()">
    <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center ps-pb-4">
      <img class="request-reset-password-image" purpleEditableImage contentId="request_reset_passoword" contentTypeId="logo" [resizeHeightPx]="250" [editAlwaysOn]="authSvc.isPurpleAdminUser()"/>
    </div>
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input your email!">
        <nz-input-group editableText tranKey='change_email_email' nzPrefixIcon="mail">
          <input type="email" nz-input formControlName="email" [placeholder]="'change_email_email' | translation: 'Indirizzo Mail': []" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzErrorTip]="errorTplPass">
        <nz-input-group nzPrefixIcon="lock">
          <input editableText tranKey='change_email_password' type="password" nz-input formControlName="password"  [placeholder]="'change_email_password' | translation: 'Password': []" />
        </nz-input-group>

        <ng-template #errorTplPass let-control>
          <ng-container editableText tranKey='required_input_error' *ngIf="control.hasError('required')">
            {{ 'required_input_error' | translation: 'Campo obbligatorio!': []}}
          </ng-container>
          <ng-container editableText tranKey='input_error_password_error' *ngIf="control.hasError('format')">
            {{ 'input_error_password_error' | translation: 'La password deve contenere almeno 8 caratteri, una letter maiuscola e un carattere speciale':
            []}}
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzErrorTip]="errorTpl">
        <nz-input-group nzPrefixIcon="lock">
          <input editableText tranKey='register_form_confirm_password' [placeholder]="'register_form_confirm_password' | translation: 'Conferma Password': []" nz-input
            type="password" nz-input formControlName="checkPassword"/>
        </nz-input-group>

        <ng-template #errorTpl let-control>
          <ng-container editableText tranKey='input_error_confirm_password_empty' *ngIf="control.hasError('required')">
            {{ 'input_error_confirm_password_empty' | translation: 'Conferma la password': []}}
          </ng-container>
          <ng-container editableText tranKey='input_error_confirm_password_incosistent' *ngIf="control.hasError('confirm')">
            {{ 'input_error_confirm_password_incosistent' | translation: 'Le password inserite non corrispondono':
            []}}
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <button nz-button class="submit-button ps-mt-4" nzType="primary">{{ 'request_reset_password_form_button' | translation: 'Richiedi reset password': []}}</button>
  </form>
</div>