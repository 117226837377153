import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { StellaFrontofficeRoutingModule } from './stella-frontoffice-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurpleFormModule, PurpleGeneralDirectiveModule, PurplePipeModule, PurpleTableModule, PurpleTranslationPipe } from 'purple-lib';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { TranslationModule } from '../../general/translations/translations.module';
import { DealTicketComponent } from './deal-ticket/deal-ticket.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { NzTableStyleService } from 'ng-zorro-antd/table';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { MtmPricesComponent } from './mtm-prices/mtm-prices.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MarketWatchComponent } from './market-watch/market-watch.component';
import { PendingDealsComponent } from './pending-deals/pending-deals.component';
import { StellaComponentsModule } from '../components/stella-components.module';
import { DealTicketDeliveryComponent } from './deal-ticket/modals/deal-ticket-delivery/deal-ticket-delivery.component';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { DealTicketMultipleDeliveryComponent } from './deal-ticket/custom-component/deal-ticket-multiple-delivery/deal-ticket-multiple-delivery.component';
import { DealTicketStepperComponent } from './deal-ticket/custom-component/deal-ticket-stepper/deal-ticket-stepper.component';

import { VintageMonthClusterComponent } from './deal-ticket/modals/vintage-month-cluster/vintage-month-cluster.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { NzSpinModule } from 'ng-zorro-antd/spin';
import { OriginClusterComponent } from './deal-ticket/modals/origin-cluster/origin-cluster.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { TechnologyClusterComponent } from './deal-ticket/modals/technology-cluster/technology-cluster.component';
import { ReviewDealAndDeliveryComponent } from './deal-ticket/modals/review-deal-and-delivery/review-deal-and-delivery.component';
import { SelectPathComponent } from './deal-ticket/modals/select-path/select-path.component';
import { DealTicketSummaryComponent } from './deal-ticket/custom-component/deal-ticket-summary/deal-ticket-summary.component';
import { EditDealComponent } from './deal-ticket/modals/edit-deal/edit-deal.component';
import { DealAgreementStatusComponent } from './deal-ticket/modals/deal-agreement-status/deal-agreement-status.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StellaFrontofficeRoutingModule,
    NzButtonModule,
    PurplePipeModule,
    TranslationModule,
    NzTableModule,
    NzNotificationModule,
    NzStepsModule,
    NzIconModule,
    PurpleTableModule,
    NzTabsModule,
    NzPopoverModule,
    NzInputNumberModule,
    PurpleFormModule,
    NzRadioModule,
    NzCollapseModule,
    NzToolTipModule,
    StellaComponentsModule,
    NzStatisticModule,
    NzCardModule,
    NzDescriptionsModule,
    NzPaginationModule,
    NzListModule,
    NzDatePickerModule,
    PurpleGeneralDirectiveModule,
    NzSpinModule,
    ScrollingModule

  ],
  declarations: [DealTicketComponent, PortfolioComponent, DealTicketStepperComponent, MtmPricesComponent, MarketWatchComponent, PendingDealsComponent, DealTicketDeliveryComponent, EditDealComponent,
    DealTicketMultipleDeliveryComponent, DealTicketSummaryComponent, VintageMonthClusterComponent, OriginClusterComponent, TechnologyClusterComponent, ReviewDealAndDeliveryComponent, SelectPathComponent, DealAgreementStatusComponent],
  providers: [PurpleTranslationPipe, DecimalPipe, NzTableStyleService, CurrencyPipe, TitleCasePipe],
})
export class StellaFrontofficeModule { }
