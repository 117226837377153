import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';

@Component({
  selector: 'app-edit-broker',
  templateUrl: './edit-broker.component.html',
  styleUrls: ['./edit-broker.component.scss']
})
export class EditBrokerComponent implements OnInit {

  constructor(private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private fb: FormBuilder) { }

  disableForm: boolean = false;

  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;

  validateForm: FormGroup = this.fb.group({});

  isLoading: boolean = false;



  ngOnInit() {

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'brokerName',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_broker_modal_name', 'Nome Cognome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_broker_modal_name_placeholder', 'Nome Cognom', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'brokerFee',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_broker_modal_fee', 'Fee', []),
            fieldPlaceholder: this.tranPipe.transform('edit_broker_modal_fee_placeholder', 'Fee', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0 }]
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'brokerFeeCurrency',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_broker_modal_fee_currency', 'Fee Currency', []),
            fieldPlaceholder: this.tranPipe.transform('edit_broker_modal_fee_currency_placeholder', 'Fee Currency', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: false },
              {
                id: 'options', value: [
                  {
                    label: 'Fee Currency',
                    value: 'Fee Currency'
                  }
                ]
              }
            ]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'brokerFeeUnit',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_broker_modal_fee_unit', 'Fee Unit', []),
            fieldPlaceholder: this.tranPipe.transform('edit_broker_modal_fee_unit_placeholder', 'Fee Unit', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          }

        ]
      }
    ]
  }

}
