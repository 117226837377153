import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppTranslationService } from '../localization/localization.service';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { GuardsHelper } from 'purple-lib';

@Injectable({
  providedIn: 'root'
})
export class ShowMenuGuard {
  constructor(private router: Router, private dashSvc: DashboardLayout2Service, private tsvc: AppTranslationService, private authSvc: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return GuardsHelper.checkRouteMenuLevel(
      [this.authSvc.currentUser?.roleLevel??environment.DEFAULT_MENU_LEVEL], 
      environment.ACTIVE_MENU, 
      this.dashSvc.showMenu,
      route.data['menu'], 
      route.data['submenu'],
      environment.DEFAULT_MENU_LEVEL, 
      this.tsvc.currentLanguage.value, 
      "dashboard", 
      [this.tsvc.currentLanguage.value, "login"], 
      this.router, 
      (menu?: string | undefined, subMenu?: string | undefined) => { this.dashSvc.setCurrentActiveMenuSubMenu(menu, subMenu) }, 
      () => { return this.authSvc.logout() });
  }
}
