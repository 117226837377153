export class Nc {
  public static IsNull<T>(target: T, defaultValue: T): T {
    if (target == undefined || target == null) {
      return defaultValue;
    } else return target;
  }

  public static IsNullCast<T, T2>(
    target: T,
    defaultValue: T2,
    transform: (input: T) => T2
  ): T2 {
    if (target == undefined || target == null) {
      return defaultValue;
    } else return transform(target);
  }
}
