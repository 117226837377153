<div class=" ps-d-flex ps-flex-wrap ps-col-12 ps-w-100">


  <div class="ps-col-12 ps-d-flex ps-justify-content-start">

    @if (purpleFormFieldGroups1!= undefined)
    {
    <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups1">
    </app-purple-form>
    }

  </div>

  <div class="ps-col-12 ps-w-100">
    @if (purpleFormFieldGroups2!= undefined)
    {
    <app-purple-form [disbledForm]="disableForm" [(formGroup)]="dynamicValidateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups2">
    </app-purple-form>
    }
  </div>

  @if (showThisOrThat==true)
  {

  <div class="ps-col-12 ps-d-flex ps-pl-0 ps-pr-0 ps-w-100">


    <div class="ps-col-4">

      <div style="margin-bottom: 7px;">
        <p class="subtitle" style=" margin-top: 0;">
          {{'standard_subtitle'|translation:'Standard'}}
        </p>
      </div>
      <div>
        <app-purple-this-or-that class="this-or-that-button " [text]="thisOrThatText"
          [selected]="selectedValueStandard ?0:1" (clickBtn)="standardSelected($event)" [buttonNumber]="2"
          [disabled]="disableForm" [isEditable]="true">
        </app-purple-this-or-that>
      </div>
    </div>

    <div class="ps-col-4 ps-pr-0">

      <div style="margin-bottom: 7px;">
        <p class="subtitle" style=" margin-top: 0;">
          {{'prepayment_subtitle'|translation:'Prepayment'}}
        </p>
      </div>
      <app-purple-this-or-that class="this-or-that-button " [text]="thisOrThatText"
        [selected]="selectedValuePrepayment ?0:1" (clickBtn)="prepaymentSelected($event)" [buttonNumber]="2"
        [disabled]="disableForm" [isEditable]="true">
      </app-purple-this-or-that>

    </div>


    <div class="ps-col-4">

      <div style="margin-bottom: 7px;">
        <p class="subtitle" style=" margin-top: 0;">
          {{'fullofftake_subtitle'|translation:'Full Offtake'}}
        </p>
      </div>
      <app-purple-this-or-that class="this-or-that-button " [text]="thisOrThatText"
        [selected]="selectedValueFullOfftake ?0:1" (clickBtn)="fullOfftakeSelected($event)" [buttonNumber]="2"
        [disabled]="disableForm" [isEditable]="true">
      </app-purple-this-or-that>

    </div>

    @if (b2bshow==true)
    {
    <button [nzTooltipTitle]="'deal_ticket_generate_b2b' | translation: 'Generate Back to Back Code'"
      (click)="generateB2BCode()" nzTooltipPlacement="top" class="ps-mr-3 b2b" nz-tooltip nz-button nzType="default"
      nzShape="round"><i nz-icon nzType="tag"></i>
    </button>
    }

  </div>

  }


  <div class="purple-button-container ps-w-100 ps-d-flex" style="margin-top: 28px">
    <button (click)="saveDeal()" nz-button class="purple-modal-button" [disabled]="isLoading" [nzLoading]="isLoading">
      <ng-container>{{ 'edit_deal_save_button' | translation: 'Save': []}}</ng-container>

    </button>
  </div>


</div>
