import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stella-table-button',
  templateUrl: './stella-table-button.component.html',
  styleUrls: ['./stella-table-button.component.scss']
})
export class StellaTableButtonComponent implements OnInit {

  constructor() { }
  @Input() buttonIcon!: string;
  @Input() buttonIconType?: "custom-font" | "ng-zorro" = "custom-font";
  @Input() buttonIconRotate?: number| undefined = undefined;
  @Input() toolTip!: string;

  ngOnInit() {
  }
}
