/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CounterPartyRowChild { 
    counterPartyId: string;
    counterPartyName: string;
    counterPartyShortName?: string | null;
    counterPartyNote?: string | null;
    userId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    dealAsBuyer?: number | null;
    dealAsSeller?: number | null;
    dealAsBroker?: number | null;
    dealAsOther?: number | null;
    originIds?: string | null;
    originClusterIds?: string | null;
    origins?: string | null;
    hasChildren: boolean;
    isChild: boolean;
    creditLimitFrom?: number | null;
    creditLimitTo?: number | null;
    children: Array<CounterPartyRowChild>;
    counterPartyRelationshipId: string;
}

