import { Component, Input, OnInit } from '@angular/core';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-purple-pill',
  templateUrl: './purple-pill.component.html',
  styleUrls: ['./purple-pill.component.scss']
})
export class PurplePillComponent implements OnInit {

  constructor() { }
  @Input() backgroundColor: string | undefined;
  @Input() borderColor: string | undefined;
  @Input() textColor: string | undefined;

  @Input() pillDefaultColor: 'green' | 'red' | 'yellow' | undefined;
  @Input() pillBackgroundType: 'one-color' | 'two-color' | undefined = 'one-color';
  @Input() pillBackgroundPercentage: number | undefined = 0;

  @Input() pillText!: string;
  @Input() pillCustomClass: string | undefined;

  backCol: string | undefined = undefined;

  defaultBackgroundColors: Map<'green' | 'red' | 'yellow', {defBck: string, percBck: string}> = new Map<'green' | 'red' | 'yellow', {defBck: string, percBck: string}>([
    ['green', {defBck: '#ECFDF5', percBck: '#BAEDC9'}],
    ['red', {defBck: '#FFF1F2', percBck: '#F4D1CD'}],
    ['yellow', {defBck: '#FFFBEB', percBck: '#FFFBEB'}]
  ])

  ngOnInit() {
    if(this.pillBackgroundType === 'two-color' && this.pillBackgroundPercentage == undefined){
      throw new TypeError("'pillBackgroundPercentage' is required in 'two-color' mode");
    }

    var defBkg = this.backgroundColor??this.defaultBackgroundColors.get(this.pillDefaultColor??'green')!.defBck;
    const percBkg = this.backgroundColor??this.defaultBackgroundColors.get(this.pillDefaultColor??'green')!.percBck;

    if(this.pillBackgroundType == 'two-color'){
      const defPerc = (this.pillBackgroundPercentage??0) + "%";

      if(this.backgroundColor != undefined){
        defBkg += "40";
      }

      this.backCol = "linear-gradient( to right, " + percBkg + " 0% " +  defPerc + ", "+ defBkg + " " + defPerc + " 100%)";
      //console.log("Background: ", this.backCol)
    }else{
      this.backCol = defBkg
    }
  }


}
