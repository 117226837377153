/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TransactionTableItem { 
    transactionId?: string | null;
    transactionTypeId?: string | null;
    transactionStatusId?: string | null;
    transactionDate?: string;
    transactionVolume?: number;
    currency?: string | null;
    counterPartyId?: string;
    totalPrice?: number;
    counterPartyTypeId?: string | null;
    origins?: string | null;
    technologies?: string | null;
    additionals?: string | null;
    earmarks?: string | null;
    vintage?: string | null;
    counterPartyName?: string | null;
    dynamicField?: { [key: string]: string | null; } | null;
    delivered?: Array<TransactionTableItem> | null;
    originsIncluded?: string | null;
    originClusters?: string | null;
    originsExcluded?: string | null;
    technologyIncluded?: string | null;
    technologyClusters?: string | null;
    technologyExcluded?: string | null;
    vintageIncluded?: { [key: string]: Array<number> | null; } | null;
    beneficiaryName?: string | null;
    powerPlantName?: string | null;
}

