import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurplePillComponent } from './purple-pill/purple-pill.component';
import { StellaTableButtonComponent } from './stella-table-button/stella-table-button.component';
import { PurpleThisOrThatComponent } from './purple-this-or-that/purple-this-or-that.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SubTableComponent } from './sub-table/sub-table.component';
import { PurplePipeModule, PurpleTableModule, PurpleTranslationPipe } from 'purple-lib';
import { TranslationModule } from '../../general/translations/translations.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  imports: [
    CommonModule,
    NzButtonModule,
    TranslationModule,
    PurplePipeModule,
    PurpleTableModule,
    NzTableModule,
    NzToolTipModule,
    NzIconModule
  ],
  declarations: [PurplePillComponent, StellaTableButtonComponent, PurpleThisOrThatComponent, SubTableComponent],
  exports: [PurplePillComponent, StellaTableButtonComponent, PurpleThisOrThatComponent, SubTableComponent],
  providers: [PurpleTranslationPipe]
})
export class StellaComponentsModule { }
