<div class="mtm-price-table-container">

</div>
<nz-table [nzScroll]="{x: '100%', y: '500px'}" *ngIf="tableInfo != undefined" class="purple-table purple-table-2" 
[nzFrontPagination]="false" [nzData]="[1]"
>
  <thead>
    <tr>
      <th nzWidth="100px">

      </th>
      <th nzAlign="center" *ngFor="let col of tableInfo.columnNames">
        {{col}}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}}" *ngFor="let data of tableInfo.technologies; let i = index">
      <td class="fw-b">{{data.technologyName | titlecase}}</td>
      <td  nzAlign="center" *ngFor="let priceItem of data.prices">
        <container-element [ngSwitch]="isEditingModeEnabled">
          <ng-container *ngSwitchCase="true">
            <nz-input-number [(ngModel)]="priceItem.mtmPrice" [nzMin]="0.001" [nzStep]="0.001"></nz-input-number>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            {{getPrice(priceItem, tableInfo.currencyId) }} 
          </ng-container>
        </container-element>
      </td>
    </tr>
  </tbody>
</nz-table>