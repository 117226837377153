import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { DealTicketComponent } from './deal-ticket/deal-ticket.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { MtmPricesComponent } from './mtm-prices/mtm-prices.component';
import { MarketWatchComponent } from './market-watch/market-watch.component';
import { PendingDealsComponent } from './pending-deals/pending-deals.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'deal-ticket',
        pathMatch: "full"
      },
      {
        path: 'deal-ticket',
        component: DealTicketComponent,
        data: { submenu: "deal-ticket" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'portfolio',
        component: PortfolioComponent,
        data: { submenu: "portfolio" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'mtm-prices',
        component: MtmPricesComponent,
        data: { submenu: "mtm-prices" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'market-watch',
        component: MarketWatchComponent,
        data: { submenu: 'market-watch' },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'pending-deals',
        component: PendingDealsComponent,
        data: { submenu: 'pending-deals' },
        canActivate: [ShowMenuGuard]
      }
    ]
  }
];

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StellaFrontofficeRoutingModule { }
