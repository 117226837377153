import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Helper, PurpleTranslationPipe } from 'purple-lib';
import { purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/environment';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { BackOfficeUsersService, PRPVRoleLevelLocalization } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-edit-role-level',
  templateUrl: './edit-role-level.component.html',
  styleUrls: ['./edit-role-level.component.scss']
})
export class EditRoleLevelComponent implements OnInit {
  originalEditItem!: EditRoleLevelItem;
  newObj!: EditRoleLevelItem;
  isNew: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private roleFull: PRPVRoleLevelLocalization | undefined, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private backUserSvc: BackOfficeUsersService, private tsvc: AppTranslationService) { }


  async ngOnInit(): Promise<void> {
    this.originalEditItem = {
      roleLevelId: this.roleFull?.roleLevelId ?? undefined,
      roleLevelName: this.roleFull?.roleLevelName ?? undefined,
      roleLevelDescription: this.roleFull?.roleLevelDescription ?? undefined,
    }

    this.isNew = this.roleFull == undefined;
    this.newObj = Helper.storeNewObj(this.originalEditItem);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-number',
            fieldControlName: 'level_id',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_role_level_level_id', 'Id', []),
            fieldPlaceholder: this.tranPipe.transform('edit_role_level_level_id_placeholder', 'Imposta il livello di sicurezza', []),
            fieldValue: this.originalEditItem.roleLevelId,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0 }]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'name',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_role_level_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_role_level_name_placeholder', 'Nome livello', []),
            fieldValue: this.originalEditItem.roleLevelName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'description',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_role_level_description', 'Descrizione', []),
            fieldPlaceholder: this.tranPipe.transform('edit_role_level_description_placeholder', 'Descrizione livello', []),
            fieldValue: this.originalEditItem.roleLevelDescription,
            fieldIsRequired: false,
            fieldOptions: [{ id: 'rowNumber', value: 5 }],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]
  }


  async editRoleLevel() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    if (this.validateForm.valid) {
      this.newObj.roleLevelId = this.validateForm.controls["level_id"].value;
      this.newObj.roleLevelName = this.validateForm.controls["name"].value;
      this.newObj.roleLevelDescription = this.validateForm.controls["description"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.originalEditItem)) {

        await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.backUserSvc.addOrUpdateRoleLevel(this.tsvc.translationLanguage.value, {
          roleLevelDescription: this.newObj.roleLevelDescription,
          roleLevelName: this.newObj.roleLevelName!,
          roleLevelId: this.newObj.roleLevelId!,
          isNew: this.isNew
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_role_level_add', 'Licello aggiunto con successo')
          } else {
            text = this.tranPipe.transform('edit_role_level_update', 'Licello aggiornato con successo')
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(true);
        })

      }
    }
    this.isLoading = false
  }
}

class EditRoleLevelItem {
  roleLevelId: number | undefined;
  roleLevelName: string | undefined;
  roleLevelDescription?: string | undefined;
}
