@if (show)
{

<nz-table nzBordered [nzData]="listOfData" [nzShowPagination]="false">

  <thead>
    <tr>
      <th [width]="50"></th>
      <th>{{ 'counter_party_table_header_event_name' | translation: 'Name'}}</th>
      <th>{{ 'counter_party_table_header_event_short_name' | translation: 'Short Name'}}</th>
      <th> {{ 'counter_party_table_header_event_relationship' | translation: 'Relationship'}}</th>
      <th> {{ 'counter_party_table_header_event_contact' | translation: 'Contact'}}</th>
      <th> {{ 'counter_party_table_header_event_contact_mail' | translation: 'Contact Email'}}</th>
      <th> {{ 'counter_party_table_header_event_credit_limit' | translation: 'Credit Limit(from/to Nvalue)'}}</th>
      <th> {{ 'counter_party_table_header_event_origin' | translation: 'Origins'}}</th>
      <th> {{ 'counter_party_table_header_event_note' | translation: 'Note'}}</th>
      <th> {{ 'counter_party_table_header_event_deals' | translation: 'Deals'}}</th>
      <th [width]="225">{{ 'table_header_actions' | translation: 'Azioni'}}</th>
    </tr>
  </thead>

  <tbody>
    @for (data of listOfData; track $index)
    {
    <ng-container>
      <tr class="{{$index%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
        <td nzAlign="center" [width]="50">
          @if (data.item.hasChildren)
          {
          <app-stella-table-button [buttonIcon]="data.expanded?'icon-minus':'icon-plus'"
            (click)="data.expanded = !data.expanded"></app-stella-table-button>

          }

        </td>

        <td>{{ data.item.counterPartyName}}</td>
        <td>{{data.item.counterPartyShortName}}</td>
        <td> {{hasChildrenRelationsip(data.item.hasChildren,data.expanded)}} </td>
        <td> {{data.item.firstName}} {{data.item.lastName}}</td>
        <td> {{data.item.email}}</td>
        <td nzAlign="center" class="ps-d-flex">
          <app-purple-pill [pillDefaultColor]="'green'" [pillText]="formatNumber(data.item.creditLimitFrom)"
            [pillBackgroundType]="'two-color'" [pillBackgroundPercentage]="70" class="pill"></app-purple-pill>
          <app-purple-pill [pillDefaultColor]="'red'" [pillText]="formatNumber(data.item.creditLimitTo)"
            [pillBackgroundType]="'two-color'" [pillBackgroundPercentage]="70" class="ps-pl-1 pill"></app-purple-pill>
        </td>
        <td> {{data.item.origins}}</td>
        <td></td>
        <td> {{sumDeals(data.item.dealAsBuyer,data.item.dealAsSeller,data.item.dealAsBroker,data.item.dealAsOther)}}
        </td>
        <td nzAlign="center" [width]="225">

          <app-stella-table-button (click)="editCounterpart(data.item)" buttonIcon="icon-edit"
            toolTip="{{'counterpart_table_button_edit'|translation:'Edit Counterpart'}}"></app-stella-table-button>
          <app-stella-table-button buttonIcon="icon-addchild" (click)="addRelationships(data.item)"
            toolTip="{{'counterpart_table_button_relationship'|translation:'Add Child'}}"></app-stella-table-button>
          <app-stella-table-button buttonIcon="icon-deliveries"
            toolTip="{{'counterpart_table_button_deals'|translation:'View Deal List'}}"
            (click)="viewDeals(data.item)"></app-stella-table-button>
          <!-- <app-stella-table-button buttonIcon="icon-history"
            toolTip="{{'counterpart_table_button_history'|translation:'View History'}}"></app-stella-table-button> -->
          <app-stella-table-button (click)="moveDeals(data.item)" buttonIcon="icon-map"
            toolTip="{{'counterpart_table_button_map'|translation:'Move all deals to another Counterpart'}}"></app-stella-table-button>
          <app-stella-table-button (click)="removeChild(data.item)"
            toolTip="{{'counterpart_table_button_remove_child'|translation:'Remove deal from this counterparty'}}"
            buttonIcon="icon-x-close"></app-stella-table-button>
        </td>
      </tr>
      <tr [nzExpand]="data.expanded">
        <div
          class=" ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">

          @if(data.expanded)
          {
          <app-sub-table [items]="data.item.children" [refresh]="refresh" [isFinanceView]="isFinanceView"
            [parentId]="data.item.counterPartyId">

          </app-sub-table>
          }

        </div>

      </tr>
    </ng-container>
    }

  </tbody>



</nz-table>


}
