/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PRPTemplatesTabsAttribute { 
    tabsAttributeTemplateId: string;
    tabsAttributeTemplateName?: string | null;
    tabId?: string | null;
    tabAttributeKey?: string | null;
    tabAttributeValue?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
}

