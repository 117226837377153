<div class="main-container">
  <nz-tabset [nzTabPosition]="'left'" [(nzSelectedIndex)]="currentTabIdx">
    <nz-tab [nzTitle]="'edit_mail_template_html_code_menu_section' | translation: 'Template Html': []">
      <div *ngIf="!data.isPreview" class="ps-d-flex ps-flex-wrap ps-w-100 ps-h-100 ps-min-h-100">
        <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center replacer-container">
          <p class="ps-mb-0 replacer-label">{{ 'edit_mail_template_available_replacers' | translation: 'Replacer Disponibili': []}}:</p>
          <ng-container *ngFor="let replacer of newObj.systemEventReplacers">
            <nz-tag nzColor="purple" class="replacer-tag" (click)="copyToClipboard($event, replacer.eventReplacerName)" 
            nz-tooltip [nzTooltipTitle]="replacer.eventReplacerDescription">
              {{replacer.eventReplacerName}}
            </nz-tag>
          </ng-container>
        </div>
        <nz-code-editor class="edit-mail-template-editor" (nzEditorInitialized)="onEditorInit($event)"
          [(ngModel)]="this.newObj.mailContent" [nzEditorOption]="{ language: 'html', fontSize: 12 }"></nz-code-editor>
      </div>
      <div *ngIf="data.isPreview" class="preview-container">
        <div [innerHtml]="previewText! | safeHtml" class="editor-preview"></div>
      </div>
    </nz-tab>

    <nz-tab [nzTitle]="'edit_mail_template_general_menu_section' | translation: 'Proprietà Generali': []">
      <nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate" class="ps-pt-4">
        <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" [disbledForm]="disableForm" 
          [(formGroup)]="validateForm" [purpleFormFieldGroups]="purpleFormFieldGroups">
        </app-purple-form>
      </nz-spin>
      <ng-template #purpleModalLoaderTemplate>
        <div class="purple-modal-custom-spinner">
          <span nz-icon nzType="loading"></span>
        </div>
      </ng-template>
    </nz-tab>


  </nz-tabset>
</div>


<div class="purple-button-container purple-edit-mail-template-button-container ps-w-100 ps-d-flex">
  <button *ngIf="currentTabIdx == 0" nz-button
    class="purple-modal-button ps-mr-auto" (click)="importMailTemplate()">
    {{ 'edit_mail_import_stripo' | translation: 'Importa Template': []}}
  </button>

  <button *ngIf="currentTabIdx == 0" nz-button class="purple-modal-button secondary" (click)="togglePreviewButton()">
    {{previewButtonText}}
  </button>

  <button nz-button class="purple-modal-button {{currentTabIdx==1?'ps-ml-auto':'ps-ml-2'}}" nzType="primary" [nzLoading]="isLoading" [disabled]="isLoading"
    (click)="saveTemplateChanges()">
    {{ 'edit_mail_save_changes' | translation: 'Salva Modifiche': []}}
  </button>
</div>