import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { CounterpartiesComponent } from './counterparties/counterparties.component';
import { StellaBackOfficeRoutingModule } from './stella-backoffice-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurpleFormModule, PurpleLayoutModule, PurplePipeModule, PurpleSelectModule, PurpleTableModule, PurpleTranslationPipe } from 'purple-lib';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ExchangeRateComponent } from './exchange-rate/exchange-rate.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { TranslationModule } from '../../general/translations/translations.module';
import { BrokersComponent } from './brokers/brokers.component';
import { EditCounterpartComponent } from './modals/edit-counterpart/edit-counterpart.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { EditBrokerComponent } from './modals/edit-broker/edit-broker.component';
import { InternalAlertsComponent } from './internal-alerts/internal-alerts.component';
import { StellaComponentsModule } from '../components/stella-components.module';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { MergeCounterpartyComponent } from './modals/merge-counterparty/merge-counterparty.component';
import { ViewDealsComponent } from './modals/view-deals/view-deals.component';
import { MoveDealsComponent } from './modals/move-deals/move-deals.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ViewDeliveriesComponent } from './modals/view-deliveries/view-deliveries.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StellaBackOfficeRoutingModule,
    PurpleSelectModule,
    PurpleTableModule,
    NgxChartsModule,
    NzButtonModule,
    PurplePipeModule,
    TranslationModule,
    NzTableModule,
    NzNotificationModule,
    NzSpinModule,
    PurpleFormModule,
    StellaComponentsModule,
    NzDescriptionsModule,
    NzIconModule,
    PurpleLayoutModule,
    NzPopoverModule,
    NzSelectModule,
    NzDrawerModule,
    NzToolTipModule
  ],
  declarations: [CounterpartiesComponent, ExchangeRateComponent, BrokersComponent, EditCounterpartComponent, EditBrokerComponent, InternalAlertsComponent, MergeCounterpartyComponent, ViewDealsComponent, MoveDealsComponent, ViewDeliveriesComponent],
  providers: [PurpleTranslationPipe, DecimalPipe, CurrencyPipe]
})
export class StellaBackofficeModule { }
