import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationsComponent } from './translations.component';
import { PurpleFormModule, PurplePipeModule, PurpleTableModule } from 'purple-lib';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableStyleService } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditTranslationComponent } from '../modals/edit-translation/edit-translation.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  imports: [
    CommonModule,
    PurpleTableModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    NzMessageModule,
    NzPopoverModule,
    NzModalModule,
    NzTableModule,
    FormsModule,
    ReactiveFormsModule,
    PurplePipeModule,
    NzToolTipModule,
    PurpleFormModule,
    NzSpinModule
  ],
  declarations: [TranslationsComponent, EditTranslationComponent],
  providers: [NzTableStyleService]
})
export class TranslationModule { }
