/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PRPLogsTab { 
    tabId: string;
    recipientId?: string | null;
    documentId: string;
    tabLabel: string;
    tabTypeId: string;
    tabInitialValue?: string | null;
    tabIsRequired: boolean;
    tabIsReadOnly: boolean;
    tabPlacementFormFieldName?: string | null;
    tabPlacementAnchor?: string | null;
    tabPlacementPage: number;
    tabPlacementOffsetXPts: number;
    tabPlacementOffsetYPts: number;
    tabPlacementWidth: number;
    tabPlacementHeight: number;
    tabsLogUserId?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
    logId: number;
    logDate: string;
    logAction: string;
}

