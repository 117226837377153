/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurpleTableSearchGroup } from './purpleTableSearchGroup';
import { PurpleTableRelationOperator } from './purpleTableRelationOperator';


export interface PurpleTableSearchGroupGroup { 
    groupGroupId: number;
    groupGroupName?: string | null;
    relationOperator: PurpleTableRelationOperator;
    groups: Array<PurpleTableSearchGroup>;
}
export namespace PurpleTableSearchGroupGroup {
}


