import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { LocalizationGuard } from 'src/core-modules/localization/localization.guard';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './authentication/login/login.component';
import { RequestResetPasswordComponent } from './authentication/request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.DEFAULT_LANGUAGE + '/login',
    pathMatch: 'full'
  },
  {
    path: ':cultureId',
    canActivate: [LocalizationGuard],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'request-reset-password',
        component: RequestResetPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'dashboard',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
