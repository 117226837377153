import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PurpleTranslationPipe, Guid } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  validateForm: FormGroup = this.fb.group({});

  constructor(private fb: FormBuilder, public authSvc: AuthenticationService, private router: Router, private breakpointObserver: BreakpointObserver,
    private tsvc: AppTranslationService, private route: ActivatedRoute, private modal: NzModalService, private tranPipe: PurpleTranslationPipe) { }

  resetId: string | undefined;
  email: string | undefined;
  subs: Subject<void> = new Subject();

  async submitForm(): Promise<void> {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      const res = await this.authSvc.resetPassword(this.validateForm.controls["email"].value, this.resetId ?? "", this.validateForm.controls['password'].value);

      if (!res.status) {
        this.modal.create({
          nzTitle: this.tranPipe.transform("modal_reset_password_error", "Ops! qualcosa è andato storto"),
          nzContent: res.message,
          nzWidth: '600px',
          nzClassName: 'ps-modal footer-center',
          nzCancelDisabled: true,
          nzFooter: [
            {
              label: this.tranPipe.transform("modal_reset_password_button", "Conferma"),
              type: "primary",
              onClick: async () => {
                await this.router.navigate([this.tsvc.currentLanguage.value, 'forgot-password']);
                this.modal.closeAll();
              }
            }
          ]
        });
      } else {
        await this.router.navigate([this.tsvc.currentLanguage.value, 'login']);
      }
    }
  }

  validatePassword = (control: UntypedFormControl): { [s: string]: boolean } => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const res = re.test(String(control.value));
    //console.log("test pass: ", res)
    if (!res) {
      return { format: true, error: true }
    }
    return {}
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls["checkPassword"].updateValueAndValidity());
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls["password"].value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  
  ngOnDestroy(): void {
    this.subs.next();
    this.subs.complete();
  }

  async ngOnInit(): Promise<void> {
    if (this.authSvc.isLoggedIn) {
      await this.router.navigate([this.tsvc.currentLanguage.value, 'event']);
    }

    this.route.queryParams
      .subscribe((params: Params) => {
        this.email = params["email"];
        this.resetId = params["resetId"];

        this.validateForm = this.fb.group({
          email: [{ value: this.email, disabled: true }, [Validators.email, Validators.required]],
          password: [null, [Validators.required, this.validatePassword]],
          checkPassword: [null, [Validators.required, this.confirmationValidator]]
        });
      });

    let check: { status: boolean, message: string } = {
      message: "",
      status: false
    }

    if (this.resetId != undefined) {
      check = await this.authSvc.checkResetPasswordLinkUrl(this.resetId)
    }

    let isMobile = true;

    this.breakpointObserver
      .observe(['(max-width: 700px)'])
      .pipe(takeUntil(this.subs)).subscribe((state: BreakpointState) => {
        if (state.matches) {
          isMobile = true;
        } else {
          isMobile = false;
        }
      });

    if (!check.status) {
      this.modal.create(
        {
          nzTitle: isMobile ? this.tranPipe.transform("modal_reset_password_on_init_title_mobile_ko", "Errore") : this.tranPipe.transform("modal_reset_password_on_init_title_ko", "Ops! qualcosa è andato storto"),
          nzContent: check.message,
          nzWidth: isMobile ? '80%' : '600px',
          nzClassName: 'purple-simple-modal',
          nzCentered: isMobile ? true : false,
          nzClosable: false,
          nzMaskClosable: false,
          nzCancelText: null,
          nzOkText: this.tranPipe.transform("modal_reset_password_on_init_button", "Conferma"),
          nzOnOk: async () => {
            await this.router.navigate([this.tsvc.currentLanguage.value, 'forgot-password']);
            this.modal.closeAll();
          }
        }
      )
    }
  }
}

