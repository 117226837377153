import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PurpleApiProxyService, PurpleTableBoxColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { BooleanPurpleApiResponse, ProductAttributesGrouped, StellaService, TransactionTableItem, TransactionTableItemPurpleTableResponse } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DealTicketDeliveryComponent, Delivery } from '../../../frontoffice/deal-ticket/modals/deal-ticket-delivery/deal-ticket-delivery.component';
import { NZ_DRAWER_DATA, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { VintageFull } from '../../../frontoffice/deal-ticket/modals/vintage-month-cluster/vintage-month-cluster.component';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-view-deliveries',
  templateUrl: './view-deliveries.component.html',
  styleUrls: ['./view-deliveries.component.scss']
})
export class ViewDeliveriesComponent implements OnInit {

  constructor(@Inject(NZ_DRAWER_DATA) private data: { transactionId: string, dealType: string, productId: string, currency: string }, private admSvc: StellaService, private tsvc: AppTranslationService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private mesSvc: NzMessageService, public dashSvc: DashboardLayout2Service, private drawerRef: NzDrawerRef, private apiProxySvc: PurpleApiProxyService) { }


  listOfData: Array<TransactionTableItemPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;

  isLoading: boolean = false;

  show: boolean = false;

  productAttributes: ProductAttributesGrouped[] | undefined;
  deliveryFields!: string[];

  hiddenVintage: boolean = this.data.productId.strEq("hfc-brk") || this.data.productId.strEq("special") || this.data.productId.strEq("internal") || this.data.productId.strEq("consulting");
  hiddenOrigin: boolean = this.data.productId.strEq("hfc-brk") || this.data.productId.strEq("hfc") || this.data.productId.strEq("hfc-uk") || this.data.productId.strEq("special") || this.data.productId.strEq("internal") || this.data.productId.strEq("consulting");
  hiddenTechnology: boolean = this.data.productId.strEq("hfc-brk") || this.data.productId.strEq("hfc") || this.data.productId.strEq("hfc-uk") || this.data.productId.strEq("special") || this.data.productId.strEq("internal") || this.data.productId.strEq("consulting");
  hiddenAdditional: boolean = this.data.productId.strEq("hfc-brk") || this.data.productId.strEq("hfc") || this.data.productId.strEq("hfc-uk") || this.data.productId.strEq("special") || this.data.productId.strEq("internal") || this.data.productId.strEq("consulting") || this.data.productId.strEq("tee") || this.data.productId.strEq("cee");
  hiddenCounterPartyName: boolean = !this.data.productId.strEq("hfc-brk");

  update: boolean = false;

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: "",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "35px"
    },
    {
      name: this.tranPipe.transform("delivery_table_completed", "Completed"),
      dbKey: "Completed",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "120px",
    },
    {
      name: this.tranPipe.transform("delivery_table_date", "Delivery Date"),
      dbKey: "Date",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "110px"
    },
    {
      name: this.tranPipe.transform("delivery_table_vintage", "Vintage"),
      dbKey: "Vintage",
      hasReorder: false,
      hasFilter: false,
      hiddenColumn: this.hiddenVintage
    },
    {
      name: this.tranPipe.transform("delivery_table_origin", "Origin"),
      dbKey: "Origin",
      hasReorder: false,
      hasFilter: false,
      hiddenColumn: this.hiddenOrigin
    },
    {
      name: this.tranPipe.transform("delivery_table_technology", "Tech"),
      dbKey: "Technology",
      hasReorder: false,
      hasFilter: false,
      hiddenColumn: this.hiddenTechnology
    },

    {
      name: this.tranPipe.transform("delivery_table_cp_name", "Counter Party Name"),
      dbKey: "CounterPartyName",
      hasReorder: false,
      hasFilter: false,
      hiddenColumn: this.hiddenCounterPartyName
    },
    {
      name: this.tranPipe.transform("delivery_table_additional", "Additional"),
      dbKey: "Additional",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "70px",
      hiddenColumn: this.hiddenAdditional
    },
    {
      name: this.tranPipe.transform("delivery_table_volume", "Volume"),
      dbKey: "Volume",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("delivery_table_price", "Price"),
      dbKey: "Price",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("delivery_table_amount", "Amount"),
      dbKey: "Amount",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "120px"
    },
    {
      name: this.tranPipe.transform("delivery_table_currency", "Currency"),
      dbKey: "Currency",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "70px"
    }
  ]


  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "transactionId",
      filterValue: this.data.transactionId
    },
    {
      property: "type",
      filterValue: this.data.dealType
    },
    {
      property: "currency",
      filterValue: this.data.currency

    }]
    return this.admSvc.getDeliveriesTable(args.culture, args)
  }

  ngOnInit() {

    this.getProductAttributes();


  }





  async getProductAttributes() {
    await this.apiProxySvc.makeRequestErrorFunction<ProductAttributesGrouped[]>(() => this.admSvc.getProductAttributes(this.tsvc.currentLanguage.value),
      true,
      "internal-loader",
      100,
      () => this.getProductAttributes(), (res) => {
        this.productAttributes = res.data!

        this.deliveryFields = this.productAttributes!.find(p => p.productId?.strEq(this.data.productId!))!.attributes!["delivery"].map(s => this.adjustAttributes(s));


        this.deliveryFields.forEach(att => {
          this.tabColumns.push(
            {
              name: this.tranPipe.transform("delivery_table_fields", "{0}", [att[0].toUpperCase() + att.slice(1).replace('_', ' ')]),
              dbKey: att,
              hasReorder: false,
              hasFilter: false,
              fixedWidth: "120px"
            }
          )

        });

        this.tabColumns.push(
          {
            name: "",
            hasReorder: false,
            hasFilter: false,
            fixedWidth: "100px",
          })

        this.show = true;

      })
  }

  adjustAttributes(att: string) {
    return att[0].toLowerCase() + att.slice(1)
  }

  addDelivered() {
    this.modal.create<DealTicketDeliveryComponent, Delivery>({
      nzContent: DealTicketDeliveryComponent,
      nzData: undefined,
      nzTitle: this.tranPipe.transform("add_delivered_modal_title", "{0} Delivered", ["New"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (res: boolean) => {
      if (res != undefined && res == true) {
        this.update = true;
        this.refreshTableRowsNumber++;
      }
    })

  }

  editDelivery(delivery: TransactionTableItem | undefined) {


    var vintages: VintageFull = {
      vintages: [],
      vintageString: delivery?.vintage
    };


    for (const year in delivery?.vintageIncluded) {

      vintages.vintages?.push(
        {
          clusters: [],
          months: delivery?.vintageIncluded[year],
          year: Number.parseInt(year)
        }
      )
    }

    var data: Delivery =
    {
      additional: delivery?.additionals,
      counterpartyId: delivery?.counterPartyId,
      currency: delivery?.currency,
      deliveryDate: delivery?.transactionDate,
      fields: delivery?.dynamicField!,
      origin:
      {
        clusters: delivery?.originClusters?.split("+"),
        originString: delivery?.origins,
        origin: delivery?.originsIncluded?.split("+"),
        toExclude: delivery?.originsExcluded?.split("+")
      },
      price: delivery?.totalPrice,
      tech: {
        clusters: delivery?.technologyClusters?.split("+"),
        technology: delivery?.technologyIncluded?.split("+"),
        techString: delivery?.technologies,
        toExclude: delivery?.technologyExcluded?.split("+")
      },
      vintageFull: vintages,
      volume: delivery?.transactionVolume,
      transactionId: delivery?.transactionId
    }

    this.modal.create<DealTicketDeliveryComponent, { product: string, isBuy: boolean, parentTransactionId: string, delivery: Delivery | undefined, currency: string | undefined }>({
      nzContent: DealTicketDeliveryComponent,
      nzData: {
        delivery: delivery != undefined ? data : undefined,
        product: this.data.productId,
        isBuy: this.data.dealType.strEq("buyer"),
        parentTransactionId: this.data.transactionId,
        currency: delivery != undefined ? delivery.currency! : this.data.currency
      },
      nzTitle: delivery == undefined ? this.tranPipe.transform("add_delivery_modal_title", "{0} Delivery", ["New"]) : this.tranPipe.transform("edit_delivery_modal_title", "{0} Delivery", ["Edit"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe(async (res: boolean | undefined) => {
      if (res != undefined && res == true) {
        this.update = true;
        this.refreshTableRowsNumber++;
      }
    })


  }

  calcAmount(volume: number | undefined, price: number | undefined) {
    var amount = (volume ?? 0) * (price ?? 0);
    return amount;
  }

  formatNumber(n: number | null | undefined): string {
    if (n == null)
      n = 0;

    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  closeDrawer() {
    this.drawerRef.close(this.update)
  }



  deleteDelivery(transactionId: string) {

    this.modal.create({
      nzTitle: this.tranPipe.transform("delete_pending_delivery", "Delete Pending Delivery", []),
      nzContent: this.tranPipe.transform("delete_pending_delivery_subtitle", "This delivery and related delivered will be deleted "),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_pending_delivery_button', 'Delete', []),
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteDelivery(this.tsvc.currentLanguage.value,
          {
            transactionId: transactionId,
            approve: false
          }), false, "internal-loader", 500, undefined, environment.MESSAGE_DURATION, (res: BooleanPurpleApiResponse) => {
            if (res.data != undefined && res.data == true) {
              this.mesSvc.success(this.tranPipe.transform("delete_pending_delivery_ok_response", "Delivery successfully deleted"), {
                nzDuration: environment.MESSAGE_DURATION
              });
              this.modal.closeAll();
            }
            else {
              this.mesSvc.error(this.tranPipe.transform("delete_pending_delivery_ko_response", "This delivery cannot be deleted"), {
                nzDuration: environment.MESSAGE_DURATION
              });
            }
          }).then(() => {
            this.refreshTableRowsNumber++;
          })
      }
    });

  }

}
