@for (number of [].constructor(buttonNumber); track i;let i = $index)
{
@switch (i)
{
@case (0)
{
<button nz-button
  class="purple-this-or-that-button left {{selected==0?'selected':''}} {{disabled?'disabled':''}}  {{isEditable?'is-editable':''}}"
  (click)="clckBtn(0)" [disabled]="disabled">
  {{text[0]}}
</button>
}
@case (buttonNumber-1)
{
<button nz-button
  class="purple-this-or-that-button right {{selected==buttonNumber-1?'selected':''}} {{disabled?'disabled':''}}  {{isEditable?'is-editable':''}}"
  (click)="clckBtn(buttonNumber-1)" [disabled]="disabled">
  {{text[buttonNumber-1]}}
</button>
}
@default
{
<button nz-button
  class="purple-this-or-that-button center {{selected==i?'selected':''}} {{disabled?'disabled':''}}  {{isEditable?'is-editable':''}}"
  (click)="clckBtn(i)" [disabled]="disabled" [disabled]="disabled">
  {{text[i]}}
</button>
}
}
}
