<nz-tabset [nzSelectedIndex]="tabIdx" (nzSelectedIndexChange)="onTabChange($event)">
  <nz-tab nzTitle="Stripo">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap stripo-mail-item-container ps-h-100">
      <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-h-100">
        <nz-spin class="ps-w-100" [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
          <nz-collapse class="ps-w-100 folder-collapse" [nzBordered]="false" [nzGhost]="true" [nzAccordion]="true">
            <nz-collapse-panel class="ps-w-100" #p *ngFor="let folder of templateFolders" [nzHeader]="folder.folder"
              [nzExpandedIcon]="expandedIcon">
              <div (click)="setCurrentTemplate(template)" class="stripo-mail-item ps-w-100 ps-d-flex 
                ps-align-items-center {{(template.id == selectedTemplate?.id)?'selected':''}}" *ngFor="let template of folder.templates">
                <div class="stripo-checkbox-container">
                  <label (ngModelChange)="setCurrentTemplate(template)" nz-checkbox [ngModel]="template.id == selectedTemplate?.id"></label>
                </div>
                <div class="stripo-text-container ps-d-flex ps-flex-wrap">
                  <p class="stripo-template-name ps-w-100 ps-mb-0">{{template.name}}</p>
                  <a [href]="template.previewImageUrl" target="_blank" class="stripo-template-preview-link ps-mb-0">{{
                    'import_stripo_show_preview' | translation: 'Mostra Antepima': []}}</a>
                </div>
              </div>
              <ng-template #expandedIcon let-active>
                {{ active }}
                <container-element [ngSwitch]="p.nzActive">
                  <ng-container *ngSwitchCase="true">
                    <span nz-icon nzTheme="twotone" nzType="folder-open" class="ant-collapse-arrow fsp-24-0"></span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <span nz-icon nzTheme="twotone" nzType="folder" class="ant-collapse-arrow fsp-24-0"></span>
                  </ng-container>
                </container-element>
              </ng-template>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-spin>
      </div>
    </div>
  </nz-tab>

  <nz-tab nzTitle="MailChimp">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap stripo-mail-item-container ps-h-100">
      <div class="ps-d-flex ps-flex-wrap ps-w-100 ps-h-100">
        <nz-spin class="ps-w-100" [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
          <nz-collapse class="ps-w-100 folder-collapse" [nzBordered]="false" [nzGhost]="true" [nzAccordion]="true">
            <nz-collapse-panel class="ps-w-100" #p *ngFor="let folder of templateFolders" [nzHeader]="folder.folder"
              [nzExpandedIcon]="expandedIcon">
              <div (click)="setCurrentTemplate(template)" class="stripo-mail-item ps-w-100 ps-d-flex 
                ps-align-items-center {{(template.id == selectedTemplate?.id)?'selected':''}}" *ngFor="let template of folder.templates">
                <div class="stripo-checkbox-container">
                  <label (ngModelChange)="setCurrentTemplate(template)" nz-checkbox [ngModel]="template.id == selectedTemplate?.id"></label>
                </div>
                <div class="stripo-text-container ps-d-flex ps-flex-wrap">
                  <p class="stripo-template-name ps-w-100 ps-mb-0">{{template.name}}</p>
                  <a [href]="template.previewImageUrl" target="_blank" class="stripo-template-preview-link ps-mb-0">{{
                    'import_stripo_show_preview' | translation: 'Mostra Antepima': []}}</a>
                </div>
              </div>
              <ng-template #expandedIcon let-active>
                {{ active }}
                <container-element [ngSwitch]="p.nzActive">
                  <ng-container *ngSwitchCase="true">
                    <span nz-icon nzTheme="twotone" nzType="folder-open" class="ant-collapse-arrow fsp-24-0"></span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <span nz-icon nzTheme="twotone" nzType="folder" class="ant-collapse-arrow fsp-24-0"></span>
                  </ng-container>
                </container-element>
              </ng-template> 
            </nz-collapse-panel>
          </nz-collapse>
        </nz-spin>
      </div>
    </div>    
  </nz-tab>
</nz-tabset>
<div class="purple-button-container ps-w-100 ps-d-flex">
  <button [disabled]="isLoading" (click)="reloadTemplateList(true)" nz-button class="purple-modal-button ps-mr-2"
    [nzLoading]="isLoading">
    {{ 'mail_template_reload_template_list' | translation: 'Sincronizza lista': []}}
  </button>
  <button [disabled]="isLoading" nzType="primary" (click)="getTemplateHtml()" nz-button class="purple-modal-button ps-ml-2"
    [nzLoading]="isLoading">
    {{ 'mail_template_import' | translation: 'Seleziona Template': []}}
  </button>
</div>


<ng-template #purpleModalLoaderTemplate>
  <div class="purple-modal-custom-spinner">
    <span nz-icon nzType="loading"></span>
  </div>
</ng-template>