<nz-spin [nzSpinning]="isLoading" [nzIndicator]="purpleModalLoaderTemplate">
  <app-purple-form *ngIf="purpleFormFieldGroups!= undefined" [disbledForm]="disableForm" [(formGroup)]="validateForm"
    [purpleFormFieldGroups]="purpleFormFieldGroups">
  </app-purple-form>
  </nz-spin>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
<div class="purple-button-container ps-w-100 ps-d-flex">
  <button (click)="editUser()" nz-button class="purple-modal-button" [disabled]="isLoading" [nzLoading]="isLoading">
    <ng-container *ngIf="!isNew">{{ 'edit_user_save_button' | translation: 'Salva Modifiche': []}}</ng-container>
    <ng-container *ngIf="isNew">{{ 'edit_user_add_new_user' | translation: 'Registra Utente': []}}</ng-container>
  </button>
</div>