import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PurplePipeModule, PurpleSelectModule, PurpleTableModule, PurpleGeneralDirectiveModule, PurpleEditImageModule, PurpleFormModule, PurpleLanguageIconPipe } from "purple-lib";
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCodeEditorModule } from 'ng-zorro-antd/code-editor';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableStyleService } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NotificationRoutingModule } from "./notification-routing.module";
import { EditSmtpServerComponent } from "./modals/edit-smtp-server/edit-smtp-server.component";
import { BaseSystemEventMailTemplatesComponent } from "./mail/base-system-event-mail-templates/base-system-event-mail-templates.component";
import { EditMailTemplateComponent } from "./modals/edit-mail-template/edit-mail-template.component";
import { BaseSystemEventNotificationsComponent } from "./base-system-event-push-notifications/base-system-event-push-notifications.component";
import { SmtpServersComponent } from "./mail/smtp-servers/smtp-servers.component";
import { ImportMailTemplateComponent } from "./modals/import-mail-template/import-mail-template.component";
import { NotificationExceptionsComponent } from "./notification-exceptions/notification-exceptions.component";
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { EditPushNotificationTemplateComponent } from "./modals/edit-push-notification-template/edit-push-notification-template.component";
import { EditNotificationExceptionComponent } from "./modals/edit-notification-exception/edit-notification-exception.component";

@NgModule({
  declarations: [SmtpServersComponent, EditSmtpServerComponent, BaseSystemEventMailTemplatesComponent, EditMailTemplateComponent, 
    BaseSystemEventNotificationsComponent, ImportMailTemplateComponent, NotificationExceptionsComponent, EditPushNotificationTemplateComponent,
  EditNotificationExceptionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzDropDownModule,
    NzButtonModule,
    NzToolTipModule,
    NzMessageModule,
    NzPopoverModule,
    NzModalModule,
    NzCheckboxModule,
    PurplePipeModule,
    NzDescriptionsModule,
    NzSelectModule,
    NzInputModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzIconModule,
    NzSwitchModule,
    NzFormModule,
    PurpleSelectModule,
    NzCodeEditorModule,
    NzTabsModule,
    NzCollapseModule,
    PurpleTableModule,
    NzTableModule,
    PurpleGeneralDirectiveModule,
    NotificationRoutingModule,
    NzBadgeModule,
    PurpleEditImageModule,
    NzImageModule,
    NzSpinModule,
    NzTagModule,
    NzAlertModule,
    PurpleFormModule
  ],
  exports:[],
  providers:[NzTableStyleService]
})
export class NotificationModule { }
