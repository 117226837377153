/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Vintages } from './vintages';


export interface DeliveryRequest { 
    deliveryDate?: string;
    deliveryVintages?: Array<Vintages> | null;
    additional?: string | null;
    volume?: number;
    price?: number;
    currency?: string | null;
    technologies?: Array<string> | null;
    technologiesClusters?: Array<string> | null;
    technologiesToExclude?: Array<string> | null;
    origins?: Array<string> | null;
    originsClusters?: Array<string> | null;
    originsToExclude?: Array<string> | null;
    counterPartyId?: string | null;
    dynamicFields?: { [key: string]: string | null; } | null;
}

