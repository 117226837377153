import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-deal-ticket-stepper',
  templateUrl: './deal-ticket-stepper.component.html',
  styleUrls: ['./deal-ticket-stepper.component.scss']
})
export class DealTicketStepperComponent implements OnInit {

  constructor() { }
  @Input() currentStepIdx: number = 0;
  @Input() steps!: Step[];
  @Input() width?: string = "100%";

  ngOnInit() {
  }

}

export interface Step{
  title: string;
  description?: string | undefined;
}
