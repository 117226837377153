/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RecipientPRPTemplatesRecipientNullableReference } from './recipientPRPTemplatesRecipientNullableReference';
import { DocumentPRPTemplatesDocumentNullableReference } from './documentPRPTemplatesDocumentNullableReference';
import { TabsAttributeNullable } from './tabsAttributeNullable';
import { TabPRPTemplatesTabNullableReference } from './tabPRPTemplatesTabNullableReference';
import { RecipientsAttributeNullable } from './recipientsAttributeNullable';
import { EnvelopePRPTemplatesEnvelopeNullableReference } from './envelopePRPTemplatesEnvelopeNullableReference';


export interface RealizeTemplateRequest { 
    envelope?: EnvelopePRPTemplatesEnvelopeNullableReference;
    recipients?: Array<RecipientPRPTemplatesRecipientNullableReference> | null;
    documents?: Array<DocumentPRPTemplatesDocumentNullableReference> | null;
    tabs?: Array<TabPRPTemplatesTabNullableReference> | null;
    tabAttributes?: Array<TabsAttributeNullable> | null;
    recipientsAttributes?: Array<RecipientsAttributeNullable> | null;
    forceExplicitRealizations?: boolean;
    doNotWriteToDb?: boolean;
}

