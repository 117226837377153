import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import { IAppTranslationService, PurpleLoaderService, PurpleStorageService } from 'purple-lib';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language, PurpleApiResponseStatus } from '../sdk/api';
import * as api from '../sdk/api/api/localization.service';

@Injectable({
  providedIn: 'root'
})
export class AppTranslationService implements IAppTranslationService {
  availableLanguages: Language[] = [];
  availableLanguagesInitialized: boolean = false;
  translations: Map<string,Map<string, string>> | undefined | null;
  currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(environment.DEFAULT_LANGUAGE);
  isInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  editTranAffix: string = "";
  newTranSymbol: string = "";

  translationLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(environment.DEFAULT_LANGUAGE);

  newTranslations: Map<string, string> = new Map<string, string>();

  private readonly language = "Language";


  constructor(private storageSvc: PurpleStorageService, private localizationSvc: api.LocalizationService, private router: Router, private loaderSvc: PurpleLoaderService) {
    this.editTranAffix = environment.EDIT_TRAN_AFFIX;
    this.newTranSymbol = environment.NEW_TRAN_SYMBOL;
  }

  changeTranslationLanguage(languageId: string): void{
    this.translationLanguage.next(languageId);
  }


  async getLanguages(){
    const res = await lastValueFrom(this.localizationSvc.getLanguages(this.currentLanguage.getValue()))
    if(res.status == PurpleApiResponseStatus.Success){
      this.availableLanguages = res.data??[];
      this.availableLanguagesInitialized = true;
    }
  }

  async checkIfCultureExist(cultureId: string, url: string) : Promise<boolean | UrlTree>{

    await this.getLanguages()
    
    const resIdx = this.availableLanguages.findIndex(f=> f.languageId == cultureId);    
    if(resIdx != -1){
      this.storageSvc.set(this.language, cultureId);
      this.currentLanguage.next(cultureId);
      
      return true
    }else{
      this.storageSvc.set(this.language, environment.DEFAULT_LANGUAGE);
      this.currentLanguage.next(environment.DEFAULT_LANGUAGE);
      const newUrl = url.replace(cultureId, environment.DEFAULT_LANGUAGE);

      return this.router.parseUrl(newUrl);
    }

  }


  async initialize(forceRefresh: boolean = false): Promise<void> {

    try {
      if (!forceRefresh && this.isInitialized.value) {
        return;
      }
  
      this.loaderSvc.addRequest("purple-global");
      const lang = this.currentLanguage.value;
      const currentCrc = this.storageSvc.get<string>("TranslationCrc") ?? undefined;
      const check = await lastValueFrom(this.localizationSvc.checkLocalizationUpdates(lang, {
        checksum: currentCrc
      }));
  
      if (!check.data) {
  
        const updTran = await lastValueFrom(this.localizationSvc.getAllTranslationValues(lang));
        //Update crc
        this.storageSvc.set("TranslationCrc", updTran.data!.checksum)
  
        //Update translations
        this.translations = (updTran!.data!.languages! as any).reduce(function(map: any, obj: any) {
          map[obj.languageId!] = obj.translations.reduce((m: any, o: any)=>{
            m[o.key!] = o.value
            return m
          });
          return map;
      }, {});
  
      this.storageSvc.set("Translations", this.translations)
      } else {
        this.translations = this.storageSvc.get("Translations");
      }
      this.loaderSvc.removeRequest("purple-global");
      this.isInitialized.next(true);
    } catch (error) {
      
    }
  }

  async switchLanguage(lang: string) {
    this.loaderSvc.addRequest("purple-global");
    const queryParams: Params = this.router.parseUrl(this.router.url).queryParams;

    var newUrl = this.router.url.replace(this.currentLanguage.value, lang);
    if (newUrl.includes("?")) {
      newUrl = newUrl.split("?")[0]
    }

    this.storageSvc.set(this.language, lang);
    this.currentLanguage.next(lang);
    await this.initialize(true);

    await this.router.navigate([newUrl], {
      replaceUrl: true,
      queryParams: queryParams
    });

    window.location.reload();
  }

  async addOrUpdateTranslation(key: string, value:string):Promise<string | undefined>{

    var res = await lastValueFrom(this.localizationSvc.addOrUpdateTranslationValue(this.currentLanguage.value,{
      key: key,
      value: value
    }));

    return res.data??undefined;
  }

  async checkTranslationUpdate(checksum: string):Promise<boolean>{

    var res = await lastValueFrom(this.localizationSvc.checkLocalizationUpdates(this.currentLanguage.value,{
      checksum: checksum
    }));

    return res.data??false;
  }

  
  async getTranslationsChecksum():Promise<string>{

    var res = await lastValueFrom(this.localizationSvc.getChecksum(this.currentLanguage.value));

    return res.data??"";
  }
}
