import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Helper, PurpleApiProxyService, PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ClusterMapGrouped, ClusterMapGroupedString, StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-origin-cluster',
  templateUrl: './origin-cluster.component.html',
  styleUrls: ['./origin-cluster.component.scss']
})
export class OriginClusterComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(NZ_MODAL_DATA) public data: { modalOrigin: OriginFull | undefined, isCounterpart: boolean }, private apiProxySvc: PurpleApiProxyService, private tranPipe: PurpleTranslationPipe, private loaderSvc: PurpleLoaderService,
    private tsvc: AppTranslationService, private admSvc: StellaService, private msgSvc: NzMessageService, private modalRef: NzModalRef) { }

  clusterMaps: ClusterMapGroupedString[] = [];
  selectedOrigins: { [key: string]: boolean } = {};
  selectedCluster: { [key: string]: boolean } = {};
  selectedExcludedOrigin: { [key: string]: boolean } = {};

  originArray: string[] = []

  toExclude: string[] = [];

  toNotShowClusters: string[] = [];


  show: boolean = false;

  origins: OriginFull =
    {
      clusters: [],
      origin: [],
      toExclude: [],
      originString: ""
    }

  originFullString: string = this.tranPipe.transform("no_origin_selected_text", "No Origin Selected");



  async ngOnInit() {

    await this.getClusters();





  }




  async getClusters() {
    await this.apiProxySvc.makeRequestErrorFunction<ClusterMapGroupedString[]>(() => this.admSvc.getOriginClusterMap(this.tsvc.currentLanguage.value),
      true,
      "internal-loader",
      100,
      () => this.getClusters(), (res) => {
        this.clusterMaps = res.data!


        this.clusterMaps.forEach(cm => {

          cm.clusterValues!.forEach(origin => {

            if (!this.originArray.includes(origin))
              this.originArray.push(origin)

          });

        });




        if (this.data.modalOrigin != undefined) {
          if (this.data.modalOrigin!.origin != undefined)
            this.data.modalOrigin!.origin.forEach(origin => {
              if (origin != undefined && origin != '')
                this.onRowClickOrigin(origin);
            });

          if (this.data.modalOrigin!.clusters != undefined && this.selectedCluster == undefined)
            this.data.modalOrigin!.clusters.forEach(cluster => {


              if (cluster != undefined && cluster != '')
                this.onRowClickCluster(cluster);
            });
          if (this.data.modalOrigin!.toExclude != undefined)
            this.data.modalOrigin!.toExclude.forEach(origin => {
              if (origin != undefined && origin != '')
                this.onRowClickToExclude(origin);
            });

        }



        this.show = true;
      })
  }



  onRowClickOrigin(origin: string) {

    var checked = this.selectedOrigins[origin] != undefined ? !this.selectedOrigins[origin] : true;


    this.selectedOrigins[origin] = checked;

    if (checked) {
      if (this.selectedExcludedOrigin[origin] == true) {
        this.onRowClickToExclude(origin)
      }
      this.origins.origin != undefined ? this.origins.origin!.push(origin) : this.origins.origin = [origin];
    }
    else {
      if (this.toExclude.includes(origin)) {
        this.onRowClickToExclude(origin)

      }
      this.origins.origin = this.origins.origin!.filter(o => o !== origin);
    }

    if (checked) {
      var m = this.clusterMaps.filter(s => s.clusterValues?.includes(origin))!;

      this.updateClustersValues(m);

    }



  }







  updateClustersValues(clusterMaps: ClusterMapGroupedString[]) {



    clusterMaps.forEach(clusterMap => {

      var allClusterOriginSelected = true;

      clusterMap.clusterValues!.forEach(n => {
        if (this.selectedOrigins[n] != true) {
          allClusterOriginSelected = false;
        }
      });

      this.selectedCluster[clusterMap.clsuterName!] = allClusterOriginSelected;

      if (allClusterOriginSelected) {

        if (!this.origins.clusters?.includes(clusterMap.clsuterName!))
          this.origins.clusters?.push(clusterMap.clsuterName!);
      }
    });

    this.updateOriginToExclude();

  }



  onRowClickCluster(cluster: string) {

    var checked = this.selectedCluster[cluster] != undefined ? !this.selectedCluster[cluster] : true;


    this.selectedCluster[cluster] = checked;

    if (checked) {
      if (!this.origins.clusters?.includes(cluster))
        this.origins.clusters?.push(cluster);
    }
    else {

      this.origins.clusters = this.origins.clusters!.filter(c =>
        c !== cluster
      );
    }

    var m = Helper.storeNewObj(this.clusterMaps.find(s => s.clsuterName?.strEq(cluster))!);

    this.updateOriginValues(m, checked);


  }



  updateOriginValues(clusterMap: ClusterMapGroupedString, checked: boolean) {

    clusterMap.clusterValues!.forEach(n => {
      if (this.selectedExcludedOrigin[n] == true && checked)
        return;
      this.selectedOrigins[n] = checked;
      if (checked) {
        if (!this.origins.origin!.includes(n))
          this.origins.origin!.push(n)
      }
      else {
        this.origins.origin = this.origins.origin!.filter(origin => origin !== n);
      }
    });

    (this.origins.clusters ?? []).forEach(cluster => {

      this.clusterMaps.find(c => c.clsuterName!.strEq(cluster))!.clusterValues!.forEach(origin => {
        if (this.selectedExcludedOrigin[origin] == true)
          return;
        this.selectedOrigins[origin] = true;
        if (!this.origins.origin?.includes(origin))
          this.origins.origin?.push(origin)
      });

    });


    this.updateOriginToExclude();
  }

  updateOriginToExclude() {


    this.toExclude = [];

    (this.origins.clusters ?? []).forEach(cluster => {

      var c = this.clusterMaps.find(c => c.clsuterName?.strEq(cluster));

      c!.clusterValues!.forEach(origin => {


        if (!this.toExclude.includes(origin))
          this.toExclude.push(origin)

      });

    });


    for (const key in this.selectedExcludedOrigin) {
      if (!this.toExclude.includes(key))
        this.selectedExcludedOrigin[key] = false;
    }

    (this.origins.toExclude ?? []).forEach(o => {
      if (!this.toExclude.includes(o))
        this.origins.toExclude = this.origins.toExclude?.filter(s => s != o)

    });

    this.updateOriginString();
  }


  onRowClickToExclude(origin: string) {


    var checked = this.selectedExcludedOrigin[origin] != undefined ? !this.selectedExcludedOrigin[origin] : true;


    this.selectedExcludedOrigin[origin] = checked;



    if (checked) {
      this.selectedOrigins[origin] = false;
      this.origins.toExclude != undefined ? this.origins.toExclude!.push(origin) : this.origins.toExclude = [origin];
    }
    else {
      this.selectedOrigins[origin] = true;
      this.origins.toExclude = this.origins.toExclude!.filter(o => o !== origin);
    }


    this.updateOriginString();

  }



  updateOriginString() {


    var tempOrigins = Helper.storeNewObj(this.origins.origin);
    var tempCluster = Helper.storeNewObj(this.origins.clusters);

    var tempToExclude = Helper.storeNewObj(this.origins.toExclude);


    var finalStringOriginAndCluster: string[] = [];
    var finalStringToExclude: string[] = [];


    (tempCluster ?? []).forEach(cluster => {

      finalStringOriginAndCluster.push(cluster);

      var c = this.clusterMaps.find(c => c.clsuterName?.strEq(cluster));

      c!.clusterValues!.forEach(origin => {
        if (tempOrigins?.includes(origin))
          tempOrigins = tempOrigins.filter(o => o != origin)
      });

    });

    (tempOrigins ?? []).forEach(origin => {
      finalStringOriginAndCluster.push(origin);

    });

    (tempToExclude ?? []).forEach(oToExclude => {
      finalStringToExclude.push(oToExclude);

    });



    var string1 = finalStringOriginAndCluster.join("+");

    var string2 = finalStringToExclude.join("+");


    if (finalStringToExclude.length > 0) {
      this.originFullString = string1.concat("|excl.", string2);
    }
    else
      this.originFullString = string1




    if (this.originFullString == "") {
      this.originFullString = this.tranPipe.transform("no_origin_selected_text", "No Origin Selected");
    }
  }


  clearAll() {

    for (const key in this.selectedOrigins) {
      this.selectedOrigins[key] = false;
    }
    for (const key in this.selectedCluster) {
      this.selectedCluster[key] = false;
    }
    for (const key in this.selectedExcludedOrigin) {
      this.selectedExcludedOrigin[key] = false
    }

    this.origins =
    {
      clusters: [],
      origin: [],
      toExclude: [],
      originString: ""
    }

    this.toExclude = []

    this.originFullString = this.tranPipe.transform("no_origin_selected_text", "No Origin Selected");

  }






  saveOrigin() {
    var returnValue: OriginFull =
    {
      clusters: this.origins.clusters,
      origin: this.origins.origin,
      toExclude: this.origins.toExclude,
      originString: this.originFullString
    }

    this.modalRef.destroy(returnValue);
  }


}



export interface OriginFull {
  origin: string[] | undefined;
  clusters: string[] | undefined;
  toExclude: string[] | undefined;
  originString: string | null | undefined;
}
