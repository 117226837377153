<ng-container *ngIf="dashSvc.dashboardHeight != undefined">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"
    [isSelectRowActive]="false" [version]="2" [showCustomPagination]="true" tableSearchType="box"
    (filterBox)="dashSvc.setPurpleTablePopover($event)" (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()"
    [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">


    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.counterPartyName}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          <td nzAlign="center">

            <app-stella-table-button buttonIcon="icon-plus"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-edit"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-file"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-x-close"></app-stella-table-button>
           <!--  <span class="icon-plus fsp-18-0"></span>
            <span class="icon-edit fsp-18-0"></span>
            <span class="icon-file fsp-18-0"></span>
            <span class="icon-x-close fsp-18-0"></span>
 -->
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>
