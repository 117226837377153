import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { DashboardLayout2Component } from './dashboard-layout-2/dashboard-layout-2.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    component: DashboardLayout2Component,
    children: [
      {
        path: '',
        redirectTo: 'administration',
        pathMatch: "full"
      },
      {
        path: 'administration',
        canActivate: [],
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        data: { menu: "administration", submenu: "customers" }
      },
      {
        path: 'notification',
        canActivate: [],
        loadChildren: () => import('./notifications/notification.module').then(m => m.NotificationModule),
        data: { menu: "notification", submenu: "smtp-servers" }
      },
      {
        path: 'general',
        canActivate: [],
        loadChildren: () => import('./general/general.module').then(m => m.GeneralModule),
        data: { menu: "general", submenu: "translation" }
      },
      {
        path: 'stella-frontoffice',
        canActivate: [],
        loadChildren: () => import('./stella/frontoffice/stella-frontoffice.module').then(m => m.StellaFrontofficeModule),
        data: { menu: "stella-frontoffice", submenu: "deal-ticket" }
      },
      {
        path: 'stella-backoffice',
        canActivate: [],
        loadChildren: () => import('./stella/backoffice/stella-backoffice.module').then(m => m.StellaBackofficeModule),
        data: { menu: "stella-backoffice", submenu: "counterparties" }
      },
      {
        path: 'stella-reporting',
        canActivate: [],
        loadChildren: () => import('./stella/reporting/stella-reporting.module').then(m => m.StellaReportingModule),
        data: { menu: "stella-reporting", submenu: "overall-by-product" }
      }
    ]
  }];

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
