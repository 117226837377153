import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { differenceInCalendarDays, getYear } from 'date-fns';
import { Nc } from 'src/lib-wish/nc';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, dashboardPurpleSelect, dashboardDate, dashboardDateRange, dashboardButton, Helper } from 'purple-lib';
import { Subject } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { MtmPriceResponse, MtmPriceTechnology, MtmPriceVintage, StellaService } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mtm-prices',
  templateUrl: './mtm-prices.component.html',
  styleUrls: ['./mtm-prices.component.scss'],
})
export class MtmPricesComponent implements OnInit {

  range(arg0: number, arg1: any): number[] {
    throw new Error('Method not implemented.');
  }

  constructor(
    private tranPipe: PurpleTranslationPipe,
    public dashSvc: DashboardLayout2Service,
    private apiProxySvc: PurpleApiProxyService,
    private admSvc: StellaService,
    private tsvc: AppTranslationService,
    private currencyPipe: CurrencyPipe,
    private numberPipe: DecimalPipe,
    private route: ActivatedRoute,
    private router: Router,
    private modal: NzModalService,
    private mesSvc: NzMessageService
  ) { }
  sub: Subject<void> = new Subject();

  tableInfo: MtmPriceResponse | undefined;
  currencyId: string = 'eur';
  startYear: number = 2018;
  endYear: number = 2030;
  productId: string = 'rego';
  mtmDate: Date = new Date();

  mtmDateRange: Date[] | undefined;
  maxYear: number = 0;
  minYear: number = 2018;

  isEditingModeEnabled: boolean = false;
  originalEditableValue: MtmPriceTechnology[] = [];

  async ngOnInit() {
    const currentYear = getYear(new Date());
    this.maxYear = currentYear + 10;

    this.route.queryParams.subscribe(async (params) => {
      this.currencyId = Nc.IsNull(params['currency'], 'eur');
      this.startYear = Nc.IsNullCast(
        params['startYear'],
        currentYear - 2,
        (str) => +str
      );
      this.endYear = Nc.IsNullCast(
        params['endYear'],
        currentYear + 2,
        (str) => +str
      );
      this.productId = Nc.IsNull(params['product'], 'goo');
      this.mtmDate = Nc.IsNullCast(
        params['mtmDate'],
        new Date(Date.now()),
        (str) => {
          console.log('param', str);
          return new Date(Date.parse(str));
        }
      );

      console.log('I filtri: ', {
        currency: this.currencyId,
        startYear: this.startYear,
        endYear: this.endYear,
        product: this.productId,
        mtmDate: this.mtmDate,
      });

      this.mtmDateRange = [
        new Date(this.startYear, 2, 1),
        new Date(this.endYear, 2, 1),
      ];

      this.dashSvc.dashMainItems = [];

      this.dashSvc.dashMainItems?.push(
        new dashboardDate(
          'mtm-date',
          'shortDate',
          (date: Date) => {
            this.mtmDate = date;
            this.setRouteParameters(true);
          },
          this.mtmDate,
          undefined,
          this.tranPipe.transform(
            'mtm_page_select_date',
            'Seleziona la data',
            []
          ),
          undefined,
          true
        ),
        new dashboardPurpleSelect(
          'currency-select',
          this.currenciesScrollFunction,
          this.currenciesSearchFunction,
          this.currenciesSearchByIdFunction,
          (id: any) => {
            this.currencyId = id;
            this.setRouteParameters(true);
          },
          this.currencyId,
          undefined,
          undefined,
          this.tranPipe.transform(
            'mtm_page_select_currency',
            'Select currency',
            []
          ),
          false,
          false,
          'currency-header-select'
        ),
        new dashboardPurpleSelect(
          'product-select',
          this.productsScrollFunction,
          this.productsSearchFunction,
          this.productsSearchByIdFunction,
          (id: any) => {
            this.productId = id;
            this.setRouteParameters(true);
          },
          this.productId,
          undefined,
          undefined,
          this.tranPipe.transform(
            'mtm_page_select_product',
            'Select product',
            []
          ),
          true,
          false,
          'product-header-select'
        ),
        new dashboardDateRange(
          'range-years',
          'yyyy',
          (dates: Date[]) => {
            this.mtmDateRange = dates;
            this.startYear = dates[0].getFullYear();
            this.endYear = dates[1].getFullYear();
            this.setRouteParameters(true);
          },
          'year',
          this.mtmDateRange,
          undefined,
          this.tranPipe.transform('mtm_page_select_range_year', 'Anni', []),
          undefined,
          true,
          false,
          this.disabledDate
        ),
        new dashboardButton(
          'edit-mode-button',
          this.tranPipe.transform(
            'enable_edit_mtm_price',
            'Modifica prezzi',
            []
          ),
          async () => {
            this.isEditingModeEnabled = !this.isEditingModeEnabled;

            if (this.isEditingModeEnabled) {
              (
                this.dashSvc.dashMainItems.find((f) =>
                  f.id.strEq('edit-mode-button')
                ) as dashboardButton
              ).buttonText = this.tranPipe.transform(
                'disable_edit_mtm_price',
                'Salva modifiche',
                []
              );
            } else {
              await this.updateMtmPrices();
              (
                this.dashSvc.dashMainItems.find((f) =>
                  f.id.strEq('edit-mode-button')
                ) as dashboardButton
              ).buttonText = this.tranPipe.transform(
                'enable_edit_mtm_price',
                'Modifica prezzi',
                []
              );
            }
          },
          'primary'
        )
      );

      setTimeout(async () => {
        await this.refreshMtmPrices();
      }, 100);
    });
  }

  async refreshMtmPrices() {
    await this.apiProxySvc.makeRequestErrorPage<MtmPriceResponse>(
      () =>
        this.admSvc.getMpmPrices(this.tsvc.currentLanguage.value, {
          currencyId: this.currencyId,
          startYear: this.startYear,
          endYear: this.endYear,
          productId: this.productId,
          mtmDate: this.mtmDate.toIsoStringPurple(),
        }),
      true,
      'internal-loader',
      500,
      'internal-error',
      () => { },
      (res: PurpleApiMakeRequestResponse<MtmPriceResponse>) => {
        this.tableInfo = res.data;
        this.originalEditableValue = Helper.storeNewObj(res.data?.technologies ?? []);
      }
    );
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = 'stella_frontoffice_mtm_prices';
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'MTM Prices', []),
      this.tranPipe.transform(
        pageName + '_page_subtitle',
        'Text explaning what this page is about',
        []
      )
    );
  }

  getPrice(item: MtmPriceVintage, currencyId: string): string {
    if (item.mtmId == undefined || item.mtmPrice == null) {
      return '-';
    } else {
      //return this.currencyPipe.transform(item.mtmPrice, currencyId.toUpperCase())!
      return (
        this.numberPipe.transform(
          item.mtmPrice,
          '1.2-3',
          this.tsvc.currentLanguage.value
        ) ?? '-'
      );
    }
  }

  async setRouteParameters(refresh: boolean = false) {
    console.log('Setto i parametri di route');
    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: {
        currency: this.currencyId,
        startYear: this.startYear,
        endYear: this.endYear,
        product: this.productId,
        mtmDate: this.mtmDate.toIsoStringPurple(),
      },
      relativeTo: this.route,
    });

    if (refresh) {
      await this.refreshMtmPrices();
    }
  }


  async updateMtmPrices() {
    if (Helper.checkIfDoChanges(this.originalEditableValue, this.tableInfo?.technologies)) {
      this.modal.create({
        nzTitle: this.tranPipe.transform("update_mtm_prices_title", "Vuoi aggiornare i prezzi MTM?", []),
        nzContent: this.tranPipe.transform("update_mtm_prices_subtitle", "Tutti prezzi modificati verranno aggiornati", []),
        nzWidth: '600px',
        nzClassName: 'purple-simple-modal',
        nzOkText: this.tranPipe.transform('update_mtm_prices_button', 'Aggiorna', []),
        nzOnOk: async () =>
          await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addOrUpdateMpmPrices(this.tsvc.currentLanguage.value, {
            currencyId: this.currencyId,
            mtmDate: this.mtmDate.toIsoStringPurple(),
            productId: this.productId,
            mtms: this.tableInfo!.technologies.map(m => {
              return m.prices.map(m1 => {
                //let price = -1;

                //if(m1.mtmPrice != null && m1.mtmPrice != undefined && m1.mtmPrice != 0){
                  //price = m1.mtmPrice
                //}

                return {
                  mtmPriceValue: (m1.mtmPrice ?? -1),
                  mtmId: m1.mtmId,
                  technologyId: m.technologyId,
                  year: m1.mtmVintage
                };
              });
            }).flat()
          }), true, "internal-loader", 500, undefined, undefined, async (res: PurpleApiMakeRequestResponse<boolean>) => {
            if (res) {
              await this.refreshMtmPrices()
            }
          })
      }
      )
    } else {
      this.mesSvc.warning(this.tranPipe.transform('no_changes', 'Nessuna modifica effettuata', []), { nzDuration: environment.MESSAGE_DURATION })
    }


  }


  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.minYear - 1) > 0 &&
    differenceInCalendarDays(current, this.maxYear + 1) < 0;

  //ScrollFunction
  currenciesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesSelect(args.culture, args);
  };
  //SearchFunction
  currenciesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  currenciesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByIdSelect(args.culture, args);
  };

  //ScrollFunction
  productsScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsSelect(args.culture, args);
  };
  //SearchFunction
  productsSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  productsSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByIdSelect(args.culture, args);
  };
}
