import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { BackOfficeNotificationService, SmtpServer, SystemEventMailTemplateItem } from 'src/core-modules/sdk/api';
import { editor } from 'monaco-editor';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Helper, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { environment } from 'src/environments/environment';
import { EditSmtpServerComponent } from '../edit-smtp-server/edit-smtp-server.component';
import { ImportMailTemplateComponent } from '../import-mail-template/import-mail-template.component';
import { PurpleApiProxyService } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-mail-template',
  templateUrl: './edit-mail-template.component.html',
  styleUrls: ['./edit-mail-template.component.scss'],
})
export class EditMailTemplateComponent implements OnInit, OnDestroy {
  newObj!: SystemEventMailTemplateItem;
  previewText: string | undefined = undefined;

  currentTabIdx: number = 0;
  editor?: editor.ICodeEditor | editor.IEditor;

  provaImg: any | undefined;
  isNotOpenNewMailTemplate: boolean = true;
  isNotOpenNewSmtp: boolean = true;


  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  previewButtonText: string = "";
  sub: Subject<void> = new Subject();

  constructor(
    @Inject(NZ_MODAL_DATA) public data: {systemEventMailTemplate: SystemEventMailTemplateItem, systemEventId: string, systemEventName: string, isPreview: boolean},
    private mesSvc: NzMessageService,
    private backNotSvc: BackOfficeNotificationService,
    private tsvc: AppTranslationService,
    private tranPipe: PurpleTranslationPipe,
    private modal: NzModalService,
    private fb: FormBuilder,
    private apiProxySvc: PurpleApiProxyService
  ) { }


  ngOnDestroy(): void {
    this.sub.next();
    this.sub.complete();
  }

  async ngOnInit(): Promise<void> {
    this.newObj = Helper.storeNewObj(this.data.systemEventMailTemplate);
    this.previewText = this.newObj.mailContent!.replace('https://{{host}}', environment.API_BASE_PATH);

    if (this.data.isPreview) {
      this.previewButtonText = this.tranPipe.transform('edit_mail_back_to_editor', 'Torna all\'editor', []);
    } else {
      this.previewButtonText = this.tranPipe.transform('edit_mail_show_preview', 'Mostra Anteprima', []);
    }
    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: true,
        fieldGroupPaddingRight: true,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'smtp',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_mail_template_smtp', 'Configurazione Smtp', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_smtp_placeholder', 'Seleziona una configurazione Smtp tra quelle disponibili', []),
            fieldOptions: [
              { id: 'scrollFunction', value: this.smtpServerScrollFunction },
              { id: 'searchFunction', value: this.smtpServerSearchFunction },
              { id: 'searchByIdFunction', value: this.smtpServerSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }
            ],
            fieldValue: this.newObj.smtpId,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'senderName',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_mail_template_sender_name', 'Nome del mittente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_sender_name_placeholder', 'Inserisci qui il nome del mittente della email', []),

            fieldValue: this.newObj.senderName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'senderEmail',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_mail_template_sender_email', 'Indirizzo Email del mittente', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_sender_email_placeholder', 'Inserisci qui l\'indirizzo email del mittente della email', []),

            fieldValue: this.newObj.senderEmail,
            fieldIsRequired: true,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'subject',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_mail_template_subject', 'Soggetto Email', []),
            fieldPlaceholder: this.tranPipe.transform('edit_mail_template_subject_placeholder', 'Inserisci qui il soggetto della email', []),

            fieldValue: this.newObj.mailSubject,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
        ]
      }
    ]
  }

  //#region Select
  //ScrollFunction
  smtpServerScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backNotSvc.getSmtpServersSelect(args.culture, args)
  }
  //SearchFunction
  smtpServerSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backNotSvc.getSmtpServersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  smtpServerSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backNotSvc.getSmtpServersByIdSelect(args.culture, args)
  }
  //#endregion

  importMailTemplate() {
    this.modal.create({
      nzContent: ImportMailTemplateComponent,
      nzTitle: this.tranPipe.transform('edit_mail_import_from_stripo_modal_title', 'Importa template da Stripo', []),
      nzWidth: '800px',
      nzClassName: 'purple-modal import-mail-template-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe((htmlContent: string | null | undefined) => {

      if (htmlContent != null && htmlContent != undefined) {
        this.newObj.mailContent = htmlContent
      }
    });
  }

  togglePreviewButton() {
    if (!this.data.isPreview) {
      this.previewText = this.newObj!.mailContent!.replace(
        'https://{{host}}',
        environment.API_BASE_PATH
      );

      this.previewButtonText = this.tranPipe.transform('edit_mail_back_to_editor', 'Torna all\'editor', []);
    } else {
      this.previewButtonText = this.tranPipe.transform('edit_mail_show_preview', 'Mostra Anteprima', []);
    }

    this.data.isPreview = !this.data.isPreview;
  }

  onEditorInit(e: editor.ICodeEditor | editor.IEditor): void {
    console.log('On editor init');
    this.editor = e;
    setTimeout(() => {
      this.editor!.trigger('', 'editor.action.formatDocument', null);
    }, 100);
  }

  checkReplacers(): string[] {
    var re = new RegExp("(?<=\{\{)(.*?)(?=\}\})", "g")

    const results = [...new Set((re.exec(this.newObj.mailContent) as RegExpExecArray | null)?.map((v, i) => v))];
    console.log("Replacers: ", results)
    const reps = this.newObj.systemEventReplacers.map(m => m.eventReplacerId);
    const diff = results.filter(x => !reps.includes(x));
    return diff;
  }

  async saveTemplateChanges() {
    this.isLoading = true;

    this.newObj.mailSubject = this.validateForm.controls["subject"].value;
    this.newObj.senderName = this.validateForm.controls["senderName"].value;
    this.newObj.senderEmail = this.validateForm.controls["senderEmail"].value;

    if (Helper.checkIfDoChanges(this.data.systemEventMailTemplate, this.newObj)) {

      var check = this.newObj.mailContent.length > 0;
      var check1: string[] = this.checkReplacers();

      this.validateForm.controls["smtp"].setValue(this.newObj.smtpId);

      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }

      //var check2 = this.validateForm.valid;


      if (!check) {
        this.currentTabIdx = 0;
        this.mesSvc.error(this.tranPipe.transform('edit_mail_template_html_content_error', 'Inserisci codice html valido', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });

        this.isLoading = false;
      }
      else if (check1.length > 0) {
        this.currentTabIdx = 0;
        this.mesSvc.error(this.tranPipe.transform('edit_mail_template_html_content_error_replacer', 'Replacer non validi: {0}', [check1.join(", ")]), {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.isLoading = false;
      }
      else if (!this.validateForm.valid) {
        this.currentTabIdx = 1;
        this.mesSvc.error(this.tranPipe.transform('edit_mail_template_general_parameter_error', 'Compila tutti i campi correttamente', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.isLoading = false;
      } else {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backNotSvc.updateSystemEventMailTemplate(this.tsvc.translationLanguage.value, {
          mailContent: this.newObj.mailContent,
          mailSubject: this.newObj.mailSubject,
          pk1: this.newObj.pk1,
          pk2: this.newObj.pk2,
          senderEmail: this.newObj.senderEmail,
          senderName: this.newObj.senderName,
          smtpId: this.newObj.smtpId,
          systemEventId: this.data.systemEventId
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success(this.tranPipe.transform('update_success', 'Aggiornato con successo', []), {
            nzDuration: environment.MESSAGE_DURATION,
          });
          this.data.systemEventMailTemplate = this.newObj;
          this.newObj = Helper.storeNewObj(this.data.systemEventMailTemplate);
        })

        this.isLoading = false;
      }
    } else {
      this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata'), {
        nzDuration: environment.MESSAGE_DURATION,
      });
      this.isLoading = false;
    }
  }

  copyToClipboard(event: Event, textToCopy: string) {
    event.stopPropagation();
    this.mesSvc.info(this.tranPipe.transform('edit_mail_template_copy_to_clipboard', 'Replacer copiato negli appunti', []))

    navigator.clipboard.writeText("{{" + textToCopy + "}}");
  }

  addSmtpServer() {
    this.isNotOpenNewSmtp = false;
    this.modal.create({
      nzContent: EditSmtpServerComponent,
      nzTitle: this.tranPipe.transform('add_new_smtp_modal_title', 'Aggiungi nuovo smtp', []),
      nzWidth: '1000px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (smtpServer: SmtpServer) => {
      if (smtpServer != undefined) {
        this.newObj.smtpId = smtpServer.smtpId;
      }
      this.isNotOpenNewSmtp = true;
    });
  }
}
