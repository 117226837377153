import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Helper, PurpleApiProxyService, PurpleTableBoxColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { DealPendingFullInfo, DealPendingFullInfoPurpleTableResponse, StellaService } from 'src/core-modules/sdk/api';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { ViewDeliveriesComponent } from '../../backoffice/modals/view-deliveries/view-deliveries.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef, NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { EditDealComponent } from '../deal-ticket/modals/edit-deal/edit-deal.component';
import { DealTicket } from '../deal-ticket/custom-component/deal-ticket-summary/deal-ticket-summary.component';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Delivery } from '../deal-ticket/modals/deal-ticket-delivery/deal-ticket-delivery.component';
import { Router } from '@angular/router';
import { DealAgreementStatusComponent } from '../deal-ticket/modals/deal-agreement-status/deal-agreement-status.component';

@Component({
  selector: 'app-pending-deals',
  templateUrl: './pending-deals.component.html',
  styleUrls: ['./pending-deals.component.scss']
})
export class PendingDealsComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private router: Router, private tsvc: AppTranslationService, private modal: NzModalService,
    private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private mesSvc: NzMessageService, private drawer: NzDrawerService, private apiProxySvc: PurpleApiProxyService) { }
  sub: Subject<void> = new Subject();

  listOfData: Array<DealPendingFullInfoPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;



  tabColumns: PurpleTableBoxColumn[] = [

    {
      name: this.tranPipe.transform("deals_table_header_event_ref_no", "Ref.No"),
      dbKey: "RefNumber",
      hasReorder: true,
      hasFilter: true,
      fixedWidth: '175px',
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'RefNumber',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_reference_number', 'Reference Number', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_reference_number_placeholder', 'Reference Number', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_product", "Product"),
      dbKey: "PortfolioId",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'PortfolioId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_name', 'Product', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_name_placeholder', 'Product', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },

    {
      name: this.tranPipe.transform("deals_table_header_event_deal_type", "Deal Type"),
      dbKey: "CounterPartyTypeId",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyTypeId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_filter_counterparty_name', 'Deal Type', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_counterparty_name_placeholder', 'Deal Type', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_counterparty_name", "Company Name"),
      dbKey: "CompanyName",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CompanyName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_filter_counterparty_name', 'Counterparty Name', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_counterparty_name_placeholder', 'Counterparty Nome', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_counterparty_name", "CounterParty Name"),
      dbKey: "CounterPartyName",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_filter_counterparty_name', 'Counterparty Name', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_counterparty_name_placeholder', 'Counterparty Name', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_portfolio_year", "Portfolio Year"),
      dbKey: "PortfolioYear",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'PortfolioYear',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_portfolio_year', 'Portfolio Year', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_portfolio_year_placeholder', 'Portfolio Year', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_volume", "Volume"),
      dbKey: "TransactionVolume",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'TransactionVolume',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_transaction_volume', 'Transaction Volume', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_transaction_volume_placeholder', 'Transaction Volume', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_price", "Price"),
      dbKey: "AvgPrice",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'AvgPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_price', 'Price', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_price_placeholder', 'Price', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="

    },
    {
      name: this.tranPipe.transform("deals_table_header_event_total_amount", "Total Amount"),
      dbKey: "TotalPrice",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'TotalPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_total_amount', 'Total Amount', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_total_amount_placeholder', 'Total Amount', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_transaction_date", "Transaction Date"),
      dbKey: "TransactionDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'TransactionDate',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_transaction_date', 'Transaction Date', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_transaction_date_placeholder', 'Transaction Date', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between",
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_currency", "Currency"),
      dbKey: "Currency",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'Currency',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_currency', 'Currency', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_currency_placeholder', 'Currency', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Actions"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "200px"
    }
  ]


  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    return this.admSvc.getPendingDealsTable(args.culture, args)
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "stella_pending_deals";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Pending Deals', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Check and monitor pending deals status.', []))
  }


  viewDelivery(deal: DealPendingFullInfo) {


    var width = window.innerWidth;
    width = this.dashSvc.isMenuCollapsed ? width - 100 : width - 270;



    this.drawer.create<ViewDeliveriesComponent, { transactionId: string, dealType: string, productId: string, currency: string }>({
      nzContent: ViewDeliveriesComponent,
      nzTitle: this.tranPipe.transform("view_deliveries_table_modal", "View all deliveries for deal",),
      nzWidth: width + 'px',
      nzData: {
        transactionId: deal.transactionId!,
        dealType: deal.counterPartyTypeId!,
        productId: deal.portfolioId!,
        currency: deal.originalCurrency!
      },
      nzMaskClosable: false,
      nzPlacement: 'right',
      nzSize: 'large',
      nzClosable: false
    }).afterClose.subscribe((update: boolean) => {
      if (update == true)
        this.refreshTableRowsNumber++;
    })
  }



  viewAgreement(deal: DealPendingFullInfo) {


    this.modal.create<DealAgreementStatusComponent, { dealTransactionid: string, refNo: string }>({
      nzContent: DealAgreementStatusComponent,
      nzTitle: this.tranPipe.transform("view_agreement_table_modal", "View agreement status",),

      nzData: {
        dealTransactionid: deal.transactionId!,
        refNo: deal.refNumber!
      },
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: true
    }).afterClose.subscribe((update: boolean) => {

    })
  }



  editDeal(deal: DealPendingFullInfo) {


    var data: DealTicket =
    {
      brokerCurrency: deal.brokerCurrency,
      brokerFee: deal.brokerFee,
      brokerId: deal.brokerId,
      companyId: deal.companyId,
      counterPartyId: deal.counterPartyId,
      dealBuySeller: deal.counterPartyTypeId,
      dealDate: deal.transactionDate,
      portfolioId: deal.portfolioId,
      portfolioYear: new Date(deal.portfolioYear!, 1, 1),
      productIds: [],
      traderId: deal.traderId,
      fields: deal.fields!
    }



    this.modal.create<EditDealComponent, { dealTicket: DealTicket, transactionId: string }>({
      nzContent: EditDealComponent,
      nzData:
      {
        dealTicket: data,
        transactionId: deal.transactionId!
      },
      nzTitle: data == undefined ? this.tranPipe.transform("table_item_edit_modal_title_add_deal_ticket", "Add Deal", []) : this.tranPipe.transform("table_item_edit_modal_title_update_deal_ticket", "Edit Deal", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).pipe(takeUntil(this.sub)).subscribe(async (res: boolean | undefined) => {
      if (res != undefined && res == true) {
        this.refreshTableRowsNumber++;
      }
    });


  }


  async duplicateDeal(dealId: string) {

    await this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'stella-frontoffice', 'deal-ticket'],
      {
        queryParams:
        {
          transactionId: dealId
        }
      }
    )
  }

  approveDeal(transactionId: string) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("approve_pending_deal", "Approve Pending Deal", []),
      nzContent: this.tranPipe.transform("approve_pending_deal_subtitle", "This deal and related deliveries will be approved "),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('approve_pending_deal_button', 'Approve', []),
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<any>(() => this.admSvc.approveOrDeletePendingDeal(this.tsvc.currentLanguage.value,
          {

            transactionId: transactionId,
            approve: true

          }), false, "internal-loader", 500, undefined, environment.MESSAGE_DURATION, () => {
            this.mesSvc.success(this.tranPipe.transform("approve_pending_deal_ok_response", "Deal successfully approved"), {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.modal.closeAll();
          }).then(() => {
            this.refreshTableRowsNumber++;
          })
      }
    });
  }


  deletePendingDeal(transactionId: string) {

    this.modal.create({
      nzTitle: this.tranPipe.transform("delete_pending_deal", "Delete Pending Deal", []),
      nzContent: this.tranPipe.transform("delete_pending_deal_subtitle", "This deal and related deliveries will be deleted "),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_pending_deal_button', 'Delete', []),
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<any>(() => this.admSvc.approveOrDeletePendingDeal(this.tsvc.currentLanguage.value,
          {
            transactionId: transactionId,
            approve: false
          }), false, "internal-loader", 500, undefined, environment.MESSAGE_DURATION, () => {
            this.mesSvc.success(this.tranPipe.transform("delete_pending_deal_ok_response", "Deal successfully deleted"), {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.modal.closeAll();
          }).then(() => {
            this.refreshTableRowsNumber++;
          })
      }
    });

  }

  formatNumber(n: number | null | undefined): string {

    if (n == null)
      n = 0;

    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }
}
