/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    userId: string;
    firstName: string;
    lastName: string;
    birthDate?: string | null;
    genderId?: string | null;
    email: string;
    initials?: string | null;
    phonePrefix?: string | null;
    phone?: string | null;
    password: string;
    registrationDate?: string | null;
    emailVerified?: boolean | null;
    defaultLocale?: string | null;
    defaultTimeZone?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
}

