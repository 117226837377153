/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiUpdateSystemEventPushNotificationTemplateRequest { 
    systemEventId: string;
    pk1: string;
    pk2: string;
    notificationTitle: string;
    notificationSubTitle?: string | null;
    notificationBody: string;
    senderName: string;
}

