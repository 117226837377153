import pkg from '../../package.json';


export const environment = {
  production: true,
  appVersion: pkg.version,
  appName: pkg.name,
  DEFAULT_LANGUAGE: "it-it",
  API_BASE_PATH: 'https://dev-api.stellapolaris.ch',

  COMPANY_NAME: "Nvalue",
  COMPANY_ABB: 'default',
  SAFARI_COLOR: "#ff0000",
  HTML_THEME_COLOR: "#ffffff",
  HTML_TITLE_COLOR: "#ffc40d",

  EDIT_TRAN_SYMBOL: "",
  NEW_TRAN_SYMBOL: "",
  EDIT_TRAN_AFFIX: "backoffice_",

  MESSAGE_DURATION: 2000,
  LOADER_DELAY: 1500,

  DEFAULT_MENU_LEVEL: 1000,
  ACTIVE_MENU: [
    //ADMNISTRATION
    { "menu": "administration", "submenu": "customers", "maxlevel": 1000 },
    { "menu": "administration", "submenu": "employees", "maxlevel": 1000 },
    { "menu": "administration", "submenu": "roles", "maxlevel": 1000 },
    { "menu": "administration", "submenu": "role-levels", "maxlevel": 0 },
    //NOTIFICATIONS
    { "menu": "notification", "submenu": "smtp-servers", "maxlevel": 1000 },
    { "menu": "notification", "submenu": "mail-templates", "maxlevel": 1000 },
    { "menu": "notification", "submenu": "notification-templates", "maxlevel": 1000 },
    { "menu": "notification", "submenu": "notification-exceptions", "maxlevel": 1000 },
    //GENERAL
    { "menu": "general", "submenu": "translations", "maxlevel": 1000 },
    //STELLA BACKOFFICE
    { "menu": "stella-backoffice", "submenu": "counterparties", "maxlevel": 1000 },
    { "menu": "stella-backoffice", "submenu": "brokers", "maxlevel": 1000 },
    { "menu": "stella-backoffice", "submenu": "exchange-rate", "maxlevel": 1000 },
    //STELLA FRONTOFFICE
    { "menu": "stella-frontoffice", "submenu": "deal-ticket", "maxlevel": 1000 },
    { "menu": "stella-frontoffice", "submenu": "portfolio", "maxlevel": 1000 },
    { "menu": "stella-frontoffice", "submenu": "mtm-prices", "maxlevel": 1000 },
    //STELLA REPORTING
    { "menu": "stella-reporting", "submenu": "overall-by-product", "maxlevel": 1000 },
    { "menu": "stella-reporting", "submenu": "overall-by-technology", "maxlevel": 1000 },
    { "menu": "stella-reporting", "submenu": "wAvg-price", "maxlevel": 1000 }

  ],


  msauth: {
    clientId: '5af23345-c6cb-4231-936f-e0c12f0fc4e1',
    authority: 'https://login.microsoftonline.com/a16949be-65cc-4258-99bd-3d9eac01a430',
    redirectUri: 'https://' + window.location.host
  }
};
