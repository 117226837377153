/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurpleTableSearchValue } from './purpleTableSearchValue';
import { PurpleTableRelationOperator } from './purpleTableRelationOperator';


export interface PurpleTableSearchGroup { 
    groupId: number;
    groupName?: string | null;
    relationOperator: PurpleTableRelationOperator;
    filters: Array<PurpleTableSearchValue>;
}
export namespace PurpleTableSearchGroup {
}


