@if (dashSvc.dashboardHeight!=undefined)
{

<ng-container>

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"
    [isSelectRowActive]="false" [version]="2" [showCustomPagination]="true" tableSearchType="box" [tableLayout]="'auto'"
    (filterBox)="dashSvc.setPurpleTablePopover($event)" (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()"
    [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      @for (data of listOfData; track i;let i = $index)
      {
      <ng-container>
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td nzAlign="center">
            @if (data.item.hasChildren)
            {
            <app-stella-table-button [buttonIcon]="data.expanded?'icon-minus':'icon-plus'"
              (click)="data.expanded = !data.expanded"
              toolTip="{{ 'counterpart_table_button_view_children'|translation:'{0} Children' :[!data.expanded?('text_view'|translation:'View'):('text_hide'|translation:'Hide')] }}"></app-stella-table-button>
            }

          </td>

          <td>{{ data.item.counterPartyName}}</td>
          <td>{{data.item.counterPartyShortName}}</td>
          <td> {{hasChildrenRelationsip(data.item.hasChildren,data.expanded)}} </td>
          <td> {{data.item.firstName}} {{data.item.lastName}}</td>
          <td> {{data.item.email}}</td>
          <td nzAlign="center" class="ps-d-flex">
            <app-purple-pill [pillDefaultColor]="'green'" [pillText]="formatNumber(data.item.creditLimitFrom)"
              [pillBackgroundType]="'two-color'" [pillBackgroundPercentage]="70" class="pill"></app-purple-pill>
            <app-purple-pill [pillDefaultColor]="'red'" [pillText]="formatNumber(data.item.creditLimitTo)"
              [pillBackgroundType]="'two-color'" [pillBackgroundPercentage]="70" class="ps-pl-1 pill"></app-purple-pill>
          </td>
          <td> {{data.item.origins}}</td>
          <td></td>
          <td> {{sumDeals(data.item.dealAsBuyer,data.item.dealAsSeller,data.item.dealAsBroker,data.item.dealAsOther)}}
          </td>
          <td nzAlign="center">

            <app-stella-table-button (click)="editCounterpart(data.item)" buttonIcon="icon-edit"
              toolTip="{{'counterpart_table_button_edit'|translation:'Edit Counterpart'}}"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-addchild" (click)="addRelationships(data.item)"
              toolTip="{{'counterpart_table_button_relationship'|translation:'Add Child'}}"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-deliveries"
              toolTip="{{'counterpart_table_button_deals'|translation:'View Deal List'}}"
              (click)="viewDeals(data.item)"></app-stella-table-button>
            <!--            <app-stella-table-button buttonIcon="icon-history"
                  toolTip="{{'counterpart_table_button_history'|translation:'View History'}}"></app-stella-table-button> -->
            <app-stella-table-button buttonIcon="icon-map" (click)="moveDeals(data.item)"
              toolTip="{{'counterpart_table_button_map'|translation:'Move all deals to another Counterpart'}}"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-x-close" (click)="deleteCounterParty(data.item)"
              toolTip="{{'counterpart_table_button_delete'|translation:'Delete Counterpart'}}"></app-stella-table-button>
          </td>
        </tr>
        <tr #expandedRow [nzExpand]="data.expanded">

          @if (data.expanded)
          {
          <app-sub-table [items]="data.item.children" [refresh]="refresh" [isFinanceView]="isFinanceView"
            [parentId]="data.item.counterPartyId">

          </app-sub-table>
          }



        </tr>
      </ng-container>
      }
    </ng-template>
  </purple-table>
</ng-container>


}
