<div class="request-reset-password-container ps-d-flex ps-flex-wrap ps-align-items-center ps-justify-content-center">
  <form class="request-reset-password-form" nz-form [formGroup]="validateForm" class="request-reset-password-form" (ngSubmit)="submitForm()">
    <div class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-center ps-pb-4">
      <img class="request-reset-password-image" purpleEditableImage contentId="request_reset_passoword" contentTypeId="logo" [resizeHeightPx]="250" [editAlwaysOn]="authSvc.isPurpleAdminUser()"/>
    </div>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="'request_reset_password_form_email_empty_error' | translation: 'Email richiesta!': []">
        <nz-input-group nzPrefixIcon="mail">
          <input type="email" nz-input formControlName="email" placeholder="Email" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <button nz-button class="submit-button ps-mt-4 purple-button" nzType="primary">{{ 'request_reset_password_form_button' | translation: 'Richiedi reset password': []}}</button>
  </form>
</div>

