import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Guid, Helper, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeUsersService, CounterPartyRow, StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';
import { OriginClusterComponent, OriginFull } from '../../../frontoffice/deal-ticket/modals/origin-cluster/origin-cluster.component';

@Component({
  selector: 'app-edit-counterpart',
  templateUrl: './edit-counterpart.component.html',
  styleUrls: ['./edit-counterpart.component.scss']
})
export class EditCounterpartComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data: { counterParty: CounterPartyRow | undefined, isFinanceView: boolean }, @Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private apiProxySvc: PurpleApiProxyService, private tsvc: AppTranslationService, private mesSvc: NzMessageService, private backUserSvc: BackOfficeUsersService, private fb: FormBuilder, private modalRef: NzModalRef, private modal: NzModalService) { }

  disableForm: boolean = false;

  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  purpleFormFieldGroups1: purpleFormFieldsGroup[] | undefined;

  purpleFormFieldGroupsFinance: purpleFormFieldsGroup[] | undefined;

  validateForm: FormGroup = this.fb.group({});

  isLoading: boolean = false;

  isNew: boolean = true;

  editObj!: EditCounterParty;
  newObj!: EditCounterParty;

  ngOnInit() {

    console.log("data", this.data)

    if (this.data.counterParty == undefined) {
      this.newObj =
      {
        counterPartyName: "",
        userId: "",
        origins:
        {
          clusters: [],
          origin: [],
          toExclude: [],
          originString: ""
        },
        note: "",
        counterPartyId: undefined,
        counterPartyShortName: "",
        counterpartyCreditFrom: 0,
        counterpartyCreditTo: 0
      }
    }
    else {
      this.newObj =
      {
        counterPartyName: this.data.counterParty?.counterPartyName,
        userId: this.data.counterParty?.userId,
        origins: {
          clusters: this.data.counterParty?.originClusterIds?.split(','),
          origin: this.data.counterParty.originIds?.split(','),
          originString: this.data.counterParty.origins ?? "",
          toExclude: []
        },
        note: this.data.counterParty?.counterPartyNote,
        counterPartyId: this.data.counterParty?.counterPartyId,
        counterPartyShortName: this.data.counterParty?.counterPartyShortName,
        counterpartyCreditFrom: this.data.counterParty.creditLimitFrom,
        counterpartyCreditTo: this.data.counterParty.creditLimitTo
      }
      this.isNew = false;
    }



    this.editObj = Helper.storeNewObj(this.newObj);
    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'counterpartName',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_name_placeholder', 'Counterpart name', []),
            fieldValue: this.editObj.counterPartyName,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          }, {
            fieldType: 'input-text',
            fieldControlName: 'counterpartShortName',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_short_name', 'Short Name', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_short_name_placeholder', 'Counterpart Short Name', []),
            fieldValue: this.editObj.counterPartyShortName,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: true
          },
          {
            fieldType: 'select',
            fieldControlName: 'counterpartContact',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_contact', 'Contact', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_contact_placeholder', 'Contact', []),
            fieldValue: this.editObj.userId,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }

            ],
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'counterpartOrigin',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_Origin', 'Origine', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_origin_placeholder', 'Segli origine', []),
            fieldValue: this.editObj.origins?.originString,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldCustomClass: 'origin-text'
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'counterpartPrePayment',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_prepayment', 'Has Pre-Payment?', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_prepayment_placeholder', 'Has Pre-Payment?', []),
            fieldValue: 0,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: true },
              {
                id: 'options', value: [
                  {
                    label: 'No',
                    value: 0
                  },
                  {
                    label: 'Yes',
                    value: 1
                  }
                ]
              }
            ]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'counterpartCreditLimitFrom',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_b_credit_limit', 'Credit limit From', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_b_credit_limit_placeholder', 'Credit limit From', []),
            fieldValue: this.editObj.counterpartyCreditFrom,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'minNumber', value: 0 }]
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'counterpartCreditLimitTo',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_s_credit_limit', 'Credit limit To', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_s_credit_limit_placeholder', 'Credit limit To', []),
            fieldValue: this.editObj.counterpartyCreditTo,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [{ id: 'minNumber', value: 0 }]
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'counterPartyNote',
            fieldBootstrapColumn: 8,
            fieldName: this.tranPipe.transform('counter_party_table_filter_level_note', 'Note', []),
            fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_level_note_placeholder', 'Note', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'rowNumber', value: 4 }]
          }

        ]
      }
    ]


    this.purpleFormFieldGroups1 = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'counterpartName',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_name_placeholder', 'Counterpart name', []),
            fieldValue: this.editObj.counterPartyName,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'select',
            fieldControlName: 'counterpartContact',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_contact', 'Contact', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_contact_placeholder', 'Contact', []),
            fieldValue: this.editObj.userId,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.usersScrollFunction },
              { id: 'searchFunction', value: this.usersSearchFunction },
              { id: 'searchByIdFunction', value: this.usersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 }

            ],
          },
          {
            fieldType: 'select-multiple',
            fieldControlName: 'counterpartOrigin',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_Origin', 'Origine', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_origin_placeholder', 'Segli origine', []),
            fieldValue: this.editObj.origins,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.originsScrollFunction },
              { id: 'searchFunction', value: this.originsSearchFunction },
              { id: 'searchByIdFunction', value: this.originsPartiesByIdsFunction },
              { id: 'refreshFieldNumber', value: 0 }
            ]
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'counterpartPrePayment',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_prepayment', 'Richiesto pre-pagamento', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_prepayment_placeholder', 'Richiesto pre-pagamento', []),
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: true },
              {
                id: 'options', value: [
                  {
                    label: 'No',
                    value: 0
                  }
                ]
              }
            ]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'counterpartCreditLimitFrom',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_b_credit_limit', 'Limite crediti acquisto', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_b_credit_limit_placeholder', 'Limite crediti acquisto', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'counterpartCreditLimitFromTo',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_s_credit_limit', 'Limite crediti vendita', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_s_credit_limit_placeholder', 'Limite crediti vendita', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'counterpartRanking',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('edit_counterpart_modal_ranting', 'Rating', []),
            fieldPlaceholder: this.tranPipe.transform('edit_counterpart_modal_rating_placeholder', 'Rating tags', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: true },
              {
                id: 'options',
                value: [
                  {
                    label: 'Low',
                    value: "Low"
                  },
                  {
                    label: 'Medium',
                    value: "Medium"
                  },
                  {
                    label: 'High',
                    value: "High"
                  }
                ]
              }
            ]
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'counterPartyNote',
            fieldBootstrapColumn: 8,
            fieldName: this.tranPipe.transform('counter_party_table_filter_level_note', 'Note', []),
            fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_level_note_placeholder', 'Note', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'rowNumber', value: 4 }]
          }

        ]
      }
    ]



  }

  async editCounterParty() {


    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    if (this.validateForm.valid) {

      this.editObj.counterPartyName = this.validateForm.controls["counterpartName"].value;
      this.editObj.userId = this.validateForm.controls["counterpartContact"].value;
      //this.editObj.origins = this.validateForm.controls["counterpartOrigin"].value;
      this.editObj.counterpartyCreditFrom = this.validateForm.controls["counterpartCreditLimitFrom"].value;
      this.editObj.counterpartyCreditTo = this.validateForm.controls["counterpartCreditLimitTo"].value;
      //this.editObj.prePayment = this.validateForm.controls["counterpartPrePayment"].value;
      //this.editObj.counterPArtyRanking = this.validateForm.controls["counterpartRanking"].value;
      this.editObj.note = this.validateForm.controls["counterPartyNote"].value;

      this.editObj.counterPartyShortName = this.validateForm.controls["counterpartShortName"].value;

      if (Helper.objIsEqual(this.newObj, this.editObj)) {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
        this.isLoading = false;
        return;
      }

      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addOrUpdateCounterParty(this.tsvc.currentLanguage.value,
        {
          isNew: this.isNew,
          counterPartyName: this.editObj.counterPartyName,
          counterPartyId: this.editObj.counterPartyId == undefined ? Guid.empty() : this.editObj.counterPartyId,
          note: this.editObj.note,
          origins: this.editObj.origins?.origin,
          originClusters: this.editObj.origins?.clusters,
          contactUserId: this.editObj.userId,
          counterPartyShortName: this.editObj.counterPartyShortName,
          creditLimitFrom: this.editObj.counterpartyCreditFrom,
          creditLimitTo: this.editObj.counterpartyCreditTo
        }), false, "internal-loader", 500, undefined, undefined, () => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_counterparty_add', 'Counter Party aggiunta con successo', [])
          } else {
            text = this.tranPipe.transform('edit_counterparty_update', 'Counter Party aggiornata con successo', [])
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(true);
        })


      this.isLoading = false



    }





    this.isLoading = false;
  }

  selectOrigin() {



    this.modal.create<OriginClusterComponent, { modalOrigin: OriginFull | undefined, isCounterpart: boolean }>({
      nzContent: OriginClusterComponent,
      nzData: { modalOrigin: this.editObj.origins, isCounterpart: true },
      nzTitle: this.tranPipe.transform("edit_delivery_origin_modal_title", "Select Origin", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: OriginFull | undefined) => {



      if (res != undefined) {


        this.editObj.origins =
        {
          clusters: res.clusters,
          origin: res.origin,
          originString: res.originString,
          toExclude: []
        }

        this.validateForm.controls["counterpartOrigin"].setValue(this.editObj.origins.originString);
      }

    })

  }






  //ScrollFunction
  usersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  usersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  usersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByIdSelect(args.culture, args)
  }

  //ScrollFunction
  originsScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {

    return this.admSvc.getOriginSelect(args.culture, args)
  }
  //SearchFunction
  originsSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {

    return this.admSvc.getOriginByIdSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  originsPartiesByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {

    return this.admSvc.getOriginByIdsSelect(args.culture, args)
  }

}

export interface EditCounterParty {
  counterPartyName?: string | null;
  counterPartyShortName?: string | null;
  counterPartyId?: string | null;
  userId?: string | null | undefined;
  origins?: OriginFull;
  counterpartyCreditFrom?: number | null | undefined;
  counterpartyCreditTo?: number | null | undefined;
  note?: string | null;
}
