import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Helper, PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { DealTicketDisplayInfo, ProductAttributesGrouped, StellaService, TransactionAgreementStatus } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { DealTicket } from '../../custom-component/deal-ticket-summary/deal-ticket-summary.component';
import { Delivery } from '../deal-ticket-delivery/deal-ticket-delivery.component';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deal-agreement-status',
  templateUrl: './deal-agreement-status.component.html',
  styleUrls: ['./deal-agreement-status.component.scss']
})
export class DealAgreementStatusComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) public data: { dealTransactionid: string, refNo: string }, @Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private modal: NzModalService, private tsvc: AppTranslationService,
    private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private fb: FormBuilder, private apiProxySvc: PurpleApiProxyService, private mesSvc: NzMessageService, private curr: CurrencyPipe) { }



  agreementData: TransactionAgreementStatus | undefined;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;


  show: boolean = false;


  ngOnInit() {


    this.getDealAgreementStatus();




  }



  async getDealAgreementStatus() {
    await this.apiProxySvc.makeRequestErrorFunction<TransactionAgreementStatus>(() => this.admSvc.getTransactionAgreement(this.tsvc.currentLanguage.value,
      {
        transactionId: this.data.dealTransactionid
      }),
      true,
      "internal-loader",
      100,
      () => this.getDealAgreementStatus(), (res) => {
        this.agreementData = res.data!

        this.purpleFormFieldGroups = [
          {
            fieldGroupNumber: 1,
            fielGroupBootstrapColumn: 12,
            fieldGroupPaddingLeft: true,
            fieldGroupPaddingRight: true,
            formFieldFormGroup: this.validateForm,
            formFields: [
              {
                fieldType: 'input-text',
                fieldControlName: 'refNo',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('agreement_status_ref_no', 'Reference Number', []),
                fieldPlaceholder: this.tranPipe.transform('agreement_status_ref_no_placeholder', 'Reference Number', []),
                fieldValue: this.data.refNo,
                fieldIsRequired: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: true,
                fieldIsDisabled: true
              },
              {
                fieldType: 'select',
                fieldControlName: 'status',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('agreement_status_status', 'Status', []),
                fieldPlaceholder: this.tranPipe.transform('agreement_status_status_placeholder', 'Status', []),
                fieldValue: this.agreementData?.agreementStatusId,
                fieldIsRequired: true,
                fieldPaddingLeft: true,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.agreementStatusScrollFunction },
                  { id: 'searchFunction', value: this.agreementStatusSearchFunction },
                  { id: 'searchByIdFunction', value: this.agreementStatusSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 }
                ],
              },
              {
                fieldType: 'textarea',
                fieldControlName: 'details',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('agreement_status_details', 'Details', []),
                fieldPlaceholder: this.tranPipe.transform('agreement_status_details_placeholder', 'Details', []),
                fieldValue: this.agreementData?.agreementDetails,
                fieldIsRequired: false,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: true,
                fieldOptions: [{ id: 'rowNumber', value: 8 }],
              }]
          }]

        this.show = true;
      })

  }


  async editAgreementStatus() {

    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {


      var statusId = this.validateForm.controls["status"].value;
      var agreementDetails = this.validateForm.controls["details"].value;

      if (statusId != this.agreementData?.agreementStatusId || agreementDetails != this.agreementData?.agreementDetails) {


        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.updateTransactionAgreement(this.tsvc.currentLanguage.value, {
          transactionId: this.data.dealTransactionid,
          agreementDetails: agreementDetails,
          statusId: statusId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          let text: string;

          text = this.tranPipe.transform('edit_agreement_ok', 'Agreement correctly uptaded', [])

          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modal.closeAll();
        })
      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'No changes', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }

    }


    this.isLoading = false;

  }



  //ScrollFunction
  agreementStatusScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getAgreementStatusSelect(args.culture, args)
  }
  //SearchFunction
  agreementStatusSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getAgreementStatusByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  agreementStatusSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.admSvc.getAgreementStatusByIdSelect(args.culture, args)
  }


}
