import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Guid, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-move-deals',
  templateUrl: './move-deals.component.html',
  styleUrls: ['./move-deals.component.scss']
})
export class MoveDealsComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private fromCounterPartyId: string | undefined, @Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private apiProxySvc: PurpleApiProxyService, private tsvc: AppTranslationService, private modalRef: NzModalRef, private fb: FormBuilder, private mesSvc: NzMessageService, private modal: NzModalService) { }

  disableForm: boolean = false;


  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;

  validateForm: FormGroup = this.fb.group({});

  isLoading: boolean = false;
  refreshStartScroll = 0;
  refreshEndScroll = 0;




  ngOnInit() {

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'FromCounterParty',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('move_deal_counterparty_from', 'From CounterParty', []),
            fieldPlaceholder: this.tranPipe.transform('move_deal_counterparty_from_placeholder', 'From CounterParty', []),
            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterpartiesScrollFunction },
              { id: 'searchFunction', value: this.counterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterpartiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: this.refreshStartScroll }

            ],
            fieldValue: this.fromCounterPartyId,
            fieldIsRequired: true,
            fieldIsDisabled: this.fromCounterPartyId != undefined,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'ToCounterParty',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('move_deal_counterparty_to', 'To CounterParty', []),
            fieldPlaceholder: this.tranPipe.transform('move_deal_counterparty_to_placeholder', 'To CounterParty', []),
            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterpartiesScrollFunction },
              { id: 'searchFunction', value: this.counterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterpartiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: this.refreshEndScroll }

            ],
            fieldValue: undefined,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }

        ]
      }
    ]
  }



  //ScrollFunction
  counterpartiesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {

    return this.admSvc.getCounterPartiesSelect(args.culture, args)
  }
  //SearchFunction
  counterpartiesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {

    return this.admSvc.getCounterPartiesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  counterpartiesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {

    return this.admSvc.getCounterPartyByIdSelect(args.culture, args)
  }





  async moveDeals() {

    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }



    if (this.validateForm.valid) {
      await this.apiProxySvc.makeRequestErrorMessage<any>(() => this.admSvc.moveDeals(this.tsvc.currentLanguage.value,
        {
          fromCounterPartyId: this.validateForm.controls["FromCounterParty"].value,
          toCounterPartyId: this.validateForm.controls["ToCounterParty"].value
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success("Successfully moved", {
            nzDuration: environment.MESSAGE_DURATION
          });

          this.modalRef.destroy(true)
        })


    }



    this.isLoading = false

  }

}
