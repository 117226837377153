import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoginResponse, ManagedAuthenticationProvider, PurpleApiResponseStatus } from '../sdk/api';
import { AppTranslationService } from '../localization/localization.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IAuthenticatedUser } from './IAuthenticatedUser';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleStorageService, PurpleTranslationPipe } from 'purple-lib';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UsersService } from '../sdk/api';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isLoggedIn = false;
  currentUser: IAuthenticatedUser | undefined;
  storageMode = 'local';

  constructor(private jwtSvc: JwtHelperService, private storageService: PurpleStorageService, private tsvc: AppTranslationService, private userSvc: UsersService,
    private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService
  ) {
    this.loadFromStorage();
  }

  isPurpleAdminUser(){
    return (this.currentUser?.roleLevel??environment.DEFAULT_MENU_LEVEL) === 0;
  }

  checkUserRoles(accessToken: string) {
    if (this.currentUser != undefined) {
      try {
        var t = this.jwtSvc.decodeToken(accessToken);
        var roles = [];
  
        if (typeof (t.role) == 'string') {
          roles.push(t.role);
        } else {
          roles = (t.role as string[]).filter((value: string, index: number, array: string[]) => array.indexOf(value) === index)
        }
        
        this.currentUser!.roleLevel = +t.RoleLevel;
        this.currentUser!.roles = roles;
      } catch (error) {
        this.currentUser!.roleLevel = environment.DEFAULT_MENU_LEVEL;
        this.currentUser!.roles = roles;
      }
    }

  }

  private setLoginOkValue(data: LoginResponse) {
    this.currentUser = {
      token: data.accessToken,
      user: data.user
    };
    this.storageService.set('CurrentUser', this.currentUser, this.storageMode);
    this.storageService.set('StorageMode', this.storageMode);

    this.checkUserRoles(data.accessToken);
    this.isLoggedIn = this.currentUser?.token != undefined;
  }

//#region LOGIN
  async login(username: string, password: string, mode = 'local'): Promise<boolean> {
    this.isLoggedIn = false;
    this.storageMode = mode;

    await this.apiProxySvc.makeRequestErrorMessage<LoginResponse>(() => this.userSvc.login(this.tsvc.currentLanguage.value, {
      email: username,
      password: password
    }), false, 'internal-loader', 500, undefined, environment.MESSAGE_DURATION, (res: PurpleApiMakeRequestResponse<LoginResponse>) => {
      this.setLoginOkValue(res.data!)
    }
    );

    return this.isLoggedIn;
  }

  async loginWithToken(firstName: string, lastName: string, token: string, authenticationProvider: ManagedAuthenticationProvider, mode = 'local'): Promise<boolean> {
    this.isLoggedIn = false;
    this.storageMode = mode;

    await this.apiProxySvc.makeRequestErrorMessage<LoginResponse>(() => this.userSvc.loginWithToken(this.tsvc.currentLanguage.value, {
      firstName: firstName,
      lastName: lastName,
      authenticationProvider: authenticationProvider,
      token: token
    }), false, 'internal-loader', 500, undefined, environment.MESSAGE_DURATION + 1500, (res: PurpleApiMakeRequestResponse<LoginResponse>) => {
      this.setLoginOkValue(res.data!)
    }
    );

    return this.isLoggedIn;
  }
//#endregion

  logout(): boolean {
    this.isLoggedIn = false;
    this.currentUser = undefined;
    this.storageService.remove('CurrentUser', this.storageMode);
    this.storageService.remove('StorageMode', this.storageMode);
    this.storageMode = 'local';
    return !this.isLoggedIn;
  }

  private loadFromStorage(): void {
    this.storageMode = this.storageService.get<string>('StorageMode', this.storageMode);
    this.currentUser = this.storageService.get<IAuthenticatedUser>('CurrentUser', this.storageMode);

    const token = this.currentUser?.token ?? "";
    try {
      if (!this.jwtSvc.isTokenExpired(token)) {
        this.isLoggedIn = true;
        this.checkUserRoles(token);
      } else {
        this.isLoggedIn = false;
      }
    } catch (error) {
      this.isLoggedIn = false;
    }

    if (!this.isLoggedIn) {
      this.storageService.remove('CurrentUser');
    }
  }

  async requestResetPassword(email: string, isMobile: boolean): Promise<boolean> {
    const reqResp = await lastValueFrom(
      this.userSvc.requestResetPassword(this.tsvc.currentLanguage.value, {
        email: email,
      })
    );

    const isOk = reqResp.status == PurpleApiResponseStatus.Success;
    let title = '';

    if (isOk) {
      if (isMobile) {
        title = this.tranPipe.transform('modal_request_reset_password_title_mobile_ok', 'Richiesta inviata');
      } else {
        title = this.tranPipe.transform('modal_request_reset_password_title_ok', 'Richiesta inviata correttamente');
      }
    } else {
      if (isMobile) {
        title = this.tranPipe.transform('modal_request_reset_password_title_mobile_ko', 'Errore');
      } else {
        title = this.tranPipe.transform('modal_request_reset_password_title_ko', 'Ops! qualcosa è andato storto');
      }
    }

    this.modal.create({
      nzTitle: title,
      nzContent: reqResp.message,
      nzWidth: isMobile ? '80%' : '600px',
      nzClassName: 'ps-modal',
      nzCentered: true,
      nzClosable: false,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOkText: null,
    });

    return isOk;
  }

  async checkResetPasswordLinkUrl(resetId: string): Promise<{ status: boolean; message: string }> {
    const reqResp = await lastValueFrom(
      this.userSvc.checkResetPasswordLink(this.tsvc.currentLanguage.value, {
        resetId: resetId,
      })
    );

    return {
      status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success,
      message: reqResp.message,
    };
  }

  async resetPassword( email: string, resetId: string, newPassword: string): Promise<{ status: boolean; message: string }> {
    const reqResp = await lastValueFrom(
      this.userSvc.resetPassword(this.tsvc.currentLanguage.value, {
        email: email,
        newPassword: newPassword,
        resetId: resetId,
        clientId: 'WebAdmin',
      })
    );

    return {
      status: (reqResp.status ?? PurpleApiResponseStatus.Error) == PurpleApiResponseStatus.Success,
      message: reqResp.message,
    };
  }
}
