/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationModeExceptionStatusEnum } from './notificationModeExceptionStatusEnum';


export interface NotificationModeExceptionStatus { 
    notificationModeId: string;
    notificationModeName: string;
    notificationModeStatus: NotificationModeExceptionStatusEnum;
}
export namespace NotificationModeExceptionStatus {
}


