/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ApiUploadCompeteMediaPurpleApiResponse } from '../model/apiUploadCompeteMediaPurpleApiResponse';
// @ts-ignore
import { BooleanPurpleApiResponse } from '../model/booleanPurpleApiResponse';
// @ts-ignore
import { CheckIfContentMediasExistUploadRequest } from '../model/checkIfContentMediasExistUploadRequest';
// @ts-ignore
import { CheckIfMediaExistUploadRequest } from '../model/checkIfMediaExistUploadRequest';
// @ts-ignore
import { ContentMediaFileListPurpleApiResponse } from '../model/contentMediaFileListPurpleApiResponse';
// @ts-ignore
import { DeleteMediaContentMediaApiRequest } from '../model/deleteMediaContentMediaApiRequest';
// @ts-ignore
import { DeleteMediaContentMediaByIdApiRequest } from '../model/deleteMediaContentMediaByIdApiRequest';
// @ts-ignore
import { GetAllImageMediasRequest } from '../model/getAllImageMediasRequest';
// @ts-ignore
import { GetAllImageMediasResponsePurpleApiResponse } from '../model/getAllImageMediasResponsePurpleApiResponse';
// @ts-ignore
import { GetMediaInfoRequest } from '../model/getMediaInfoRequest';
// @ts-ignore
import { MediaFileListPurpleApiResponse } from '../model/mediaFileListPurpleApiResponse';
// @ts-ignore
import { MediaInfoPurpleApiResponse } from '../model/mediaInfoPurpleApiResponse';
// @ts-ignore
import { OutputSupportedExtensions } from '../model/outputSupportedExtensions';
// @ts-ignore
import { UploadImageOptionsPurpleApiResponse } from '../model/uploadImageOptionsPurpleApiResponse';
// @ts-ignore
import { UploadMediaType } from '../model/uploadMediaType';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class MediaManagerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param culture 
     * @param checkIfContentMediasExistUploadRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ContentMediaFileListPurpleApiResponse>;
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ContentMediaFileListPurpleApiResponse>>;
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ContentMediaFileListPurpleApiResponse>>;
    public checkIfContentMediasExistUpload(culture: string, checkIfContentMediasExistUploadRequest?: CheckIfContentMediasExistUploadRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling checkIfContentMediasExistUpload.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/CheckIfContentMediasExistUpload`;
        return this.httpClient.request<ContentMediaFileListPurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkIfContentMediasExistUploadRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param checkIfMediaExistUploadRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<MediaFileListPurpleApiResponse>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<MediaFileListPurpleApiResponse>>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<MediaFileListPurpleApiResponse>>;
    public checkIfMediaExistUpload(culture: string, checkIfMediaExistUploadRequest?: CheckIfMediaExistUploadRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling checkIfMediaExistUpload.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/CheckIfMediaExistUpload`;
        return this.httpClient.request<MediaFileListPurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkIfMediaExistUploadRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteMediaContentMediaApiRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BooleanPurpleApiResponse>;
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteMediaContentMedia(culture: string, deleteMediaContentMediaApiRequest?: DeleteMediaContentMediaApiRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling deleteMediaContentMedia.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/DeleteMediaContentMedia`;
        return this.httpClient.request<BooleanPurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deleteMediaContentMediaApiRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param deleteMediaContentMediaByIdApiRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BooleanPurpleApiResponse>;
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BooleanPurpleApiResponse>>;
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BooleanPurpleApiResponse>>;
    public deleteMediaContentMediaById(culture: string, deleteMediaContentMediaByIdApiRequest?: DeleteMediaContentMediaByIdApiRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling deleteMediaContentMediaById.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/DeleteMediaContentMediaById`;
        return this.httpClient.request<BooleanPurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deleteMediaContentMediaByIdApiRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getAllImageMediasRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetAllImageMediasResponsePurpleApiResponse>;
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetAllImageMediasResponsePurpleApiResponse>>;
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetAllImageMediasResponsePurpleApiResponse>>;
    public getAllImageMedias(culture: string, getAllImageMediasRequest?: GetAllImageMediasRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling getAllImageMedias.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/GetAllImageMedias`;
        return this.httpClient.request<GetAllImageMediasResponsePurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getAllImageMediasRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contentId 
     * @param contentTypeId 
     * @param culture 
     * @param contentMediaIndex 
     * @param resizeImageIsThumb 
     * @param resizeImagePxWidth 
     * @param resizeImagePxHeight 
     * @param resizeImageIgnoreAspectRatio 
     * @param resizeImageShrinkOnlyLargerImage 
     * @param resizeImageEnlargeOnlySmallerImage 
     * @param resizeImageFillArea 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getMedia(contentId: string, contentTypeId: string, culture: string, contentMediaIndex?: number, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (contentId === null || contentId === undefined) {
            throw new Error('Required parameter contentId was null or undefined when calling getMedia.');
        }
        if (contentTypeId === null || contentTypeId === undefined) {
            throw new Error('Required parameter contentTypeId was null or undefined when calling getMedia.');
        }
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling getMedia.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (contentId !== undefined && contentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contentId, 'ContentId');
        }
        if (contentTypeId !== undefined && contentTypeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contentTypeId, 'ContentTypeId');
        }
        if (contentMediaIndex !== undefined && contentMediaIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>contentMediaIndex, 'ContentMediaIndex');
        }
        if (resizeImageIsThumb !== undefined && resizeImageIsThumb !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageIsThumb, 'ResizeImage.IsThumb');
        }
        if (resizeImagePxWidth !== undefined && resizeImagePxWidth !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImagePxWidth, 'ResizeImage.PxWidth');
        }
        if (resizeImagePxHeight !== undefined && resizeImagePxHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImagePxHeight, 'ResizeImage.PxHeight');
        }
        if (resizeImageIgnoreAspectRatio !== undefined && resizeImageIgnoreAspectRatio !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageIgnoreAspectRatio, 'ResizeImage.IgnoreAspectRatio');
        }
        if (resizeImageShrinkOnlyLargerImage !== undefined && resizeImageShrinkOnlyLargerImage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageShrinkOnlyLargerImage, 'ResizeImage.ShrinkOnlyLargerImage');
        }
        if (resizeImageEnlargeOnlySmallerImage !== undefined && resizeImageEnlargeOnlySmallerImage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageEnlargeOnlySmallerImage, 'ResizeImage.EnlargeOnlySmallerImage');
        }
        if (resizeImageFillArea !== undefined && resizeImageFillArea !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageFillArea, 'ResizeImage.FillArea');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/GetMedia`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param mediaId 
     * @param culture 
     * @param resizeImageIsThumb 
     * @param resizeImagePxWidth 
     * @param resizeImagePxHeight 
     * @param resizeImageIgnoreAspectRatio 
     * @param resizeImageShrinkOnlyLargerImage 
     * @param resizeImageEnlargeOnlySmallerImage 
     * @param resizeImageFillArea 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getMediaById(mediaId: string, culture: string, resizeImageIsThumb?: boolean, resizeImagePxWidth?: number, resizeImagePxHeight?: number, resizeImageIgnoreAspectRatio?: boolean, resizeImageShrinkOnlyLargerImage?: boolean, resizeImageEnlargeOnlySmallerImage?: boolean, resizeImageFillArea?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling getMediaById.');
        }
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling getMediaById.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (mediaId !== undefined && mediaId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>mediaId, 'MediaId');
        }
        if (resizeImageIsThumb !== undefined && resizeImageIsThumb !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageIsThumb, 'ResizeImage.IsThumb');
        }
        if (resizeImagePxWidth !== undefined && resizeImagePxWidth !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImagePxWidth, 'ResizeImage.PxWidth');
        }
        if (resizeImagePxHeight !== undefined && resizeImagePxHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImagePxHeight, 'ResizeImage.PxHeight');
        }
        if (resizeImageIgnoreAspectRatio !== undefined && resizeImageIgnoreAspectRatio !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageIgnoreAspectRatio, 'ResizeImage.IgnoreAspectRatio');
        }
        if (resizeImageShrinkOnlyLargerImage !== undefined && resizeImageShrinkOnlyLargerImage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageShrinkOnlyLargerImage, 'ResizeImage.ShrinkOnlyLargerImage');
        }
        if (resizeImageEnlargeOnlySmallerImage !== undefined && resizeImageEnlargeOnlySmallerImage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageEnlargeOnlySmallerImage, 'ResizeImage.EnlargeOnlySmallerImage');
        }
        if (resizeImageFillArea !== undefined && resizeImageFillArea !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resizeImageFillArea, 'ResizeImage.FillArea');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/GetMediaById`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param getMediaInfoRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<MediaInfoPurpleApiResponse>;
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<MediaInfoPurpleApiResponse>>;
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<MediaInfoPurpleApiResponse>>;
    public getMediaInfo(culture: string, getMediaInfoRequest?: GetMediaInfoRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling getMediaInfo.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/GetMediaInfo`;
        return this.httpClient.request<MediaInfoPurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: getMediaInfoRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public toGen(culture: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<UploadImageOptionsPurpleApiResponse>;
    public toGen(culture: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<UploadImageOptionsPurpleApiResponse>>;
    public toGen(culture: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<UploadImageOptionsPurpleApiResponse>>;
    public toGen(culture: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling toGen.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/ToGen`;
        return this.httpClient.request<UploadImageOptionsPurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param mediaFile 
     * @param uploadMediaType 
     * @param contentTypeId 
     * @param contentId 
     * @param contentMediaIndex 
     * @param overwriteContentMedia 
     * @param uploadImageOptionsUseDefaultValues 
     * @param name 
     * @param description 
     * @param overrideCrc 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage 
     * @param uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea 
     * @param uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension 
     * @param uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality 
     * @param uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ApiUploadCompeteMediaPurpleApiResponse>;
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ApiUploadCompeteMediaPurpleApiResponse>>;
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ApiUploadCompeteMediaPurpleApiResponse>>;
    public uploadComplete(culture: string, mediaFile: Blob, uploadMediaType: UploadMediaType, contentTypeId: string, contentId: string, contentMediaIndex: number, overwriteContentMedia: boolean, uploadImageOptionsUseDefaultValues: boolean, name?: string, description?: string, overrideCrc?: string, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight?: number, uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage?: boolean, uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea?: boolean, uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension?: OutputSupportedExtensions, uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality?: number, uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling uploadComplete.');
        }
        if (mediaFile === null || mediaFile === undefined) {
            throw new Error('Required parameter mediaFile was null or undefined when calling uploadComplete.');
        }
        if (uploadMediaType === null || uploadMediaType === undefined) {
            throw new Error('Required parameter uploadMediaType was null or undefined when calling uploadComplete.');
        }
        if (contentTypeId === null || contentTypeId === undefined) {
            throw new Error('Required parameter contentTypeId was null or undefined when calling uploadComplete.');
        }
        if (contentId === null || contentId === undefined) {
            throw new Error('Required parameter contentId was null or undefined when calling uploadComplete.');
        }
        if (contentMediaIndex === null || contentMediaIndex === undefined) {
            throw new Error('Required parameter contentMediaIndex was null or undefined when calling uploadComplete.');
        }
        if (overwriteContentMedia === null || overwriteContentMedia === undefined) {
            throw new Error('Required parameter overwriteContentMedia was null or undefined when calling uploadComplete.');
        }
        if (uploadImageOptionsUseDefaultValues === null || uploadImageOptionsUseDefaultValues === undefined) {
            throw new Error('Required parameter uploadImageOptionsUseDefaultValues was null or undefined when calling uploadComplete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (mediaFile !== undefined) {
            localVarFormParams = localVarFormParams.append('MediaFile', <any>mediaFile) as any || localVarFormParams;
        }
        if (uploadMediaType !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadMediaType', <any>uploadMediaType) as any || localVarFormParams;
        }
        if (contentTypeId !== undefined) {
            localVarFormParams = localVarFormParams.append('ContentTypeId', <any>contentTypeId) as any || localVarFormParams;
        }
        if (contentId !== undefined) {
            localVarFormParams = localVarFormParams.append('ContentId', <any>contentId) as any || localVarFormParams;
        }
        if (contentMediaIndex !== undefined) {
            localVarFormParams = localVarFormParams.append('ContentMediaIndex', <any>contentMediaIndex) as any || localVarFormParams;
        }
        if (overwriteContentMedia !== undefined) {
            localVarFormParams = localVarFormParams.append('OverwriteContentMedia', <any>overwriteContentMedia) as any || localVarFormParams;
        }
        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('Name', <any>name) as any || localVarFormParams;
        }
        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('Description', <any>description) as any || localVarFormParams;
        }
        if (overrideCrc !== undefined) {
            localVarFormParams = localVarFormParams.append('OverrideCrc', <any>overrideCrc) as any || localVarFormParams;
        }
        if (uploadImageOptionsUseDefaultValues !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.UseDefaultValues', <any>uploadImageOptionsUseDefaultValues) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.PxWidth', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsPxWidth) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.PxHeight', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsPxHeight) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.IgnoreAspectRatio', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsIgnoreAspectRatio) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.ShrinkOnlyLargerImage', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsShrinkOnlyLargerImage) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.EnlargeOnlySmallerImage', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsEnlargeOnlySmallerImage) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.ResizeImageOptions.FillArea', <any>uploadImageOptionsProcessImageSettingsResizeImageOptionsFillArea) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.ConvertImageOptions.ToExtension', <any>uploadImageOptionsProcessImageSettingsConvertImageOptionsToExtension) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.CompressImageOptions.Quality', <any>uploadImageOptionsProcessImageSettingsCompressImageOptionsQuality) as any || localVarFormParams;
        }
        if (uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess !== undefined) {
            localVarFormParams = localVarFormParams.append('UploadImageOptions.ProcessImageSettings.CompressImageOptions.LossLess', <any>uploadImageOptionsProcessImageSettingsCompressImageOptionsLossLess) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/MediaManager/UploadComplete`;
        return this.httpClient.request<ApiUploadCompeteMediaPurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
