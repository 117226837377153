import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PurpleApiProxyService, PurpleTableBoxColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardPopover } from 'purple-lib';
import { Subject } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { DealFullInfo, DealFullInfoPurpleTableResponse, StellaService } from 'src/core-modules/sdk/api';
import { NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { ViewDeliveriesComponent } from '../view-deliveries/view-deliveries.component';
import { NZ_DRAWER_DATA, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { DealAgreementStatusComponent } from '../../../frontoffice/deal-ticket/modals/deal-agreement-status/deal-agreement-status.component';
@Component({
  selector: 'app-view-deals',
  templateUrl: './view-deals.component.html',
  styleUrls: ['./view-deals.component.scss']
})
export class ViewDealsComponent implements OnInit, AfterViewInit {

  @ViewChild('myButton') myButton: ElementRef | undefined;

  constructor(@Inject(NZ_DRAWER_DATA) private counterParty: string, @Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private modal: NzModalService, private tsvc: AppTranslationService,
    private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private apiProxySvc: PurpleApiProxyService, private mesSvc: NzMessageService, private drawer: NzDrawerService) { }

  sub: Subject<void> = new Subject();

  listOfData: Array<DealFullInfoPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;


  dealType: string = "buyer"


  thisOrThatTexts: string[] =
    [
      "Sell",
      "Buy",
      "Broker",
      "Full"
    ]

  counterPartyTypeFilters: PurpleTableSelectFilter[] = [
    {
      property: 'counterPartyId',
      filterValue: this.counterParty
    },
    {
      property: 'counterPartyTypeId',
      filterValue: "Buyer"
    }
  ]

  purpleTableBoxFilterStatus: "open" | "close" = "close";
  filters!: dashboardPopover;
  show: boolean = false;

  tabColumns: PurpleTableBoxColumn[] = [

    {
      name: this.tranPipe.transform("deals_table_header_event_ref_no", "Ref.No"),
      dbKey: "RefNumber",
      hasReorder: true,
      hasFilter: true,
      fixedWidth: '175px',
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'RefNumber',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_reference_number', 'Reference Number', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_reference_number_placeholder', 'Reference Number', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_product", "Product"),
      dbKey: "PortfolioId",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'PortfolioId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_name', 'Product', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_name_placeholder', 'Product', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    }, {
      name: this.tranPipe.transform("deals_table_header_event_counterparty_name", "CounterParty Name"),
      dbKey: "CounterPartyName",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_filter_counterparty_name', 'Counterparty Nome', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_counterparty_name_placeholder', 'Counterparty Nome', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_portfolio_year", "Portfolio Year"),
      dbKey: "PortfolioYear",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'PortfolioYear',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_portfolio_year', 'Portfolio Year', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_portfolio_year_placeholder', 'Portfolio Year', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_volume", "Volume"),
      dbKey: "TransactionVolume",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'TransactionVolume',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_transaction_volume', 'Transaction Volume', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_transaction_volume_placeholder', 'Transaction Volume', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_price", "Price"),
      dbKey: "AvgPrice",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'AvgPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_price', 'Price', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_price_placeholder', 'Price', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="

    },
    {
      name: this.tranPipe.transform("deals_table_header_event_total_amount", "Total Amount"),
      dbKey: "TotalPrice",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'TotalPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_total_amount', 'Total Amount', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_total_amount_placeholder', 'Total Amount', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_transaction_date", "Transaction Date"),
      dbKey: "TransactionDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'TransactionDate',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_transaction_date', 'Transaction Date', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_transaction_date_placeholder', 'Transaction Date', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between",
    },
    {
      name: this.tranPipe.transform("deals_table_header_event_currency", "Currency"),
      dbKey: "Currency",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'Currency',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('deals_table_filter_currency', 'Currency', []),
        fieldPlaceholder: this.tranPipe.transform('deals_table_filter_currency_placeholder', 'Currency', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "75px"
    }
  ]



  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = this.counterPartyTypeFilters


    return this.admSvc.getDealsTable(args.culture, args)
  }


  ngOnInit() {


  }

  ngAfterViewInit(): void {

    setInterval(() => {

      this.show = true;
    }, 500);


  }

  closePurpleTablePopover() {

    this.filters.closePopover()

  }


  setPurpleTablePopover(template: TemplateRef<any>) {

    this.purpleTableBoxFilterStatus = "close";


    this.filters = new dashboardPopover("purple-modal-table-filter-popover",
      this.tranPipe.transform('purple_table_filter_button', 'Filtri', []),
      template,
      undefined,
      'default',
      'down',
      'filter-button',
      undefined,
      'click',
      'bottomRight',
      'purple-table-filters-popover',
      false,
      (isOpen: boolean) => {
        this.purpleTableBoxFilterStatus = isOpen ? "open" : "close"
      });


  }


  setDealType(type: number) {

    var selectedType = "buyer";

    if (type == 1)
      selectedType = "seller";
    else if (type == 2)
      selectedType = "broker";
    else if (type == 3)
      selectedType = "full";

    this.dealType = selectedType;

    this.counterPartyTypeFilters = []

    this.counterPartyTypeFilters.push(
      {
        property: 'counterPartyId',
        filterValue: this.counterParty
      },
      {
        property: 'counterPartyTypeId',
        filterValue: this.dealType
      }
    )

    this.refreshTableRowsNumber++;
  }


  viewDelivery(deal: DealFullInfo) {


    var width = window.innerWidth;
    width = this.dashSvc.isMenuCollapsed ? width - 100 : width - 270;


    this.drawer.create<ViewDeliveriesComponent, { transactionId: string, dealType: string, productId: string, currency: string }>({
      nzContent: ViewDeliveriesComponent,
      nzTitle: this.tranPipe.transform("view_deliveries_table_modal", "View all deliveries for deal",),
      nzWidth: width + 'px',
      nzData: {
        transactionId: deal.transactionId!,
        dealType: this.dealType,
        productId: deal.portfolioId!,
        currency: deal.originalCurrency!
      },
      nzMaskClosable: false,
      nzPlacement: 'right',
      nzSize: 'large'
    }).afterClose.subscribe(() => {
      this.refreshTableRowsNumber++;
    })


  }


  viewAgreement(deal: DealFullInfo) {


    this.modal.create<DealAgreementStatusComponent, { dealTransactionid: string, refNo: string }>({
      nzContent: DealAgreementStatusComponent,
      nzTitle: this.tranPipe.transform("view_agreement_table_modal", "View agreement status",),

      nzData: {
        dealTransactionid: deal.transactionId!,
        refNo: deal.refNumber!
      },
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: true
    }).afterClose.subscribe((update: boolean) => {

    })
  }

  formatNumber(n: number | null | undefined): string {

    if (n == null)
      n = 0;

    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

}
