import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PurpleTranslationPipe, purpleFormFieldsGroup, PurpleSelectFilter, purpleFormField, Helper } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { StellaService } from 'src/core-modules/sdk/api';
import { Deliveries, Delivery } from '../../modals/deal-ticket-delivery/deal-ticket-delivery.component';
import { VintageFull, VintageMonthClusterComponent } from '../../modals/vintage-month-cluster/vintage-month-cluster.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CurrencyPipe } from '@angular/common';
import { OriginClusterComponent, OriginFull } from '../../modals/origin-cluster/origin-cluster.component';
import { TechFull, TechnologyClusterComponent } from '../../modals/technology-cluster/technology-cluster.component';
import { SelectPathComponent } from '../../modals/select-path/select-path.component';

@Component({
  selector: 'app-deal-ticket-multiple-delivery',
  templateUrl: './deal-ticket-multiple-delivery.component.html',
  styleUrls: ['./deal-ticket-multiple-delivery.component.scss']
})
export class DealTicketMultipleDeliveryComponent implements OnInit {



  constructor(@Inject(LOCALE_ID) public locale: string, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private fb: FormBuilder, private modal: NzModalService, private curr: CurrencyPipe) {

  }

  panels = [
    {
      active: true,
      disabled: false
    }
  ];


  @Input() product!: string;
  @Input() broker: string | null | undefined;
  @Input() bCurrency: string | null | undefined;
  @Input() bFee: number | null | undefined;
  @Input() dealComment: string | null | undefined;
  @Input() clonedDeliveries: Delivery[] | undefined;
  @Output() saveDeliveries = new EventEmitter<Deliveries>();

  deliveryFormFieldGroup1: purpleFormFieldsGroup[][] = [];
  tempDeliveryField1: purpleFormFieldsGroup[] | undefined;
  deliveryFormFieldGroup2: purpleFormFieldsGroup[][] = [];
  tempDeliveryField2: purpleFormFieldsGroup[] | undefined;
  formGroups: FormGroup[] = [];
  dynamicFromGroups: FormGroup[] = [];

  vintages: VintageFull[] = [];
  origins: OriginFull[] = [];
  technologies: TechFull[] = [];
  vintagePosition: string = "";
  originPosition: string = "";
  techPosition: string = "";


  brokerFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  validateForm: FormGroup = this.fb.group({});
  disableForm: boolean = false;

  tempDelivery!: Delivery;

  totalPanelNumber: number = 0;

  deliveries: Delivery[] = [];
  returnValue!: Deliveries;
  allValid: boolean = true;


  totalVolume: number = 0;
  totalSum: number = 0;



  brokerAmount: number | undefined;
  brokerCurrency: string = "";

  showBroker: boolean = true;


  ngOnInit() {

    this.showBroker = !this.product.strEq("hfc-brk");


    this.tempDelivery =
    {

      currency: "",
      deliveryDate: undefined,
      origin: {
        clusters: [],
        origin: [],
        toExclude: [],
        originString: ""
      },
      price: undefined,
      tech: {
        clusters: [],
        technology: [],
        techString: "",
        toExclude: []
      },
      vintageFull: {
        vintages: [{
          clusters: [],
          months: [],
          year: undefined
        }],
        vintageString: ""
      },
      volume: undefined,
      fields: {},
      additional: undefined,
      counterpartyId: undefined,
      transactionId: undefined
    }

    this.brokerFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'broker',
            fieldBootstrapColumn: 3,
            fieldName: this.tranPipe.transform('broker_name_deal_detail', '', []),
            fieldPlaceholder: this.tranPipe.transform('broker_name_deal_detail_placeholder', 'Broker name', []),
            fieldValue: this.broker,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
              { id: 'searchFunction', value: this.counterPartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'currency',
            fieldBootstrapColumn: 3,
            fieldName: this.tranPipe.transform('currency_deal_detail', '', []),
            fieldPlaceholder: this.tranPipe.transform('currency_deal_detail_placeholder', 'Currency', []),
            fieldValue: this.bCurrency,
            fieldIsRequired: false,
            fieldAffix: {
              fieldControlName: "fee",
              fieldPlaceholder: this.tranPipe.transform('fee_deal_detail', 'Fee', []),
              fieldType: "input-number",
              fieldWidth: "85px",
              fieldIsRequired: false,
              fieldValue: this.bFee,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.currenciesScrollFunction },
              { id: 'searchFunction', value: this.currenciesSearchFunction },
              { id: 'searchByIdFunction', value: this.currenciesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ],
            fieldCustomClass: "custom-select"
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'dealComment',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('comment_deal_detail', '', []),
            fieldPlaceholder: this.tranPipe.transform('comment_deal_detail_placeholder', 'Comment', []),
            fieldValue: this.dealComment,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          }
        ]
      }
    ]



    if (this.clonedDeliveries != undefined && this.clonedDeliveries.length > 0) {

      this.clonedDeliveries.forEach((del, i) => {

        if (i > 0)
          this.totalPanelNumber++;
        if (this.totalPanelNumber > 0) {
          this.panels.push(
            {
              active: true,
              disabled: false
            })
          this.panels.at(this.totalPanelNumber - 1)!.active = false;

        }


        this.createFormDelivery(del);


        if (del.origin != undefined)
          this.origins.push(del.origin);

        if (del.tech != undefined)
          this.technologies.push(del.tech);

        if (del.vintageFull != undefined)
          this.vintages.push(del.vintageFull);
      });
    }
    else
      this.createFormDelivery(undefined);


    setInterval(() => {

      //this.firstCurrency = this.formGroups[0].controls["currency"].value;
      this.totalVolume = 0;
      this.totalSum = 0;
      if (this.showBroker == true) {
        this.validateForm.controls["fee"].valueChanges.subscribe((value: number | undefined) => {

          if (value != undefined) {
            this.brokerAmount = value;
          }

        });

        this.validateForm.controls["currency"].valueChanges.subscribe((value: string | undefined) => {

          if (value != undefined) {
            this.brokerCurrency = value;
          }

        });


      }
      for (let i = 0; i <= this.totalPanelNumber; i++) {



        const price = this.formGroups[i].controls["price"].value;
        const volume = this.formGroups[i].controls["volume"].value;

        if (price != undefined && volume != undefined) {
          this.totalVolume += volume;
          var currency = this.formGroups[i].controls["currency"].value;

          this.formGroups[i].controls["totalAmount"].setValue(this.calculateTotalAmount(price, volume, currency));

        }

      }

    }, 200);
  }






  addPanel() {

    for (const i in this.formGroups[this.totalPanelNumber].controls) {
      this.formGroups[this.totalPanelNumber].controls[i].markAsDirty();
      this.formGroups[this.totalPanelNumber].controls[i].updateValueAndValidity();
    }

    for (const i in this.dynamicFromGroups[this.totalPanelNumber].controls) {
      this.dynamicFromGroups[this.totalPanelNumber].controls[i].markAsDirty();
      this.dynamicFromGroups[this.totalPanelNumber].controls[i].updateValueAndValidity();
    }

    if (this.formGroups[this.totalPanelNumber].valid && this.dynamicFromGroups[this.totalPanelNumber].valid) {

      this.panels.at(this.totalPanelNumber)!.active = false;

      this.totalPanelNumber++;
      this.panels.push(
        {
          active: true,
          disabled: false
        })
      this.createFormDelivery(undefined);
    }

  }


  deletePanel(panelIdx: number) {


    if (this.totalPanelNumber == 0) {
      return;
    }

    /* console.log("deliveryFormFieldGroup1 pre::", this.deliveryFormFieldGroup1)
    console.log("deliveryFormFieldGroup2 pre::", this.deliveryFormFieldGroup2) */


    this.formGroups.splice(panelIdx, 1);                       // = this.formGroups.slice(0, panelIdx).concat(this.formGroups.slice(panelIdx + 1));
    this.dynamicFromGroups.splice(panelIdx, 1);                // = this.dynamicFromGroups.slice(0, panelIdx).concat(this.dynamicFromGroups.slice(panelIdx + 1));
    this.deliveryFormFieldGroup1.splice(panelIdx, 1)           // = this.deliveryFormFieldGroup1.slice(0, panelIdx).concat(this.deliveryFormFieldGroup1.slice(panelIdx + 1));
    this.deliveryFormFieldGroup2.splice(panelIdx, 1)           // = this.deliveryFormFieldGroup2.slice(0, panelIdx).concat(this.deliveryFormFieldGroup2.slice(panelIdx + 1));
    this.vintages.splice(panelIdx, 1);                         // = this.vintages.slice(0, panelIdx).concat(this.vintages.slice(panelIdx + 1));
    this.technologies.splice(panelIdx, 1);                     // = this.technologies.slice(0, panelIdx).concat(this.technologies.slice(panelIdx + 1));
    this.origins.splice(panelIdx, 1);                          // = this.origins.slice(0, panelIdx).concat(this.origins.slice(panelIdx + 1));
    this.panels.splice(panelIdx, 1);                           // = this.panels.slice(0, panelIdx).concat(this.panels.slice(panelIdx + 1));




    this.totalPanelNumber--;


    /* console.log("deliveryFormFieldGroup1 post::", this.deliveryFormFieldGroup1)
    console.log("deliveryFormFieldGroup2 post::", this.deliveryFormFieldGroup2) */


  }

  createFormDelivery(delivery: Delivery | undefined) {


    this.formGroups.push(this.fb.group({}));
    this.dynamicFromGroups.push(this.fb.group({}));
    this.tempDeliveryField1 =
      [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.dynamicFromGroups[this.totalPanelNumber],
          formFields: this.generateFormDelivery(delivery)
        }
      ];

    this.tempDeliveryField2 =
      [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.formGroups[this.totalPanelNumber],

          formFields: [
            {
              fieldType: 'input-number',
              fieldControlName: 'volume',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_volume', 'Volume', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_volume_placeholder', 'Volume', []),
              fieldValue: delivery?.volume,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            {
              fieldType: 'input-number',
              fieldControlName: 'price',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_price', 'Price', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_price_placeholder', 'Price', []),
              fieldValue: delivery?.price,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            {
              fieldType: 'select',
              fieldControlName: 'currency',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_currency', 'Currency', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_currency_placeholder', 'Currency', []),
              fieldValue: delivery?.currency,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [
                { id: 'scrollFunction', value: this.currenciesScrollFunction },
                { id: 'searchFunction', value: this.currenciesSearchFunction },
                { id: 'searchByIdFunction', value: this.currenciesSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'totalAmount',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_total_amount', 'Total Amount', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_total_amount_placeholder', 'Total Amount', []),
              fieldValue: this.calculateTotalAmount(delivery?.price, delivery?.volume, delivery?.currency),
              fieldIsRequired: false,
              fieldIsDisabled: true,
              fieldPaddingLeft: false,
              fieldPaddingRight: true
            }
          ]
        }
      ];

    this.deliveryFormFieldGroup1.push(this.tempDeliveryField1);
    this.deliveryFormFieldGroup2.push(this.tempDeliveryField2);

    this.tempDelivery =
    {
      currency: "",
      deliveryDate: undefined,
      origin: {
        clusters: [],
        origin: [],
        toExclude: [],
        originString: ""
      },
      price: undefined,
      tech: {
        clusters: [],
        technology: [],
        techString: "",
        toExclude: []
      },
      vintageFull: {
        vintages: [{
          clusters: [],
          months: [],
          year: undefined
        }],
        vintageString: ""
      },
      volume: undefined,
      fields: {},
      additional: undefined,
      counterpartyId: undefined,
      transactionId: undefined
    }
  }


  save(goNext: boolean) {


    this.deliveries = []

    for (let i = 0; i <= this.totalPanelNumber; i++) {
      var deliveryToInsert: Delivery = {
        currency: "",
        deliveryDate: undefined,
        origin: undefined,
        price: undefined,
        tech: undefined,
        vintageFull: undefined,
        volume: undefined,
        fields: {},
        additional: undefined,
        counterpartyId: undefined,
        transactionId: undefined
      }

      for (const j in this.formGroups[i].controls) {
        this.formGroups[i].controls[j].markAsDirty();
        this.formGroups[i].controls[j].updateValueAndValidity();
      }

      for (const j in this.dynamicFromGroups[i].controls) {
        this.dynamicFromGroups[i].controls[j].markAsDirty();
        this.dynamicFromGroups[i].controls[j].updateValueAndValidity();
      }

      if (this.formGroups[i].valid && this.dynamicFromGroups[i].valid) {
        deliveryToInsert.currency = this.formGroups[i].controls["currency"].value ?? "";
        deliveryToInsert.price = this.formGroups[i].controls["price"].value ?? "";
        deliveryToInsert.volume = this.formGroups[i].controls["volume"].value ?? "";
        this.allValid = true;

        const ddate = (this.dynamicFromGroups[i].controls["deliveryDate"] != undefined && this.dynamicFromGroups[i].controls["deliveryDate"]?.value != undefined) ? new Date(this.dynamicFromGroups[i].controls["deliveryDate"]?.value) : undefined;

        for (const j in this.dynamicFromGroups[i].controls) {
          if (j.strEq("technology") && (this.product.strEq("cee") || this.product.strEq("tee"))) {


            this.technologies.push(
              {
                clusters: [],
                technology: [this.dynamicFromGroups[i].controls[j].value],
                techString: this.dynamicFromGroups[i].controls[j].value,
                toExclude: []
              }
            )


          }
          if (!j.strEq("deliveryDate") && !j.strEq("technology") && !j.strEq("origin") && !j.strEq("vintage") && !j.strEq("additional"))
            deliveryToInsert.fields[j] = this.dynamicFromGroups[i].controls[j].value;
        }

        deliveryToInsert.deliveryDate = ddate != undefined ? ddate.toIsoStringPurple() : undefined;
        deliveryToInsert.vintageFull = this.vintages.length == 0 ? undefined : this.vintages[i];
        deliveryToInsert.origin = this.origins.length == 0 ? undefined : this.origins[i];
        deliveryToInsert.tech = this.technologies.length == 0 ? undefined : this.technologies[i];
        deliveryToInsert.additional = this.dynamicFromGroups[i].controls["additional"] != undefined ? this.dynamicFromGroups[i].controls["additional"].value : undefined
        deliveryToInsert.counterpartyId = this.dynamicFromGroups[i].controls["counterparty"] != undefined ? this.dynamicFromGroups[i].controls["counterparty"].value : undefined

      }
      else
        this.allValid = false;


      if (this.allValid == true) {
        this.deliveries.push(deliveryToInsert);
      }


    }


    if (this.allValid || !goNext) {
      this.returnValue =
      {
        deliveries: this.deliveries.length > 0 ? this.deliveries : undefined,
        brokerId: this.validateForm.controls["broker"] != undefined ? this.validateForm.controls["broker"].value : undefined,
        brokerFee: this.brokerAmount,
        brokerCurrency: this.validateForm.controls["currency"] != undefined ? this.validateForm.controls["currency"].value : undefined,
        dealComment: this.validateForm.controls["dealComment"] != undefined ? this.validateForm.controls["dealComment"].value : undefined,
        goNext: goNext
      }
      this.saveDeliveries.emit(this.returnValue);
    }


  }


  selectVintage(index: number) {

    var data;

    if (this.vintages[index] != undefined && this.vintages.length > 0) {

      data = this.vintages[index];

    }
    this.modal.create<VintageMonthClusterComponent, VintageFull | undefined>({
      nzContent: VintageMonthClusterComponent,
      nzData: data,
      nzTitle: this.tranPipe.transform("edit_delivery_vintage_modal_title", "Select Vintage", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: VintageFull | undefined) => {

      if (res != undefined) {
        if (this.vintages.length == 0) {
          this.vintages.push(res);
        }
        else
          this.vintages[index] = res;


        this.dynamicFromGroups[index].controls["vintage"].setValue(res.vintageString)
      }

    })

  }

  selectOrigin(index: number) {

    var data;

    if (this.origins != undefined && this.origins[index] != undefined && this.origins.length > 0) {

      data = this.origins[index];

    }


    this.modal.create<OriginClusterComponent, { modalOrigin: OriginFull | undefined, isCounterpart: boolean }>({
      nzContent: OriginClusterComponent,
      nzData: { modalOrigin: data, isCounterpart: false },
      nzTitle: this.tranPipe.transform("edit_delivery_origin_modal_title", "Select Origin", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: OriginFull | undefined) => {



      if (res != undefined) {
        if (this.origins.length == 0) {
          this.origins.push(res);
        }
        else
          this.origins[index] = res;


        this.dynamicFromGroups[index].controls["origin"].setValue(this.origins[index].originString)
      }

    })

  }

  selectTechnology(index: number) {

    var data;

    if (this.techPosition != undefined && this.technologies[index] != undefined && this.technologies.length > 0) {

      data = this.technologies[index];

    }

    this.modal.create<TechnologyClusterComponent, { productId: string, modalTech: TechFull | undefined }>({
      nzContent: TechnologyClusterComponent,
      nzData: { modalTech: data, productId: this.product },
      nzTitle: this.tranPipe.transform("edit_delivery_technology_modal_title", "Select Technologies", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: TechFull | undefined) => {

      if (res != undefined) {
        if (this.technologies.length == 0) {
          this.technologies.push(res);
        }
        else
          this.technologies[index] = res;


        this.dynamicFromGroups[index].controls["technology"].setValue(this.technologies[index].techString)
      }

    })

  }


  selectPath(index: number) {

    var data;


    data = this.dynamicFromGroups[index].controls["path"].value;


    this.modal.create<SelectPathComponent, string | undefined>({
      nzContent: SelectPathComponent,
      nzData: data,
      nzTitle: this.tranPipe.transform("edit_delivery_path_modal_title", "Select Path", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.subscribe((res: string | undefined) => {

      if (res != undefined) {

        this.dynamicFromGroups[index].controls["path"].setValue(res)
      }

    })

  }

  generateFormDelivery(delivery: Delivery | undefined) {

    var fields: purpleFormField[] = []



    switch (this.product) {
      case "ver":
        {
          fields =
            [

              {
                fieldType: 'date',
                fieldControlName: 'deliveryDate',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
                fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
              },
              {
                fieldType: 'select',
                fieldControlName: 'technology',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_standard', 'Standard', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_standard_placeholder', 'Standard', []),
                fieldValue: delivery?.tech?.techString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.productTechnologyScrollFunction },
                  { id: 'searchFunction', value: this.productTechnologySearchFunction },
                  { id: 'searchByIdFunction', value: this.productTechnologySearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 }
                ]
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'projectId',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_project_id', 'Project ID', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_project_id_placeholder', 'Project ID', []),
                fieldValue: delivery?.fields["projectId"],
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'projectProponent',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_project_proponent', 'Project Proponent', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_project_proponent_placeholder', 'Project Proponent', []),
                fieldValue: delivery?.fields["projectProponent"],
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: true
              },
              {
                fieldType: 'select',
                fieldControlName: 'additional',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_additional', 'Additional', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_additional_placeholder', 'Additional', []),
                fieldValue: delivery?.additional,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.additionalScrollFunction },
                  { id: 'searchFunction', value: this.additionalSearchFunction },
                  { id: 'searchByIdFunction', value: this.additionalSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              },
              {
                fieldType: 'select',
                fieldControlName: 'projectType',
                fieldBootstrapColumn: 8,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_project_type', 'Project Type', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_project_type_placeholder', 'Project Type', []),
                fieldValue: delivery?.fields["projectType"],
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.projectTypelScrollFunction },
                  { id: 'searchFunction', value: this.projectTypeSearchFunction },
                  { id: 'searchByIdFunction', value: this.projectTypeSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ],
                fieldCustomClass: 'porject-type-select'
              },
              {
                fieldType: 'textarea',
                fieldControlName: 'comment',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_comment', 'Comment', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_comment_placeholder', 'Comment', []),
                fieldValue: delivery?.fields["comment"],
                fieldIsRequired: false,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [{ id: 'rowNumber', value: 4 }]
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'origin',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_location', 'Location', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_location_placeholder', 'Location', []),
                fieldValue: delivery?.origin?.originString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'vintage',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
                fieldValue: delivery?.vintageFull?.vintageString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              }
            ]
        }
        this.vintagePosition = "case-2";
        this.originPosition = "case-1";
        this.techPosition = "case-1";

        break;

      case "cee":
      case "tee":
        {
          fields =
            [

              {
                fieldType: 'date',
                fieldControlName: 'deliveryDate',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
                fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'origin',
                fieldBootstrapColumn: 6,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_origin', 'Origin', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_origin_placeholder', 'Origin', []),
                fieldValue: delivery?.origin?.originString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              },
              {
                fieldType: 'input-text',
                fieldControlName: 'vintage',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
                fieldValue: delivery?.vintageFull?.vintageString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldCustomClass: 'disable-text-field'
              },
              {
                fieldType: 'select-local',
                fieldControlName: 'quality',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_quality', 'Quality', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_quality_placeholder', 'Quality', []),
                fieldValue: delivery?.fields["quality"],
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'isMultiple', value: false },
                  { id: 'showArrow', value: true },
                  { id: 'enableSearch', value: false },
                  {
                    id: 'options', value: [
                      {
                        label: 'ANY',
                        value: 'ANY'
                      },
                      {
                        label: 'HIG/STANDARD',
                        value: 'HIG/STANDARD'
                      },
                      {
                        label: 'LOW/STANDARD',
                        value: 'LOW/STANDARD'
                      },
                      {
                        label: 'HIGH/NON-STANDARD',
                        value: 'HIGH/NON-STANDARD'
                      },
                      {
                        label: 'LOW/NON-STANDARD',
                        value: 'LOW/NON-STANDARD'
                      }
                    ]
                  }
                ]
              },
              {
                fieldType: 'select',
                fieldControlName: 'beneficiary',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_beneficiary', 'Beneficiary', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_beneficiary_placeholder', 'Beneficiary', []),
                fieldValue: delivery?.fields["beneficiary"],
                fieldIsRequired: false,
                fieldIsDisabled: !this.product.strEq("cee"),
                fieldPaddingLeft: true,
                fieldPaddingRight: true,
                fieldShowInfo: !this.product.strEq("cee"),
                fieldInfoText: this.tranPipe.transform("deal_ticket_region_info_text", "This field is available only if the product selected is CEE"),
                fieldOptions: [
                  { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
                  { id: 'searchFunction', value: this.counterPartiesSearchFunction },
                  { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              },
              {
                fieldType: 'select',
                fieldControlName: 'technology',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_standard', 'Type', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_standard_placeholder', 'Type', []),
                fieldValue: delivery?.tech?.techString,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.productTechnologyScrollFunction },
                  { id: 'searchFunction', value: this.productTechnologySearchFunction },
                  { id: 'searchByIdFunction', value: this.productTechnologySearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              }
            ]
        }
        this.techPosition = "case-1";
        break;

      case "hfc":
      case "hfc-uk":
        {
          fields = [

            {
              fieldType: 'date',
              fieldControlName: 'deliveryDate',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
              fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
            },
            {
              fieldType: 'textarea',
              fieldControlName: 'comment',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_comment', 'Comment', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_comment_placeholder', 'Comment', []),
              fieldValue: delivery?.fields["comment"],
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'rowNumber', value: 4 }]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'vintage',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
              fieldValue: delivery?.vintageFull?.vintageString,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            }
          ]
        }
        this.vintagePosition = "case-2";
        this.originPosition = "case-2";
        this.techPosition = "case-1";
        this.originPosition = "case-3";


        break;
      case "hfc-brk":
        {
          fields =
            [
              {
                fieldType: 'select',
                fieldControlName: 'counterparty',
                fieldBootstrapColumn: 8,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_counterparty', 'Counterparty', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_counterparty_placeholder', 'Counterparty', []),
                fieldValue: delivery?.counterpartyId,
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: true,
                fieldOptions: [
                  { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
                  { id: 'searchFunction', value: this.counterPartiesSearchFunction },
                  { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
                  { id: 'refreshFieldNumber', value: 0 },
                ]
              },
              {
                fieldType: 'select-local',
                fieldControlName: 'Buyer/Seller',
                fieldBootstrapColumn: 4,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_buyer_seller', 'Buyer or Seller', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_buyer_seller_placeholder', 'Buyer or Seller', []),
                fieldValue: delivery?.fields["Buyer/Seller"],
                fieldIsRequired: true,
                fieldIsDisabled: false,
                fieldPaddingLeft: true,
                fieldPaddingRight: false,
                fieldOptions: [
                  { id: 'isMultiple', value: false },
                  { id: 'showArrow', value: true },
                  { id: 'enableSearch', value: false },
                  {
                    id: 'options', value: [
                      {
                        label: 'Buyer',
                        value: 'Buyer'
                      },
                      {
                        label: 'Seller',
                        value: 'Seller'
                      }
                    ]
                  }
                ]
              },
              {
                fieldType: 'textarea',
                fieldControlName: 'comment',
                fieldBootstrapColumn: 12,
                fieldName: this.tranPipe.transform('deal_ticket_delivery_comment', 'Comment', []),
                fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_comment_placeholder', 'Comment', []),
                fieldValue: delivery?.fields["comment"],
                fieldIsRequired: false,
                fieldIsDisabled: false,
                fieldPaddingLeft: false,
                fieldPaddingRight: false,
                fieldOptions: [{ id: 'rowNumber', value: 4 }]
              }
            ]
        }
        this.vintagePosition = "case-3";
        this.originPosition = "case-3";
        this.techPosition = "case-1";


        break;

      case "special":
      case "internal":
      case "consulting":
        {
          fields = [
            {
              fieldType: 'date',
              fieldControlName: 'deliveryDate',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
              fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
            },
            {
              fieldType: 'textarea',
              fieldControlName: 'description',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_description', 'Description', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_description_placeholder', 'Description', []),
              fieldValue: delivery?.fields["description"],
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'rowNumber', value: 4 }]
            }]
        }
        this.vintagePosition = "case-3";
        this.originPosition = "case-3";
        this.techPosition = "case-1";
        break;

      default:
        {
          fields = [
            {
              fieldType: 'date',
              fieldControlName: 'deliveryDate',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_date', 'Delivery Date', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
              fieldValue: (delivery?.deliveryDate != undefined && delivery?.deliveryDate != "") ? new Date(delivery?.deliveryDate!) : undefined,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'technology',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_tech', 'Tech', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_tech_placeholder', 'Tech', []),
              fieldValue: delivery?.tech?.techString,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            },
            {
              fieldType: 'select',
              fieldControlName: 'additional',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_additional', 'Additional', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_additional_placeholder', 'Additional', []),
              fieldValue: delivery?.additional,
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [
                { id: 'scrollFunction', value: this.additionalScrollFunction },
                { id: 'searchFunction', value: this.additionalSearchFunction },
                { id: 'searchByIdFunction', value: this.additionalSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'select',
              fieldControlName: 'earmark',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_earmark', 'Earmark', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_earmark_placeholder', 'Earmark', []),
              fieldValue: delivery?.fields["earmark"],
              fieldIsRequired: !this.product.includes("bme"),
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [
                { id: 'scrollFunction', value: this.earmarklScrollFunction },
                { id: 'searchFunction', value: this.earmarkSearchFunction },
                { id: 'searchByIdFunction', value: this.earmarkSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'origin',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_origin', 'Origin', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_origin_placeholder', 'Origin', []),
              fieldValue: delivery?.origin?.originString,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            },
            {
              fieldType: 'select',
              fieldControlName: 'power_plant',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_power_plant', 'Power Plant', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_power_plant_placeholder', 'Plant', []),
              fieldValue: delivery?.fields["power_plant"],
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldOptions: [
                { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
                { id: 'searchFunction', value: this.counterPartiesSearchFunction },
                { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'vintage',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_vintage', 'Vintage', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_vintage_placeholder', 'Vintage', []),
              fieldValue: delivery?.vintageFull?.vintageString,
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldCustomClass: 'disable-text-field'
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'region',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_region', 'Region', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_region_placeholder', 'Region', []),
              fieldValue: delivery?.fields["region"],
              fieldIsRequired: false,
              fieldIsDisabled: !this.product.strEq("gof"),
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldShowInfo: !this.product.strEq("gof"),
              fieldInfoText: this.tranPipe.transform("deal_ticket_region_info_text", "This field is available only if the product selected is GoF")
            },
            {
              fieldType: 'select-local',
              fieldControlName: 'physical',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_physical', 'Physical', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_physical_placeholder', 'Choose', []),
              fieldValue: delivery?.fields["physical"],
              fieldIsRequired: false,
              fieldIsDisabled: !this.product.includes("bme"),
              fieldPaddingLeft: false,
              fieldPaddingRight: true,
              fieldShowInfo: !this.product.includes("bme"),
              fieldInfoText: this.tranPipe.transform("deal_ticket_path_info_text", "This field is available only if the product selected is bme-(uk/eu/ch)"),
              fieldOptions: [
                { id: 'isMultiple', value: false },
                { id: 'showArrow', value: true },
                { id: 'enableSearch', value: false },
                {
                  id: 'options', value: [
                    {
                      label: 'Optional',
                      value: 'Optional'
                    },
                    {
                      label: 'Physical',
                      value: 'Physical'
                    },
                    {
                      label: 'Non-Physical',
                      value: 'Non-Physical'
                    }
                  ]
                }
              ]
            },
            {
              fieldType: 'input-text',
              fieldControlName: 'path',
              fieldBootstrapColumn: 4,
              fieldName: this.tranPipe.transform('deal_ticket_delivery_path', 'Path', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_path_placeholder', 'Path', []),
              fieldValue: delivery?.fields["path"],
              fieldIsRequired: false,
              fieldIsDisabled: !this.product.strEq("ukmarket"),
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldShowInfo: !this.product.strEq("ukmarket"),
              fieldInfoText: this.tranPipe.transform("deal_ticket_path_info_text", "This field is available only if the product selected is Uk Market"),
              fieldCustomClass: 'disable-text-field'
            },
          ]
        }
    }


    return fields;

  }



  calculateTotalAmount(price: number | null | undefined, volume: number | null | undefined, currency: string | null | undefined) {

    if (price == undefined || volume == undefined)
      return '0';

    var p: number = +price;
    var v: number = +volume;

    var total = p * v;

    this.totalSum += total;

    return (currency != undefined ? this.curr.transform(total, currency) : total)

  }


  totalString() {

    var currencies: string[] = []

    for (let i = 0; i <= this.totalPanelNumber; i++) {

      var currency = this.formGroups[i].controls["currency"] != undefined ? this.formGroups[i].controls["currency"].value : undefined;

      if (currency != undefined && !currencies.includes(currency))
        currencies.push(currency)
    }

    return this.totalSum.toFixed(4) + " " + currencies.join("/")


  }

  //ScrollFunction
  currenciesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesSelect(args.culture, args);
  };
  //SearchFunction
  currenciesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  currenciesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByIdSelect(args.culture, args);
  };

  //ScrollFunction
  originScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getOriginSelect(args.culture, args);
  };
  //SearchFunction
  originSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getOriginByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  originSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getOriginByIdsSelect(args.culture, args);
  };




  //ScrollFunction
  technologyScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getTechnologiesSelect(args.culture, args);
  };
  //SearchFunction
  technologySearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getTechnologyByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  technologySearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getTechnologiesByIdsSelect(args.culture, args);
  };



  //ScrollFunction
  productTechnologyScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.product],
        filterOperator: "="
      }
    )
    return this.admSvc.getProductTechnologiesSelect(args.culture, args);
  };
  //SearchFunction
  productTechnologySearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.product],
        filterOperator: "="
      }
    )
    return this.admSvc.getProductTechnologyByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  productTechnologySearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.product],
        filterOperator: "="
      }
    )


    return this.admSvc.getProductTechnologyByIdSelect(args.culture, args);
  };

  //ScrollFunction
  vintageMonthScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthSelectFilter
    return this.admSvc.getVintageMonthsSelect(args.culture, args);
  };
  //SearchFunction
  vintageMonthSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthSelectFilter
    return this.admSvc.getVintageMonthByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  vintageMonthSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthSelectFilter
    return this.admSvc.getVintageMonthsByIdsSelect(args.culture, args);
  };



  vintageMonthClusterScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthClusterSelectFilter

    return this.admSvc.getVintageMonthClustersSelect(args.culture, args);
  };
  //SearchFunction
  vintageMonthClusterSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthClusterSelectFilter

    return this.admSvc.getVintageMonthClusterByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  vintageMonthClusterSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    //args.searchFilters = this.monthClusterSelectFilter

    return this.admSvc.getVintageMonthClustersByIdsSelect(args.culture, args);
  };



  //ScrollFunction
  additionalScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.product],
        filterOperator: "="
      }
    )

    return this.admSvc.getProductAdditionalsSelect(args.culture, args);
  };
  //SearchFunction
  additionalSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {

    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.product],
        filterOperator: "="
      }
    )

    return this.admSvc.getProductAdditionalByIdSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  additionalSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {

    args.searchFilters.push(
      {
        propertyName: "productId",
        filterValues: [this.product],
        filterOperator: "="
      }
    )

    return this.admSvc.getProductAdditionalByIdSelect(args.culture, args);
  };

  //ScrollFunction
  earmarklScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getEarmarkSelect(args.culture, args);
  };
  //SearchFunction
  earmarkSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getEarmarkByIdSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  earmarkSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getEarmarkByIdSelect(args.culture, args);
  };


  //ScrollFunction
  counterPartiesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesSelect(args.culture, args);
  };
  //SearchFunction
  counterPartiesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  counterPartiesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartyByIdSelect(args.culture, args);
  };

  //ScrollFunction
  projectTypelScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProjectTypeSelect(args.culture, args);
  };
  //SearchFunction
  projectTypeSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProjectTypeByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  projectTypeSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProjectTypeByIdSelect(args.culture, args);
  };

}


