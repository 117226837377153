<container-element [ngSwitch]="isModeEnable">
  <ng-container *ngSwitchCase="true">
    <container-element [ngSwitch]="isExceptionModeEnable">
      <ng-container *ngSwitchCase="true">
        <ng-container *ngIf="dashSvc.dashboardHeight != undefined">
          <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
            [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
            [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
            [showCustomPagination]="true">
            <ng-template #tableBody>
              <ng-container *ngFor="let data of listOfData; let i = index">
                <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
                  <td nzAlign="center"><span class="{{data.item.languageId | languageIcon}}"></span></td>
                    <td nzAlign="center">
                    <container-element [ngSwitch]="data.item.isTranslated">
                      <ng-container *ngSwitchCase="true">
                        <span nz-icon nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <span nz-icon nzType="close-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></span>
                      </ng-container>
                    </container-element>
                  </td>
                  <td>{{data.item.systemEventName}}</td>
                  <td>{{data.item.systemEventDescription}}</td>
                  <td nzAlign="center">
                    <container-element [ngSwitch]="data.item.isNotificationModeActive">
                      <ng-container *ngSwitchCase="true">
                        <p class="ps-mb-0 fw-b">{{ 'table_yes' | translation: 'Si': []}}</p>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <p class="ps-mb-0 fw-b">{{ 'table_no' | translation: 'No': []}}</p>
                      </ng-container>
                    </container-element>
                  </td>
                  <td nzAlign="center">
                    <button
                      (click)="editSystemEventMailTemplate(data.item.pk1, data.item.pk2, data.item.systemEventId, data.item.systemEventName)"
                      [nzTooltipTitle]='"push_notification_table_notification_template_preview" | translation: "Mostra Anteprima"'
                      nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle">
                      <i nz-icon class="fsp-18-0" nzType="eye"></i>
                    </button>

                    <button
                      (click)="editSystemEventMailTemplate(data.item.pk1, data.item.pk2, data.item.systemEventId, data.item.systemEventName)"
                      [nzTooltipTitle]='"push_notification_table_notification_edit" | translation: "Modifica Template"'
                      nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                      <i nz-icon class="fsp-18-0" nzType="edit"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </purple-table>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center fsp-16-0 ps-mt-3">
          <div
            [innerHTML]="'push_notification_table_notification_mode_box_is_disable_by_system' | translation: 'L\'invio delle notifiche \<b>{0}\</b> è disabilitato': ['PUSH']">
          </div>
          <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center ps-pt-2">
            <button nz-button nzType="primary" (click)="enableDisableNotificationModeException()">
              {{ isExceptionModeEnable?
              ('push_notification_table_disable_push_notification' | translation: 'Disabilita Notifiche')
              :
              ('push_notification_table_enable_push_notification' | translation: 'Abilita Notifiche')}}
            </button>
          </div>
        </div>
      </ng-container>
    </container-element>
  </ng-container>

  <ng-container *ngSwitchCase="false">
    <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-justify-content-center fsp-16-0 ps-mt-3">
      <div class="ps-text-center"
        [innerHTML]="'push_notification_table_notification_mode_box_is_disable_by_purplesoft' | translation: 'L\'invio delle notifiche \<b>{0}\</b> non è disponibile \</br>Richiedere informazioni a \<b>PURPLESOFT\<b>': ['PUSH']">
      </div>
    </div>
  </ng-container>
</container-element>