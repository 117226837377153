/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PRPTemplatesEnvelope { 
    envelopeTemplateId: string;
    envelopeTemplateName?: string | null;
    envelopeId?: string | null;
    envelopeName?: string | null;
    envelopeDescription?: string | null;
    envelopeEmailBlurb?: string | null;
    envelopeEmailSubject?: string | null;
    accountId?: string | null;
    externalEnvelopeId?: string | null;
    certificateId?: string | null;
    envelopeStatusId?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
}

