import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent implements OnInit, OnDestroy {
  validateForm!: FormGroup;
  isMobile = false;
  subs: Subject<void> = new Subject();

  async submitForm(): Promise<void> {

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.authSvc.requestResetPassword(this.validateForm.controls["email"].value, this.isMobile)
    }
  }

  constructor(private fb: FormBuilder, public authSvc: AuthenticationService, private breakpointObserver: BreakpointObserver) { }

  ngOnDestroy(): void {
    this.subs.next();
    this.subs.complete();
  }

  async ngOnInit(): Promise<void> {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]]
    });

    this.breakpointObserver
      .observe(['(max-width: 700px)'])
      .pipe(takeUntil(this.subs))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }
  
}
