/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiUpdatedDealTicketRequest { 
    transactionId?: string | null;
    dealCompanyId?: string | null;
    dealTraderId?: string | null;
    dealPortfolioId?: string | null;
    dealProductsIds?: Array<string> | null;
    dealPorfolioYear?: number;
    dealIsBuyer?: boolean;
    dealDealDate?: string;
    dealCounterPartyId?: string | null;
    dealBrokerId?: string | null;
    dealBrokerFee?: number | null;
    dealBrokerCurrency?: string | null;
    dealDynamicFields?: { [key: string]: string | null; } | null;
}

