<div class="ps-col-12 ps-d-flex ps-mb-4 ps-justify-content-end">
  <app-purple-this-or-that [buttonNumber]="4" (clickBtn)="setDealType($event)" [text]="thisOrThatTexts">
  </app-purple-this-or-that>

  <span style="padding-left: 15px;">
    @if (show)
    {
    <button nz-button [nzType]="filters.buttonStyle??'default'" class="filter-modal-button center" nz-popover
      [nzPopoverContent]="filters.popoverContent" [nzPopoverTrigger]="filters.popoverTrigger"
      [nzPopoverPlacement]="filters.popoverPlacement" [nzPopoverOverlayClassName]="filters.popoverCustomClass"
      [(nzPopoverVisible)]="filters.popovervisible">
      {{filters.buttonText}}
      <i [nzRotate]="filters.popovervisible?180:0" *ngIf="filters.buttonIcon!= undefined" nz-icon
        [nzType]="filters.buttonIcon!"></i>
    </button>
    }

  </span>
</div>


<purple-table #purpleTable class="ps-col-12" [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
  [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
  [tableScroll]="{y:dashSvc.dashboardHeight-200}" [refreshTableRowsNumber]="refreshTableRowsNumber"
  [isSelectRowActive]="false" [version]="2" [showCustomPagination]="true" tableSearchType="box"
  (filterBox)="setPurpleTablePopover($event)" (onSearchBoxEnd)="closePurpleTablePopover()"
  [filterBoxStatus]="purpleTableBoxFilterStatus" [pageSize]="15" [routePaginationIsEnabled]="false">

  <ng-template #tableBody>
    @for (data of listOfData; track i;let i = $index)
    {
    <ng-container>
      <tr class=" main-purple-table-tr-even {{(data.item.transactionStatusId=='deleted')?'red':''}}">
        <td>{{data.item.refNumber}}</td>
        <td> {{data.item.portfolioId}} </td>
        <td> {{data.item.counterPartyName}} </td>
        <td> {{data.item.portfolioYear}} </td>
        <td> {{formatNumber(data.item.transactionVolume)}}</td>
        <td> {{data.item.avgPrice}}</td>

        <td> {{((data.item.totalPrice! < 0) ? (data.item.totalPrice! * -1) : data.item.totalPrice ) | currency:
            data.item.originalCurrency! : 'symbol' : "1.2-4" }}</td>
        <td> {{data.item.transactionDate|date}}</td>
        <td> {{data.item.originalCurrency}} </td>
        <td nzAlign="center">
          @if(!data.item.counterPartyTypeId?.strEq("broker")&&!data.item.transactionStatusId?.strEq("canceled")&&!data.item.transactionStatusId?.strEq("deleted"))
          {
          <app-stella-table-button (click)="viewDelivery(data.item!)" buttonIcon="icon-file"
            toolTip="{{'view_delivery_table_button'|translation:'View Delivery'}}"></app-stella-table-button>
          <app-stella-table-button (click)="viewAgreement(data.item!)" buttonIcon="icon-agreement"
            toolTip="{{'view_agreement_status_table_button'|translation:'View Agreement Status'}}"></app-stella-table-button>
          }

        </td>
      </tr>
    </ng-container>
    }
  </ng-template>
</purple-table>
