import { Component, OnInit } from '@angular/core';
import { PurpleTranslationPipe, PurpleTableColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, dashboardButton, PurpleLanguageIconPipe } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { BackOfficeUsersService, PRPVRoleLevelLocalization, PRPVRoleLevelLocalizationPurpleTableResponse } from 'src/core-modules/sdk/api';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { EditRoleLevelComponent } from '../modals/edit-role-level/edit-role-level.component';

@Component({
  selector: 'app-role-levels',
  templateUrl: './role-levels.component.html',
  styleUrls: ['./role-levels.component.scss']
})
export class RoleLevelsComponent implements OnInit {

  constructor(public backUserSvc: BackOfficeUsersService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, 
    private modal: NzModalService, private languageIconPipe: PurpleLanguageIconPipe, public tsvc: AppTranslationService ) { }
  sub: Subject<void> = new Subject();

  listOfData: Array<PRPVRoleLevelLocalizationPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("translation_table_header_translation_language", "Lingua"),
      dbKey: "",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "80px"
    },
    {
      name: this.tranPipe.transform("table_header_is_translated", "Tradotto"),
      dbKey: 'isTranslated',
      hasReorder: true,
      hasFilter: true,
      filterType: 'select',
      filters: [
        {
          text: 'Si',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      fixedWidth: "130px"
    },
    {
      name: this.tranPipe.transform("role_level_table_header_id", "Livello"),
      dbKey: "RoleLevelId",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "RoleLevelName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_description", "Descrizione"),
      dbKey: "RoleLevelDescription",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "150px",
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.culture = this.tsvc.translationLanguage.value;
    return this.backUserSvc.getRoleLevelTable(args.culture, args)
  }

  ngOnInit() {
    this.dashSvc.addTranslationSelect();
    this.tsvc.translationLanguage.pipe(takeUntil(this.sub)).subscribe(async () => {
      await this.refreshTableRowsNumber++;
    });
    this.dashSvc.dashMainItems.push(new dashboardButton("add-new-role-level", this.tranPipe.transform('add_new_role_level','Aggiungi Livello', []), ()=>{
      this.editItem(undefined)
    }))
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "role_levels";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Livelli sicurezza', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista dei livelli di sicurezza del sistema', []))
  }

  editItem(roleLevel: PRPVRoleLevelLocalization | undefined) {
    this.modal.create<EditRoleLevelComponent, PRPVRoleLevelLocalization | undefined>({
      nzContent: EditRoleLevelComponent,
      nzData: roleLevel,
      nzTitle: roleLevel == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0} {1}", ["livello", '<span class="ps-ml-1 '+this.languageIconPipe.transform(this.tsvc.translationLanguage.value) +'"></span>']) : 
      this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0} {1}", ["livello", '<span class="ps-ml-1 '+this.languageIconPipe.transform(this.tsvc.translationLanguage.value) +'"></span>']),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (item: boolean | undefined) => {
      if (item==true) {
        this.refreshTableRowsNumber++;
      }
    });
  }

}
