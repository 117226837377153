<div class="ps-col-12 ps-d-flex ps-pr-0" style="margin-bottom: 12px;">
  <div class="ps-col-9">
    <div>
      <label><b>Path selected:</b></label>
    </div>
    <div><input nz-input type="text" [(ngModel)]="pathFullString" class="text-field" [disabled]="true"></div>

  </div>
</div>


@if (show)
{
<div class="ps-col-12">

  <div class="ps-col-12 ps-d-flex" style="margin-bottom: 12px;">
    <div class="ps-col-11 ps-pl-0" style="padding-right: 0;">


      <div class="ps-col-12 ps-d-flex ps-pl-0">
        <div class="ps-col-6 ps-pl-0">

          <nz-list nzBordered nzHeader="Path">

            <cdk-virtual-scroll-viewport itemSize="10" class="path-container">

              @for (item of normalPath?.values|keyvalue; track $index)
              {
              <nz-list-item (click)="onRowClickPath(item!.key)"
                class="list-line {{(selectedPath![item.key]!=undefined&&selectedPath![item.key])?'selected':''}}">
                {{ item.value }}

              </nz-list-item>
              }

            </cdk-virtual-scroll-viewport>
          </nz-list>

        </div>
        <div class="ps-col-6">
          <nz-list nzBordered nzHeader="Path">

            <cdk-virtual-scroll-viewport itemSize="10" class="path-container">

              @for (item of specialPath?.values|keyvalue; track $index)
              {
              <nz-list-item (click)="onRowClickSpecial(item!.key)"
                class="list-line {{(selectedSpecials![item.key]!=undefined&&selectedSpecials![item.key])?'selected':''}}">
                {{ item.value }}

              </nz-list-item>
              }

            </cdk-virtual-scroll-viewport>
          </nz-list>
        </div>


      </div>

    </div>

  </div>

</div>
}
@else
{
<div>
  <nz-spin [nzSpinning]="!show" [nzIndicator]="purpleModalLoaderTemplate">
  </nz-spin>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
</div>
}




<div class="ps-col-11 ps-d-flex ps-justify-content-end ps-pr-0">
  <div class="ps-col-2">
    <button (click)="clearAll()" [nzTooltipTitle]='"clear_origin_placeholder" | translation: "Clear All Data"'
      nz-tooltip nz-button class="modal-button clear">
      <ng-container>{{ 'remove_all_data' | translation: 'Clear': []}}</ng-container>
    </button>
  </div>
  <div class="ps-col-2">
    <button (click)="savePath()" [nzTooltipTitle]='"save_origin_placeholder" | translation: "Save Origin"' nz-tooltip
      nz-button class="modal-button">
      <ng-container>{{ 'save_origin_button' | translation: 'Save': []}}</ng-container>
    </button>
  </div>
</div>
