/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OutputSupportedExtensions = 'png' | 'jpeg' | 'jpg' | 'webp' | 'gif' | 'svg' | 'tiff';

export const OutputSupportedExtensions = {
    Png: 'png' as OutputSupportedExtensions,
    Jpeg: 'jpeg' as OutputSupportedExtensions,
    Jpg: 'jpg' as OutputSupportedExtensions,
    Webp: 'webp' as OutputSupportedExtensions,
    Gif: 'gif' as OutputSupportedExtensions,
    Svg: 'svg' as OutputSupportedExtensions,
    Tiff: 'tiff' as OutputSupportedExtensions
};

