<div class=" ps-d-flex ps-flex-wrap ps-col-12">

  @if (purpleFormFieldGroups1!= undefined)
  {
  <div class="ps-col-6 ps-d-flex ps-justify-content-start">
    <span class="form-title">
      {{'deal_details_subtitle'|translation:'Deal details'}}
    </span>
  </div>
  <div class="ps-col-6 ps-d-flex ps-flex-wrap ps-pr-0" style="padding-top: 12px;">
    <div class="ps-col-10 ps-pl-0">
      <span class="form-title">
        {{'deal_product_subtitle'|translation:'Product details'}}
      </span>
    </div>
    <div class="ps-col-2 ps-d-flex">
      <!--  <span class="form-title new-cp">
        {{'deal_add_counterpart'|translation:'Add a new counterpart?'}}
      </span> -->
      <button [nzTooltipTitle]='"add_new_counterpart" | translation: "Add a new counterpart"' nzTooltipPlacement="top"
        nz-tooltip nz-button nzType="default" nzShape="round" class="counterpart-button " (click)="addCounterpart()">
        <span class="icon-counterpart_icon fsp-18-0"></span>
      </button>
    </div>
  </div>
  }


  <div class="ps-col-12 ps-d-flex ps-justify-content-start">

    @if (purpleFormFieldGroups1!= undefined)
    {
    <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups1">
    </app-purple-form>
    }

  </div>

  <div class="ps-col-3">

    <div style="margin-bottom: 7px;">
      <p class="subtitle" style=" margin-top: 0;">
        {{'deal_type_subtitle'|translation:'Deal type'}}
      </p>
    </div>
    <div>
      <app-purple-this-or-that class="this-or-that-button" [text]="buySellTexts" [buttonNumber]="2"
        (clickBtn)="buySelected($event)" [disabled]="disableThisOrThatBuy" [isEditable]="true"
        [selected]="selectedDealTypeBuy ?0:1">
      </app-purple-this-or-that>
    </div>
  </div>

  <div class="ps-col-9 ps-d-flex ps-justify-content-start" style="padding-left: 24px;">

    @if (purpleFormFieldGroups2!= undefined)
    {
    <app-purple-form [disbledForm]="disableForm" [(formGroup)]="validateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups2">
    </app-purple-form>
    }

  </div>


  @if (showMoreDetails)
  {

  <!-- Deal more details -->

  <div class="ps-col-12 ps-w-100">
    @if (purpleFormFieldGroups3!= undefined)
    {
    <app-purple-form [disbledForm]="disableForm" [(formGroup)]="dynamicValidateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups3">
    </app-purple-form>
    }

  </div>
  @if (showThisOrThat==true)
  {

  <div class="ps-col-12 ps-d-flex ps-pl-0 ps-pr-0 ps-w-100">


    <div class="ps-col-3">

      <div style="margin-bottom: 7px;">
        <p class="subtitle" style=" margin-top: 0;">
          {{'standard_subtitle'|translation:'Standard'}}
        </p>
      </div>
      <div>
        <app-purple-this-or-that class="this-or-that-button " [text]="thisOrThatText"
          [selected]="selectedValueStandard ?0:1" (clickBtn)="standardSelected($event)" [buttonNumber]="2"
          [disabled]="disableForm" [isEditable]="true">
        </app-purple-this-or-that>
      </div>
    </div>

    <div class="ps-col-3 ps-pr-0">

      <div style="margin-bottom: 7px;">
        <p class="subtitle" style=" margin-top: 0;">
          {{'prepayment_subtitle'|translation:'Prepayment'}}
        </p>
      </div>
      <app-purple-this-or-that class="this-or-that-button " [text]="thisOrThatText"
        [selected]="selectedValuePrepayment ?0:1" (clickBtn)="prepaymentSelected($event)" [buttonNumber]="2"
        [disabled]="disableForm" [isEditable]="true">
      </app-purple-this-or-that>

    </div>


    <div class="ps-col-3">

      <div style="margin-bottom: 7px;">
        <p class="subtitle" style=" margin-top: 0;">
          {{'fullofftake_subtitle'|translation:'Full Offtake'}}
        </p>
      </div>
      <app-purple-this-or-that class="this-or-that-button " [text]="thisOrThatText"
        [selected]="selectedValueFullOfftake ?0:1" (clickBtn)="fullOfftakeSelected($event)" [buttonNumber]="2"
        [disabled]="disableForm" [isEditable]="true">
      </app-purple-this-or-that>

    </div>

    @if (b2bshow==true)
    {
    <button [nzTooltipTitle]="'deal_ticket_generate_b2b' | translation: 'Generate Back to Back Code'"
      (click)="generateB2BCode()" nzTooltipPlacement="top" class="ps-mr-3 b2b" nz-tooltip nz-button nzType="default"
      nzShape="round"><i nz-icon nzType="tag"></i>
    </button>
    }


  </div>
  }


  <!-- Deliveries -->

  <app-deal-ticket-multiple-delivery [product]="editDeal!.portfolioId!" (saveDeliveries)="save($event)"
    [broker]="editDeal.brokerId" [bCurrency]="editDeal.brokerCurrency" [bFee]="editDeal.brokerFee"
    [dealComment]="editDeal.fields['comment']!=undefined? editDeal.fields['comment']:undefined"
    [clonedDeliveries]="clonedDeliveries">
  </app-deal-ticket-multiple-delivery>


  }



  <!--  <div class="ps-col-12 ps-d-flex">
    <div style="padding-top:0px" class="ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-end">
      <button (click)="back()" class="deal-progress-previous-button">
        {{'deal_button_previous_text'|translation:'Torna indietro'}}
      </button>

    </div>
    <div class="ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-start" style="padding-top:0px">
      <button (click)="save()" class="deal-progress-next-button">
        {{'delivery_button_next_text'|translation:'Next'}}
      </button>
    </div>
  </div> -->

</div>
