import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Helper, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormField, purpleFormFieldsGroup } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeUsersService, StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { CurrencyPipe } from '@angular/common';
import { DealTicket } from '../../custom-component/deal-ticket-summary/deal-ticket-summary.component';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-edit-deal',
  templateUrl: './edit-deal.component.html',
  styleUrls: ['./edit-deal.component.scss']
})
export class EditDealComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(NZ_MODAL_DATA) public data: { dealTicket: DealTicket, transactionId: string }, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private fb: FormBuilder, private modalRef: NzModalRef, private mesSvc: NzMessageService,
    private backUserSvc: BackOfficeUsersService, private modal: NzModalService, private tsvc: AppTranslationService, private curr: CurrencyPipe, private apiProxySvc: PurpleApiProxyService) { }



  editDeal!: DealTicket;

  purpleFormFieldGroups1: purpleFormFieldsGroup[] | undefined;

  purpleFormFieldGroups2: purpleFormFieldsGroup[] | undefined;

  validateForm: FormGroup = this.fb.group({});
  dynamicValidateForm: FormGroup = this.fb.group({});
  disableForm: boolean = false;
  isLoading: boolean = false;
  thisOrThatText: string[] =
    [
      this.tranPipe.transform("this_or_that_yes", "Yes"),
      this.tranPipe.transform("this_or_that_no", "No")
    ]
  selectedValueStandard: boolean = true;
  selectedValuePrepayment: boolean = true;
  selectedValueFullOfftake: boolean = false;
  tradersSearchFilter: PurpleSelectFilter[] = [
    { propertyName: "traders", filterValues: ['true'] }
  ];
  dealDate: Date | undefined = new Date(this.data.dealTicket.dealDate!);
  deliveryDate: Date | undefined = undefined;
  codeList: string[] = [];
  b2bshow: boolean = true;
  showThisOrThat: boolean = false;


  ngOnInit() {




    this.editDeal = Helper.storeNewObj(this.data.dealTicket);





    this.purpleFormFieldGroups1 = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'company',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('company_deal_details', 'Company', []),
            fieldPlaceholder: this.tranPipe.transform('company_deal_details_placeholder', 'Nvalue AG', []),
            fieldValue: this.editDeal.companyId,
            fieldIsRequired: true,
            fieldIsDisabled: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.companiesScrollFunction },
              { id: 'searchFunction', value: this.companiesSearchFunction },
              { id: 'searchByIdFunction', value: this.companiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'trader',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('trader_initials_deal_details', 'Trader', []),
            fieldPlaceholder: this.tranPipe.transform('trader_initials_deal_details_placeholder', 'Trader', []),
            fieldValue: this.editDeal.traderId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.tradersScrollFunction },
              { id: 'searchFunction', value: this.tradersSearchFunction },
              { id: 'searchByIdFunction', value: this.tradersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'date',
            fieldControlName: 'portfolioYear',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_year_deal_detail', 'Portfolio year', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_year_deal_detail_placeholder', 'Year', []),
            fieldValue: this.editDeal.portfolioYear,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'pickerMode', value: 'year' }]
          },
          {
            fieldType: 'date',
            fieldControlName: 'date',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('date_deal_details', 'Date', []),
            fieldPlaceholder: this.tranPipe.transform('date_deal_details_placeholder', 'Date', []),
            fieldValue: this.editDeal.dealDate != undefined ? new Date(this.editDeal.dealDate) : (new Date()),
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'dateFormat', value: "yyyy-MM-dd" }]
          },
          {
            fieldType: 'select',
            fieldControlName: 'counterparty',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('counterparty_deal_detail', 'Counterparty', []),
            fieldPlaceholder: this.tranPipe.transform('counterparty_deal_detail_placeholder', 'Counterparty', []),
            fieldValue: this.editDeal.counterPartyId,
            fieldIsRequired: true,
            fieldIsDisabled: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
              { id: 'searchFunction', value: this.counterPartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'dealComment',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('comment_deal_detail', 'Comment', []),
            fieldPlaceholder: this.tranPipe.transform('comment_deal_detail_placeholder', 'Comment', []),
            fieldValue: this.editDeal.fields["comment"],
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select',
            fieldControlName: 'broker',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_broker_name_deal_detail', 'Broker name', []),
            fieldPlaceholder: this.tranPipe.transform('broker_name_deal_detail_placeholder', 'Broker name', []),
            fieldValue: this.editDeal.brokerId,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
              { id: 'searchFunction', value: this.counterPartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'currency',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_fee_currency_deal_detail', 'Broker Fee and Currency', []),
            fieldPlaceholder: this.tranPipe.transform('currency_deal_detail_placeholder', 'Fee & Currency', []),
            fieldValue: this.editDeal.brokerCurrency,
            fieldIsRequired: false,
            fieldAffix: {
              fieldControlName: "fee",
              fieldPlaceholder: this.tranPipe.transform('edit_fee_deal_detail', 'Fee', []),
              fieldType: "input-number",
              fieldWidth: "350px",
              fieldIsRequired: false,
              fieldValue: this.editDeal.brokerFee,
              fieldOptions: [{ id: 'minNumber', value: 0 }]
            },
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.currenciesScrollFunction },
              { id: 'searchFunction', value: this.currenciesSearchFunction },
              { id: 'searchByIdFunction', value: this.currenciesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          }
        ]
      }

    ]


    this.purpleFormFieldGroups2 =
      [
        {
          fieldGroupNumber: 1,
          fielGroupBootstrapColumn: 12,
          fieldGroupPaddingLeft: false,
          fieldGroupPaddingRight: false,
          formFieldFormGroup: this.dynamicValidateForm,
          formFields: this.showOtherInfos(this.editDeal.portfolioId!)
        }
      ]

    this.getAllB2BCode()

  }


  showOtherInfos(product: string) {

    var typeDisabled = !(product.strEq("hfc") || product.strEq("hfc-uk"));
    var fields: purpleFormField[] = []
    switch (product) {
      case "special":
      case "internal":
      case "consulting":
        {
          fields = [
            {
              fieldType: 'input-text',
              fieldControlName: 'serviceName',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('service_name_deal_details', 'Service Name', []),
              fieldPlaceholder: this.tranPipe.transform('service_name_deal_details_placeholder', 'Service Name', []),
              fieldValue: this.editDeal?.fields["serviceName"],
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true
            },
            {
              fieldType: 'select',
              fieldControlName: 'linkedProduct',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('product_deal_detail', 'Linked Product', []),
              fieldPlaceholder: this.tranPipe.transform('product_deal_detail_placeholder', 'Product', []),
              fieldValue: this.editDeal?.fields["linkedProduct"],
              fieldIsRequired: product.strEq("special"),
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [
                { id: 'scrollFunction', value: this.productsScrollFunction },
                { id: 'searchFunction', value: this.productsSearchFunction },
                { id: 'searchByIdFunction', value: this.productSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            }
          ]
        }
        this.showThisOrThat = true;

        this.selectedValueStandard = this.editDeal.fields["standard"]!.strEq("Standard")
        this.selectedValueFullOfftake = this.editDeal.fields["is_offtake"]!.strEq("Full Offtake")
        this.selectedValuePrepayment = this.editDeal.fields["prepayment"]!.strEq("Prepayment")


        this.editDeal.fields["standard"] = this.selectedValueStandard ? 'Standard' : 'Non Standard';
        this.editDeal.fields["prepayment"] = this.selectedValuePrepayment ? 'Prepayment' : 'No Prepayment';
        this.editDeal.fields["is_offtake"] = this.selectedValueFullOfftake ? 'Full Offtake' : 'No Full Offtake';

        this.b2bshow = false;

        break;

      case "hfc-brk":

        fields = [
          {
            fieldType: 'textarea',
            fieldControlName: 'description',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('deal_ticket_description', 'Description', []),
            fieldPlaceholder: this.tranPipe.transform('deal_ticket_description_placeholder', 'Description', []),
            fieldValue: this.editDeal?.fields["description"],
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: 'rowNumber', value: 2 }]
          },
          {
            fieldType: 'date',
            fieldControlName: 'deliveryDate',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('deal_ticket_date', 'Delivery Date', []),
            fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
            fieldValue: this.deliveryDate != undefined ? this.deliveryDate : new Date(),
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
          }
        ]
        this.showThisOrThat = false;
        this.b2bshow = false;

        break;

      default:

        fields = [
          {
            fieldType: 'input-text',
            fieldControlName: 'b2b_code',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('back_to_back_code_deal_details', 'Back to back code', []),
            fieldPlaceholder: this.tranPipe.transform('back_to_back_code_deal_details_placeholder', 'Back to back code', []),
            fieldValue: this.editDeal?.fields["b2b_code"],
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldCustomClass: 'b2b-select'
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'type',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('type_deal_details', 'Type', []),
            fieldPlaceholder: this.tranPipe.transform('type_deal_details_placeholder', 'Type', []),
            fieldValue: this.editDeal?.fields["type"],
            fieldIsRequired: false,
            fieldIsDisabled: typeDisabled,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              { id: 'enableSearch', value: false },
              {
                id: 'options', value: [
                  {
                    label: 'Incumbent',
                    value: 'incumbent'
                  },
                  {
                    label: 'New Entrant',
                    value: 'New Entrant'
                  },
                  {
                    label: 'Seeker',
                    value: 'Seeker'
                  },
                ]
              }
            ],
            fieldShowInfo: (!product.strEq("hfc") || !product.strEq("hfc-uk")),
            fieldInfoText: this.tranPipe.transform("view_deal_type_info_text", "This field is enabled when product selected is hfc or hfc-uk")
          }

        ]

        this.showThisOrThat = true;

        this.selectedValueStandard = this.editDeal.fields["standard"]!.strEq("Standard")
        this.selectedValueFullOfftake = this.editDeal.fields["is_offtake"]!.strEq("Full Offtake")
        this.selectedValuePrepayment = this.editDeal.fields["prepayment"]!.strEq("Prepayment")


        this.editDeal.fields["standard"] = this.selectedValueStandard ? 'Standard' : 'Non Standard';
        this.editDeal.fields["prepayment"] = this.selectedValuePrepayment ? 'Prepayment' : 'No Prepayment';
        this.editDeal.fields["is_offtake"] = this.selectedValueFullOfftake ? 'Full Offtake' : 'No Full Offtake';
        this.b2bshow = true;

        break;
    }


    return fields;

  }



  generateB2BCode() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let res = '';
    const length = 6;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      res += chars.charAt(randomIndex);
    }


    if (this.codeList.includes(res))
      this.generateB2BCode();


    this.dynamicValidateForm.controls["b2b_code"].setValue(res)
  }

  async getAllB2BCode() {

    await this.apiProxySvc.makeRequestErrorFunction<string[]>(() => this.admSvc.getB2BCode(this.tsvc.currentLanguage.value),
      true,
      "internal-loader",
      100,
      () => this.getAllB2BCode(), (res) => {
        this.codeList = res.data!
      })

  }



  standardSelected(clicked: number) {
    this.selectedValueStandard = clicked == 0 ? true : false;
  }

  prepaymentSelected(clicked: number) {

    this.selectedValuePrepayment = clicked == 0 ? true : false;



  }

  fullOfftakeSelected(clicked: number) {

    this.selectedValueFullOfftake = clicked == 0 ? true : false;



  }


  saveDeal() {


    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    for (const i in this.dynamicValidateForm.controls) {
      this.dynamicValidateForm.controls[i].markAsDirty();
      this.dynamicValidateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid && this.dynamicValidateForm.valid) {




      const portfolioYear = this.validateForm.controls["portfolioYear"].value != undefined ? new Date(this.validateForm.controls["portfolioYear"].value) : undefined;


      this.editDeal.brokerCurrency = this.validateForm.controls["currency"].value;
      this.editDeal.brokerFee = this.validateForm.controls["fee"].value;
      this.editDeal.brokerId = this.validateForm.controls["broker"].value;
      this.editDeal.companyId = this.validateForm.controls["company"].value;
      this.editDeal.counterPartyId = this.validateForm.controls["counterparty"].value;
      this.editDeal.dealDate = this.dealDate != undefined ? this.dealDate.toISOString() : undefined;
      this.editDeal.portfolioYear = portfolioYear;
      this.editDeal.traderId = this.validateForm.controls["trader"].value;

      this.editDeal.fields["comment"] = this.validateForm.controls["dealComment"].value


      this.editDeal.fields["is_offtake"] = this.selectedValueFullOfftake ? "Full Offtake" : "No Full Offtake";
      this.editDeal.fields["prepayment"] = this.selectedValuePrepayment ? "Prepayment" : "No Prepayment ";
      this.editDeal.fields["standard"] = this.selectedValueStandard ? "Standard" : "Non Standard";

      for (const i in this.dynamicValidateForm.controls) {

        if (i.strEq("deliveryDate")) {
          this.editDeal.fields[i] = this.deliveryDate != undefined ? this.deliveryDate?.toISOString() : null;

        }
        else
          this.editDeal.fields[i] = this.dynamicValidateForm.controls[i].value;
      }

      if (Helper.checkIfDoChanges(this.data.dealTicket, this.editDeal)) {

        //update method

        console.log("this.editDeal.fields", this.editDeal.fields)
        this.apiProxySvc.makeRequestErrorMessage<boolean>(() =>
          this.admSvc.updateDealTicket(this.tsvc.currentLanguage.value,
            {
              dealBrokerCurrency: this.editDeal.brokerCurrency,
              dealBrokerFee: this.editDeal.brokerFee,
              dealBrokerId: this.editDeal.brokerId,
              dealCompanyId: this.editDeal.companyId,
              dealCounterPartyId: this.editDeal.counterPartyId,
              dealDealDate: this.editDeal.dealDate,
              dealDynamicFields: this.editDeal.fields,
              dealIsBuyer: this.editDeal.dealBuySeller?.strEq("Buy"),
              dealPorfolioYear: this.editDeal.portfolioYear?.getFullYear(),
              dealPortfolioId: this.editDeal.portfolioId,
              dealProductsIds: [],
              dealTraderId: this.editDeal.traderId,
              transactionId: this.data.transactionId

            })
          , false, "internal-loader", 500, undefined, environment.MESSAGE_DURATION, (res) => {
            if (res.data)
              this.modalRef.destroy(true);
          })



      }
      else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'No changes', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }

    }
    else {
      this.isLoading = false;
    }







  }




  //ScrollFunction
  companiesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCompaniesSelect(args.culture, args);
  };
  //SearchFunction
  companiesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCompaniesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  companiesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCompaniesByIdSelect(args.culture, args);
  };



  //ScrollFunction
  tradersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.tradersSearchFilter;
    return this.backUserSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  tradersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.tradersSearchFilter;
    return this.backUserSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  tradersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.tradersSearchFilter;
    return this.backUserSvc.getUsersByIdSelect(args.culture, args)

  }


  //ScrollFunction
  counterPartiesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesSelect(args.culture, args);
  };
  //SearchFunction
  counterPartiesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  counterPartiesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartyByIdSelect(args.culture, args);
  };



  //ScrollFunction
  currenciesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesSelect(args.culture, args);
  };
  //SearchFunction
  currenciesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  currenciesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCurrenciesByIdSelect(args.culture, args);
  };




  //ScrollFunction
  productsScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsSelect(args.culture, args);
  };
  //SearchFunction
  productsSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  productsSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByIdsSelect(args.culture, args);
  };

  productSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByIdSelect(args.culture, args);
  };


}
