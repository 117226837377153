/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { PRPTemplatesDocumentListPurpleApiResponse } from '../model/pRPTemplatesDocumentListPurpleApiResponse';
// @ts-ignore
import { PRPTemplatesEnvelopeListPurpleApiResponse } from '../model/pRPTemplatesEnvelopeListPurpleApiResponse';
// @ts-ignore
import { PRPTemplatesRecipientListPurpleApiResponse } from '../model/pRPTemplatesRecipientListPurpleApiResponse';
// @ts-ignore
import { PRPTemplatesTabListPurpleApiResponse } from '../model/pRPTemplatesTabListPurpleApiResponse';
// @ts-ignore
import { RealizeTemplateRequest } from '../model/realizeTemplateRequest';
// @ts-ignore
import { RealizeTemplateResponsePurpleApiResponse } from '../model/realizeTemplateResponsePurpleApiResponse';
// @ts-ignore
import { TemplatizeDocumentRequest } from '../model/templatizeDocumentRequest';
// @ts-ignore
import { TemplatizeDocumentResponsePurpleApiResponse } from '../model/templatizeDocumentResponsePurpleApiResponse';
// @ts-ignore
import { TemplatizeEnvelopeRequest } from '../model/templatizeEnvelopeRequest';
// @ts-ignore
import { TemplatizeEnvelopeResponsePurpleApiResponse } from '../model/templatizeEnvelopeResponsePurpleApiResponse';
// @ts-ignore
import { TemplatizeRecipientRequest } from '../model/templatizeRecipientRequest';
// @ts-ignore
import { TemplatizeRecipientResponsePurpleApiResponse } from '../model/templatizeRecipientResponsePurpleApiResponse';
// @ts-ignore
import { TemplatizeTabRequest } from '../model/templatizeTabRequest';
// @ts-ignore
import { TemplatizeTabResponsePurpleApiResponse } from '../model/templatizeTabResponsePurpleApiResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SignatureTemplatesService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param culture 
     * @param documentTemplateId 
     * @param documentTemplateName 
     * @param documentId 
     * @param envelopeId 
     * @param documentSequence 
     * @param documentName 
     * @param documentContentId 
     * @param documentContentCrc 
     * @param documentContentVersion 
     * @param signedDocumentId 
     * @param signedDocumentCrc 
     * @param isActive 
     * @param timeStamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplatesGet(culture: string, documentTemplateId?: string, documentTemplateName?: string, documentId?: string, envelopeId?: string, documentSequence?: number, documentName?: string, documentContentId?: string, documentContentCrc?: string, documentContentVersion?: number, signedDocumentId?: string, signedDocumentCrc?: string, isActive?: boolean, timeStamp?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<PRPTemplatesDocumentListPurpleApiResponse>;
    public documentTemplatesGet(culture: string, documentTemplateId?: string, documentTemplateName?: string, documentId?: string, envelopeId?: string, documentSequence?: number, documentName?: string, documentContentId?: string, documentContentCrc?: string, documentContentVersion?: number, signedDocumentId?: string, signedDocumentCrc?: string, isActive?: boolean, timeStamp?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PRPTemplatesDocumentListPurpleApiResponse>>;
    public documentTemplatesGet(culture: string, documentTemplateId?: string, documentTemplateName?: string, documentId?: string, envelopeId?: string, documentSequence?: number, documentName?: string, documentContentId?: string, documentContentCrc?: string, documentContentVersion?: number, signedDocumentId?: string, signedDocumentCrc?: string, isActive?: boolean, timeStamp?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PRPTemplatesDocumentListPurpleApiResponse>>;
    public documentTemplatesGet(culture: string, documentTemplateId?: string, documentTemplateName?: string, documentId?: string, envelopeId?: string, documentSequence?: number, documentName?: string, documentContentId?: string, documentContentCrc?: string, documentContentVersion?: number, signedDocumentId?: string, signedDocumentCrc?: string, isActive?: boolean, timeStamp?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling documentTemplatesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (documentTemplateId !== undefined && documentTemplateId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentTemplateId, 'DocumentTemplateId');
        }
        if (documentTemplateName !== undefined && documentTemplateName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentTemplateName, 'DocumentTemplateName');
        }
        if (documentId !== undefined && documentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentId, 'DocumentId');
        }
        if (envelopeId !== undefined && envelopeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeId, 'EnvelopeId');
        }
        if (documentSequence !== undefined && documentSequence !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentSequence, 'DocumentSequence');
        }
        if (documentName !== undefined && documentName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentName, 'DocumentName');
        }
        if (documentContentId !== undefined && documentContentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentContentId, 'DocumentContentId');
        }
        if (documentContentCrc !== undefined && documentContentCrc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentContentCrc, 'DocumentContentCrc');
        }
        if (documentContentVersion !== undefined && documentContentVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentContentVersion, 'DocumentContentVersion');
        }
        if (signedDocumentId !== undefined && signedDocumentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>signedDocumentId, 'SignedDocumentId');
        }
        if (signedDocumentCrc !== undefined && signedDocumentCrc !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>signedDocumentCrc, 'SignedDocumentCrc');
        }
        if (isActive !== undefined && isActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isActive, 'IsActive');
        }
        if (timeStamp !== undefined && timeStamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeStamp, 'TimeStamp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/DocumentTemplatesGet`;
        return this.httpClient.request<PRPTemplatesDocumentListPurpleApiResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param templatizeDocumentRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentTemplatize(culture: string, templatizeDocumentRequest?: TemplatizeDocumentRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<TemplatizeDocumentResponsePurpleApiResponse>;
    public documentTemplatize(culture: string, templatizeDocumentRequest?: TemplatizeDocumentRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TemplatizeDocumentResponsePurpleApiResponse>>;
    public documentTemplatize(culture: string, templatizeDocumentRequest?: TemplatizeDocumentRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TemplatizeDocumentResponsePurpleApiResponse>>;
    public documentTemplatize(culture: string, templatizeDocumentRequest?: TemplatizeDocumentRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling documentTemplatize.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/DocumentTemplatize`;
        return this.httpClient.request<TemplatizeDocumentResponsePurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: templatizeDocumentRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param envelopeTemplateId 
     * @param envelopeTemplateName 
     * @param envelopeId 
     * @param envelopeName 
     * @param envelopeDescription 
     * @param envelopeEmailBlurb 
     * @param envelopeEmailSubject 
     * @param accountId 
     * @param externalEnvelopeId 
     * @param certificateId 
     * @param envelopeStatusId 
     * @param isActive 
     * @param timeStamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public envelopeTemplatesGet(culture: string, envelopeTemplateId?: string, envelopeTemplateName?: string, envelopeId?: string, envelopeName?: string, envelopeDescription?: string, envelopeEmailBlurb?: string, envelopeEmailSubject?: string, accountId?: string, externalEnvelopeId?: string, certificateId?: string, envelopeStatusId?: string, isActive?: boolean, timeStamp?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<PRPTemplatesEnvelopeListPurpleApiResponse>;
    public envelopeTemplatesGet(culture: string, envelopeTemplateId?: string, envelopeTemplateName?: string, envelopeId?: string, envelopeName?: string, envelopeDescription?: string, envelopeEmailBlurb?: string, envelopeEmailSubject?: string, accountId?: string, externalEnvelopeId?: string, certificateId?: string, envelopeStatusId?: string, isActive?: boolean, timeStamp?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PRPTemplatesEnvelopeListPurpleApiResponse>>;
    public envelopeTemplatesGet(culture: string, envelopeTemplateId?: string, envelopeTemplateName?: string, envelopeId?: string, envelopeName?: string, envelopeDescription?: string, envelopeEmailBlurb?: string, envelopeEmailSubject?: string, accountId?: string, externalEnvelopeId?: string, certificateId?: string, envelopeStatusId?: string, isActive?: boolean, timeStamp?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PRPTemplatesEnvelopeListPurpleApiResponse>>;
    public envelopeTemplatesGet(culture: string, envelopeTemplateId?: string, envelopeTemplateName?: string, envelopeId?: string, envelopeName?: string, envelopeDescription?: string, envelopeEmailBlurb?: string, envelopeEmailSubject?: string, accountId?: string, externalEnvelopeId?: string, certificateId?: string, envelopeStatusId?: string, isActive?: boolean, timeStamp?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling envelopeTemplatesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (envelopeTemplateId !== undefined && envelopeTemplateId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeTemplateId, 'EnvelopeTemplateId');
        }
        if (envelopeTemplateName !== undefined && envelopeTemplateName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeTemplateName, 'EnvelopeTemplateName');
        }
        if (envelopeId !== undefined && envelopeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeId, 'EnvelopeId');
        }
        if (envelopeName !== undefined && envelopeName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeName, 'EnvelopeName');
        }
        if (envelopeDescription !== undefined && envelopeDescription !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeDescription, 'EnvelopeDescription');
        }
        if (envelopeEmailBlurb !== undefined && envelopeEmailBlurb !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeEmailBlurb, 'EnvelopeEmailBlurb');
        }
        if (envelopeEmailSubject !== undefined && envelopeEmailSubject !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeEmailSubject, 'EnvelopeEmailSubject');
        }
        if (accountId !== undefined && accountId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accountId, 'AccountId');
        }
        if (externalEnvelopeId !== undefined && externalEnvelopeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>externalEnvelopeId, 'ExternalEnvelopeId');
        }
        if (certificateId !== undefined && certificateId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>certificateId, 'CertificateId');
        }
        if (envelopeStatusId !== undefined && envelopeStatusId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>envelopeStatusId, 'EnvelopeStatusId');
        }
        if (isActive !== undefined && isActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isActive, 'IsActive');
        }
        if (timeStamp !== undefined && timeStamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeStamp, 'TimeStamp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/EnvelopeTemplatesGet`;
        return this.httpClient.request<PRPTemplatesEnvelopeListPurpleApiResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param templatizeEnvelopeRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public envelopeTemplatize(culture: string, templatizeEnvelopeRequest?: TemplatizeEnvelopeRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<TemplatizeEnvelopeResponsePurpleApiResponse>;
    public envelopeTemplatize(culture: string, templatizeEnvelopeRequest?: TemplatizeEnvelopeRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TemplatizeEnvelopeResponsePurpleApiResponse>>;
    public envelopeTemplatize(culture: string, templatizeEnvelopeRequest?: TemplatizeEnvelopeRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TemplatizeEnvelopeResponsePurpleApiResponse>>;
    public envelopeTemplatize(culture: string, templatizeEnvelopeRequest?: TemplatizeEnvelopeRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling envelopeTemplatize.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/EnvelopeTemplatize`;
        return this.httpClient.request<TemplatizeEnvelopeResponsePurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: templatizeEnvelopeRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param realizeTemplateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public realize(culture: string, realizeTemplateRequest?: RealizeTemplateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<RealizeTemplateResponsePurpleApiResponse>;
    public realize(culture: string, realizeTemplateRequest?: RealizeTemplateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RealizeTemplateResponsePurpleApiResponse>>;
    public realize(culture: string, realizeTemplateRequest?: RealizeTemplateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RealizeTemplateResponsePurpleApiResponse>>;
    public realize(culture: string, realizeTemplateRequest?: RealizeTemplateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling realize.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/Realize`;
        return this.httpClient.request<RealizeTemplateResponsePurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: realizeTemplateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param templatizeRecipientRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recipientTemplatize(culture: string, templatizeRecipientRequest?: TemplatizeRecipientRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<TemplatizeRecipientResponsePurpleApiResponse>;
    public recipientTemplatize(culture: string, templatizeRecipientRequest?: TemplatizeRecipientRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TemplatizeRecipientResponsePurpleApiResponse>>;
    public recipientTemplatize(culture: string, templatizeRecipientRequest?: TemplatizeRecipientRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TemplatizeRecipientResponsePurpleApiResponse>>;
    public recipientTemplatize(culture: string, templatizeRecipientRequest?: TemplatizeRecipientRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling recipientTemplatize.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/RecipientTemplatize`;
        return this.httpClient.request<TemplatizeRecipientResponsePurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: templatizeRecipientRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param recipientTemplateId 
     * @param recipientTemplateName 
     * @param recipientId 
     * @param recipientRoleId 
     * @param userEmail 
     * @param recipientEnvelopeId 
     * @param routingOrder 
     * @param isActive 
     * @param timeStamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recipientsTemplatesGet(culture: string, recipientTemplateId?: string, recipientTemplateName?: string, recipientId?: string, recipientRoleId?: string, userEmail?: string, recipientEnvelopeId?: string, routingOrder?: number, isActive?: boolean, timeStamp?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<PRPTemplatesRecipientListPurpleApiResponse>;
    public recipientsTemplatesGet(culture: string, recipientTemplateId?: string, recipientTemplateName?: string, recipientId?: string, recipientRoleId?: string, userEmail?: string, recipientEnvelopeId?: string, routingOrder?: number, isActive?: boolean, timeStamp?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PRPTemplatesRecipientListPurpleApiResponse>>;
    public recipientsTemplatesGet(culture: string, recipientTemplateId?: string, recipientTemplateName?: string, recipientId?: string, recipientRoleId?: string, userEmail?: string, recipientEnvelopeId?: string, routingOrder?: number, isActive?: boolean, timeStamp?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PRPTemplatesRecipientListPurpleApiResponse>>;
    public recipientsTemplatesGet(culture: string, recipientTemplateId?: string, recipientTemplateName?: string, recipientId?: string, recipientRoleId?: string, userEmail?: string, recipientEnvelopeId?: string, routingOrder?: number, isActive?: boolean, timeStamp?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling recipientsTemplatesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (recipientTemplateId !== undefined && recipientTemplateId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientTemplateId, 'RecipientTemplateId');
        }
        if (recipientTemplateName !== undefined && recipientTemplateName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientTemplateName, 'RecipientTemplateName');
        }
        if (recipientId !== undefined && recipientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientId, 'RecipientId');
        }
        if (recipientRoleId !== undefined && recipientRoleId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientRoleId, 'RecipientRoleId');
        }
        if (userEmail !== undefined && userEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userEmail, 'UserEmail');
        }
        if (recipientEnvelopeId !== undefined && recipientEnvelopeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientEnvelopeId, 'RecipientEnvelopeId');
        }
        if (routingOrder !== undefined && routingOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>routingOrder, 'RoutingOrder');
        }
        if (isActive !== undefined && isActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isActive, 'IsActive');
        }
        if (timeStamp !== undefined && timeStamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeStamp, 'TimeStamp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/RecipientsTemplatesGet`;
        return this.httpClient.request<PRPTemplatesRecipientListPurpleApiResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param tabTemplateId 
     * @param tabTemplateName 
     * @param tabId 
     * @param recipientId 
     * @param documentId 
     * @param tabLabel 
     * @param tabTypeId 
     * @param tabInitialValue 
     * @param tabIsRequired 
     * @param tabIsReadOnly 
     * @param tabPlacementFormFieldName 
     * @param tabPlacementAnchor 
     * @param tabPlacementPage 
     * @param tabPlacementOffsetXPts 
     * @param tabPlacementOffsetYPts 
     * @param tabPlacementWidth 
     * @param tabPlacementHeight 
     * @param isActive 
     * @param timeStamp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tabTemplatesGet(culture: string, tabTemplateId?: string, tabTemplateName?: string, tabId?: string, recipientId?: string, documentId?: string, tabLabel?: string, tabTypeId?: string, tabInitialValue?: string, tabIsRequired?: boolean, tabIsReadOnly?: boolean, tabPlacementFormFieldName?: string, tabPlacementAnchor?: string, tabPlacementPage?: number, tabPlacementOffsetXPts?: number, tabPlacementOffsetYPts?: number, tabPlacementWidth?: number, tabPlacementHeight?: number, isActive?: boolean, timeStamp?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<PRPTemplatesTabListPurpleApiResponse>;
    public tabTemplatesGet(culture: string, tabTemplateId?: string, tabTemplateName?: string, tabId?: string, recipientId?: string, documentId?: string, tabLabel?: string, tabTypeId?: string, tabInitialValue?: string, tabIsRequired?: boolean, tabIsReadOnly?: boolean, tabPlacementFormFieldName?: string, tabPlacementAnchor?: string, tabPlacementPage?: number, tabPlacementOffsetXPts?: number, tabPlacementOffsetYPts?: number, tabPlacementWidth?: number, tabPlacementHeight?: number, isActive?: boolean, timeStamp?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PRPTemplatesTabListPurpleApiResponse>>;
    public tabTemplatesGet(culture: string, tabTemplateId?: string, tabTemplateName?: string, tabId?: string, recipientId?: string, documentId?: string, tabLabel?: string, tabTypeId?: string, tabInitialValue?: string, tabIsRequired?: boolean, tabIsReadOnly?: boolean, tabPlacementFormFieldName?: string, tabPlacementAnchor?: string, tabPlacementPage?: number, tabPlacementOffsetXPts?: number, tabPlacementOffsetYPts?: number, tabPlacementWidth?: number, tabPlacementHeight?: number, isActive?: boolean, timeStamp?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PRPTemplatesTabListPurpleApiResponse>>;
    public tabTemplatesGet(culture: string, tabTemplateId?: string, tabTemplateName?: string, tabId?: string, recipientId?: string, documentId?: string, tabLabel?: string, tabTypeId?: string, tabInitialValue?: string, tabIsRequired?: boolean, tabIsReadOnly?: boolean, tabPlacementFormFieldName?: string, tabPlacementAnchor?: string, tabPlacementPage?: number, tabPlacementOffsetXPts?: number, tabPlacementOffsetYPts?: number, tabPlacementWidth?: number, tabPlacementHeight?: number, isActive?: boolean, timeStamp?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling tabTemplatesGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (tabTemplateId !== undefined && tabTemplateId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabTemplateId, 'TabTemplateId');
        }
        if (tabTemplateName !== undefined && tabTemplateName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabTemplateName, 'TabTemplateName');
        }
        if (tabId !== undefined && tabId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabId, 'TabId');
        }
        if (recipientId !== undefined && recipientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientId, 'RecipientId');
        }
        if (documentId !== undefined && documentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentId, 'DocumentId');
        }
        if (tabLabel !== undefined && tabLabel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabLabel, 'TabLabel');
        }
        if (tabTypeId !== undefined && tabTypeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabTypeId, 'TabTypeId');
        }
        if (tabInitialValue !== undefined && tabInitialValue !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabInitialValue, 'TabInitialValue');
        }
        if (tabIsRequired !== undefined && tabIsRequired !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabIsRequired, 'TabIsRequired');
        }
        if (tabIsReadOnly !== undefined && tabIsReadOnly !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabIsReadOnly, 'TabIsReadOnly');
        }
        if (tabPlacementFormFieldName !== undefined && tabPlacementFormFieldName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabPlacementFormFieldName, 'TabPlacementFormFieldName');
        }
        if (tabPlacementAnchor !== undefined && tabPlacementAnchor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabPlacementAnchor, 'TabPlacementAnchor');
        }
        if (tabPlacementPage !== undefined && tabPlacementPage !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabPlacementPage, 'TabPlacementPage');
        }
        if (tabPlacementOffsetXPts !== undefined && tabPlacementOffsetXPts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabPlacementOffsetXPts, 'TabPlacementOffsetXPts');
        }
        if (tabPlacementOffsetYPts !== undefined && tabPlacementOffsetYPts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabPlacementOffsetYPts, 'TabPlacementOffsetYPts');
        }
        if (tabPlacementWidth !== undefined && tabPlacementWidth !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabPlacementWidth, 'TabPlacementWidth');
        }
        if (tabPlacementHeight !== undefined && tabPlacementHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tabPlacementHeight, 'TabPlacementHeight');
        }
        if (isActive !== undefined && isActive !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isActive, 'IsActive');
        }
        if (timeStamp !== undefined && timeStamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeStamp, 'TimeStamp');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/tab`;
        return this.httpClient.request<PRPTemplatesTabListPurpleApiResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param culture 
     * @param templatizeTabRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public tabTemplatize(culture: string, templatizeTabRequest?: TemplatizeTabRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<TemplatizeTabResponsePurpleApiResponse>;
    public tabTemplatize(culture: string, templatizeTabRequest?: TemplatizeTabRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TemplatizeTabResponsePurpleApiResponse>>;
    public tabTemplatize(culture: string, templatizeTabRequest?: TemplatizeTabRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TemplatizeTabResponsePurpleApiResponse>>;
    public tabTemplatize(culture: string, templatizeTabRequest?: TemplatizeTabRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (culture === null || culture === undefined) {
            throw new Error('Required parameter culture was null or undefined when calling tabTemplatize.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/${this.configuration.encodeParam({name: "culture", value: culture, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/api/SignatureTemplates/TabTemplatize`;
        return this.httpClient.request<TemplatizeTabResponsePurpleApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: templatizeTabRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
