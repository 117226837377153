import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { PurpleApiProxyService, PurpleTableBoxColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton, dashboardToggle } from 'purple-lib';
import { Subject } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { CounterPartyRow, CounterPartyRowPurpleTableResponse, StellaService } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EditCounterpartComponent } from '../modals/edit-counterpart/edit-counterpart.component';
import { MergeCounterpartyComponent } from '../modals/merge-counterparty/merge-counterparty.component';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ViewDealsComponent } from '../modals/view-deals/view-deals.component';
import { MoveDealsComponent } from '../modals/move-deals/move-deals.component';

import { NZ_DRAWER_DATA, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-counterparties',
  templateUrl: './counterparties.component.html',
  styleUrls: ['./counterparties.component.scss']
})
export class CounterpartiesComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private modal: NzModalService, private tsvc: AppTranslationService,
    private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private apiProxySvc: PurpleApiProxyService, private mesSvc: NzMessageService, private drawer: NzDrawerService) { }
  sub: Subject<void> = new Subject();



  isFinanceView: boolean = false;
  allDataExpanded: boolean = false;
  viewOnlyParent: boolean = true;
  listOfData: Array<CounterPartyRowPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableBoxColumn[] = [

    {
      name: "",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "50px",
    },/*  {
      name: this.tranPipe.transform("counter_party_table_header_rating", "Rating"),
      dbKey: "CounterPartyRating",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'select-local',
        fieldControlName: 'CounterPartyRating',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_rating', 'Valutazione', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_rating_placeholder', 'Valutazione', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'High',
                value: 'High'
              },
              {
                label: 'Medium',
                value: 'Medium'
              },
              {
                label: 'Low',
                value: 'Low'
              }
            ]
          }
        ]
      },
      operatorType: "="
    }, */
    {
      name: this.tranPipe.transform("counter_party_table_header_name", "Name"),
      dbKey: "CounterPartyName",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_name', 'Name', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_name_placeholder', 'Name', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_short_name", "Short Name"),
      dbKey: "CounterPartyShortName",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyShortName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_short_name', 'Short Name', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_short_name_placeholder', 'Short Name', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    }, {
      name: this.tranPipe.transform("counter_party_table_header_relationship", "Relationship"),
      dbKey: "CounterPartyRelationship",
      hasReorder: false,
      hasFilter: false,
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_contact", "Contact"),
      dbKey: "FirstName",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'FirstName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_contact', 'Contact', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_contact_placeholder', 'Contact', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_contact_mail", "Contact Email"),
      dbKey: "CounterPartyContactMail",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyContactMail',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_contact_mail', 'Contact Email', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_contact_mail_placeholder', 'Contact Email', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_credit_limit", "Credit Limit(from/to Nvalue)"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "190px",
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_origin", "Origins"),
      dbKey: "origins",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'origins',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_origin', 'Orignis', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_origin_placeholder', 'Origins', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_note", "Note"),
      dbKey: "CounterPartyNote",
      hasReorder: false,
      hasFilter: false,
      operatorType: "like",
    }, {
      name: this.tranPipe.transform("counter_party_table_header_deals", "Deals"),
      dbKey: "CounterPartyDeals",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'CounterPartyDeals',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_deals', 'Deals', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_deals_placeholder', 'Deals', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "=",
      fixedWidth: "180px"
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Actions"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "225px"
    }
  ]


  tabColumnsFinance: PurpleTableBoxColumn[] = [

    {
      name: "",
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "50px",
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_rating", "Rating"),
      dbKey: "CounterPartyRating",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'select-local',
        fieldControlName: 'CounterPartyRating',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_rating', 'Valutazione', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_rating_placeholder', 'Valutazione', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'High',
                value: 'High'
              },
              {
                label: 'Medium',
                value: 'Medium'
              },
              {
                label: 'Low',
                value: 'Low'
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_name", "Nome"),
      dbKey: "CounterPartyName",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_name', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_name_placeholder', 'Nome', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_short_name", "Short Name"),
      dbKey: "CounterPartyShortName",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyShortName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_short_name', 'Abbreviazione', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_short_name_placeholder', 'Abbreviazione', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    }, {
      name: this.tranPipe.transform("counter_party_table_header_relationship", "Relationship"),
      dbKey: "CounterPartyRelationship",
      hasReorder: false,
      hasFilter: false,
      /* filterField:
      {
        fieldType: 'toggle',
        fieldControlName: 'onlyParent',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_relationship', 'Visualizza tutte le counterparts', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_relationship_placeholder', 'Visualizza tutte le counterparts', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'checkText', value: this.tranPipe.transform('switch_yes', 'Si', []) },
          { id: 'unCheckText', value: this.tranPipe.transform('switch_no', 'No', []) },
        ],
      },
      operatorType: "="*/
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_contact", "Contatto"),
      dbKey: "FirstName",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'FirstName',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_contact', 'Contatto', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_contact_placeholder', 'Contatto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_contact_mail", "Email Contatto"),
      dbKey: "CounterPartyContactMail",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyContactMail',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_contact_mail', 'Email Contatto', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_contact_mail_placeholder', 'Email Contatto', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },

    {
      name: this.tranPipe.transform("counter_party_table_header_b_s_credit_limit", "Buy/Sell Credit Limit"),
      dbKey: "CounterPartyBS",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: '',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_b_s_credit_limit', 'Buy/Sell Credit Limit', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_b_s_credit_limit_placeholder', 'Buy/Sell Credit Limit', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },

    {
      name: this.tranPipe.transform("counter_party_table_header_exposure", "Exposure"),
      dbKey: "CounterPartyExposure",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'CounterPartyExposure',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_exposure', 'Exposure', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_exposure_placeholder', 'Exposure', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_origin", "Origin"),
      dbKey: "origins",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'origins',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_origin', 'Orignis', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_origin_placeholder', 'Origins', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_note", "Note"),
      dbKey: "CounterPartyNote",
      hasReorder: false,
      hasFilter: false,
      /*  filterField: {
         fieldType: 'textarea',
         fieldControlName: 'CounterPartyNote',
         fieldBootstrapColumn: 8,
         fieldName: this.tranPipe.transform('counter_party_table_filter_level_note', 'Note', []),
         fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_level_note_placeholder', 'Note', []),
         fieldValue: undefined,
         fieldIsRequired: false,
         fieldIsDisabled: false,
         fieldPaddingLeft: false,
         fieldPaddingRight: true,
         fieldOptions: [{ id: 'rowNumber', value: 4 }]
       }, */
      operatorType: "like",
    }, {
      name: this.tranPipe.transform("counter_party_table_header_deals", "Deals"),
      dbKey: "CounterPartyDeals",
      hasReorder: false,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'CounterPartyDeals',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_deals', 'Deals', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_deals_placeholder', 'Deals', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "=",
      fixedWidth: "180px"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_chk", "PP chk/ last update"),
      dbKey: "CounterPartyLastUpdate",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'CounterPartyLastUpdate',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('counter_party_table_filter_level_chk', 'PP chk/ last update', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_level_note_placeholder', 'PP chk/ last update', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "like",
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "225px"
    }
  ]




  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [
      {
        property: "onlyParent",
        filterValue: this.viewOnlyParent ? "true" : "false"

      }
    ]


    return this.admSvc.getCounterPartyCustomTable(args.culture, args)
  }


  refresh = () => { this.refreshTableRowsNumber++ }
  ngOnInit() {


    this.reloadValues();

  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "stella_counterparties";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Counterparties', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista delle counterparties', []))
  }


  editCounterpart(counterpart: CounterPartyRow | undefined) {
    this.modal.create<EditCounterpartComponent, { counterParty: CounterPartyRow | undefined, isFinanceView: boolean }>({
      nzContent: EditCounterpartComponent,
      nzTitle: counterpart == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0}", ["counterparty"]) : this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0}", ["counterparty"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
      nzData: {
        counterParty: counterpart,
        isFinanceView: this.isFinanceView
      }
    }).afterClose.subscribe((refresh: boolean | undefined) => {
      if (refresh)
        this.refreshTableRowsNumber++;
    })
  }

  mergeCounterparts(counterpart: string | undefined) {

    this.modal.create<MergeCounterpartyComponent, { parentCounterPartyId: string | undefined, isMerge: boolean }>({
      nzContent: MergeCounterpartyComponent,
      nzTitle: this.tranPipe.transform("table_item_merge_modal_title_new", "Merge {0}", ["CounterParty"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzData: { parentCounterPartyId: counterpart, isMerge: true },
      nzMaskClosable: false
    }).afterClose.subscribe((value: boolean | undefined) => {
      if (value)
        this.refreshTableRowsNumber++;


    })
  }

  viewDeals(counterpart: CounterPartyRow) {
    var width = window.innerWidth;

    width = this.dashSvc.isMenuCollapsed ? width - 100 : width - 270;

    //var width = this.dashSvc.dashboardWidth * 0.75;
    this.drawer.create<ViewDealsComponent, string>({
      nzContent: ViewDealsComponent,
      nzTitle: this.tranPipe.transform("table_item_view_deal_modal_title_new", "View {0} {1}", [counterpart.counterPartyName, "Deals"]),
      nzWidth: width + 'px',
      nzData: counterpart.counterPartyId,
      nzMaskClosable: false,
      nzPlacement: 'right'

    }).afterClose.subscribe(() => {
    })
  }

  moveDeals(counterpart: CounterPartyRow) {
    this.modal.create<MoveDealsComponent, string>({
      nzContent: MoveDealsComponent,
      nzTitle: this.tranPipe.transform("table_item_move_deal_modal_title_new", "Move {0} {1}", [counterpart.counterPartyName, "Deals"]),
      nzWidth: '1200px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzData: counterpart.counterPartyId,
      nzMaskClosable: false
    }).afterClose.subscribe((value: boolean | undefined) => {
      if (value)
        this.refreshTableRowsNumber++;
    })
  }



  async addRelationships(counterpart: CounterPartyRow | undefined) {


    this.modal.create<MergeCounterpartyComponent, { parentCounterPartyId: string | undefined, childrenCounterPartyId: string[] | undefined, isMerge: boolean }>({
      nzContent: MergeCounterpartyComponent,
      nzTitle: this.tranPipe.transform("table_item_add_relationships_modal_title_new", "Add  {0} Realtionships", ["CounterParty"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzData: { parentCounterPartyId: counterpart?.counterPartyId, childrenCounterPartyId: (counterpart?.children ?? []).map(m => m.counterPartyId), isMerge: false },
      nzMaskClosable: false
    }).afterClose.subscribe((value: boolean | undefined) => {
      if (value)
        this.refreshTableRowsNumber++;

    })
  }


  deleteCounterParty(counterpart: CounterPartyRow) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("delete_counterparty_relationship_title", "Delete {0}", [counterpart?.counterPartyName]),
      nzContent: this.tranPipe.transform("delete_counterparty_relationship_subtitle", "Do you really want to delete Counter Party <b> {0} </b>? (Its children won't be deleted). <br> <b> Also deals will be deleted!! </b> Map them another counterparts first.", [counterpart?.counterPartyName]),
      nzWidth: '800px',
      nzOkText: "Elimina",
      nzClassName: 'purple-simple-modal',
      nzFooter: [
        {
          label: "Delete",
          type: "primary",
          onClick: async () => await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.deleteCounterParty(this.tsvc.currentLanguage.value, {
            counterPartyId: counterpart!.counterPartyId
          }), false, "internal-loader", 500, undefined, undefined, () => {
            this.mesSvc.success(this.tranPipe.transform("delete_counterparty_success", "Counter Party successfully deleted"), {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.refreshTableRowsNumber++;
            this.modal.closeAll()
          })
        }
      ]

    })
  }

  sumDeals(buyer: number | null | undefined, seller: number | null | undefined, broker: number | null | undefined, others: number | null | undefined) {

    var res = (buyer ?? 0) + (seller ?? 0) + (broker ?? 0) + (others ?? 0);

    var split = res == 0 ? (0 + "") : (res + " ( ");

    if (buyer != null && buyer > 0) {
      split += buyer + "BUY "
    }

    if (seller != null && seller > 0) {
      split += seller + "SELL "
    }

    if (broker != null && broker > 0) {
      split += broker + "BROKER "
    }
    if (others != null && others > 0) {
      split += others + "OTHERS "
    }

    if (res > 0)
      split += ")";

    return split;


  }

  reloadValues() {


    this.dashSvc.dashMainItems = []


    this.dashSvc.dashMainItems.push(
      /*new dashboardButton("counterparties-standard-view", this.tranPipe.transform('counterparties_standard_view', 'Standard view', []), () => {
        this.isFinanceView = false;

        this.reloadValues();
      }, !this.isFinanceView ? 'primary' : 'default', undefined, this.isFinanceView == false ? 'test-button test-button-sx' : 'test-button-sx'),
       new dashboardButton("add-new-counterparties", this.tranPipe.transform('counterparties_finance_view', 'Finance view', []), () => {
        this.isFinanceView = true;

        this.reloadValues();
      }, this.isFinanceView ? 'primary' : 'default', undefined, this.isFinanceView == true ? 'test-button test-button-dx' : 'test-button-dx'), */

      new dashboardToggle("all-counterparties-toggle",
        () => {
          this.viewOnlyParent = !this.viewOnlyParent;

          this.refreshTableRowsNumber++;
        }, this.tranPipe.transform('counter_party_table_filter_relationship', 'View Child'), false),
      new dashboardButton("expan-counterparties", this.tranPipe.transform('expand_counterparties', 'Expand All', []), () => { this.expandAllData() }, 'default', undefined, 'add-button', undefined),
      new dashboardButton("merge-counterparties", this.tranPipe.transform('merge_counterparties', 'Merge', []), () => { this.mergeCounterparts(undefined) }, 'default', 'link', 'add-button', 'left'),

      new dashboardButton("add-new-counterparties", this.tranPipe.transform('add_new_counterparties', 'Add New', []), () => { this.editCounterpart(undefined) }, 'primary', 'plus', 'add-button', 'left'),
    )
  }

  expandAllData() {
    this.allDataExpanded = !this.allDataExpanded
    if (this.listOfData != undefined && this.listOfData != null) {
      this.listOfData.forEach(element => {
        if (element.item.children.length > 0) {
          element.expanded = this.allDataExpanded;
        }
      });
    }
  }



  hasChildrenRelationsip(hasChildren: boolean, expanded: boolean) {
    if (hasChildren && !expanded)
      return this.tranPipe.transform("relationship_group", "Group");
    else if (hasChildren && expanded)
      return this.tranPipe.transform("relationship_parent", "Parent");
    else
      return this.tranPipe.transform("relationship_none", "None");
  }


  formatNumber(n: number | null | undefined): string {

    if (n == null)
      n = 0;

    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

}



