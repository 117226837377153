<div class="portfolio-page-container ps-d-flex ps-flex-wrap ps-w-100">


  <nz-tabset class="ps-w-100" [nzSelectedIndex]="viewType">
    <nz-tab class="ps-w-100">
      <div class="ps-d-flex ps-flex-wrap ps-w-100">


        <!-- SALES -->
        <div *ngFor="let portfolio of portfolioTables | keyvalue: portfolioTableOrder"
          class="portfolio-item-external-container ps-d-flex ps-w-100 ps-col-6">
          <div class="portfolio-item-container ps-d-flex ps-w-100 ps-flex-wrap ps-align-items-start">
            <div class="ps-d-flex ps-w-100 ps-flex-wrap">
              <div class="ps-w-100 portfolio-title-container">
                <p class="portfolio-title">{{portfolio.value.tableTitle}} ({{portfolio.value.totalItems}})</p>
                <div *ngIf="showTable || tableHeight != undefined"
                  class="portfolio-title-buttons-container ps-d-flex ps-align-items-center ps-flex-wrap">
                  <!-- <button (click)="switchDeliveredFilter('sales')" class="{{salesIsNotDelivered?'active':''}} portfolio-delivery-button">
                    {{ 'is_delivered' | translation: 'Delivered': []}}
                  </button> -->

                  <button *ngIf="portfolio.value.filter" nz-button
                    [nzType]="portfolio.value.filter.buttonStyle??'default'" class="portfolio-table-action-popover
                    {{portfolio.value.filter.buttonCustomClass}}" nz-popover
                    [nzPopoverContent]="portfolio.value.filter.popoverContent"
                    [nzPopoverTrigger]="portfolio.value.filter.popoverTrigger"
                    [nzPopoverPlacement]="portfolio.value.filter.popoverPlacement"
                    [nzPopoverOverlayClassName]="portfolio.value.filter.popoverCustomClass"
                    [(nzPopoverVisible)]="portfolio.value.filter.popovervisible">
                    {{portfolio.value.filter.buttonText}}
                    <i [nzRotate]="portfolio.value.filter.popovervisible?180:0"
                      *ngIf="portfolio.value.filter.buttonIcon!= undefined" nz-icon
                      [nzType]="portfolio.value.filter.buttonIcon!"></i>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="showTable || tableHeight != undefined">
                <purple-table #purpleTable [tableBody]="tableSalesBody" [isBordered]="true" [switchOn]="false"
                  [refreshFunction]="portfolio.value.refreshFunction" (tableData)="portfolio.value.listOfData = $event"
                  (totalItems)="portfolio.value.totalItems = $event" [tableColumns]="portfolio.value.tableColumns"
                  [tableScroll]="{y:tableHeight}" [refreshTableRowsNumber]="portfolio.value.refreshTableRowsNumber"
                  [version]="2" [showCustomPagination]="true" [tableLayout]="'auto'" tableSearchType="box"
                  (filterBox)="setPortfolioTablePopover(portfolio.value, $event)" [routePaginationIsEnabled]="false"
                  (onSearchBoxEnd)="closePortfolioTablePopover(portfolio.value)"
                  [filterBoxStatus]="portfolio.value.filterBoxStatus">
                  <ng-template #tableSalesBody>
                    <ng-container *ngFor="let data of portfolio.value.listOfData; let i = index">
                      <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} {{(data.item.transactionStatusId=='deleted')?'red':''}} ">
                        <td>
                          <span class="ps-cursor-pointer" nz-tooltip [nzTooltipTitle]="getTraders(data.item).fullNames"
                            nzTooltipColor="#585858">{{getTraders(data.item).initials}}
                          </span>
                        </td>
                        <td>{{data.item.transactionDate | date: "shortDate"}}</td>
                        <td>{{data.item.counterParty?.counterPartyName??"-"}}</td>
                        <td nzEllipsis>
                          <span class="ps-cursor-pointer" nz-tooltip
                            [nzTooltipTitle]="data.item.transactionReferenceNumber"
                            nzTooltipColor="#585858">{{data.item.transactionReferenceNumber}}
                          </span>
                        </td>
                        <td>{{data.item.origins.join(", ")}}</td>
                        <td>{{data.item.technologies.join("+")}}</td>
                        <td>{{data.item.transactionVolume}}</td>
                        <td>{{data.item.weightedAvgPrice | currency: data.item.currencyId : 'symbol' : "1.2-4"}}</td>
                        <td nzAlign="center">
                          <app-stella-table-button (click)="data.expanded = !data.expanded"
                            [buttonIconType]="'ng-zorro'" [buttonIcon]="data.expanded?'eye-invisible':'eye'" [toolTip]='data.expanded?
                            ("hide_more_button_customer_table" | translation: "Nascondi dati aggiuntivi"):
                            ("show_more_button_customer_table" | translation: "Mostra più dati")'>
                          </app-stella-table-button>
                          <app-stella-table-button
                            (click)="viewDelivery(data.item.transactionId!, (portfolio.key=='sales'?'seller':'buyer'),data.item.portfolioId,data.item.currencyId!)"
                            buttonIcon="icon-file"
                            toolTip="{{'view_delivery_table_button'|translation:'View Delivery'}}"></app-stella-table-button>
                            <app-stella-table-button buttonIcon="icon-duplicate-1"
                            toolTip="{{'duplicate_deal_table_button'|translation:'Duplicate Deal'}}"
                            (click)="duplicateDeal(data.item.transactionId!)"> </app-stella-table-button>
                            <app-stella-table-button (click)="viewAgreement(data.item!)" buttonIcon="icon-agreement"
                            toolTip="{{'view_agreement_status_table_button'|translation:'View Agreement Status'}}">
                          </app-stella-table-button>
                        </td>
                      </tr>
                      <tr #expandedRow [nzExpand]="data.expanded">
                        <div class="ps-d-flex ps-w-100 ps-justify-content-center">
                          <div
                            class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">
                            <nz-descriptions class="ps-w-100"
                              [nzTitle]="'portfolio_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []"
                              nzBordered [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }">

                              <nz-descriptions-item
                                [nzTitle]="'portfolio_table_brokers_roles' | translation: 'Brokers': []" [nzSpan]="4">
                                <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center">

                                  <div *ngFor="let cp of data.item.brokers" class="counterparty-pill">
                                    <p purpleStringToColor mode="back" [word]="cp.brokerId" [opacity]="20"
                                      class="counterparty-pill-name">
                                      {{cp.brokerName}}
                                    </p>
                                    <p class="counterparty-pill-role">
                                      {{cp.brokerFeeTotalPrice | currency: data.item.currencyId }}({{cp.brokerFee |
                                      currency: data.item.currencyId : 'symbol' : "1.2-4"}}/MwH)
                                    </p>
                                  </div>
                                </div>
                              </nz-descriptions-item>
                            </nz-descriptions>
                          </div>
                        </div>
                      </tr>
                    </ng-container>
                  </ng-template>
                </purple-table>
              </ng-container>
            </div>
          </div>
        </div>


        <!-- PURCHASES -->
        <!-- <div class="portfolio-item-external-container ps-d-flex ps-w-100 ps-col-6">
          <div class="portfolio-item-container ps-d-flex ps-w-100 ps-flex-wrap ps-align-items-start">
            <div class="ps-d-flex ps-w-100 ps-flex-wrap">
              <div class="ps-w-100 portfolio-title-container">
                <p class="portfolio-title">{{ 'portfolio_purchases' | translation: 'Purchases': []}}
                  ({{purchasesTotalItems}})</p>
                <div *ngIf="showTable || tableHeight != undefined"
                  class="portfolio-title-buttons-container ps-d-flex ps-align-items-center ps-flex-wrap">

                  <button *ngIf="portfolioFilters.get('purchases')?.filter" nz-button
                    [nzType]="portfolioFilters.get('purchases')!.filter!.buttonStyle??'default'"
                    class="portfolio-table-action-popover
                    {{portfolioFilters.get('purchases')!.filter!.buttonCustomClass}}" nz-popover
                    [nzPopoverContent]="portfolioFilters.get('purchases')!.filter!.popoverContent"
                    [nzPopoverTrigger]="portfolioFilters.get('purchases')!.filter!.popoverTrigger"
                    [nzPopoverPlacement]="portfolioFilters.get('purchases')!.filter!.popoverPlacement"
                    [nzPopoverOverlayClassName]="portfolioFilters.get('purchases')!.filter!.popoverCustomClass"
                    [(nzPopoverVisible)]="portfolioFilters.get('purchases')!.filter!.popovervisible">
                    {{portfolioFilters.get('purchases')!.filter!.buttonText}}
                    <i [nzRotate]="portfolioFilters.get('purchases')!.filter!.popovervisible?180:0"
                      *ngIf="portfolioFilters.get('purchases')!.filter!.buttonIcon!= undefined" nz-icon
                      [nzType]="portfolioFilters.get('purchases')!.filter!.buttonIcon!"></i>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="showTable || tableHeight != undefined">
                <purple-table #purpleTable [tableBody]="tablePurchasesBody" [isBordered]="true" [switchOn]="false"
                  [refreshFunction]="refreshPurchasesFunction" (tableData)="listOfDataPurchases = $event"
                  [tableColumns]="tabColumns" [tableScroll]="{y:tableHeight}"
                  (totalItems)="purchasesTotalItems = $event" [refreshTableRowsNumber]="refreshTablePurchasesRowsNumber"
                  [version]="2" [showCustomPagination]="true" tableSearchType="box" (filterBox)="setPortfolioTablePopover('purchases', $event)"
                  (onSearchBoxEnd)="closePortfolioTablePopover('purchases')"
                  [filterBoxStatus]="portfolioFilters.get('purchases')!.filterBoxStatus">
                  <ng-template #tablePurchasesBody>
                    <ng-container *ngFor="let data of listOfDataPurchases; let i = index">
                      <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
                        <td><span class="ps-cursor-pointer" nz-tooltip [nzTooltipTitle]="getTraders(data.item).fullNames" nzTooltipColor="#585858">{{getTraders(data.item).initials}}</span></td>
                        <td>{{data.item.transactionDate | date: "shortDate"}}</td>
                        <td>{{data.item.counterParty?.counterPartyName??"-"}}</td>
                        <td nzEllipsis>{{data.item.transactionId }}</td>
                        <td>{{data.item.origins.join(", ")}}</td>
                        <td>{{data.item.technologies.join("+")}}</td>
                        <td>{{data.item.transactionVolume}}</td>
                        <td>{{data.item.weightedAvgPrice | currency: data.item.currencyId}}</td>
                        <td></td>
                      </tr>
                    </ng-container>
                  </ng-template>
                </purple-table>
              </ng-container>
            </div>
          </div>
        </div> -->
      </div>
    </nz-tab>
    <nz-tab>

      ciao
    </nz-tab>
  </nz-tabset>
</div>
