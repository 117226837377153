/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ManagedAuthenticationProvider = 'google' | 'apple' | 'microsoft' | 'azureAd' | 'facebook' | 'linkedin' | 'instagram' | 'github' | 'twitter';

export const ManagedAuthenticationProvider = {
    Google: 'google' as ManagedAuthenticationProvider,
    Apple: 'apple' as ManagedAuthenticationProvider,
    Microsoft: 'microsoft' as ManagedAuthenticationProvider,
    AzureAd: 'azureAd' as ManagedAuthenticationProvider,
    Facebook: 'facebook' as ManagedAuthenticationProvider,
    Linkedin: 'linkedin' as ManagedAuthenticationProvider,
    Instagram: 'instagram' as ManagedAuthenticationProvider,
    Github: 'github' as ManagedAuthenticationProvider,
    Twitter: 'twitter' as ManagedAuthenticationProvider
};

