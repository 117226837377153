import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { StellaService } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurpleTableBoxColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { Subject } from 'rxjs';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';

@Component({
  selector: 'app-market-watch',
  templateUrl: './market-watch.component.html',
  styleUrls: ['./market-watch.component.scss']
})
export class MarketWatchComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(@Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private modal: NzModalService,
    private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service) { }


  sub: Subject<void> = new Subject();

  listOfData: Array<any> | undefined | null = null;
  refreshTableRowsNumber: number = 0;

  tabColumns: PurpleTableBoxColumn[] = [

    {
      name: this.tranPipe.transform("market_watch_table_header_trader", "Trader"),
      dbKey: "marketWatchTrader",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchTrader',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_trader', 'Trader', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_trader_placeholder', 'Trader', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_product", "Product"),
      dbKey: "marketWatchProduct",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchProduct',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_product', 'Product', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_product_placeholder', 'Product', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_price_date", "Price date"),
      dbKey: "marketWatchPriceDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {

        fieldType: 'date',
        fieldControlName: 'marketWatchPriceDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('market_watch_table_filter_price_date', 'Price date', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_price_date_placeholder', 'Price date', []),
        fieldValue: undefined,
        fieldIsRequired: true,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [{ id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) }]

      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_buyer", "Buyer"),
      dbKey: "marketWatchBuyer",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchBuyer',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_buyer', 'Buyer', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_buyer_placeholder', 'Buyer', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_seller", "Seller"),
      dbKey: "marketWatchSeller",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchSeller',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_seller', 'Seller', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_seller_placeholder', 'Seller', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_initiator", "Initiator"),
      dbKey: "marketWatchInitiator",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchInitiator',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_initiator', 'Initiator', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_initiator_placeholder', 'Initiator', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_broker", "Broker"),
      dbKey: "marketWatchBroker",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchBroker',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_broker', 'Broker', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_broker_placeholder', 'Broker', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_vintage", "Vintage"),
      dbKey: "marketWatchVintage",
      hasReorder: true,
      hasFilter: true,
      filterField: {

        fieldType: 'date',
        fieldControlName: 'marketWatchVintage',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_vintage', 'Vintage', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_vintage_placeholder', 'Vintage', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'pickerMode', value: 'year' }]

      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_origin", "Origin"),
      dbKey: "marketWatchOrigin",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchOrigin',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_origin', 'Origin', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_origin_placeholder', 'Origin', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_technology", "Technology"),
      dbKey: "marketWatchTechnology",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchTechnology',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_technology', 'Technology', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_technology_placeholder', 'Technology', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_earmark", "Earmark"),
      dbKey: "marketWatchEarmark",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchEarmark',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_earmark', 'Earmark', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_earmark_placeholder', 'Earmark', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },

    {
      name: this.tranPipe.transform("market_watch_table_header_label", "Label"),
      dbKey: "marketWatchLabel",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchLabel',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_label', 'Label', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_label_placeholder', 'Label', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_volume", "Volume"),
      dbKey: "marketWatchVolume",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-number',
        fieldControlName: 'marketWatchVolume',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_volume', 'Volume', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_volume_placeholder', 'Volume', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_traded_price", "Trade Price"),
      dbKey: "marketWatchTradedPrice",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-number',
        fieldControlName: 'marketWatchTradedPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_traded_price', 'Trade Price', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_traded_price_placeholder', 'Trade Price', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_bid_price", "Prezzo offerto"),
      dbKey: "marketWatchBidPrice",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-number',
        fieldControlName: 'marketWatchBidPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_bid_price', 'Bid Price', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_bid_price_placeholder', 'Bid Price', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_ask_price", "Ask Price"),
      dbKey: "marketWatchAskPrice",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-number',
        fieldControlName: 'marketWatchAskPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_ask_price', 'Ask Price', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_ask_price_placeholder', 'Ask Price', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("market_watch_table_header_tags", "Tags"),
      dbKey: "marketWatchTags",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'marketWatchTags',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('market_watch_table_filter_tags', 'Tags', []),
        fieldPlaceholder: this.tranPipe.transform('market_watch_table_filter_tags_placeholder', 'Tags', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Actions"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "200px"
    }
  ]

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }
  ngOnInit() {
    this.dashSvc.dashMainItems.push(
      new dashboardButton(
        'import-button',
        this.tranPipe.transform("market_watch_import_button", "Import"),
        () => { },
        undefined,
        'import',
        undefined,
        'left'
      ),
      new dashboardButton(
        'add-button',
        this.tranPipe.transform("market_watch_add_button", "Add New"),
        () => { },
        'primary',
        'plus',
        undefined,
        'left'
      )
    )
  }

  ngAfterViewInit(): void {
    const pageName = "stella_marketwatch";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Market Watch', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Check and monitor different deals happening on the market.', []))
  }



  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [
      {
        property: "CounterPartyType",
        filterValue: "broker"
      }
    ]
    return this.admSvc.getCounterPartyTable(args.culture, args)
  }
}
