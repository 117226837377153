@if (show)
{

<div class=" ps-col-12 ps-justify-content-end purple-button-container  ps-d-flex ps-pr-4 ps-mb-4">
  <button nz-button class="add-button" (click)="editDelivery(undefined)" [disabled]="isLoading" [nzLoading]="isLoading">
    <ng-container>{{ 'edit_delivery_add_new_delivery' | translation: 'Add Delivery':[]}}</ng-container>
  </button>
</div>

<div class="ps-col-12 ps-d-flex ps-mb-4 ps-justify-content-end">
  <purple-table #purpleTable class="ps-col-12 sub-table" [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight-200}" [refreshTableRowsNumber]="refreshTableRowsNumber"
    [isSelectRowActive]="false" [version]="2" [showCustomPagination]="true" [pageSize]="20"
    [routePaginationIsEnabled]="false">

    <ng-template #tableBody>
      @for (data of listOfData; track $index)
      {
      <ng-container>
        <tr class="main-purple-table-tr-even">
          <td nzAlign="center">
            @if (data.item.delivered!.length>0)
            {
            <app-stella-table-button [buttonIcon]="data.expanded?'icon-eye-off':'icon-eye'"
              (click)="data.expanded = !data.expanded"
              toolTip="{{ 'counterpart_table_button_view_delivered'|translation:'{0} Delivered' :[!data.expanded?('text_view'|translation:'View'):('text_hide'|translation:'Hide')] }}"></app-stella-table-button>
            }
          </td>
          <td>
            @switch (data.item.transactionStatusId)
            {
            @case ("completed")
            {
            <app-purple-pill [pillDefaultColor]="'green'" [pillText]="data.item.transactionStatusId|titlecase"
              [pillBackgroundType]="'two-color'" [pillBackgroundPercentage]="100">
            </app-purple-pill>
            }
            @case ("in_progress")
            {
            <app-purple-pill [pillDefaultColor]="'yellow'" [pillText]="data.item.transactionStatusId!|titlecase"
              [pillBackgroundType]="'two-color'" [pillBackgroundPercentage]="100">
            </app-purple-pill>
            }
            }
          </td>
          <td>{{data.item.transactionDate|date}}</td>
          @if(!hiddenVintage)
          {
          <td> {{data.item.vintage??'-'}} </td>
          }
          @if (!hiddenOrigin)
          {
          <td> {{data.item.origins??'-'}} </td>
          }
          @if(!hiddenTechnology)
          {
          <td> {{data.item.technologies??'-'}} </td>
          }
          @if (!hiddenAdditional)
          {
          <td> {{data.item.additionals??'-'}}</td>
          }
          @if(!hiddenCounterPartyName)
          {
          <td> {{data.item.counterPartyName??'-'}}</td>
          }
          <td> {{formatNumber(data.item.transactionVolume)}}</td>
          <td> {{data.item.totalPrice}}</td>
          <td> {{calcAmount(data.item.transactionVolume,data.item.totalPrice) | currency:
            data.item.currency! : 'symbol' : "1.2-4" }}</td>
          <td> {{data.item.currency}} </td>

          @for (att of deliveryFields; track $index)
          {
          @if (data.item.dynamicField!=undefined&&data.item.dynamicField!=null)
          {

          @if(att.strEq("beneficiary"))
          {
          <td>
            {{data.item.beneficiaryName??'-'}}
          </td>
          }
          @else if(att.strEq("power_plant"))
          {
          <td>
            {{data.item.powerPlantName??'-'}}
          </td>
          }
          @else
          {
          <td>
            {{data.item.dynamicField[att]??'-'}}
          </td>
          }
          }
          @else
          {
          <td>
            -
          </td>
          }

          }
          <td nzAlign="center">
            @if (data.item.transactionStatusId=='in_progress')
            {
            <app-stella-table-button [buttonIcon]="'icon-edit'" (click)="editDelivery(data.item)"
              toolTip="{{ 'deliveries_table_button_edit_delivery'|translation:'Edit Delviery' }}"></app-stella-table-button>
            <app-stella-table-button [buttonIcon]="'icon-x-close'" (click)="deleteDelivery(data.item.transactionId!)"
              toolTip="{{ 'deliveries_table_button_delete_delivery'|translation:'Delete Delivery' }}"></app-stella-table-button>

            }

          </td>
        </tr>
        <tr #expandedRow [nzExpand]="data.expanded" class="sub-table">
          <div>
            <nz-table nzSize="small" [nzData]="data.item.delivered!=undefined?data.item.delivered:[]"
              nzShowPagination="false">

              <thead>
                <tr>
                  <th>{{ 'delivered_table_header_event_date' | translation: 'Delivered Date'}}</th>
                  <th>{{ 'delivered_table_header_event_vintage' | translation: 'Vintage'}}</th>
                  <th> {{ 'delivered_table_header_event_origin' | translation: 'Origin'}}</th>
                  <th> {{ 'delivered_table_header_event_technology' | translation: 'Technology'}}</th>
                  <th> {{ 'delivered_table_header_event_cp_name' | translation: 'CounterParty Name'}}</th>
                  <th> {{ 'delivered_table_header_event_additional' | translation: 'Additional'}}</th>
                  <th> {{ 'delivered_table_header_event_volume' | translation: 'Volume'}}</th>
                  <th>{{ 'counter_party_table_header_event_price' | translation: 'Price'}}</th>
                  <th>{{ 'counter_party_table_header_event_amount' | translation: 'Amount'}}</th>
                  <th>{{ 'counter_party_table_header_event_currency' | translation: 'Currency'}}</th>

                  @for (field of deliveryFields; track $index)
                  {

                  <th>{{ 'counter_party_table_header_event_dynamic' | translation: '{0}':[field.replace('_',' ')|titlecase]}}</th>
                  }

                </tr>
              </thead>

              <tbody>
                @for (data of data.item.delivered!=undefined?data.item.delivered:[]; track $index)
                {
                <tr>
                  <td>{{data.transactionDate|date}}</td>
                  <td> {{data.vintage??'-'}} </td>
                  <td> {{data.origins??'-'}} </td>
                  <td> {{data.technologies??'-'}} </td>
                  <td> {{data.additionals??'-'}}</td>
                  <td> {{data.earmarks??'-'}}</td>

                  <td> {{formatNumber(data.transactionVolume)}}</td>
                  <td> {{data.totalPrice}}</td>
                  <td> {{calcAmount(data.transactionVolume,data.totalPrice) | currency:
                    data.currency! : 'symbol' : "1.2-4" }}</td>
                  <td> {{data.currency}} </td>

                  @for (att of deliveryFields; track $index)
                  {
                  @if (data.dynamicField!=undefined&&data.dynamicField!=null)
                  {

                  @if(att.strEq("beneficiary"))
                  {
                  <td>
                    {{data.beneficiaryName??'-'}}
                  </td>
                  }
                  @else if(att.strEq("power_plant"))
                  {
                  <td>
                    {{data.powerPlantName??'-'}}
                  </td>
                  }
                  @else
                  {
                  <td>
                    {{data.dynamicField[att]??'-'}}
                  </td>
                  }
                  }
                  @else
                  {
                  <td>
                    -
                  </td>
                  }

                  }

                </tr>
                }

              </tbody>

            </nz-table>
          </div>

        </tr>
      </ng-container>
      }

    </ng-template>
  </purple-table>
</div>


<div class=" ps-col-12 ps-justify-content-start purple-button-container  ps-d-flex ps-pr-4 ps-mb-4">
  <button nz-button class="close-button" (click)="closeDrawer()" [disabled]="isLoading" [nzLoading]="isLoading">
    <ng-container>{{ 'edit_delivery_go_back' | translation: 'Go Back':[]}}</ng-container>
  </button>
</div>

}
