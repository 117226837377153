/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateRecipientRequest { 
    recipientId: string;
    recipientRoleId: string;
    userEmail: string;
    recipientEnvelopeId: string;
    routingOrder: number;
    recipientsLogUserId?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
}

