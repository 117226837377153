import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Helper, PurpleTranslationPipe } from 'purple-lib';
import { purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeNotificationService, SmtpServer } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/environment';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';

@Component({
  selector: 'app-edit-smtp-server',
  templateUrl: './edit-smtp-server.component.html',
  styleUrls: ['./edit-smtp-server.component.scss']
})
export class EditSmtpServerComponent implements OnInit {

  originalEditItem!: EditSmtpItem;
  newObj!: EditSmtpItem;
  isNew: boolean = false;
  passwordVisible = false;
  isTestLoading: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) public smtpServerFull: SmtpServer | undefined, private modalRef: NzModalRef, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService,
    private backNotSvc: BackOfficeNotificationService, private tsvc: AppTranslationService, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe) { }

  async ngOnInit(): Promise<void> {
    this.originalEditItem = {
      smtpHost: this.smtpServerFull?.smtpHost ?? undefined,
      smtpId: this.smtpServerFull?.smtpId ?? undefined,
      smtpName: this.smtpServerFull?.smtpName ?? undefined,
      smtpPassword: this.smtpServerFull?.smtpPassword ?? undefined,
      smtpPort: this.smtpServerFull?.smtpPort ?? undefined,
      smtpUsername: this.smtpServerFull?.smtpUsername ?? undefined,
      smtpDescription: this.smtpServerFull?.smtpDescription ?? undefined
    }

    this.isNew = this.smtpServerFull == undefined;
    this.newObj = Helper.storeNewObj(this.originalEditItem);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'name',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_smtp_name_placeholder', 'Etichetta', []),
            fieldPlaceholder: this.tranPipe.transform('edit_smtp_name_placeholder_placeholder', 'Etichetta smtp', []),

            fieldValue: this.newObj.smtpName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'host',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_smtp_host_placeholder', 'Host', []),
            fieldPlaceholder: this.tranPipe.transform('edit_smtp_host_placeholder_placeholder', 'Host smtp', []),
            fieldValue: this.newObj.smtpHost,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-number',
            fieldControlName: 'port',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_smtp_port_placeholder', 'Porta', []),
            fieldPlaceholder: this.tranPipe.transform('edit_smtp_port_placeholder_placeholder', 'Porta smtp', []),

            fieldValue: this.newObj.smtpPort,
            fieldIsRequired: true,
            fieldOptions: [{ id: 'minNumber', value: 0 }],
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'username',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_smtp_username_placeholder', 'Username', []),
            fieldPlaceholder: this.tranPipe.transform('edit_smtp_username_placeholder_placeholder', 'Username smtp', []),

            fieldValue: this.newObj.smtpUsername,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'password',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_smtp_password_placeholder', 'Password', []),
            fieldPlaceholder: this.tranPipe.transform('edit_smtp_password_placeholder_placeholder', 'Password smtp', []),

            fieldValue: this.newObj.smtpPassword,
            fieldIsRequired: true,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'description',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_smtp_description_placeholder', 'Descrizione', []),
            fieldPlaceholder: this.tranPipe.transform('edit_smtp_description_placeholder_placeholder', 'Descrizione smtp', []),
            fieldValue: this.newObj.smtpName,
            fieldIsRequired: false,
            fieldOptions: [{ id: 'rowNumber', value: 5 }],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          }
        ]
      }
    ]

  }

  async testSmtp() {
    this.isTestLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.setSmtpServer();
      await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backNotSvc.testSmtpConfiguration(this.tsvc.currentLanguage.value, {
        smtpHost: this.newObj.smtpHost!,
        smtpId: this.newObj.smtpId!,
        smtpPassword: this.newObj.smtpPassword!,
        smtpPort: this.newObj.smtpPort!,
        smtpUsername: this.newObj.smtpUsername!,
        smtpDescription: this.newObj.smtpDescription,
        smtpName: this.newObj.smtpName
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
        this.mesSvc.success(this.tranPipe.transform('test_smtp_configuration_ok', 'Configurazione testata con seccesso', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      });
    }

    this.isTestLoading = false;
  }

  private setSmtpServer(){
    this.newObj.smtpName = this.validateForm.controls["name"].value;
    this.newObj.smtpDescription = this.validateForm.controls["description"].value;
    this.newObj.smtpHost = this.validateForm.controls["host"].value;
    this.newObj.smtpPort = this.validateForm.controls["port"].value;
    this.newObj.smtpUsername = this.validateForm.controls["username"].value;
    this.newObj.smtpPassword = this.validateForm.controls["password"].value;
  }

  async editSmtpServer() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.setSmtpServer();

      await this.apiProxySvc.makeRequestErrorMessage<SmtpServer>(() => this.backNotSvc.addOrUpdateSmtpServer(this.tsvc.currentLanguage.value, {
        isNew: this.isNew,
        smtpServer: this.newObj
      }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<SmtpServer>) => {
        let text: string;
        if (this.isNew) {
          text = this.tranPipe.transform('edit_smtp_add', 'Configurazione smtp aggiunta con successo', [])
        } else {
          text = this.tranPipe.transform('edit_smtp_update', 'Configurazione smtp aggiornata con successo', [])
        }
        this.mesSvc.success(text, {
          nzDuration: environment.MESSAGE_DURATION
        });
        this.isLoading = false;
        this.modalRef.destroy(res.data);
      })

    }
    this.isLoading = false
  }

}


interface EditSmtpItem {
  smtpId: string | undefined;
  smtpName: string | undefined;
  smtpDescription: string | undefined;
  smtpHost: string | undefined;
  smtpPort: number | undefined;
  smtpUsername: string | undefined;
  smtpPassword: string | undefined;
}