<div class="ps-col-12 ps-d-flex ps-pr-0" style="margin-bottom: 12px;">
  <div class="ps-col-9">
    <div>
      <label><b>Origin selected:</b></label>
    </div>
    <div><input nz-input type="text" [(ngModel)]="originFullString" class="text-field" [disabled]="true"></div>

  </div>
</div>
@if (show)
{
<div class="ps-col-12">

  <div class="ps-col-12 ps-d-flex" style="margin-bottom: 12px;">
    <div class="ps-col-11 ps-pl-0" style="padding-right: 0;">


      <div class="ps-col-12 ps-d-flex ps-pl-0">
        <div class="{{data.isCounterpart?'ps-col-6 ps-pl-0':'ps-col-4 ps-pl-0'}}">

          <nz-list nzBordered nzHeader="Origins">

            <cdk-virtual-scroll-viewport itemSize="10" class="origin-container">

              @for (item of originArray; track $index)
              {
              <nz-list-item (click)="onRowClickOrigin(item!)"
                class="list-line {{(selectedOrigins![item!]!=undefined&&selectedOrigins![item!])?'selected':''}}">
                {{ item }}

              </nz-list-item>
              }

            </cdk-virtual-scroll-viewport>
          </nz-list>

        </div>
        <div class="{{data.isCounterpart?'ps-col-6':'ps-col-4'}}">
          <nz-list nzBordered nzHeader="Specials">
            @for (cluster of clusterMaps; track $index)
            {

            <nz-list-item (click)="onRowClickCluster(cluster.clsuterName!)"
              class="list-line {{(selectedCluster![cluster.clsuterName!]!=undefined&&selectedCluster![cluster.clsuterName!])?'selected':''}}">
              {{ cluster.clsuterName }}

            </nz-list-item>


            }

          </nz-list>
        </div>
        @if (!data.isCounterpart)
        {
        <div class="ps-col-4">

          <nz-list nzBordered nzHeader="Exclude">
            <cdk-virtual-scroll-viewport itemSize="10" class="origin-container">
              @for (cluster of toExclude; track $index)
              {
              <nz-list-item (click)="onRowClickToExclude(cluster)"
                class="list-line {{(selectedExcludedOrigin![cluster!]!=undefined&&selectedExcludedOrigin![cluster!])?'selected':''}}">
                {{ cluster }}

              </nz-list-item>
              }
            </cdk-virtual-scroll-viewport>
          </nz-list>

        </div>
        }


      </div>

    </div>

  </div>

</div>
}
@else
{
<div>
  <nz-spin [nzSpinning]="!show" [nzIndicator]="purpleModalLoaderTemplate">
  </nz-spin>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
</div>
}




<div class="ps-col-11 ps-d-flex ps-justify-content-end ps-pr-0">
  <div class="ps-col-2">
    <button (click)="clearAll()" [nzTooltipTitle]='"clear_origin_placeholder" | translation: "Clear All Data"'
      nz-tooltip nz-button class="modal-button clear">
      <ng-container>{{ 'remove_all_data' | translation: 'Clear': []}}</ng-container>
    </button>
  </div>
  <div class="ps-col-2">
    <button (click)="saveOrigin()" [nzTooltipTitle]='"save_origin_placeholder" | translation: "Save Origin"' nz-tooltip
      nz-button class="modal-button">
      <ng-container>{{ 'save_origin_button' | translation: 'Save': []}}</ng-container>
    </button>
  </div>
</div>
