/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EpexPriceDto } from './epexPriceDto';
import { EpexAggregates } from './epexAggregates';
import { EpexQuantityDto } from './epexQuantityDto';


export interface EpexCompleteDto { 
    epexPrices?: Array<EpexPriceDto> | null;
    epexQuantities?: Array<EpexQuantityDto> | null;
    aggregates?: EpexAggregates;
}

