import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Helper, PurpleTranslationPipe } from 'purple-lib';
import { purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeUsersService, User, UserTableItem } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/environment';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  newObj!: UserTableItem;
  isNew: boolean = false;

  phonePrefixes: { label: string, value: string }[] = [];


  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;


  constructor(@Inject(NZ_MODAL_DATA) private user: UserTableItem | undefined, @Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService,
    private backUserSvc: BackOfficeUsersService, private tsvc: AppTranslationService, private fb: FormBuilder, private modalRef: NzModalRef) {
    this.phonePrefixes = Helper.getPhonePrefixes().map(m => { return { label: m, value: m } });
  }

  async ngOnInit(): Promise<void> {
    if (this.user == undefined) {
      this.user = {
        birthDate: undefined,
        email: "",
        emailVerified: false,
        firstName: "",
        genderId: undefined,
        isActive: false,
        lastName: "",
        password: "",
        phone: undefined,
        phonePrefix: undefined,
        registrationDate: undefined,
        timeStamp: "",
        userId: "",
        generalUserRoles: [],
        counterPartyUserRoles: [],
        defaultLocale: undefined,
        initials: "",
        defaultTimeZone: undefined
      }
      this.isNew = true;
    }
    this.newObj = Helper.storeNewObj(this.user);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'firstname',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_name_placeholder', 'Nome utente', []),
            fieldValue: this.user.firstName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'lastname',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_lastname', 'Cognome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_lastname_placeholder', 'Cognome utente', []),
            fieldValue: this.user.lastName,
            fieldIsRequired: true,
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'email',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_email', 'Email', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_email_placeholder', 'Indirizzo Email', []),
            fieldValue: this.user.email,
            fieldIsRequired: true,
            fieldCustomValidators: [Validators.email],
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'input-text',
            fieldControlName: 'phoneNumber',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_phone', 'Telefono', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_phone_placeholder', 'Telefono Utente', []),
            fieldValue: this.user.phone,
            fieldIsRequired: false,
            fieldAffix: {
              fieldControlName: "phonePrefix",
              fieldPlaceholder: this.tranPipe.transform('edit_user_phone_prefix_placeholder', 'Pref.', []),
              fieldType: "select-local",
              fieldWidth: "90px",
              fieldIsRequired: false,
              fieldValue: this.user.phonePrefix,
              fieldOptions: [
                { id: 'isMultiple', value: false },
                { id: 'showArrow', value: false },
                {
                  id: 'options', value: this.phonePrefixes
                }
              ]
            },
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          },
          {
            fieldType: 'date',
            fieldControlName: 'birthdate',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_birthdate', 'Data di nascita', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_birthdate_placeholder', 'Data di nascita utente', []),
            fieldValue: this.user.birthDate,
            fieldIsRequired: false,
            fieldOptions: [{ id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) }],
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'select-local',
            fieldControlName: 'gender',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_user_gender', 'Sesso', []),
            fieldPlaceholder: this.tranPipe.transform('edit_user_gender_placeholder', 'Sesso utente', []),
            fieldValue: this.user.genderId,
            fieldIsRequired: false,
            fieldOptions: [
              { id: 'isMultiple', value: false },
              { id: 'showArrow', value: true },
              {
                id: 'options', value: [
                  {
                    label: this.tranPipe.transform('edit_user_gender_man', 'Uomo', []),
                    value: "M"
                  },
                  {
                    label: this.tranPipe.transform('edit_user_gender_female', 'Donna', []),
                    value: "F"
                  },
                  {
                    label: this.tranPipe.transform('edit_user_gender_other', 'Altro', []),
                    value: "O"
                  }
                ]
              }
            ],
            fieldPaddingLeft: true,
            fieldPaddingRight: false
          }          
        ]
      }
    ]
  }


  async editUser() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.newObj.firstName = this.validateForm.controls["firstname"].value;
      this.newObj.lastName = this.validateForm.controls["lastname"].value;
      this.newObj.email = this.validateForm.controls["email"].value;
      this.newObj.phone = this.validateForm.controls["phoneNumber"].value;
      this.newObj.phonePrefix = this.validateForm.controls["phonePrefix"].value;
      this.newObj.birthDate = this.validateForm.controls["birthdate"].value;
      this.newObj.genderId = this.validateForm.controls["gender"].value;

      console.log("Modifiche?: ", Helper.checkIfDoChanges(this.newObj, this.user))
      console.log("Nuovo Oggetto: ", this.newObj)
      console.log("Nuovo Oggetto: ", this.user)
      if (Helper.checkIfDoChanges(this.newObj, this.user)) {
        console.log("Entro in changes")

        await this.apiProxySvc.makeRequestErrorMessage<User>(() => this.backUserSvc.addOrUpdateUser(this.tsvc.currentLanguage.value, {
          isNew: this.isNew,
          email: this.newObj.email,
          firstName: this.newObj.firstName,
          genderId: this.newObj.genderId,
          lastName: this.newObj.lastName,
          birthDate: this.newObj.birthDate,
          phone: this.newObj.phone,
          userId: this.newObj.userId,
          phonePrefix: this.newObj.phonePrefix          
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<User>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_user_add', 'Utente registrato con successo', [])
          } else {
            text = this.tranPipe.transform('edit_user_update', 'Utente aggiornato con successo', [])
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.isLoading = false;
          this.modalRef.destroy(res.data);
        })
      } else {
        this.mesSvc.warning(this.tranPipe.transform('edit_no_changes', 'Nessuna modifica effettuata', []), {
          nzDuration: environment.MESSAGE_DURATION,
        });
      }
    }

    this.isLoading = false
  }
}