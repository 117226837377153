@if (show)
{
<div class="ps-w-100 ps-d-flex ps-flex-wrap deal-ticket-container">
  <div class="ps-w-100 ps-d-flex ps-flex-wrap deal-ticket-internal-container">


    <app-deal-ticket-stepper *ngIf="stepIdx!=3" style="height: 100px;" [currentStepIdx]="stepIdx"
      [steps]="steps"></app-deal-ticket-stepper>


    <!--PAGE 1-->
    <app-deal-ticket-summary *ngIf="stepIdx==1" [dealTicket]="dealTicket" [clonedDeliveries]="deliveries"
      (saveDeal)="saveDeal($event)" (goBack)="previousStep()">
    </app-deal-ticket-summary>





    <!--BUTTONS-->
    <div [ngStyle]="{'padding-top':stepIdx==0? '170px' : '0px' }"
      [ngClass]="stepIdx==0? 'ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-center':'ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-end'">
      <!-- S -->
      <span *ngIf="stepIdx==0">
        <button *ngIf="stepIdx==0" class=" deal-progress-first-button">
          {{'pre_deal_button'|translation:'Pre deal'}}
        </button>
      </span>
    </div>
    <div
      [ngClass]="stepIdx==0? 'ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-center':'ps-col-6 ps-mt-2 ps-d-flex ps-justify-content-start'"
      [ngStyle]="{'padding-top':stepIdx==0? '170px' : '0px' }">
      <button *ngIf="stepIdx==3" class="deal-progress-next-button">
        {{'delivery_button_previous_text'|translation:'Complete'}}
      </button>
      <span *ngIf="stepIdx==0">
        <button *ngIf="stepIdx==0" (click)="nextStep()" class="deal-progress-first-button">
          {{'deal_ticket_button'|translation:'Deal ticket'}}
        </button>
      </span>
    </div>


    <!--CHECK AND SAVE-->


  </div>


</div>


}
