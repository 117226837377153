/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PnlElement } from './pnlElement';


export interface PnlDto { 
    total?: PnlElement;
    open?: PnlElement;
    closed?: PnlElement;
    contracted?: PnlElement;
    stock?: PnlElement;
    fx?: PnlElement;
    brokerage?: PnlElement;
    linked?: PnlElement;
    year?: number | null;
    product?: string | null;
    dealCount?: number | null;
    deliveryCount?: number | null;
    deliveredCount?: number | null;
}

