import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { RouterModule } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { PurpleEditImageModule, PurpleErrorModule, PurpleGeneralDirectiveModule, PurpleLayoutModule, PurpleLoaderModule, PurplePipeModule, PurpleTranslationPipe } from 'purple-lib';
import { AdministrationModule } from './administration/administration.module';
import { NotificationModule } from './notifications/notification.module';
import { GeneralModule } from './general/general.module';
import { DashboardLayout2Component } from './dashboard-layout-2/dashboard-layout-2.component';
import { DashboardLayout2Service } from './dashboard-layout-2/dashboard-layout-2.service';
import { StellaBackofficeModule } from './stella/backoffice/stella-backoffice.module';
import { StellaFrontofficeModule } from './stella/frontoffice/stella-frontoffice.module';

@NgModule({
  declarations: [DashboardLayout2Component],
  imports: [
    DashboardRoutingModule,
    CommonModule,
    NzLayoutModule,
    NzMenuModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzSkeletonModule,
    NzModalModule,
    PurplePipeModule,
    NzSwitchModule,
    RouterModule,
    NzToolTipModule,
    NzIconModule,
    NzPopoverModule,
    PurpleLoaderModule,
    PurpleGeneralDirectiveModule,
    PurpleEditImageModule,
    PurpleErrorModule,
    PurpleLayoutModule,
    NotificationModule,
    AdministrationModule,
    GeneralModule,
    StellaBackofficeModule,
    StellaFrontofficeModule
  ],
  exports: [],
  providers: [PurpleTranslationPipe]
})
export class DashboardModule {
  static forRoot(): ModuleWithProviders<DashboardModule> {
    return {
      ngModule: DashboardModule,
      providers: [DashboardLayout2Service],
    };
  }
}
