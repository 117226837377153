import {
  MailOutline, LockOutline, LogoutOutline, MenuFoldOutline, ArrowRightOutline, UnderlineOutline, EyeInvisibleOutline, RedoOutline, PushpinOutline, MinusOutline, PlusSquareOutline,
  UnorderedListOutline, UserOutline, ToolOutline, TeamOutline, PhoneOutline, CrownOutline, ManOutline, FormOutline, CloseCircleTwoTone, CheckCircleTwoTone, CreditCardOutline,
  HomeOutline, PlusOutline, SmileTwoTone, PoweroffOutline, NotificationOutline, TabletOutline, MessageOutline, CloudDownloadOutline, SyncOutline, WarningOutline, PictureOutline,
  TranslationOutline, SearchOutline, ShoppingCartOutline, ShoppingOutline, DatabaseOutline, FieldNumberOutline, RiseOutline, Loading3QuartersOutline, CloudOutline, DollarOutline,
  EllipsisOutline, ClearOutline, SaveOutline, ArrowLeftOutline, LikeTwoTone, DislikeTwoTone, CarOutline, EnvironmentOutline, MobileOutline, TableOutline, ArrowDownOutline, FallOutline,
  ArrowUpOutline, FilePdfOutline, IdcardOutline, OrderedListOutline, StockOutline, DownloadOutline, TagOutline, CheckOutline, FolderOutline, LineChartOutline, LayoutOutline, InboxOutline,
  SwitcherOutline, ExportOutline, BackwardOutline, ExclamationCircleOutline, ReloadOutline, LinkOutline, CalendarOutline, InfoCircleOutline, ImportOutline, GlobalOutline,
  BranchesOutline
} from '@ant-design/icons-angular/icons';

export const ICONS = [MailOutline, LockOutline, LogoutOutline, MenuFoldOutline, ArrowRightOutline, UnderlineOutline, FormOutline, RedoOutline, PushpinOutline, SearchOutline, MinusOutline, ArrowLeftOutline,
  EyeInvisibleOutline, UnorderedListOutline, UserOutline, ToolOutline, TeamOutline, PhoneOutline, CrownOutline, ManOutline, CloseCircleTwoTone, CheckCircleTwoTone, PictureOutline, SaveOutline,
  HomeOutline, PlusOutline, SmileTwoTone, PoweroffOutline, NotificationOutline, TabletOutline, MessageOutline, CloudDownloadOutline, SyncOutline, WarningOutline, TranslationOutline, ClearOutline,
  ShoppingCartOutline, ShoppingOutline, DatabaseOutline, FieldNumberOutline, RiseOutline, Loading3QuartersOutline, CloudOutline, DollarOutline, CreditCardOutline, EllipsisOutline, PlusSquareOutline, BranchesOutline,
  LikeTwoTone, DislikeTwoTone, CarOutline, EnvironmentOutline, MobileOutline, TableOutline, ArrowDownOutline, FallOutline, ArrowUpOutline, FilePdfOutline, IdcardOutline, OrderedListOutline, StockOutline, CalendarOutline, GlobalOutline,
  DownloadOutline, TagOutline, CheckOutline, FolderOutline, LineChartOutline, LayoutOutline, InboxOutline, SwitcherOutline, ExportOutline, BackwardOutline, ExclamationCircleOutline, ReloadOutline, LinkOutline, InfoCircleOutline, ImportOutline];
