/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Tab } from './tab';
import { RecipientsAttribute } from './recipientsAttribute';
import { Recipient } from './recipient';
import { Document } from './document';
import { Envelope } from './envelope';
import { TabsAttribute } from './tabsAttribute';


export interface RealizeTemplateResponse { 
    envelope?: Envelope;
    documents?: Array<Document> | null;
    recipients?: Array<Recipient> | null;
    tabs?: Array<Tab> | null;
    tabAttributes?: Array<TabsAttribute> | null;
    readonly recipientsAttributes?: Array<RecipientsAttribute> | null;
}

