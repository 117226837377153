/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TransactionNullable { 
    readonly spolloNullIsValue_ParentTransactionId?: boolean;
    transactionId?: string | null;
    transactionDate?: string | null;
    transactionTypeId?: string | null;
    transactionStatusId?: string | null;
    transactionVolume?: number | null;
    portfolioId?: string | null;
    portfolioYear?: number | null;
    parentTransactionId?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
}

