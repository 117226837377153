import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Helper, PurpleApiProxyService, PurpleLoaderService, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ClusterMapGrouped, ClusterMapGroupedString, StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-technology-cluster',
  templateUrl: './technology-cluster.component.html',
  styleUrls: ['./technology-cluster.component.scss']
})
export class TechnologyClusterComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(NZ_MODAL_DATA) public data: { productId: string, modalTech: TechFull | undefined }, private apiProxySvc: PurpleApiProxyService, private tranPipe: PurpleTranslationPipe, private loaderSvc: PurpleLoaderService,
    private tsvc: AppTranslationService, private admSvc: StellaService, private msgSvc: NzMessageService, private modalRef: NzModalRef) { }

  clusterMaps: ClusterMapGroupedString[] = [];
  selectedTech: { [key: string]: boolean } = {};
  selectedCluster: { [key: string]: boolean } = {};
  selectedExcludedTech: { [key: string]: boolean } = {};

  techArray: string[] = []

  toExclude: string[] = [];

  show: boolean = false;

  technologies: TechFull =
    {
      clusters: [],
      technology: [],
      techString: "",
      toExclude: []
    }

  techFullString: string = this.tranPipe.transform("no_tech_selected_text", "No Technologies Selected");

  ngOnInit() {

    this.getClusters();




  }





  async getClusters() {

    await this.apiProxySvc.makeRequestErrorFunction<ClusterMapGroupedString[]>(() => this.admSvc.getProductTechnologyClusterMaps(this.tsvc.currentLanguage.value,
      this.data.productId),
      true,
      "internal-loader",
      100,
      () => this.getClusters(), (res) => {
        this.clusterMaps = res.data!


        this.clusterMaps.forEach(cm => {

          cm.clusterValues!.forEach(tech => {

            if (!this.techArray.includes(tech))
              this.techArray.push(tech)

          });

        });


        console.log("modalTech", this.data.modalTech)


        if (this.data.modalTech != undefined) {
          if (this.data.modalTech.technology != undefined)
            this.data.modalTech!.technology.forEach(tech => {

              if (tech != undefined && tech != '')
                this.onRowClickTech(tech);
            });
          if (this.data.modalTech.clusters != undefined && this.selectedCluster == undefined) {


            this.data.modalTech!.clusters.forEach(cluster => {

              if (cluster != undefined && cluster != '')
                this.onRowClickCluster(cluster);
            });
          }

          if (this.data.modalTech.toExclude != undefined)
            this.data.modalTech!.toExclude.forEach(tech => {
              if (tech != undefined && tech != '')
                this.onRowClickToExclude(tech);
            });
        }

        this.show = true;

      })
  }


  onRowClickTech(tech: string) {

    var checked = this.selectedTech[tech] != undefined ? !this.selectedTech[tech] : true;


    this.selectedTech[tech] = checked;

    if (checked) {
      if (this.selectedExcludedTech[tech] == true) {
        this.onRowClickToExclude(tech)
      }
      this.technologies.technology != undefined ? this.technologies.technology!.push(tech) : this.technologies.technology = [tech];
    }
    else {
      if (this.toExclude.includes(tech)) {
        this.onRowClickToExclude(tech)

      }
      this.technologies.technology = this.technologies.technology!.filter(t => t !== tech);
    }

    if (checked) {
      var m = this.clusterMaps.filter(s => s.clusterValues?.includes(tech))!;

      this.updateClustersValues(m);

    }



  }







  updateClustersValues(clusterMaps: ClusterMapGroupedString[]) {



    clusterMaps.forEach(clusterMap => {

      var allClusterOriginSelected = true;

      clusterMap.clusterValues!.forEach(n => {
        if (this.selectedTech[n] != true) {
          allClusterOriginSelected = false;
        }
      });

      this.selectedCluster[clusterMap.clsuterName!] = allClusterOriginSelected;

      if (allClusterOriginSelected) {

        if (!this.technologies.clusters?.includes(clusterMap.clsuterName!))
          this.technologies.clusters?.push(clusterMap.clsuterName!);
      }
    });



    this.updateTTechnologiesToExclude();

  }



  onRowClickCluster(cluster: string) {



    var checked = this.selectedCluster[cluster] != undefined ? !this.selectedCluster[cluster] : true;

    this.selectedCluster[cluster] = checked;

    if (checked) {
      if (!this.technologies.clusters?.includes(cluster))
        this.technologies.clusters?.push(cluster);
    }
    else {

      this.technologies.clusters = this.technologies.clusters!.filter(c =>
        c !== cluster
      );
    }


    var m = Helper.storeNewObj(this.clusterMaps.find(s => s.clsuterName?.strEq(cluster))!);

    this.updateTechnologiesValues(m, checked);


  }



  updateTechnologiesValues(clusterMap: ClusterMapGroupedString, checked: boolean) {



    clusterMap.clusterValues!.forEach(n => {
      if (this.selectedExcludedTech[n] == true && checked)
        return;
      this.selectedTech[n] = checked;
      if (checked) {
        if (!this.technologies.technology!.includes(n))
          this.technologies.technology!.push(n)
      }
      else {
        this.technologies.technology = this.technologies.technology!.filter(tech => tech !== n);
      }


    });

    (this.technologies.clusters ?? []).forEach(cluster => {

      this.clusterMaps.find(c => c.clsuterName!.strEq(cluster))!.clusterValues!.forEach(tech => {
        if (this.selectedExcludedTech[tech] == true)
          return;
        this.selectedTech[tech] = true;
        if (!this.technologies.technology?.includes(tech))
          this.technologies.technology?.push(tech)
      });

    });


    this.updateTTechnologiesToExclude();
  }

  updateTTechnologiesToExclude() {


    this.toExclude = [];

    (this.technologies.clusters ?? []).forEach(cluster => {

      var c = this.clusterMaps.find(c => c.clsuterName?.strEq(cluster));

      if (c?.clusterValues?.length == 1)
        return;
      c!.clusterValues!.forEach(origin => {


        if (!this.toExclude.includes(origin))
          this.toExclude.push(origin)

      });

    });


    for (const key in this.selectedExcludedTech) {
      if (!this.toExclude.includes(key))
        this.selectedExcludedTech[key] = false;
    }

    (this.technologies.toExclude ?? []).forEach(o => {
      if (!this.toExclude.includes(o))
        this.technologies.toExclude = this.technologies.toExclude?.filter(s => s != o)

    });

    this.updateTechString();
  }


  onRowClickToExclude(origin: string) {


    var checked = this.selectedExcludedTech[origin] != undefined ? !this.selectedExcludedTech[origin] : true;


    this.selectedExcludedTech[origin] = checked;



    if (checked) {
      this.selectedTech[origin] = false;
      this.technologies.toExclude != undefined ? this.technologies.toExclude!.push(origin) : this.technologies.toExclude = [origin];
    }
    else {
      this.selectedTech[origin] = true;
      this.technologies.toExclude = this.technologies.toExclude!.filter(o => o !== origin);
    }


    this.updateTechString();

  }



  updateTechString() {


    var tempTechs = Helper.storeNewObj(this.technologies.technology);
    var tempCluster = Helper.storeNewObj(this.technologies.clusters);

    var tempToExclude = Helper.storeNewObj(this.technologies.toExclude);


    var finalStringTechAndCluster: string[] = [];
    var finalStringToExclude: string[] = [];


    (tempCluster ?? []).forEach(cluster => {

      finalStringTechAndCluster.push(cluster);

      var c = this.clusterMaps.find(c => c.clsuterName?.strEq(cluster));

      c!.clusterValues!.forEach(tech => {
        if (tempTechs?.includes(tech))
          tempTechs = tempTechs.filter(o => o != tech)
      });

    });

    (tempTechs ?? []).forEach(tech => {
      finalStringTechAndCluster.push(tech);

    });

    (tempToExclude ?? []).forEach(oToExclude => {
      finalStringToExclude.push(oToExclude);

    });



    var string1 = finalStringTechAndCluster.join("+");

    var string2 = finalStringToExclude.join("+");


    if (finalStringToExclude.length > 0) {
      this.techFullString = string1.concat("|excl.", string2);
    }
    else
      this.techFullString = string1




    if (this.techFullString == "") {
      this.techFullString = this.tranPipe.transform("no_tech_selected_text", "No Technologies Selected");
    }
  }


  clearAll() {

    for (const key in this.selectedTech) {
      this.selectedTech[key] = false;
    }
    for (const key in this.selectedCluster) {
      this.selectedCluster[key] = false;
    }
    for (const key in this.selectedExcludedTech) {
      this.selectedExcludedTech[key] = false
    }

    this.technologies =
    {
      clusters: [],
      technology: [],
      toExclude: [],
      techString: ""
    }

    this.toExclude = []

    this.techFullString = this.tranPipe.transform("no_tech_selected_text", "No Technoloogies Selected");

  }






  saveOrigin() {
    var returnValue: TechFull =
    {
      clusters: this.technologies.clusters,
      technology: this.technologies.technology,
      toExclude: this.technologies.toExclude,
      techString: this.techFullString
    }

    this.modalRef.destroy(returnValue);
  }



}

export interface TechFull {
  technology: string[] | undefined;
  clusters: string[] | undefined;
  toExclude: string[] | undefined;
  techString: string | null | undefined;
}
