<div class="ps-col-12 ps-d-flex ps-pr-0" style="margin-bottom: 12px;">
  <div class="ps-col-9">
    <div>
      <label><b>Vintage selected:</b></label>
    </div>
    <div><input nz-input type="text" [(ngModel)]="vintageFullString" class="text-field" [disabled]="true"></div>

  </div>
  <div class="purple-button-container ps-col-3 ps-w-100 ps-d-flex ps-pr-0">
    <button (click)="addVintage(undefined)" nz-button class="add-button">
      <ng-container>{{ 'add_vintage_button' | translation: 'Add': []}}</ng-container>
    </button>
  </div>
</div>
@if (show)
{
<div class="ps-col-12">
  @for (panel of panels; track i;let i = $index)
  {
  <div class="ps-col-12 ps-d-flex" style="margin-bottom: 12px;">
    <div class="ps-col-11 ps-pl-0" style="padding-right: 0;">
      <nz-collapse nzAccordion [nzBordered]="true" nzExpandIconPosition="right" (click)="closeAllPanel(i)">

        <nz-collapse-panel class="ps-col-12" style="border-bottom: none;margin-bottom: 6px;margin-top: 6px;" #p
          nzHeader="Vintage  {{vintages![i].year!=undefined?': '+ vintages![i].year!:''}} "
          [nzActive]="panel.active" [nzExpandedIcon]="expandedIcon">


          <ng-template #expandedIcon let-active>
            {{ active }}
            <span nz-icon nzType="right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -90"></span>
          </ng-template>

          <div class="ps-col-12 ps-d-flex">
            <div class="ps-col-4">
              <nz-list nzBordered nzHeader="Months">
                @for (month of monthList; track m;let m = $index)
                {
                <nz-list-item (click)="onRowClickMonth(i,m)"
                  class="list-line {{(selectedMonths![i][m]!=undefined&&selectedMonths![i][m])?'selected':''}}">
                  {{ month.toUpperCase() }}
                </nz-list-item>
                }

              </nz-list>
            </div>
            <div class="ps-col-4">
              <nz-list nzBordered nzHeader="Clusters">
                @for (cluster of clusterMaps; track $index)
                {
                <nz-list-item (click)="onRowClickCluster(i,cluster.vintageMonthClsuterName!)"
                  class="list-line {{(selectedCluster![i][cluster.vintageMonthClsuterName!]!=undefined&&selectedCluster![i][cluster.vintageMonthClsuterName!])?'selected':''}}">
                  {{ cluster.vintageMonthClsuterName }}

                </nz-list-item>
                }

              </nz-list>
            </div>
            <div class="ps-col-4">
              <nz-list nzBordered nzHeader="Year">
                <nz-list-item>
                  <nz-date-picker nzMode="year"  [(ngModel)]="years![i] " style="width: -webkit-fill-available;"
                    [nzDisabledDate]="disabledDate" (ngModelChange)="convertVintage()"></nz-date-picker>
                </nz-list-item>
              </nz-list>

            </div>
          </div>


        </nz-collapse-panel>

      </nz-collapse>
    </div>



    <div class="ps-col-1 ps-justify-content-end ps-pr-0">
      <div style="padding-bottom: 1px;">
        <button class="right-panel-button-bottom clear" (click)="clearPanel(i)"
          [nzTooltipTitle]='"clear_vintage_placeholder" | translation: "Clear Vintage Data"' nzTooltipPlacement="top"
          nz-tooltip nz-button nzType="default" nzShape="round">
          <span class="icon-clear fsp-22-0"></span>
        </button>
      </div>
      <div>
        <button class="right-panel-button-bottom delete" (click)="deleteVintage(i)"
          [nzTooltipTitle]='"delete_vintage_placeholder" | translation: "Delete Vintage"' nzTooltipPlacement="top"
          nz-tooltip nz-button nzType="default" nzShape="round">
          <span class="icon-trash fsp-22-0"></span>
        </button>
      </div>
    </div>
  </div>
  }

</div>
}
@else
{
<div>
  <nz-spin [nzSpinning]="!show" [nzIndicator]="purpleModalLoaderTemplate">
  </nz-spin>
  <ng-template #purpleModalLoaderTemplate>
    <div class="purple-modal-custom-spinner">
      <span nz-icon nzType="loading"></span>
    </div>
  </ng-template>
</div>
}




<div class="ps-col-11 ps-d-flex ps-justify-content-end ps-pr-0">
  <div class="ps-col-2">
    <button (click)="clearAll()" [nzTooltipTitle]='"clear_vintage_placeholder" | translation: "Clear All Data"'
      nz-tooltip nz-button class="clear-button">
      <ng-container>{{ 'remove_all_data' | translation: 'Clear': []}}</ng-container>
    </button>
  </div>
  <div class="ps-col-2">
    <button (click)="saveVintage()" [nzTooltipTitle]='"save_vintage_placeholder" | translation: "Save Vintage"'
      nz-tooltip nz-button class="save-button">
      <ng-container>{{ 'save_vintage_button' | translation: 'Save': []}}</ng-container>
    </button>
  </div>
</div>
