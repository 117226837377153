/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateRecipientRequest { 
    recipientId?: string | null;
    recipientRoleId?: string | null;
    userEmail?: string | null;
    recipientEnvelopeId?: string | null;
    routingOrder?: number | null;
    recipientsLogUserId?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
    readonly spolloNullIsValue_RecipientsLogUserId?: boolean;
}

