<div class="ps-d2-main-layout" (window:resize)="onResize()">

  <!----------------------------------------------- SIDE MENU COMPONENT ----------------------------------------------------------------->
  <app-purple-layout-left-side [activeMenus]="activeMenus" [(isMenuCollapsed)]="dashSvc.isMenuCollapsed"
    [menuSections]="menuSections" [(isUserMenuOpen)]="dashSvc.isUserMenuOpen"
    [userEmail]="authSvc.currentUser!.user!.email" [userFirstName]="authSvc.currentUser!.user!.firstName"
    [userLastName]="authSvc.currentUser!.user!.lastName" [isPurpleAdmin]="authSvc.isPurpleAdminUser()"
    [softwareVersion]="dashSvc.softwareVersion" [currentMenu]="dashSvc.currentMenu" [menuVersion]="'v2'"
    [currentSubMenu]="dashSvc.currentSubMenu" (clickLogout)="dashSvc.logout(false)" [collpaseOtherMenus]="false">
  </app-purple-layout-left-side>
  <!---------------------------------------------------------------------------------------------------------------------------------->


  <div
    class="ps-d2-main-container {{dashSvc.isMenuCollapsed?'ps-menu-collapsed':'ps-menu-not-collapsed'}} ps-d-block ps-w-100">

    <!----------------------------------------------- HEADER COMPONENT --------------------------------------------------------------->
    <app-purple-layout-header [isMenuCollapsed]="dashSvc.isMenuCollapsed" [dashBackButtons]="dashSvc.dashBackButtons"
      [dashMainItems]="dashSvc.dashMainItems" [pageTitle]="pgTitle" [headerType]="'small'"
      [pageSubTitle]="pgSubTitle">
    </app-purple-layout-header> 
    <!-------------------------------------------------------------------------------------------------------------------------------->

    <div class="ps-d2-content" (click)="contentClick()">
      <purple-loader class="internal-loader {{dashSvc.isMenuCollapsed?'ps-d2-menu-collapsed':''}}" height="250px"
        [isGlobal]="true" loaderPath="/assets/loaders/internal-loader.json" loaderId="internal-loader">
      </purple-loader>

      <purple-500-error errorPageId="internal-error"
        customClass="error-page-internal-custom {{dashSvc.isMenuCollapsed?'ps-d2-menu-collapsed':'ps-d2-menu-not-collapsed'}}"
        [isGlobal]="true">
      </purple-500-error>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>


<ng-template #customLeftSideTopPanel>
  <div class="ps-w-100 ps-d-flex ps-flex-wrap">
    <div
      class="stella-side-menu-top-container {{dashSvc.isMenuCollapsed?'purple-layout-menu-collapsed':''}} ps-d-flex ps-align-items-center">
      <img src="assets/images/stella-star.svg" class="stella-side-menu-image" />
      <div class="stella-side-menu-text-container ps-flex-wrap">
        <p class="stella-side-menu-text-stella ps-w-100">
          Stella
        </p>
        <p class="stella-side-menu-text-company ps-w-100">
          NValue NG
        </p>
      </div>
      <div class="up-down-icons-container ps-flex-wrap">
        <div class="ps-w-100 up">
          <span class="up-down-icon" nz-icon nzType="up" nzTheme="outline"></span>
        </div>
        <div class="ps-w-100 down">
          <span class="up-down-icon" nz-icon nzType="down" nzTheme="outline"></span>
        </div>
      </div>
    </div>
    <div class="stella-side-menu-top-chat-container {{dashSvc.isMenuCollapsed?'purple-layout-menu-collapsed':''}}">
      <ul nz-menu [nzInlineCollapsed]="dashSvc.isMenuCollapsed" nzMode="inline"
        class="stella-side-menu-item ps-w-100">
        <li [queryParams]="" [routerLink]="[]" nz-menu-item class="{{dashSvc.isMenuCollapsed?'stella-side-menu-item-collapsed':''}}">
          <span class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between" title>
            <div class="ps-d-flex ps-align-items-center stella-side-menu-item-text">
              <i nz-icon nzType="inbox"></i>
              <span>My inbox</span>
            </div>
            <div class="stella-side-menu-item-number">
              2
            </div>
          </span>
        </li>
        <li [queryParams]="" [routerLink]="[]" nz-menu-item>
          <span class="ps-w-100 ps-d-flex ps-align-items-center ps-justify-content-between" title>
            <div class="ps-d-flex ps-align-items-center stella-side-menu-item-text">
              <i nz-icon nzType="switcher"></i>
              <span>My tasks</span>
            </div>
            <div class="stella-side-menu-item-number">
              0
            </div>
          </span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>