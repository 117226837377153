/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DealBaseTrader } from './dealBaseTrader';
import { DealBaseCounterParty } from './dealBaseCounterParty';
import { DealBaseBroker } from './dealBaseBroker';


export interface DealBaseGrouped { 
    transactionId: string;
    transactionDate: string;
    transactionTypeId: string;
    transactionStatusId: string;
    transactionVolume: number;
    portfolioId: string;
    portfolioYear: number;
    counterParty?: DealBaseCounterParty;
    brokers: Array<DealBaseBroker>;
    weightedAvgPrice?: number | null;
    avgPrice?: number | null;
    totalPrice?: number | null;
    currencyId: string;
    traders: Array<DealBaseTrader>;
    origins: Array<string>;
    totalDeliveries?: number | null;
    totalDeliveriesDelivered?: number | null;
    technologies: Array<string>;
    isDelivered: boolean;
    transactionReferenceNumber: string;
}

