/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PnlDataElement } from './pnlDataElement';


export interface PnlElement { 
    s?: number;
    b?: number;
    val?: number;
    position?: number;
    viewVal?: string | null;
    conservative?: number;
    breakdown?: { [key: string]: number; } | null;
    mixBreakdown?: { [key: string]: number; } | null;
    data?: Array<PnlDataElement> | null;
}

