import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Helper, PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { DealTicketDisplayInfo, ProductAttributesGrouped, StellaService } from 'src/core-modules/sdk/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_DRAWER_DATA, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { DealTicket } from '../../custom-component/deal-ticket-summary/deal-ticket-summary.component';
import { Delivery } from '../deal-ticket-delivery/deal-ticket-delivery.component';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-review-deal-and-delivery',
  templateUrl: './review-deal-and-delivery.component.html',
  styleUrls: ['./review-deal-and-delivery.component.scss']
})
export class ReviewDealAndDeliveryComponent implements OnInit {

  constructor(@Inject(NZ_DRAWER_DATA) public data: { dealTicket: DealTicket, deliveries: Delivery[] }, @Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private modal: NzModalService, private tsvc: AppTranslationService,
    private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private apiProxySvc: PurpleApiProxyService, private mesSvc: NzMessageService, private drawer: NzDrawerService, private drawerRef: NzDrawerRef, private curr: CurrencyPipe) { }

  productAttributes: ProductAttributesGrouped[] | undefined;

  tableRows!: string[];

  dealAttributes!: string[];

  traderName: string = "";
  brokerName: string | undefined | null;

  cpNames: { [key: string]: string } = {}


  ngOnInit() {

    this.getProductAttributes();

    this.getDealInfos();


  }

  async getProductAttributes() {
    await this.apiProxySvc.makeRequestErrorFunction<ProductAttributesGrouped[]>(() => this.admSvc.getProductAttributes(this.tsvc.currentLanguage.value),
      true,
      "internal-loader",
      100,
      () => this.getProductAttributes(), (res) => {
        this.productAttributes = res.data!

        this.tableRows = this.productAttributes!.find(p => p.productId?.strEq(this.data.dealTicket.portfolioId!))!.attributes!["delivery"]

        this.dealAttributes = this.productAttributes!.find(p => p.productId?.strEq(this.data.dealTicket.portfolioId!))!.attributes!["deal"]

      })
  }

  async getDealInfos() {

    var ids: string[] = [];

    if (this.data.dealTicket.companyId != undefined)
      ids.push(this.data.dealTicket.companyId)
    if (this.data.dealTicket.counterPartyId != undefined)
      ids.push(this.data.dealTicket.counterPartyId)
    if (this.data.dealTicket.portfolioId?.strEq("hfc-brk"))
      this.data.deliveries.map(cp => cp.counterpartyId!).forEach(cpid => {
        ids.push(cpid)
      });

    this.data.deliveries.map(m => m).forEach(delivery => {
      if (delivery.fields != undefined && delivery.fields["beneficiary"] != undefined)
        ids.push(delivery.fields["beneficiary"])
      if (delivery.fields != undefined && delivery.fields["power_plant"] != undefined)
        ids.push(delivery.fields["power_plant"])
    });



    await this.apiProxySvc.makeRequestErrorFunction<DealTicketDisplayInfo>(() => this.admSvc.getDealInfoByIds(this.tsvc.currentLanguage.value,
      {

        traderId: this.data.dealTicket.traderId!,
        brokerId: this.data.dealTicket.brokerId,
        counterPartyIds: ids
      }),
      true,
      "internal-loader",
      100,
      () => this.getDealInfos(), (res) => {


        this.traderName = res.data!.traderName!;
        this.brokerName = res.data?.brokerName;

        this.cpNames = Helper.storeNewObj(res.data?.counterpartyNames!);
      })
  }

  cancel() {
    this.drawerRef.close(false);
  }

  confirm() {
    this.drawerRef.close(true);

  }

  calculateTotal() {

    var totalPrice = 0;
    var currencies: string[] = [];


    this.data.deliveries.forEach(delivery => {

      totalPrice += delivery.price!;
      if (!currencies.includes(delivery.currency!))
        currencies.push(delivery.currency!)

    });



    /* this.curr.transform(totalPrice, this.data.deliveries.at(0)?.currency) */

    return totalPrice.toFixed(4) + " " + currencies.join("/");

  }

}
