<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabBoxColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true" tableSearchType="box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">
    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.firstName | titlecase}}</td>
          <td>{{ data.item.lastName | titlecase}}</td>
          <td>{{ data.item.email }}</td>
          <td>
            <span *ngIf="(data.item.phone??undefined)!= undefined">
              <span *ngIf="(data.item.phonePrefix??undefined)!= undefined">{{data.item.phonePrefix}}</span>
              {{data.item.phone}}
            </span>
            <span *ngIf="(data.item.phone??undefined)== undefined">
              {{ 'customer_table_not_specify' | translation: 'Non specificato': [] }}
            </span>
          </td>
          <td>{{ data.item.registrationDate | date:'dd MMM yyyy (HH:mm)' }}</td>
          <td>
            <nz-tag *ngFor="let role of data.item.generalUserRoles.slice(0,5)??[]" class="ps-mt-2" purpleStringToColor
              [word]="role.roleId!" [opacity]="10">
              {{role.roleName}}
            </nz-tag>
          </td>
          <td nzAlign="center">
            <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
              data.expanded?
              ("hide_more_button_customer_table" | translation: "Nascondi dati aggiuntivi"):
              ("show_more_button_customer_table" | translation: "Mostra più dati")' nzTooltipPlacement="top"
              class="ps-mr-3" nz-tooltip nz-button nzType="default" nzShape="circle"><i nz-icon 
                [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button>

            <button (click)="editUser(data.item!)"
              [nzTooltipTitle]='"edit_button_customer_table" | translation: "Modifica cliente"' nzTooltipPlacement="top"
              nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon 
                nzType="edit"></i></button>

            <button nz-popover nzPopoverPlacement="left"
              [nzPopoverTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzPopoverTrigger='click'
              [nzPopoverContent]="userMoreActions"
              [nzTooltipTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzTooltipPlacement="top" nz-tooltip
              class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon nzType="unordered-list"
                 nzTheme="outline"></i></button>

            <ng-template #userMoreActions>
              <div class="ps-d-flex ps-flex-wrap">

                <button (click)="editUserRoles(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"edit_user_role_button_customer_table" | translation: "Gestione Ruolo Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon  nzType="tool"></i>
                </button>

                <button (click)="resetPassword(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"reset_password_button_customer_table" | translation: "Resetta Password Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon  nzType="redo"></i>
                </button>

                <button *ngIf="!data.item.emailVerified" class="ps-mr-3" (click)="verifyEmail(data.item!)"
                  [nzTooltipTitle]='"verify_user_email_customer_table" | translation: "Verifica email"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon  nzType="check"></i>
                </button>

                <!-- <button (click)="editUserAddresses(data.item!)"
                  [nzTooltipTitle]='"edit_user_addresses_button_customer_table" | translation: "Gestione Indirizzi Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon  nzType="home"></i>
                </button> -->
              </div>
            </ng-template>
          </td>
        </tr>
        <tr #expandedRow [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">
              <nz-descriptions class="ps-w-100"
                [nzTitle]="'customer_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []" nzBordered
                [nzColumn]="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzTitle]="'customer_table_gender' | translation: 'Sesso': []">
                  {{data.item.genderId | gender}}
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_birthdate' | translation: 'Data di nascita': []">
                  <span *ngIf="(data.item.birthDate??undefined)!= undefined">
                    {{data.item.birthDate | date:"dd MMM yyyy"}}
                  </span>
                  <span *ngIf="(data.item.birthDate??undefined)== undefined">
                    {{ 'customer_table_not_specify_w' | translation: 'Non specificata': [] }}
                  </span>
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_email_verified' | translation: 'Email Verificata': []">
                  <nz-badge [nzStatus]="data.item.emailVerified?'success':'error'"
                    [nzText]="data.item.emailVerified?'Si':'No'">
                  </nz-badge>
                </nz-descriptions-item>
                <nz-descriptions-item
                [nzTitle]="'customer_table_counterparty_roles' | translation: 'Counterparty': []"
                  [nzSpan]="4">
                  <div class="ps-w-100 ps-d-flex ps-flex-wrap ps-align-items-center">

                    <div *ngFor="let cp of data.item.counterPartyUserRoles" class="counterparty-pill">
                      <p purpleStringToColor mode="back" [word]="cp.counterPartyId" [opacity]="20"
                        class="counterparty-pill-name">
                        {{cp.counterPartyShortName??cp.counterPartyName}}
                      </p>
                      <p class="counterparty-pill-role">
                        {{getCounterPartyRolesStr(cp)}}
                      </p>
                    </div>
                  </div>
                </nz-descriptions-item>

                <!-- <nz-descriptions-item [nzTitle]="'customer_table_roles' | translation: 'Ruoli': []">



                </nz-descriptions-item> -->
              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>














<!-- <ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber" [version]="2"
    [showCustomPagination]="true"

    >
    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.firstName }}</td>
          <td>{{ data.item.lastName }}</td>
          <td>{{ data.item.email }}</td>
          <td>
            <span *ngIf="(data.item.phone??undefined)!= undefined">
              <span *ngIf="(data.item.phonePrefix??undefined)!= undefined">{{data.item.phonePrefix}}</span>
              {{data.item.phone}}
            </span>
            <span *ngIf="(data.item.phone??undefined)== undefined">
              {{ 'customer_table_not_specify' | translation: 'Non specificato': [] }}
            </span>
          </td>
          <td>{{ data.item.registrationDate | date:'dd MMM yyyy (HH:mm)' }}</td>
          <td nzAlign="center">
            <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
              data.expanded?
              ("hide_more_button_customer_table" | translation: "Nascondi dati aggiuntivi"):
              ("show_more_button_customer_table" | translation: "Mostra più dati")'
              nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default" nzShape="circle"><i
                nz-icon  [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button>

            <button (click)="editUser(data.item!)"
              [nzTooltipTitle]='"edit_button_customer_table" | translation: "Modifica cliente"' nzTooltipPlacement="top"
              nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon 
                nzType="edit"></i></button>

            <button nz-popover nzPopoverPlacement="left"
              [nzPopoverTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzPopoverTrigger='click'
              [nzPopoverContent]="userMoreActions"
              [nzTooltipTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzTooltipPlacement="top" nz-tooltip
              class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon nzType="unordered-list"
                 nzTheme="outline"></i></button>

            <ng-template #userMoreActions>
              <div class="ps-d-flex ps-flex-wrap">

                 <button (click)="deleteUser(data.item!)" class="ps-mr-3"
                [nzTooltipTitle]='"delete_button_customer_table" | translation: "Elimina cliente"'
                nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                <i nz-icon  nzType="delete"></i>
              </button>

                <button (click)="resetPassword(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"reset_password_button_customer_table" | translation: "Resetta Password Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon  nzType="redo"></i>
                </button>

                <button (click)="editUserAddresses(data.item!)"
                  [nzTooltipTitle]='"edit_user_addresses_button_customer_table" | translation: "Gestione Indirizzi Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon  nzType="home"></i>
                </button>
              </div>
            </ng-template>
          </td>
        </tr>
        <tr #expandedRow [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">
              <nz-descriptions class="ps-w-100"
                [nzTitle]="'customer_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []" nzBordered
                [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzTitle]="'customer_table_gender' | translation: 'Sesso': []">
                  {{data.item.genderId | gender}}
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_birthdate' | translation: 'Data di nascita': []">
                  <span *ngIf="(data.item.birthDate??undefined)!= undefined">
                    {{data.item.birthDate | date:"dd MMM yyyy"}}
                  </span>
                  <span *ngIf="(data.item.birthDate??undefined)== undefined">
                    {{ 'customer_table_not_specify_w' | translation: 'Non specificata': [] }}
                  </span>
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'customer_table_email_verified' | translation: 'Email Verificata': []">
                  <nz-badge [nzStatus]="data.item.emailVerified?'success':'error'"
                    [nzText]="data.item.emailVerified?'Si':'No'">
                  </nz-badge>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container> -->