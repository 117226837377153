import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { BaseSystemEventNotificationsComponent } from './base-system-event-push-notifications/base-system-event-push-notifications.component';
import { BaseSystemEventMailTemplatesComponent } from './mail/base-system-event-mail-templates/base-system-event-mail-templates.component';
import { SmtpServersComponent } from './mail/smtp-servers/smtp-servers.component';
import { NotificationExceptionsComponent } from './notification-exceptions/notification-exceptions.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'mail-templates',
        pathMatch: "full"
      },
      {
        path: 'mail-templates',
        component: BaseSystemEventMailTemplatesComponent,
        data: { submenu: "mail-templates" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'smtp-servers',
        component: SmtpServersComponent,
        data: { submenu: "smtp-servers" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'notification-templates',
        component: BaseSystemEventNotificationsComponent,
        data: { submenu: "notification-templates" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'notification-exceptions',
        component: NotificationExceptionsComponent,
        data: { submenu: "notification-exceptions" },
        canActivate: [ShowMenuGuard]
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationRoutingModule { }
