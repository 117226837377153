export * from './addressManager.service';
import { AddressManagerService } from './addressManager.service';
export * from './backOfficeLocalization.service';
import { BackOfficeLocalizationService } from './backOfficeLocalization.service';
export * from './backOfficeNotification.service';
import { BackOfficeNotificationService } from './backOfficeNotification.service';
export * from './backOfficeUsers.service';
import { BackOfficeUsersService } from './backOfficeUsers.service';
export * from './codeGrantOauth.service';
import { CodeGrantOauthService } from './codeGrantOauth.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './dsWebhooks.service';
import { DsWebhooksService } from './dsWebhooks.service';
export * from './envelopes.service';
import { EnvelopesService } from './envelopes.service';
export * from './localization.service';
import { LocalizationService } from './localization.service';
export * from './mediaManager.service';
import { MediaManagerService } from './mediaManager.service';
export * from './power.service';
import { PowerService } from './power.service';
export * from './recipients.service';
import { RecipientsService } from './recipients.service';
export * from './signatureTemplates.service';
import { SignatureTemplatesService } from './signatureTemplates.service';
export * from './stella.service';
import { StellaService } from './stella.service';
export * from './tabs.service';
import { TabsService } from './tabs.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './utils.service';
import { UtilsService } from './utils.service';
export const APIS = [AddressManagerService, BackOfficeLocalizationService, BackOfficeNotificationService, BackOfficeUsersService, CodeGrantOauthService, DocumentsService, DsWebhooksService, EnvelopesService, LocalizationService, MediaManagerService, PowerService, RecipientsService, SignatureTemplatesService, StellaService, TabsService, UsersService, UtilsService];
