import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.then(() => {
  const icon1 = document.createElement('link');
  const icon2 = document.createElement('link');
  const icon3 = document.createElement('link');
  const manifest = document.createElement('link');
  const icon4 = document.createElement('link');

  const title = document.createElement('title');

  const safeCompanyAbb = String.prototype.isNullOrEmpty(environment.COMPANY_ABB) ? "default" : environment.COMPANY_ABB;
  const safeCompanyName = String.prototype.isNullOrEmpty(environment.COMPANY_NAME) ? "PurpleAdmin" : environment.COMPANY_NAME;


  title.innerText = safeCompanyName + " - Manager"

  document.head.appendChild(title)

  const meta1 = document.createElement('meta');
  meta1.name = "msapplication-TileColor";
  meta1.content = String.prototype.isNullOrEmpty(environment.COMPANY_NAME) ? "#000000" : environment.HTML_TITLE_COLOR;
  document.head.appendChild(meta1)

  const meta2 = document.createElement('meta');
  meta2.name = "theme-color";
  meta2.content = String.prototype.isNullOrEmpty(environment.HTML_THEME_COLOR) ? "#000000" : environment.HTML_THEME_COLOR;
  document.head.appendChild(meta2)

  fetch("/assets/icons/" + safeCompanyAbb + "/manifest.webmanifest")
    .then(
      () => {

        icon1.rel = "apple-touch-icon";
        icon1.setAttribute("sizes", "180x180")
        icon1.href = "/assets/icons/" + safeCompanyAbb + "/apple-touch-icon.png"

        icon2.rel = "icon";
        icon2.type = "image/png";
        icon2.setAttribute("sizes", "32x32")
        icon2.href = "/assets/icons/" + safeCompanyAbb + "/favicon-32x32.png"

        icon3.rel = "icon";
        icon3.type = "image/png";
        icon3.setAttribute("sizes", "16x16")
        icon3.href = "/assets/icons/" + safeCompanyAbb + "/favicon-16x16.png"

        manifest.href = "/assets/icons/" + safeCompanyAbb + "/manifest.webmanifest"

        icon4.rel = "mask-icon"
        icon4.href = "/assets/icons/" + safeCompanyAbb + "/safari-pinned-tab.svg"
        icon4.setAttribute("color", String.prototype.isNullOrEmpty(environment.SAFARI_COLOR) ? "#000000" : environment.SAFARI_COLOR)

        document.head.appendChild(icon1)
        document.head.appendChild(icon2)
        document.head.appendChild(icon3)
        document.head.appendChild(icon4)
        document.head.appendChild(manifest)
      }
    )
    .catch(
      () => {
        icon1.rel = "apple-touch-icon";
        icon1.setAttribute("sizes", "180x180")
        icon1.href = "/assets/icons/default/apple-touch-icon.png"

        icon2.rel = "icon";
        icon2.type = "image/png";
        icon2.setAttribute("sizes", "32x32")
        icon2.href = "/assets/icons/default/favicon-32x32.png"

        icon3.rel = "icon";
        icon3.type = "image/png";
        icon3.setAttribute("sizes", "16x16")
        icon3.href = "/assets/icons/default/favicon-16x16.png"

        manifest.href = "/assets/icons/default/manifest.webmanifest"

        icon4.rel = "mask-icon"
        icon4.href = "/assets/icons/default/safari-pinned-tab.svg"
        icon4.setAttribute("color", "#000000")

        document.head.appendChild(icon1)
        document.head.appendChild(icon2)
        document.head.appendChild(icon3)
        document.head.appendChild(icon4)
        document.head.appendChild(manifest)
      }
    )
}).catch(err => console.error(err));
