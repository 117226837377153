import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralRoutingModule } from './general-routing.module';
import { TranslationModule } from './translations/translations.module';

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    GeneralRoutingModule
  ],
  declarations: []
})
export class GeneralModule { }
