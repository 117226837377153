/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateTabRequest { 
    tabId?: string | null;
    recipientId?: string | null;
    documentId?: string | null;
    tabLabel?: string | null;
    tabTypeId?: string | null;
    tabInitialValue?: string | null;
    tabIsRequired?: boolean | null;
    tabIsReadOnly?: boolean | null;
    tabPlacementFormFieldName?: string | null;
    tabPlacementAnchor?: string | null;
    tabPlacementPage?: number | null;
    tabPlacementOffsetXPts?: number | null;
    tabPlacementOffsetYPts?: number | null;
    tabPlacementWidth?: number | null;
    tabPlacementHeight?: number | null;
    tabsLogUserId?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
    readonly spolloNullIsValue_RecipientId?: boolean;
    readonly spolloNullIsValue_TabInitialValue?: boolean;
    readonly spolloNullIsValue_TabPlacementFormFieldName?: boolean;
    readonly spolloNullIsValue_TabPlacementAnchor?: boolean;
    readonly spolloNullIsValue_TabsLogUserId?: boolean;
}

