/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PRPVTranslationLocalization { 
    translationId: string;
    translationValue: string;
    isActive: boolean;
    timeStamp: string;
    languageId: string;
    isTranslated: boolean;
    isActiveLoc?: boolean | null;
    timeStampLoc?: string | null;
}

