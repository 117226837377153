

<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"
    [isSelectRowActive]="false" [version]="2" [showCustomPagination]="true" tableSearchType="box"
    (filterBox)="dashSvc.setPurpleTablePopover($event)" (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()"
    [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">

    <ng-template #tableBody>
      @for (data of listOfData; track i;let i = $index)
      {
      <ng-container>
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{data.item.refNumber}}</td>
          <td> {{data.item.portfolioId}} </td>
          <td>
            @if(data.item.counterPartyTypeId?.strEq("buyer"))
            {
            {{'sell_text'|translation:'SELL'}}
            }
            @else
            {
            {{'buy_text'|translation:'BUY'}}
            }
          </td>
          <td> {{data.item.company}} </td>
          <td> {{data.item.counterPartyName}} </td>
          <td> {{data.item.portfolioYear}} </td>
          <td> {{formatNumber(data.item.transactionVolume)}}</td>
          <td> {{data.item.avgPrice}}</td>

          <td> {{((data.item.totalPrice! < 0) ? (data.item.totalPrice! * -1) : data.item.totalPrice ) | currency:
              data.item.originalCurrency! : 'symbol' : "1.2-4" }}</td>
          <td> {{data.item.transactionDate|date}}</td>
          <td> {{data.item.originalCurrency}} </td>
          <td nzAlign="center">
            <app-stella-table-button (click)="approveDeal(data.item.transactionId!)" buttonIcon="icon-check"
              toolTip="{{'approve_deal_table_button'|translation:'Approve Deal'}}"></app-stella-table-button>
            <app-stella-table-button (click)="viewDelivery(data.item!)" buttonIcon="icon-file"
              toolTip="{{'view_delivery_table_button'|translation:'View Delivery'}}"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-duplicate-1"
              toolTip="{{'duplicate_deal_table_button'|translation:'Duplicate Deal'}}"
              (click)="duplicateDeal(data.item.transactionId!)"> </app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-edit"
              toolTip="{{'edit_deal_table_button'|translation:'Edit Deal'}}"
              (click)="editDeal(data.item)"></app-stella-table-button>

            <app-stella-table-button buttonIcon="icon-agreement"
              toolTip="{{'deal_agreement_table_button'|translation:'Agreement Status'}}"
              (click)="viewAgreement(data.item)"></app-stella-table-button>
            <app-stella-table-button buttonIcon="icon-x-close"
              toolTip="{{'delete_deal_table_button'|translation:'Delete Deal'}}"
              (click)="deletePendingDeal(data.item.transactionId!)"></app-stella-table-button>
          </td>
        </tr>
      </ng-container>
      }
    </ng-template>
  </purple-table>
</ng-container>
