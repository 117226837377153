import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { User, UsersService, UserTableItem, BackOfficeUsersService, UserTableItemPurpleTableResponse } from 'src/core-modules/sdk/api';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { EditUserComponent } from '../modals/edit-user/edit-user.component';
import { PurpleTranslationPipe, PurpleTableSearchGroupGroup, PurpleTableComponent, PurpleTableSelectFilter, PurpleLoaderService, PurpleTableBoxColumn } from 'purple-lib';
import { environment } from 'src/environments/environment';
import { EditEmployeeComponent } from '../modals/edit-employee/edit-employee.component';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { PurpleApiProxyService } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private mesSvc: NzMessageService, private tranPipe: PurpleTranslationPipe, private userSvc: UsersService, private loaderSvc: PurpleLoaderService,
    public dashSvc: DashboardLayout2Service, public backUserSvc: BackOfficeUsersService, private tsvc: AppTranslationService, private modal: NzModalService,
    private apiProxySvc: PurpleApiProxyService) {
  }


  ngAfterViewInit(): void {
    const pageName = "employee";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Anagrafica Dipendenti', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista dei dipendenti con i rispettivi ruoli', []))
  }

  @ViewChild(PurpleTableComponent) table!: PurpleTableComponent;

  sub: Subject<void> = new Subject();
  listOfData: Array<UserTableItemPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "firstname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'firstname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_firstname', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_firstname_placeholder', 'Nome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("user_table_header_lastname", "Cognome"),
      dbKey: "lastname",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'lastname',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_lastname', 'Cognome', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_lastname_placeholder', 'Cognome del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("user_table_header_email", "Email"),
      dbKey: "email",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'email',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_email', 'Email', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_email_placeholder', 'Email del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: 'like'
    },
    {
      name: this.tranPipe.transform("user_table_header_phone", "Telefono"),
      dbKey: "phone",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'phone',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_phone', 'Telefono', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_phone_placeholder', 'Telefono del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("employee_table_header_roles", "Ruoli"),
      dbKey: "roles",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "190px"
    },
    {
      name: this.tranPipe.transform("user_table_header_tax_id", "Codice fiscale"),
      dbKey: "taxId",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'input-text',
        fieldControlName: 'taxId',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_tax_id', 'Codice fiscale', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_tax_id_placeholder', 'Codice fiscale del cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_registration_date", "Data Registrazione"),
      dbKey: "registrationDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'registrationDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_registration_date', 'Data Registrazione', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_registration_date_placeholder', 'Data Registrazione cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_gender", "Sesso"),
      dbKey: "genderId",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'genderId',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_gender_id', 'Sesso', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_gender_id_placeholder', 'Sesso cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Uomo',
                value: 'M'
              },
              {
                label: 'Donna',
                value: 'F'
              },
              {
                label: 'Altro',
                value: 'O'
              }
            ]
          }
        ]
      },
      operatorType: "=",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_email_verified", "Email verificata"),
      dbKey: "emailVerified",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'select-local',
        fieldControlName: 'emailVerified',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('table_filter_email_verified', 'Email verificata', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_email_verified_placeholder', 'Email verifiata cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'Si',
                value: 1
              },
              {
                label: 'No',
                value: 0
              }
            ]
          }
        ]
      },
      operatorType: "=",
      hiddenColumn: true
    },
    {
      name: this.tranPipe.transform("user_table_header_birthdate", "Data di nascita"),
      dbKey: "birthDate",
      hasReorder: true,
      hasFilter: true,
      filterField: {
        fieldType: 'date-range',
        fieldControlName: 'birthDate',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('table_filter_birthdate', 'Data di nascita', []),
        fieldPlaceholder: this.tranPipe.transform('table_filter_birthdate_placeholder', 'Data di nascita cliente', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
          { id: 'disabledDate', value: undefined }
        ]
      },
      operatorType: "date between",
      hiddenColumn: true
    },
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "type",
      filterValue: "employees"
    }];
    args.switchOn = false;

    return this.backUserSvc.getUsersTable(args.culture, args)
  }

  checkIfItemIsSelected = (selectedItems: UserTableItem[], item: UserTableItem) => {
    const find = selectedItems.findIndex(f => f.userId.strEq(item.userId));
    return find > -1;
  }

  onItemChecked(obj: UserTableItemPurpleTableResponse, checked: boolean): void {
    this.table.updateCheckedSet(obj, checked);
    this.table.refreshCheckedStatus();
  }

  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")
    this.dashSvc.dashMainItems?.push(
      {
        id: "bt",
        type: "button",
        buttonText: this.tranPipe.transform("table_add_new_item_button", "Aggiungi {0}", ["Dipendente"]),
        buttonFunction: () => { this.editEmployee(undefined) }
      })
    this.loaderSvc.removeRequest("internal-loader", 350)
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  editUser(user: UserTableItem | undefined) {
    this.modal.create<EditUserComponent, { userFull: UserTableItem | undefined, roles: string[] | undefined }>({
      nzContent: EditUserComponent,
      nzData: {
        userFull: user,
        roles: ["admin"]
      },
      nzTitle: user == undefined ? this.tranPipe.transform("table_item_edit_modal_title_add_user", "Aggiungi utente", []) : this.tranPipe.transform("table_item_edit_modal_title_update_user", "Modifica utente", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (user: User | undefined) => {
      if (user != undefined) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  editEmployee(user: UserTableItem | undefined) {
    this.modal.create<EditEmployeeComponent, { employeeFull: UserTableItem | undefined, isEditUser: boolean }>({
      nzContent: EditEmployeeComponent,
      nzData: {
        employeeFull: user,
        isEditUser: false
      },
      nzTitle: user == undefined ? this.tranPipe.transform("table_item_edit_modal_title_add_employee", "Aggiungi dipendente", []) : this.tranPipe.transform("table_item_edit_modal_title_update_employee", "Modifica dipendente", []),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean | undefined) => {
      if (refresh ?? false) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  deleteEmployee(user: UserTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("delete_employee_title", "Rimuovere {0} dai dipendenti?", [user.firstName + " " + user.lastName]),
      nzContent: this.tranPipe.transform("delete_employee_subtitle", "<b>{0}</b> verrà rimosso dai dipendenti", [user.firstName + " " + user.lastName]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('delete_employee_button', 'Rimuovi', []),
      nzOnOk: async () =>
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backUserSvc.deleteEmployee(this.tsvc.currentLanguage.value, {
          userId: user.userId
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success(this.tranPipe.transform("delete_employee_ok_response", "<b>{0}</b> rimosso dei dipendenti", [user.firstName + " " + user.lastName]), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modal.closeAll();
          this.refreshTableRowsNumber++;
        })
    },
    )
  }

  resetPassword(user: UserTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("reset_user_password_admin_title", "Reset password {0}", [user.firstName + " " + user.lastName]),
      nzContent: this.tranPipe.transform("reset_user_password_admin_subtitle", "La richiesta di reset password per l'utente <b>{0}</b> verrà inviata al seguente indirizzo email: <b>{1}</b>", [user.firstName + " " + user.lastName, user.email!]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('reset_user_password_admin_button', 'Resetta', []),
      nzOnOk: async () =>
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.userSvc.requestResetPassword(this.tsvc.currentLanguage.value, {
          email: user.email
        }), false, "internal-loader", 500, undefined, undefined, () => {
          this.mesSvc.success(this.tranPipe.transform("reset_user_password_admin_ok_response", "Email di richiesta reset password inviata correttamente"), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modal.closeAll();
        })
    });
  }


  verifyEmail(user: UserTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("verify_email_title", "Verifica email {0}", [user.firstName + " " + user.lastName]),
      nzContent: this.tranPipe.transform("verify_email_subtitle", "Verifica la mail dell'utente: {0}", [user.firstName + " " + user.lastName, user.email!]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('verify_email_button', 'Verifica', []),
      nzOnOk: async () => {
        await this.apiProxySvc.makeRequestErrorMessage<any>(() => 
        
        this.backUserSvc.verifyUserEmailAdmin(this.tsvc.currentLanguage.value,
          {
            userId: user.userId
          }), false, "internal-loader", 500, undefined, environment.MESSAGE_DURATION, () => {
            this.mesSvc.success(this.tranPipe.transform("verify_email_ok_response", "Email verificata con successo"), {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.refreshTableRowsNumber++;
            this.modal.closeAll();
          });
      },
    });
  }
}
