import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Helper, PurpleSelectFilter, PurpleTranslationPipe } from 'purple-lib';
import { purpleFormFieldsGroup } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { environment } from 'src/environments/environment';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { BackOfficeUsersService, RoleTableItem } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {

  originalEditItem!: EditRoleItem;
  newObj!: EditRoleItem;
  isNew: boolean = false;

  validateForm: FormGroup = this.fb.group({});
  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;
  disableForm: boolean = false;
  isLoading: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) private roleFull: RoleTableItem | undefined, private modalRef: NzModalRef, private fb: FormBuilder, private tranPipe: PurpleTranslationPipe, private apiProxySvc: PurpleApiProxyService,
    private mesSvc: NzMessageService, private backUserSvc: BackOfficeUsersService, private tsvc: AppTranslationService) { }


  async ngOnInit(): Promise<void> {

    const roleLevels = this.roleFull?.roleLevels ?? [];

    this.originalEditItem = {
      roleId: this.roleFull?.roleId ?? undefined,
      roleName: this.roleFull?.roleName ?? undefined,
      roleDescription: this.roleFull?.roleDescription ?? undefined,
      isStaffRole: this.roleFull?.isStaffRole ?? undefined,
      roleLevelId: roleLevels.length > 0 ? roleLevels[0].roleLevelId : undefined
    }

    this.isNew = this.roleFull == undefined;
    this.newObj = Helper.storeNewObj(this.originalEditItem);

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'input-text',
            fieldControlName: 'name',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_role_name', 'Nome', []),
            fieldPlaceholder: this.tranPipe.transform('edit_role_name_placeholder', 'Nome ruolo', []),
            fieldValue: this.originalEditItem.roleName,
            fieldIsRequired: true,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'textarea',
            fieldControlName: 'description',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('edit_role_description', 'Descrizione', []),
            fieldPlaceholder: this.tranPipe.transform('edit_role_description_placeholder', 'Descrizione ruolo', []),
            fieldValue: this.originalEditItem.roleDescription,
            fieldIsRequired: false,
            fieldOptions: [{ id: 'rowNumber', value: 5 }],
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'toggle',
            fieldControlName: 'isStaffRole',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_role_is_staff_role', 'Ruolo Staff', []),
            fieldPlaceholder: "",
            fieldValue: this.originalEditItem.isStaffRole,
            fieldIsRequired: true,
            fieldOptions: [
              { id: 'checkText', value: this.tranPipe.transform('switch_yes', 'Si', []) },
              { id: 'unCheckText', value: this.tranPipe.transform('switch_no', 'No', []) },
            ],
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          {
            fieldType: 'select',
            fieldControlName: 'roleLevel',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('edit_role_role_level', 'Livello Ruolo', []),
            fieldPlaceholder: this.tranPipe.transform('edit_role_role_level_placeholder', 'Seleziona il livello associato al ruolo', []),
            fieldValue: this.originalEditItem.roleLevelId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.roleLevelScrollFunction },
              { id: 'searchFunction', value: this.roleLevelSearchFunction },
              { id: 'searchByIdFunction', value: this.roleLevelSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          }
        ]
      }
    ]
  }


  async editRole() {
    this.isLoading = true;
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    if (this.validateForm.valid) {
      this.newObj.roleName = this.validateForm.controls["name"].value;
      this.newObj.roleDescription = this.validateForm.controls["description"].value;
      this.newObj.isStaffRole = this.validateForm.controls["isStaffRole"].value;
      this.newObj.roleLevelId = this.validateForm.controls["roleLevel"].value;

      if (Helper.checkIfDoChanges(this.newObj, this.originalEditItem)) {

        await this.apiProxySvc.makeRequestErrorMessage<Boolean>(() => this.backUserSvc.addOrUpdateRole(this.tsvc.translationLanguage.value, {
          isStaffRole: this.newObj.isStaffRole!,
          roleDescription: this.newObj.roleDescription,
          roleName: this.newObj.roleName!,
          roleId: this.newObj.roleId,
          roleLevelIds: [this.newObj.roleLevelId!],
          isNew: this.isNew
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<Boolean>) => {
          let text: string;
          if (this.isNew) {
            text = this.tranPipe.transform('edit_role_add', 'Ruolo aggiunto con successo')
          } else {
            text = this.tranPipe.transform('edit_role_update', 'Ruolo aggiornato con successo')
          }
          this.mesSvc.success(text, {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modalRef.destroy(res.data);
        })

      }
    }
    this.isLoading = false
  }


  //ScrollFunction
  roleLevelScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getRoleLevelsSelect(args.culture, args)
  }
  //SearchFunction
  roleLevelSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getRoleLevelsByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  roleLevelSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getRoleLevelsByIdSelect(args.culture, args)
  }
}

class EditRoleItem {
  roleId: string | undefined;
  roleName: string | undefined;
  roleDescription?: string | undefined;
  isStaffRole: boolean | undefined;
  roleLevelId: number | undefined;
}
