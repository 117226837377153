import { Component, Input, OnInit } from '@angular/core';
import { PurpleApiProxyService, PurpleTranslationPipe } from 'purple-lib';
import { CounterPartyRow, StellaService } from 'src/core-modules/sdk/api';
import { EditCounterpartComponent } from '../../backoffice/modals/edit-counterpart/edit-counterpart.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MergeCounterpartyComponent } from '../../backoffice/modals/merge-counterparty/merge-counterparty.component';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { ViewDealsComponent } from '../../backoffice/modals/view-deals/view-deals.component';
import { MoveDealsComponent } from '../../backoffice/modals/move-deals/move-deals.component';

import { NZ_DRAWER_DATA, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
@Component({
  selector: 'app-sub-table',
  templateUrl: './sub-table.component.html',
  styleUrls: ['./sub-table.component.scss']
})
export class SubTableComponent implements OnInit {

  constructor(private modal: NzModalService, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, private mesSvc: NzMessageService, private tsvc: AppTranslationService,
    public dashSvc: DashboardLayout2Service, public apiProxySvc: PurpleApiProxyService, private drawer: NzDrawerService) { }


  @Input() items: Array<CounterPartyRow> | undefined | null;
  @Input() refresh!: (() => void);
  @Input() isFinanceView!: boolean;
  @Input() parentId!: string;


  listOfData: Array<childrenRow> = [];

  expanded: boolean = false;
  show: boolean = false;


  ngOnInit() {

    this.items!.forEach(child => {
      this.listOfData?.push(
        {
          item: child,
          expanded: false
        })

    });
    this.show = true;
  }

  editCounterpart(counterpart: any) {
    this.modal.create<EditCounterpartComponent, { counterParty: CounterPartyRow | undefined, isFinanceView: boolean }>({
      nzContent: EditCounterpartComponent,
      nzTitle: counterpart == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0}", ["counterparty"]) : this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0}", ["counterparty"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
      nzData: {
        counterParty: undefined,
        isFinanceView: this.isFinanceView
      }
    })
  }

  async addRelationships(counterpart: CounterPartyRow | undefined) {


    console.log("data", counterpart?.children)

    this.modal.create<MergeCounterpartyComponent, { parentCounterPartyId: string | undefined, childrenCounterPartyId: string[] | undefined, isMerge: boolean }>({
      nzContent: MergeCounterpartyComponent,
      nzTitle: this.tranPipe.transform("table_item_add_relationships_modal_title_new", "Add  {0} Realtionships", ["CounterParty"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzData: { parentCounterPartyId: counterpart?.counterPartyId, childrenCounterPartyId: (counterpart?.children ?? []).map(m => m.counterPartyId), isMerge: false },
      nzMaskClosable: false
    }).afterClose.subscribe((value: boolean | undefined) => {

      if (value)
        this.refresh();
    })
  }


  hasChildrenRelationsip(hasChildren: boolean, expanded: boolean) {
    if (hasChildren && !expanded)
      return this.tranPipe.transform("relationship_group", "Group");
    else if (hasChildren && expanded)
      return this.tranPipe.transform("relationship_parent", "Parent");
    else
      return this.tranPipe.transform("relationship_child", "Child");
  }

  sumDeals(buyer: number | null | undefined, seller: number | null | undefined, broker: number | null | undefined, others: number | null | undefined) {

    var res = (buyer ?? 0) + (seller ?? 0) + (broker ?? 0) + (others ?? 0);

    var split = res == 0 ? (0 + "") : (res + " ( ");

    if (buyer != null && buyer > 0) {
      split += buyer + "BUY "
    }

    if (seller != null && seller > 0) {
      split += seller + "S "
    }

    if (broker != null && broker > 0) {
      split += broker + "B "
    }
    if (others != null && others > 0) {
      split += others + "O "
    }

    if (res > 0)
      split += ")";

    return split;


  }

  removeChild(counterpart: CounterPartyRow) {

    this.modal.create({
      nzTitle: this.tranPipe.transform("delete_counterparty_relationship_title", "Eliminare {0} dalle relazioni", [counterpart?.counterPartyName]),
      nzContent: this.tranPipe.transform("delete_counterparty_relationship_subtitle", "Vuoi davvero eliminare la Counter Party <b> {0} </b> dalle relazioni?", [counterpart?.counterPartyName]),
      nzWidth: '600px',
      nzOkText: "Elimina",
      nzClassName: 'purple-simple-modal',
      nzFooter: [
        {
          label: "Elimina",
          type: "primary",
          onClick: async () => await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.removeCounterPartsRelatioship(this.tsvc.currentLanguage.value, {
            parentCounterpartyId: this.parentId,
            childCounterpartyId: counterpart!.counterPartyId
          }), false, "internal-loader", 500, undefined, undefined, () => {
            this.mesSvc.success(this.tranPipe.transform("delete_counterparty_relationship_success", "Relazione elimiata correttamente"), {
              nzDuration: environment.MESSAGE_DURATION
            });
            this.refresh();
            this.modal.closeAll();
          })
        }
      ]
    })

  }


  viewDeals(counterpart: CounterPartyRow) {

    var width = window.innerWidth;

    width = this.dashSvc.isMenuCollapsed ? width - 100 : width - 270;

    this.drawer.create<ViewDealsComponent, string>({
      nzContent: ViewDealsComponent,
      nzTitle: this.tranPipe.transform("table_item_view_deal_modal_title_new", "View {0} {1}", [counterpart.counterPartyName, "Deals"]),
      nzWidth: width + 'px',
      nzData: counterpart.counterPartyId,
      nzMaskClosable: false,
      nzPlacement: 'right'
    }).afterClose.subscribe(() => {
    })
  }



  moveDeals(counterpart: CounterPartyRow) {
    this.modal.create<MoveDealsComponent, string>({
      nzContent: MoveDealsComponent,
      nzTitle: this.tranPipe.transform("table_item_move_deal_modal_title_new", "Move {0} {1}", [counterpart.counterPartyName, "Deals"]),
      nzWidth: '1200px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzData: counterpart.counterPartyId,
      nzMaskClosable: false
    }).afterClose.subscribe((value: boolean | undefined) => {
      if (value)
        this.refresh();
      this.modal.closeAll();
    })
  }


  formatNumber(n: number | null | undefined): string {

    if (n == null)
      n = 0;

    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }


}


export interface childrenRow {
  item: CounterPartyRow;
  expanded: boolean;
}
