/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PRPTemplatesDocumentNullable { 
    documentTemplateId?: string | null;
    documentTemplateName?: string | null;
    documentId?: string | null;
    envelopeId?: string | null;
    documentSequence?: number | null;
    documentName?: string | null;
    documentContentId?: string | null;
    documentContentCrc?: string | null;
    documentContentVersion?: number | null;
    signedDocumentId?: string | null;
    signedDocumentCrc?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
}

