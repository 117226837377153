import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { PurpleStorageService, PurpleTranslationPipe, psMenuItem, showMenu } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { DashboardLayout2Service } from './dashboard-layout-2.service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard-layout-2',
  templateUrl: './dashboard-layout-2.component.html',
  styleUrls: ['./dashboard-layout-2.component.scss']
})

export class DashboardLayout2Component implements OnInit, OnDestroy {

  constructor(public strSvc: PurpleStorageService, public dashSvc: DashboardLayout2Service, private route: ActivatedRoute,
    public tsvc: AppTranslationService, private tranPipe: PurpleTranslationPipe, public authSvc: AuthenticationService) { }

  isOpen: boolean = true;

  pgTitle: string = "";
  pgSubTitle: string = "";
  activeMenus: showMenu[] = environment.ACTIVE_MENU;
  subs: Subject<void> = new Subject();


  menuSections: psMenuItem[] = [
    {
      id: "stella-backoffice",
      name: this.tranPipe.transform('menu_stella_backoffice', 'Back office', []),
      relativeLink: "stella-backoffice",
      collapseIconName: "idcard",
      subMenus: [
        {
          id: "counterparties",
          iconName: "icon-counterpart_icon",
          iconType: 'custom-font',
          iconRotate: 90,
          name: this.tranPipe.transform('menu_stella_backoffice_counterparties', 'Counterparties', []),
          relativeLink: "counterparties"
        },
        {
          id: "brokers",
          iconName: "icon-brokers_icon",
          iconType: "custom-font",
          name: this.tranPipe.transform('menu_stella_backoffice_brokers', 'Brokers', []),
          relativeLink: "brokers"
        },
        {
          id: "exchange-rate",
          iconName: "icon-exchangerate_icon",
          iconType: "custom-font",
          name: this.tranPipe.transform('menu_stella_backoffice_exchange_rate', 'Exchange Rate', []),
          relativeLink: "exchange-rate"
        },
        {
          id: "internal-alerts",
          iconName: "icon-internalalerts_icon",
          iconType: "custom-font",
          name: this.tranPipe.transform('menu_stella_backoffice_internal_alerts', 'Internal Alerts', []),
          relativeLink: "internal-alerts"
        }
      ]
    },
    {
      id: "stella-frontoffice",
      name: this.tranPipe.transform('menu_stella_frontoffice', 'Front office', []),
      relativeLink: "stella-frontoffice",
      collapseIconName: "idcard",
      subMenus: [
        {
          id: "deal-ticket",
          iconName: "icon-dealslog_icon",
          iconType: "custom-font",
          name: this.tranPipe.transform('menu_stella_frontoffice_counterparties', 'Deal ticket', []),
          relativeLink: "deal-ticket"
        },
        {
          id: "portfolio",
          iconName: "icon-portfolio_icon",
          iconType: "custom-font",
          name: this.tranPipe.transform('menu_stella_frontoffice_portfolio', 'Portfolio', []),
          relativeLink: "portfolio"
        },
        {
          id: "mtm-prices",
          iconName: "icon-mtmprices_icon",
          iconType: "custom-font",
          name: this.tranPipe.transform('menu_stella_frontoffice_mtm_prices', 'MTM prices', []),
          relativeLink: "mtm-prices"
        },
        {
          id: "market-watch",
          iconType: "custom-font",
          iconName: "icon-marketwatch_icon",
          name: this.tranPipe.transform('menu_stella_frontoffice_market_watch', 'Market watch', []),
          relativeLink: "market-watch"
        },
        {
          id: "pending-deals",
          iconType: "custom-font",
          iconName: "icon-pendingdeals_icon",
          name: this.tranPipe.transform('menu_stella_frontoffice_pending_deals', 'Pending Deals', []),
          relativeLink: "pending-deals"
        }
      ]
    },
    {
      id: "stella-reporting",
      name: this.tranPipe.transform('menu_stella_reporting', 'Reporting', []),
      relativeLink: "stella-reporting",
      collapseIconName: "idcard",
      subMenus:
        [
          {
            id: "overall-by-product",
            iconName: "icon-overallbyproduct_icon",
            iconType: "custom-font",
            name: this.tranPipe.transform('menu_stella_reporting_overall_by_product', 'Overall by Product', []),
            relativeLink: "overall-by-product"
          },
          {
            id: "overall-by-technology",
            iconName: "icon-overallbytechnologies_icon",
            iconType: "custom-font",
            name: this.tranPipe.transform('menu_stella_reporting_overall_by_technology', 'Overall by Technology', []),
            relativeLink: "overall-by-technology"
          },
          {
            id: "wAvg-price",
            iconName: "icon-wavgprices_icon",
            iconType: "custom-font",
            name: this.tranPipe.transform('menu_stella_reporting_wAvg_price', 'wAvg Price', []),
            relativeLink: "wAvg-price"
          }
        ]


    },
    {
      id: "administration",
      name: this.tranPipe.transform('menu_administration', 'Amministrazione', []),
      collapsedName: this.tranPipe.transform('menu_collapsed_administration', 'Amminist.', []),
      relativeLink: "administration",
      collapseIconName: "team",
      subMenus: [
        {
          id: "customers",
          iconName: "user",
          name: this.tranPipe.transform('menu_users_customers', 'Clienti', []),
          relativeLink: "customers"
        },
        {
          id: "employees",
          iconName: "team",
          name: this.tranPipe.transform('menu_users_employees', 'Dipendenti', []),
          relativeLink: "employees"
        },
        {
          id: "roles",
          iconName: "tool",
          name: this.tranPipe.transform('menu_users_roles', 'Ruoli', []),
          relativeLink: "roles"
        },
        {
          id: "role-levels",
          iconName: "ordered-list",
          name: this.tranPipe.transform('menu_users_role_levels', 'Livello Ruoli', []),
          relativeLink: "role-levels"
        }
      ]
    },
    {
      id: "notification",
      name: this.tranPipe.transform('menu_private_sale_notifications_mail', 'Notifiche', []),
      relativeLink: "notification",
      collapseIconName: "message",
      subMenus: [
        {
          id: "mail-templates",
          iconName: "message",
          name: this.tranPipe.transform('menu_notification_mail_templates', 'Mail Template', []),
          relativeLink: "mail-templates"
        },
        {
          id: "smtp-servers",
          iconName: "tool",
          name: this.tranPipe.transform('menu_notification_smtp_servers', 'Configurazioni Smtp', []),
          relativeLink: "smtp-servers"
        },
        {
          id: "notification-templates",
          iconName: "mobile",
          name: this.tranPipe.transform('menu_notification_notification_templates', 'Template Notifiche', []),
          relativeLink: "notification-templates"
        },
        {
          id: "notification-exceptions",
          iconName: "warning",
          name: this.tranPipe.transform('menu_notification_exceptions', 'Eccezioni', []),
          relativeLink: "notification-exceptions"
        }
      ]
    },
    {
      id: "general",
      name: this.tranPipe.transform('menu_general', 'Generale', []),
      relativeLink: "general",
      collapseIconName: "tool",
      subMenus: [
        {
          id: "translations",
          iconName: "translation",
          name: this.tranPipe.transform('menu_general_translations', 'Traduzioni', []),
          relativeLink: "translations"
        }
      ]
    }
  ]

  ngOnDestroy() {
    this.subs.next();
    this.subs.complete();
  }

  async ngOnInit(): Promise<void> {
    var minLevel = this.authSvc.currentUser?.roleLevel ?? environment.DEFAULT_MENU_LEVEL;

    this.activeMenus = this.activeMenus.filter(f => f.maxlevel >= minLevel);
    this.route.queryParams.subscribe(async params => {
      this.tsvc.translationLanguage.next(params['translationLanguage'] ?? this.tsvc.currentLanguage.value);
    });

    this.dashSvc.pageTitle.pipe(takeUntil(this.subs)).subscribe(message => this.pgTitle = message ?? "")
    this.dashSvc.pageSubTitle.pipe(takeUntil(this.subs)).subscribe(message => this.pgSubTitle = message ?? "")
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getDashHeight();
      this.getDashWidth();


    }, 100);

  }

  onResize() {
    //console.log("On resize")
    this.getDashHeight();
    this.getDashWidth();
  }

  getDashHeight() {
    const wh = window.innerHeight;
    const natH = wh - 240;
    this.dashSvc.dashboardHeight = natH + 'px';
    this.dashSvc.dashboardHeightNumber.next(natH);
  }

  getDashWidth() {
    const ww = window.innerWidth;
    //console.log("WW: ", ww)
    if (ww < 1600) {
      this.dashSvc.isMenuCollapsed = true;
    } else {
      this.dashSvc.isMenuCollapsed = false;
    }
    const natW = ww - (this.dashSvc.isMenuCollapsed ? 310 : 140);
    this.dashSvc.dashboardWidth = natW + 'px';
  }

  contentClick() {
    this.dashSvc.isUserMenuOpen = false;
  }
}
