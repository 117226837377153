import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, input } from '@angular/core';
import { PurpleTranslationPipe, purpleFormFieldsGroup, PurpleSelectFilter, Helper, PurpleApiProxyService, purpleFormField } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { BackOfficeUsersService, CounterPartyRow, DeliveryRequest, ProductAttributesGrouped, StellaService, Vintages } from 'src/core-modules/sdk/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { Deliveries, Delivery } from '../../modals/deal-ticket-delivery/deal-ticket-delivery.component';
import { NZ_DRAWER_DATA, NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { ReviewDealAndDeliveryComponent } from '../../modals/review-deal-and-delivery/review-deal-and-delivery.component';
import { Router } from '@angular/router';
import { EditCounterpartComponent } from 'src/app/dashboard/stella/backoffice/modals/edit-counterpart/edit-counterpart.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-deal-ticket-summary',
  templateUrl: './deal-ticket-summary.component.html',
  styleUrls: ['./deal-ticket-summary.component.scss']
})
export class DealTicketSummaryComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service, private router: Router,
    private fb: FormBuilder, private backUserSvc: BackOfficeUsersService, private apiProxySvc: PurpleApiProxyService, private modal: NzModalService, private tsvc: AppTranslationService, private drawer: NzDrawerService) { }


  @Input() dealTicket: DealTicket | undefined;
  /*   @Input() buySellTexts!: string[];
    @Input() thisOrThatText!: string[]; */


  buySellTexts: string[] =
    [
      this.tranPipe.transform("deal_type_buy_lable", "Buy"),
      this.tranPipe.transform("deal_type_sell_lable", "Sell")
    ]

  thisOrThatText: string[] =
    [
      this.tranPipe.transform("this_or_that_yes", "Yes"),
      this.tranPipe.transform("this_or_that_no", "No")
    ]


  @Input() clonedDeliveries: Delivery[] | undefined;

  @Output() saveDeal = new EventEmitter<DealTicket>();
  @Output() goBack = new EventEmitter();


  showMoreDetails: boolean = false;
  showThisOrThat: boolean = false;

  purpleFormFieldGroups1: purpleFormFieldsGroup[] | undefined;
  purpleFormFieldGroups2: purpleFormFieldsGroup[] | undefined;
  purpleFormFieldGroups3: purpleFormFieldsGroup[] | undefined;

  validateForm: FormGroup = this.fb.group({});
  dynamicValidateForm: FormGroup = this.fb.group({});

  selectedDealTypeBuy: boolean = true;
  selectedValueStandard: boolean = true;
  selectedValuePrepayment: boolean = false;
  selectedValueFullOfftake: boolean = false;

  tradersSearchFilter: PurpleSelectFilter[] = [
    { propertyName: "traders", filterValues: ['true'] }
  ];
  disableForm: boolean = false;
  disableThisOrThatBuy: boolean = false;

  editDeal!: DealTicket;
  dealDate: Date | undefined = undefined;
  deliveryDate: Date | undefined = undefined;

  returnValue: DealTicketFull | undefined;

  created: boolean = false;

  codeList: string[] = [];

  currentControls: string[] = [];

  b2bshow: boolean = true;

  ngOnInit() {

    if (this.dealTicket == undefined)
      this.dealTicket =
      {
        companyId: undefined,
        traderId: undefined,
        productIds: undefined,
        portfolioYear: undefined,
        dealBuySeller: undefined,
        dealDate: undefined,
        counterPartyId: undefined,
        brokerId: undefined,
        brokerFee: undefined,
        brokerCurrency: undefined,
        portfolioId: undefined,
        fields: {}
      }


    this.editDeal = Helper.storeNewObj(this.dealTicket);

    this.selectedDealTypeBuy = (this.editDeal.dealBuySeller?.strEq("Buy") || this.editDeal.dealBuySeller == undefined) ? true : false;

    this.purpleFormFieldGroups1 = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'company',
            fieldBootstrapColumn: 3,
            fieldName: this.tranPipe.transform('company_deal_details', 'Company', []),
            fieldPlaceholder: this.tranPipe.transform('company_deal_details_placeholder', 'Nvalue AG', []),
            fieldValue: this.editDeal.companyId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.companiesScrollFunction },
              { id: 'searchFunction', value: this.companiesSearchFunction },
              { id: 'searchByIdFunction', value: this.companiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'trader',
            fieldBootstrapColumn: 3,
            fieldName: this.tranPipe.transform('trader_initials_deal_details', 'Trader', []),
            fieldPlaceholder: this.tranPipe.transform('trader_initials_deal_details_placeholder', 'Trader', []),
            fieldValue: this.editDeal.traderId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.tradersScrollFunction },
              { id: 'searchFunction', value: this.tradersSearchFunction },
              { id: 'searchByIdFunction', value: this.tradersSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'select',
            fieldControlName: 'portfolioId',
            fieldBootstrapColumn: 3,
            fieldName: this.tranPipe.transform('portfolio_deal_detail', 'Portfolio', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_deal_detail_placeholder', 'Portfolio', []),
            fieldValue: this.editDeal.portfolioId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.portfolioScrollFunction },
              { id: 'searchFunction', value: this.portfolioSearchFunction },
              { id: 'searchByIdFunction', value: this.portfolioSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ]
          },
          {
            fieldType: 'date',
            fieldControlName: 'portfolioYear',
            fieldBootstrapColumn: 3,
            fieldName: this.tranPipe.transform('portfolio_year_deal_detail', 'Portfolio year', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_year_deal_detail_placeholder', 'Year', []),
            fieldValue: this.editDeal.portfolioYear,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'pickerMode', value: 'year' }]
          }
        ]
      }
    ]

    this.purpleFormFieldGroups2 = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'date',
            fieldControlName: 'date',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('date_deal_details', 'Date', []),
            fieldPlaceholder: this.tranPipe.transform('date_deal_details_placeholder', 'Date', []),
            fieldValue: this.editDeal.dealDate != undefined ? new Date(this.editDeal.dealDate) : (new Date()),
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [{ id: 'dateFormat', value: "yyyy-MM-dd" }],
            fieldCustomClass: "deal-date"
          },
          {
            fieldType: 'select-multiple',
            fieldControlName: 'product',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('product_deal_detail', 'Product', []),
            fieldPlaceholder: this.tranPipe.transform('product_deal_detail_placeholder', 'Product', []),
            fieldValue: this.editDeal.productIds,
            fieldIsRequired: false,
            fieldIsDisabled: true,
            fieldPaddingLeft: true,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.productsScrollFunction },
              { id: 'searchFunction', value: this.productsSearchFunction },
              { id: 'searchByIdFunction', value: this.productsSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ],
            fieldCustomClass: "pr-select"
          },
          {
            fieldType: 'select',
            fieldControlName: 'counterparty',
            fieldBootstrapColumn: 4,
            fieldName: this.tranPipe.transform('counterparty_deal_detail', 'Counterparty', []),
            fieldPlaceholder: this.tranPipe.transform('counterparty_deal_detail_placeholder', 'Counterparty', []),
            fieldValue: this.editDeal.counterPartyId,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterPartiesScrollFunction },
              { id: 'searchFunction', value: this.counterPartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterPartiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: 0 },
            ],
            fieldCustomClass: "cp-select"
          }
        ]
      }
    ]
    this.getAllB2BCode();




    setInterval(() => {
      this.validateForm.controls["date"].valueChanges.subscribe((value: Date | undefined) => {

        if (value != undefined) {
          this.dealDate = value;
        }

      });

      if (this.dealTicket != undefined && this.dealTicket.portfolioId != undefined && this.created == false) {

        this.purpleFormFieldGroups3 =
          [
            {
              fieldGroupNumber: 1,
              fielGroupBootstrapColumn: 12,
              fieldGroupPaddingLeft: false,
              fieldGroupPaddingRight: false,
              formFieldFormGroup: this.dynamicValidateForm,
              formFields: this.showOtherInfos(this.editDeal.portfolioId!)
            }
          ]

        console.log("this.editDeal", this.editDeal)

        if (this.editDeal.portfolioId!.strEq("hfc-brk"))
          this.validateForm.controls["counterparty"].disable();

        else
          this.validateForm.controls["counterparty"].enable();

        this.showMoreDetails = true;
        this.created = true;
      }

      this.validateForm.controls["portfolioId"].valueChanges.subscribe((value: string | undefined) => {


        if (value != undefined) {
          this.editDeal!.portfolioId = value;

          this.purpleFormFieldGroups3 =
            [
              {
                fieldGroupNumber: 1,
                fielGroupBootstrapColumn: 12,
                fieldGroupPaddingLeft: false,
                fieldGroupPaddingRight: false,
                formFieldFormGroup: this.dynamicValidateForm,
                formFields: this.showOtherInfos(value)
              }
            ]

          if (value.strEq("hfc-brk"))
            this.validateForm.controls["counterparty"].disable();

          else {
            this.validateForm.controls["counterparty"].enable();
          }

          this.showMoreDetails = true;
        }
        else {
          this.editDeal!.portfolioId = value;
          this.showMoreDetails = false;
        }
      })

      if (this.dynamicValidateForm.controls["deliveryDate"] != undefined) {

        this.dynamicValidateForm.controls["deliveryDate"].valueChanges.subscribe((value: Date | undefined) => {
          this.deliveryDate = value;
        });
      }

    }, 200);
  }



  showOtherInfos(product: string) {


    var typeDisabled = !(product.strEq("hfc") || product.strEq("hfc-uk"));
    var fields: purpleFormField[] = [];
    this.disableThisOrThatBuy = false;
    switch (product) {
      case "special":
      case "internal":
      case "consulting":
        {
          fields = [
            {
              fieldType: 'input-text',
              fieldControlName: 'serviceName',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('service_name_deal_details', 'Service Name', []),
              fieldPlaceholder: this.tranPipe.transform('service_name_deal_details_placeholder', 'Service Name', []),
              fieldValue: this.editDeal?.fields["serviceName"],
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true
            },
            {
              fieldType: 'select',
              fieldControlName: 'linkedProduct',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('product_deal_detail', 'Linked Product', []),
              fieldPlaceholder: this.tranPipe.transform('product_deal_detail_placeholder', 'Product', []),
              fieldValue: this.editDeal?.fields["linkedProduct"],
              fieldIsRequired: product.strEq("special"),
              fieldIsDisabled: false,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [
                { id: 'scrollFunction', value: this.productsScrollFunction },
                { id: 'searchFunction', value: this.productsSearchFunction },
                { id: 'searchByIdFunction', value: this.productSearchByIdFunction },
                { id: 'refreshFieldNumber', value: 0 },
              ]
            }
          ]
        }
        this.showThisOrThat = true;

        this.selectedValueStandard = (this.editDeal.fields != undefined && this.editDeal.fields["standard"] != undefined) ? this.editDeal.fields["standard"]!.strEq("Standard") : true
        this.selectedValueFullOfftake = (this.editDeal.fields != undefined && this.editDeal.fields["is_offtake"] != undefined) ? this.editDeal.fields["is_offtake"]!.strEq("Full Offtake") : false
        this.selectedValuePrepayment = (this.editDeal.fields != undefined && this.editDeal.fields["prepayment"] != undefined) ? this.editDeal.fields["prepayment"]!.strEq("Prepayment") : false


        this.editDeal.fields["standard"] = this.selectedValueStandard ? 'Standard' : 'Non Standard';
        this.editDeal.fields["prepayment"] = this.selectedValuePrepayment ? 'Prepayment' : 'No Prepayment';
        this.editDeal.fields["is_offtake"] = this.selectedValueFullOfftake ? 'Full Offtake' : 'No Full Offtake';

        this.b2bshow = false;


        this.currentControls = []

        fields.forEach(field => {
          this.currentControls.push(field.fieldControlName);
        });


        break;

      case "hfc-brk":
        {
          fields = [
            {
              fieldType: 'textarea',
              fieldControlName: 'description',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_description', 'Description', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_description_placeholder', 'Description', []),
              fieldValue: this.editDeal?.fields["description"],
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'rowNumber', value: 2 }]
            },
            {
              fieldType: 'textarea',
              fieldControlName: 'comment',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_comment', 'Deal Comment', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_comment_placeholder', 'Deal Comment', []),
              fieldValue: this.editDeal?.fields["comment"],
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldOptions: [{ id: 'rowNumber', value: 2 }]
            },

            {
              fieldType: 'date',
              fieldControlName: 'deliveryDate',
              fieldBootstrapColumn: 12,
              fieldName: this.tranPipe.transform('deal_ticket_date', 'Delivery Date', []),
              fieldPlaceholder: this.tranPipe.transform('deal_ticket_delivery_date_placeholder', 'Delivery Date', []),
              fieldValue: this.deliveryDate != undefined ? this.deliveryDate : new Date(),
              fieldIsRequired: true,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: false,
              fieldOptions: [{ id: "dateFormat", value: "yyyy-MM-dd" }]
            }
          ]
        }
        this.showThisOrThat = false;

        this.editDeal.dealBuySeller = "Sell"
        this.selectedDealTypeBuy = false;
        this.disableThisOrThatBuy = true;
        this.b2bshow = false;


        this.currentControls = []

        fields.forEach(field => {
          this.currentControls.push(field.fieldControlName);
        });

        break;

      default:
        {
          fields = [
            {
              fieldType: 'input-text',
              fieldControlName: 'b2b_code',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('back_to_back_code_deal_details', 'Back to back code', []),
              fieldPlaceholder: this.tranPipe.transform('back_to_back_code_deal_details_placeholder', 'Back to back code', []),
              fieldValue: this.editDeal?.fields["b2b_code"],
              fieldIsRequired: false,
              fieldIsDisabled: false,
              fieldPaddingLeft: false,
              fieldPaddingRight: true
            },
            {
              fieldType: 'select-local',
              fieldControlName: 'type',
              fieldBootstrapColumn: 6,
              fieldName: this.tranPipe.transform('type_deal_details', 'Type', []),
              fieldPlaceholder: this.tranPipe.transform('type_deal_details_placeholder', 'Type', []),
              fieldValue: this.editDeal?.fields["type"],
              fieldIsRequired: false,
              fieldIsDisabled: typeDisabled,
              fieldPaddingLeft: true,
              fieldPaddingRight: false,
              fieldOptions: [
                { id: 'isMultiple', value: false },
                { id: 'showArrow', value: true },
                { id: 'enableSearch', value: false },
                {
                  id: 'options', value: [
                    {
                      label: 'Incumbent',
                      value: 'incumbent'
                    },
                    {
                      label: 'New Entrant',
                      value: 'New Entrant'
                    },
                    {
                      label: 'Seeker',
                      value: 'Seeker'
                    },
                  ]
                }
              ],
              fieldShowInfo: (!product.strEq("hfc") || !product.strEq("hfc-uk")),
              fieldInfoText: this.tranPipe.transform("view_deal_type_info_text", "This field is enabled when product selected is hfc or hfc-uk")
            }

          ]
        }
        this.showThisOrThat = true;

        this.selectedValueStandard = (this.editDeal.fields != undefined && this.editDeal.fields["standard"] != undefined) ? this.editDeal.fields["standard"]!.strEq("Standard") : true
        this.selectedValueFullOfftake = (this.editDeal.fields != undefined && this.editDeal.fields["is_offtake"] != undefined) ? this.editDeal.fields["is_offtake"]!.strEq("Full Offtake") : false
        this.selectedValuePrepayment = (this.editDeal.fields != undefined && this.editDeal.fields["prepayment"] != undefined) ? this.editDeal.fields["prepayment"]!.strEq("Prepayment") : false


        this.editDeal.fields["standard"] = this.selectedValueStandard ? 'Standard' : 'Non Standard';
        this.editDeal.fields["prepayment"] = this.selectedValuePrepayment ? 'Prepayment' : 'No Prepayment';
        this.editDeal.fields["is_offtake"] = this.selectedValueFullOfftake ? 'Full Offtake' : 'No Full Offtake';
        this.b2bshow = true;


        this.currentControls = []

        fields.forEach(field => {
          this.currentControls.push(field.fieldControlName);
        });


        break;
    }


    return fields;

  }


  async save(deliveries: Deliveries) {

    this.returnValue = undefined;

    if (!deliveries.goNext) {

      this.back();
      return;

    }


    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }


    for (const i in this.dynamicValidateForm.controls) {

      if (this.currentControls.includes(i)) {
        this.dynamicValidateForm.controls[i].markAsDirty();
        this.dynamicValidateForm.controls[i].updateValueAndValidity();
      }
    }



    console.log("this.validateForm", this.validateForm)
    console.log("this.dynamicValidateForm", this.dynamicValidateForm)

    if (this.validateForm.valid && this.dynamicValidateForm.valid) {

      const portfolioYear = this.validateForm.controls["portfolioYear"].value != undefined ? new Date(this.validateForm.controls["portfolioYear"].value) : undefined;


      this.editDeal.brokerCurrency = deliveries.brokerCurrency;
      this.editDeal.brokerFee = deliveries.brokerFee;
      this.editDeal.brokerId = deliveries.brokerId;

      this.editDeal.dealBuySeller = this.selectedDealTypeBuy ? "buy" : "sell";
      this.editDeal.companyId = this.validateForm.controls["company"].value;
      this.editDeal.counterPartyId = this.validateForm.controls["counterparty"].value;
      this.editDeal.dealDate = this.dealDate != undefined ? this.dealDate.toISOString() : undefined;
      this.editDeal.portfolioId = this.validateForm.controls["portfolioId"].value;
      this.editDeal.productIds = this.validateForm.controls["product"].value;
      this.editDeal.portfolioYear = portfolioYear;
      this.editDeal.traderId = this.validateForm.controls["trader"].value;


      this.editDeal.fields["is_offtake"] = this.selectedValueFullOfftake ? "Full Offtake" : "No Full Offtake";
      this.editDeal.fields["prepayment"] = this.selectedValuePrepayment ? "Prepayment" : "No Prepayment ";
      this.editDeal.fields["standard"] = this.selectedValueStandard ? "Standard" : "Non Standard";

      for (const i in this.dynamicValidateForm.controls) {

        if (i.strEq("deliveryDate")) {
          this.editDeal.fields[i] = this.deliveryDate != undefined ? this.deliveryDate?.toISOString() : null;
        }
        else
          this.editDeal.fields[i] = this.dynamicValidateForm.controls[i].value;
      }


      if (deliveries.dealComment != undefined) {
        this.editDeal.fields["comment"] = deliveries.dealComment
      }
      if (Helper.checkIfDoChanges(this.dealTicket, this.editDeal)) {
        this.dealTicket = Helper.storeNewObj(this.editDeal);
      }

      this.returnValue =
      {
        dealTicket: this.dealTicket!,
        deliveries: deliveries.deliveries!
      }
    }


    if (this.returnValue != undefined) {

      var width = window.innerWidth;
      width = this.dashSvc.isMenuCollapsed ? width - 100 : width - 270;

      this.drawer.create<ReviewDealAndDeliveryComponent, { dealTicket: DealTicket, deliveries: Delivery[] }>({
        nzContent: ReviewDealAndDeliveryComponent,
        nzTitle: this.tranPipe.transform("view_deliveries_table_modal", "View all deliveries for deal",),
        nzWidth: width + 'px',
        nzData: {
          dealTicket: this.returnValue!.dealTicket,
          deliveries: this.returnValue!.deliveries
        },
        nzMaskClosable: false,
        nzPlacement: 'right',
        nzSize: 'large'
      }).afterClose.subscribe(async (save: boolean) => {
        if (save == true) {
          await this.dbSave(deliveries);
        }
      })
    }


  }



  async dbSave(deliveries: Deliveries) {

    const portfolioYear = this.returnValue?.dealTicket.portfolioYear != undefined ? this.returnValue?.dealTicket.portfolioYear.getFullYear() : undefined;


    var toSaveDeliveries: Array<DeliveryRequest> = []


    deliveries.deliveries!.forEach(delivery => {

      var v: Vintages[] = []
      delivery.vintageFull?.vintages?.forEach(vin => {

        var temp: Vintages =
        {
          vintageClsuters: vin.clusters,
          vintageMonths: vin.months,
          vintageYear: vin.year!
        }
        v.push(temp)
      });

      var ds: DeliveryRequest =
      {
        additional: delivery.additional,
        currency: delivery.currency,
        deliveryDate: delivery.deliveryDate!,
        deliveryVintages: v,
        dynamicFields: delivery.fields,
        price: delivery.price!,
        volume: delivery.volume!,
        origins: delivery.origin?.origin,
        originsClusters: delivery.origin?.clusters,
        originsToExclude: delivery.origin?.toExclude,
        technologies: delivery.tech?.technology,
        technologiesClusters: delivery.tech?.clusters,
        technologiesToExclude: delivery.tech?.toExclude,
        counterPartyId: delivery.counterpartyId
      }
      toSaveDeliveries.push(ds);
    });



    await this.apiProxySvc.makeRequestErrorPage<any>(() => this.admSvc.addNewDealTicket(this.tsvc.currentLanguage.value,
      {
        dealCompanyId: this.returnValue?.dealTicket.companyId,
        dealTraderId: this.returnValue?.dealTicket.traderId,
        dealPortfolioId: this.returnValue?.dealTicket.portfolioId,
        dealProductsIds: this.returnValue?.dealTicket.productIds ?? ["goo"],
        dealPorfolioYear: portfolioYear,
        dealIsBuyer: this.returnValue?.dealTicket.dealBuySeller?.strEq("buy"),
        dealDealDate: this.returnValue!.dealTicket.dealDate,
        dealCounterPartyId: this.returnValue?.dealTicket.counterPartyId,
        dealBrokerId: this.returnValue?.dealTicket.brokerId,
        dealBrokerFee: this.returnValue?.dealTicket.brokerFee,
        dealBrokerCurrency: this.returnValue?.dealTicket.brokerCurrency,
        dealDynamicFields: this.returnValue?.dealTicket.fields,
        deliveries: toSaveDeliveries
      }), true, "internal-loader", 350, "internal-error", undefined, async () => {
        await this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'stella-frontoffice', 'pending-deals'])
      })


  }

  buySelected(clicked: number) {
    this.selectedDealTypeBuy = clicked == 0 ? true : false;
  }


  standardSelected(clicked: number) {
    this.selectedValueStandard = clicked == 0 ? true : false;
  }

  prepaymentSelected(clicked: number) {

    this.selectedValuePrepayment = clicked == 0 ? true : false;

  }

  fullOfftakeSelected(clicked: number) {

    this.selectedValueFullOfftake = clicked == 0 ? true : false;


  }


  back() {
    console.log("back");
    this.goBack.emit();
  }


  generateB2BCode() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let res = '';
    const length = 6;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      res += chars.charAt(randomIndex);
    }


    if (this.codeList.includes(res))
      this.generateB2BCode();


    this.dynamicValidateForm.controls["b2b_code"].setValue(res)
  }

  async getAllB2BCode() {

    await this.apiProxySvc.makeRequestErrorFunction<string[]>(() => this.admSvc.getB2BCode(this.tsvc.currentLanguage.value),
      true,
      "internal-loader",
      100,
      () => this.getAllB2BCode(), (res) => {
        this.codeList = res.data!
      })

  }


  addCounterpart() {


    this.modal.create<EditCounterpartComponent, { counterParty: CounterPartyRow | undefined, isFinanceView: boolean }>({
      nzContent: EditCounterpartComponent,
      nzTitle: this.tranPipe.transform("table_item_edit_modal_title_new", "Add {0}", ["CounterParty"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
      nzData: {
        counterParty: undefined,
        isFinanceView: false
      }
    })
  }


  //ScrollFunction
  companiesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCompaniesSelect(args.culture, args);
  };
  //SearchFunction
  companiesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCompaniesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  companiesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCompaniesByIdSelect(args.culture, args);
  };


  //ScrollFunction
  counterPartiesScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesSelect(args.culture, args);
  };
  //SearchFunction
  counterPartiesSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartiesByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  counterPartiesSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getCounterPartyByIdSelect(args.culture, args);
  };






  //ScrollFunction
  productsScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsSelect(args.culture, args);
  };
  //SearchFunction
  productsSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  productsSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string[];
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByIdsSelect(args.culture, args);
  };

  productSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getProductsByIdSelect(args.culture, args);
  };



  //ScrollFunction
  tradersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.tradersSearchFilter;
    return this.backUserSvc.getUsersSelect(args.culture, args)
  }
  //SearchFunction
  tradersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.tradersSearchFilter;
    return this.backUserSvc.getUsersByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  tradersSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.tradersSearchFilter;
    return this.backUserSvc.getUsersByIdSelect(args.culture, args)

  }


  //ScrollFunction
  portfolioScrollFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getPortfoliosSelect(args.culture, args);
  };
  //SearchFunction
  portfolioSearchFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getPortfoliosByNameSelect(args.culture, args);
  };
  //InitFunction (SearchByIdFunction)
  portfolioSearchByIdFunction = (args: {
    pageNumber: number;
    pageSize: number;
    culture: string;
    searchName: string;
    searchFilters: PurpleSelectFilter[];
  }) => {
    return this.admSvc.getPortfoliosByIdSelect(args.culture, args);
  };
}


export interface DealTicket {
  companyId: string | null | undefined;
  traderId: string | null | undefined;
  productIds: string[] | null | undefined;
  portfolioYear: Date | null | undefined;
  dealBuySeller: string | null | undefined;
  dealDate: string | undefined;
  counterPartyId: string | null | undefined;
  portfolioId: string | null | undefined;
  brokerId: string | null | undefined;
  brokerFee: number | null | undefined;
  brokerCurrency: string | null | undefined


  fields: { [key: string]: string | null }
}



export interface DealTicketFull {
  dealTicket: DealTicket;
  deliveries: Delivery[];
}
