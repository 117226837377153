import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PurpleTableColumn, PurpleTableSearchGroupGroup, PurpleTranslationPipe, PurpleTableSelectFilter, PurpleLoaderService, PurpleStorageService } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeNotificationService, NotificationModeExceptionStatus, NotificationModeSystemEventExceptionTableItem, NotificationModeSystemEventExceptionTableItemPurpleTableResponse } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/environment';
import { EditNotificationExceptionComponent } from '../modals/edit-notification-exception/edit-notification-exception.component';
import { DashboardLayout2Service } from '../../dashboard-layout-2/dashboard-layout-2.service';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-notification-exceptions',
  templateUrl: './notification-exceptions.component.html',
  styleUrls: ['./notification-exceptions.component.scss']
})
export class NotificationExceptionsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public strSvc: PurpleStorageService, public dashSvc: DashboardLayout2Service, public backNotSvc: BackOfficeNotificationService, private loaderSvc: PurpleLoaderService,
    public tsvc: AppTranslationService, private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private mesSvc: NzMessageService, private apiProxySvc: PurpleApiProxyService) { }

  exceptionStatus: Array<NotificationModeExceptionStatus> | undefined;
  sub: Subject<void> = new Subject();

  listOfData: Array<NotificationModeSystemEventExceptionTableItemPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("notification_exception_table_header_event_name", "Nome Evento"),
      dbKey: "SystemEventName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("notification_exception_table_header_event_description", "Descrizione Evento"),
      dbKey: "SystemEventDescription",
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("notification_exception_table_header_is_mode_disabled", "Modalità Disabilitata"),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "150px",
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {

    return this.backNotSvc.getNotificationModeSystemEventExceptionTable(args.culture, args)
  }

  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")
    await this.checkStatus();
    this.dashSvc.dashMainItems?.push({
      id: "bt",
      type: "button",
      buttonText: this.tranPipe.transform("table_add_new_item_button", "Aggiungi {0}", ["Eccezione"]),
      buttonFunction: () => { this.editSystemEventNotificationModes(undefined) }
    })
    this.loaderSvc.removeRequest("internal-loader", 350)
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "notification_exceptions";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Eccezioni Notifiche', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista delle eccezioni impostate', []))
  }

  async enableNotificationMode(notificationModeId: string, notificationModeName: string) {

    await this.apiProxySvc.makeRequestErrorPage<boolean>(() => this.backNotSvc.addOrRemoveNotificationModeException(this.tsvc.currentLanguage.value, {
      isCurrentStatusEnable: false,
      notificationModeId: notificationModeId
    }), true, "internal-loader", 500, "internal-error", () => this.enableNotificationMode, async (res: PurpleApiMakeRequestResponse<boolean>) => {
      this.mesSvc.success(this.tranPipe.transform('success_remove_notification_mode_exception', '{0} abilitate', [notificationModeName]), {
        nzDuration: environment.MESSAGE_DURATION
      });
      this.refreshTableRowsNumber++;
      await this.checkStatus();
    });
  }

  editSystemEventNotificationModes(notMode: NotificationModeSystemEventExceptionTableItem | undefined) {
    this.modal.create<EditNotificationExceptionComponent, NotificationModeSystemEventExceptionTableItem | undefined>({
      nzContent: EditNotificationExceptionComponent,
      nzData: notMode,
      nzTitle: notMode == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0}", ["eccezione"]) : this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0}", ["eccezione"]),
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (refresh: boolean | undefined) => {
      if (refresh ?? false) {
        this.refreshTableRowsNumber++;
      }
    });
  }

  deleteSystemEventNotificationModes(notModSysEvent: NotificationModeSystemEventExceptionTableItem) {
    this.modal.create({
      nzTitle: this.tranPipe.transform("notification_exception_table_delete_title", "Rimuovere {0} dalle eccezioni?", [notModSysEvent.systemEventName]),
      nzContent: this.tranPipe.transform("notification_exception_table_delete_subtitle", "<b>{0}</b> verrà rimosso dalle eccezioni", [notModSysEvent.systemEventName]),
      nzWidth: '600px',
      nzClassName: 'purple-simple-modal',
      nzOkText: this.tranPipe.transform('notification_exception_table_delete_button', 'Rimuovi', []),
      nzOnOk: async () => {

        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.backNotSvc.deleteSystemEventNotificationException(this.tsvc.currentLanguage.value, {
          systemEventId: notModSysEvent.systemEventId
        }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<boolean>) => {
          this.mesSvc.success(this.tranPipe.transform("notification_exception_table_delete_ok_response", "<b>{0}</b> rimosso delle eccezioni", [notModSysEvent.systemEventName]), {
            nzDuration: environment.MESSAGE_DURATION
          });
          this.modal.closeAll();
          this.refreshTableRowsNumber++;
        })
      }
    })
  }

  async checkStatus() {
    await this.apiProxySvc.makeRequestErrorPage<Array<NotificationModeExceptionStatus>>(() => this.backNotSvc.checkNotificationModeExceptionStatus(this.tsvc.currentLanguage.value, {
      clientNotificationModeExceptionIds: ["email", "push"]
    }), true, "internal-loader", 500, "internal-error", () => this.checkStatus, (res: PurpleApiMakeRequestResponse<Array<NotificationModeExceptionStatus>>) => {
      this.exceptionStatus = res.data!;
    })
  }
}
