/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurpleApiResponseStatus } from './purpleApiResponseStatus';
import { PRPVSystemEventTemplateTablePurpleTablePagedResponse } from './pRPVSystemEventTemplateTablePurpleTablePagedResponse';


export interface PRPVSystemEventTemplateTablePurpleTablePagedResponsePurpleApiResponse { 
    status: PurpleApiResponseStatus;
    calledController?: string | null;
    calledMethod?: string | null;
    message: string;
    exitCode: number;
    data?: PRPVSystemEventTemplateTablePurpleTablePagedResponse;
}
export namespace PRPVSystemEventTemplateTablePurpleTablePagedResponsePurpleApiResponse {
}


