@if (show)
{
<div class="ps-col-12" style="margin-bottom: 12px;">

  <div class="ps-col-12 ps-d-flex ps-pb-2 ps-pr-0 ">
    <div class="ps-col-11 ps-pl-0">
      <span class="ps-justify-content-start form-title">
        {{'delivey_details_subtitle'|translation:'Delivery details'}}
      </span>
    </div>
    <div class="ps-col-1 ps-d-flex ps-justify-content-end ps-pr-0" style="padding-top: 4px;">
      <button [nzTooltipTitle]='"add_new_counterpart" | translation: "Add a new counterpart"' nzTooltipPlacement="top"
        nz-tooltip nz-button nzType="default" nzShape="round" class="counterpart-button " (click)="addCounterpart()">
        <span class="icon-counterpart_icon fsp-18-0"></span>
      </button>
    </div>
  </div>

  <div class="ps-col-12" style="padding-right: 0;">

    <app-purple-form *ngIf="purpleFormFieldGroups1!=undefined" [disbledForm]="disableForm"
      [(formGroup)]="dynamicFromGroups" [purpleFormFieldGroups]="purpleFormFieldGroups1">
    </app-purple-form>

    <button [nzTooltipTitle]="'deal_ticket_select_vintage' | translation: 'Select vintage'" (click)="selectVintage()"
      nzTooltipPlacement="top" class="ps-mr-3 select-button vintage {{vintagePosition}}" nz-tooltip nz-button
      nzType="default" nzShape="round"><i nz-icon nzType="calendar"></i>
    </button>

    <button [nzTooltipTitle]="'deal_ticket_select_origin' | translation: 'Select origin'" (click)="selectOrigin()"
      nzTooltipPlacement="top" class="ps-mr-3 select-button origin {{originPosition}}" nz-tooltip nz-button
      nzType="default" nzShape="round"><i nz-icon nzType="environment"></i>
    </button>

    <button [nzTooltipTitle]="'deal_ticket_select_origin' | translation: 'Select Technology'"
      (click)="selectTechnology()" nzTooltipPlacement="top" class="ps-mr-3 select-button technology {{techPosition}}"
      nz-tooltip nz-button nzType="default" nzShape="round"><i nz-icon nzType="global"></i>
    </button>

    @if (this.data.product!.strEq("ukmarket"))
    {
    <button [nzTooltipTitle]="'deal_ticket_select_origin' | translation: 'Select Technology'" (click)="selectPath()"
      nzTooltipPlacement="top" class="ps-mr-3 select-button path" nz-tooltip nz-button nzType="default"
      nzShape="round"><i nz-icon nzType="branches"></i>
    </button>
    }

    <div class="ps-d-flex ps-justify-content-start">
      <span class="form-title">
        {{'pricing_information_subtitle'|translation:'Pricing information'}}
      </span>
    </div>
    <app-purple-form *ngIf="purpleFormFieldGroups2!=undefined" [disbledForm]="disableForm" [(formGroup)]="validateForm"
      [purpleFormFieldGroups]="purpleFormFieldGroups2">
    </app-purple-form>

  </div>


</div>
<div class=" ps-col-12 ps-justify-content-end purple-button-container ps-w-100 ps-d-flex ps-pr-4">
  <button (click)="save()" nz-button class="purple-modal-button" [disabled]="isLoading" [nzLoading]="isLoading">
    <ng-container *ngIf="!isNew">{{ 'edit_delivery_save_button' | translation: 'Save': []}}</ng-container>
    <ng-container *ngIf="isNew">{{ 'edit_delivery_add_new_user' | translation: 'Add Delivery':[]}}</ng-container>
  </button>
</div>
}
