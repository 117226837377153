import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PurpleTableSearchGroupGroup, PurpleTableColumn, PurpleTranslationPipe, PurpleTableSelectFilter, PurpleLoaderService, PurpleStorageService } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeNotificationService, SmtpServer, SmtpServerPurpleTableResponse } from 'src/core-modules/sdk/api';
import { EditSmtpServerComponent } from '../../modals/edit-smtp-server/edit-smtp-server.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-smtp-servers',
  templateUrl: './smtp-servers.component.html',
  styleUrls: ['./smtp-servers.component.scss']
})
export class SmtpServersComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public strSvc: PurpleStorageService, public dashSvc: DashboardLayout2Service, public backNotSvc: BackOfficeNotificationService,
    public tsvc: AppTranslationService, private modal: NzModalService, private tranPipe: PurpleTranslationPipe, private loaderSvc: PurpleLoaderService) {}
  
  passwordVisible: string[] = [];
  listOfData: Array<SmtpServerPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  sub: Subject<void> = new Subject();
  tabColumns: PurpleTableColumn[] = [
    {
      name: this.tranPipe.transform("table_header_name", "Nome"),
      dbKey: "smtpName",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("table_header_description", "Descrizione"),
      dbKey: "smtpDescription",
      hasReorder: true,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("smtp_table_header_host", "Host"),
      dbKey: "smtpHost",
      hasReorder: false,
      hasFilter: true,
      filterType: 'search'
    },
    {
      name: this.tranPipe.transform("smtp_table_header_username", "Username"),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("smtp_table_header_password", "Password"),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("smtp_table_header_port", "Porta"),
      hasReorder: false,
      hasFilter: false
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [{
      property: "type",
      filterValue: "customers"
    }];
    args.switchOn = false;

    return this.backNotSvc.getSmtpServersTable(args.culture, args)
  }

  async ngOnInit() {
    this.loaderSvc.addRequest("internal-loader")
    this.dashSvc.dashMainItems?.push({
      id: "bt",
      type: "button",
      buttonText: this.tranPipe.transform("table_add_new_item_button", "Aggiungi {0}", ["Smtp"]),
      buttonFunction: () => { this.editSmtpServer(undefined) }
    })
    this.loaderSvc.removeRequest("internal-loader", 350)
  }

  ngAfterViewInit(): void {
    const pageName = "smtp_servers";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Configurazioni Smtp', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista delle configurazioni smtp disponibili per l\invio delle mail', []))
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  setPasswordVisibility(smtpId: string) {
    const idx = this.passwordVisible.findIndex(f => f == smtpId);

    if (idx == -1) {
      this.passwordVisible.push(smtpId)
    } else {
      this.passwordVisible.splice(idx, 1);
    }
  }

  getPasswordVisibility(smtpId: string): boolean {
    return this.passwordVisible.findIndex(f => f == smtpId) != -1;
  }

  editSmtpServer(smtpServer: SmtpServer | undefined) {
    this.modal.create<EditSmtpServerComponent, SmtpServer | undefined>({
      nzContent: EditSmtpServerComponent,
      nzData: smtpServer,
      nzTitle: smtpServer == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0}", ["server smtp"]) : this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0}", ["server smtp"]),
      nzWidth: '1000px',
      nzOkText: "Conferma",
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false,
    }).afterClose.pipe(takeUntil(this.sub)).subscribe(async (smtpServer: SmtpServer) => {
      if (smtpServer != undefined) {
        this.refreshTableRowsNumber++
      }
    });
  }

  /* async deleteSmtpServer(smtpServer: SmtpServer) {
    this.modal.create({
      nzTitle: "Eliminare " + smtpServer.smtpName,
      nzContent: `Vuoi davvero eliminare Smtp: ${smtpServer.smtpName}?`,
      nzWidth: '600px',
      nzOkText: "Elimina",
      nzClassName: 'ps-modal',
      nzFooter: [
        {
          label: "Elimina globale",
          type: "primary",
          onClick: async () => {
            const res = await lastValueFrom(this.admSvc.deleteSmtpServer(this.tsvc.currentLanguage.value, {
              smtpServerId: smtpServer.smtpId
            }))

            if (res.status!.toLowerCase() == PurpleApiResponseStatus.Success.toLowerCase()) {

              this.mesSvc.success("Smtp elimiato correttamente", {
                nzDuration: environment.MESSAGE_DURATION
              });
              await this.getSmtpServers(this.orderColumnName, this.orderType, this.activeFilters)
              this.modal.closeAll()
            } else {
              this.mesSvc.error(res.message ?? "Errore! Smtp non eliminato", {
                nzDuration: environment.MESSAGE_DURATION
              });
            }
          }
        }
      ]
    })
  } */

}
