<div [style.width]="width" class="ps-d-flex ps-flex-wrap deal-ticket-stepper-container">
  <nz-steps class="deal-progress-custom" [nzCurrent]="currentStepIdx" [nzProgressDot]="progressTemplate">
    @for (step of steps; track $index)
    {
    <nz-step [nzTitle]="step.title" [nzDescription]="step.description">
    </nz-step>
    }

  </nz-steps>
</div>

<ng-template #progressTemplate let-dot let-status="status" let-index="index">
  <container-element [ngSwitch]="status=='finish'">
    <ng-container *ngSwitchCase="true">
      <div class="deal-progress-custom-dot success-dot">
        <span class="icon-success" nz-icon nzType="check" nzTheme="outline"></span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <div class="deal-progress-custom-dot">
        <div class="deal-progress-custom-dot-internal-dot {{(status== 'process')?'process': ''}}">
        </div>
      </div>
    </ng-container>
  </container-element>
</ng-template>
