<ng-container *ngIf="dashSvc.dashboardHeight != undefined">

  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"[version]="2" 
    [showCustomPagination]="true" >


    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}} ">
          <td>{{ data.item.smtpName}}</td>
          <td>{{ data.item.smtpDescription }}</td>
          <td>{{ data.item.smtpHost }}</td>
          <td>{{ data.item.smtpUsername }}</td>
          <td>
            <nz-input-group class="table-password-input-group" [nzSuffix]="suffixTemplate">
              <input [type]="getPasswordVisibility(data.item.smtpId) ? 'text' : 'password'" nz-input [disabled]="true"
                placeholder="input password" [(ngModel)]="data.item!.smtpPassword!" nzBorderless />
            </nz-input-group>
            <ng-template #suffixTemplate>
              <i nz-icon [nzType]="getPasswordVisibility(data.item.smtpId) ? 'eye-invisible' : 'eye'"
                (click)="setPasswordVisibility(data.item.smtpId)"></i>
            </ng-template>
          </td>
          <td>{{ data.item.smtpPort }}</td>


          <td nzAlign="center">
            <button (click)="editSmtpServer(data.item!)"
              [nzTooltipTitle]="'edit_smtp_server_edit_smtp' | translation: 'Modifica Configurazione Smtp': []"
              nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon
                class="fsp-18-0" nzType="edit"></i></button>
            <!-- <button (click)="deleteSmtpServer(data.item!)" nzTooltipTitle='Elimina traduzione' nzTooltipPlacement="top"
            nz-tooltip nz-button nzType="default" nzShape="circle"><i nz-icon class="fsp-18-0"
              nzType="delete"></i></button> -->
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>