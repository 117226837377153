/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurpleTableSearchGroupGroup } from './purpleTableSearchGroupGroup';
import { PurpleTableSelectFilter } from './purpleTableSelectFilter';


export interface ApiGetPurpleTableRequest { 
    pageNumber: number;
    pageSize: number;
    orderColumnName?: string | null;
    orderType?: string | null;
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null;
    tableFilters?: Array<PurpleTableSelectFilter> | null;
    switchOn?: boolean | null;
}

