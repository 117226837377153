/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PurpleApiResponseStatus = 'success' | 'warning' | 'exception' | 'error';

export const PurpleApiResponseStatus = {
    Success: 'success' as PurpleApiResponseStatus,
    Warning: 'warning' as PurpleApiResponseStatus,
    Exception: 'exception' as PurpleApiResponseStatus,
    Error: 'error' as PurpleApiResponseStatus
};

