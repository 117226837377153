<ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"
    [isSelectRowActive]="false" [checkIfItemIsSelected]="checkIfItemIsSelected" [version]="2"
    [showCustomPagination]="true" tableSearchType="advanced-box" (filterBox)="dashSvc.setPurpleTablePopover($event)"
    (onSearchBoxEnd)="dashSvc.closePurpleTablePopover()" [filterBoxStatus]="dashSvc.purpleTableBoxFilterStatus">
    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}}">
          <td>{{ data.item.firstName | titlecase }}</td>
          <td>{{ data.item.lastName | titlecase }}</td>
          <td>{{ data.item.email }}</td>
          <td>
            <span *ngIf="(data.item.phone??undefined)!= undefined">
              <span *ngIf="(data.item.phonePrefix??undefined)!= undefined">{{data.item.phonePrefix}}</span>
              {{data.item.phone}}
            </span>
            <span *ngIf="(data.item.phone??undefined)== undefined">
              {{ 'customer_table_not_specify' | translation: 'Non specificato': [] }}
            </span>
          </td>

          <td>
            <ng-container *ngFor="let role of data.item.generalUserRoles.slice(0,5)??[]">
              <nz-tag class="ps-mt-2" purpleStringToColor [word]="role.roleId!" [opacity]="10">
                {{role.roleName}}
              </nz-tag>
            </ng-container>
            <ng-container *ngIf="(data.item.generalUserRoles.length??0) > 5">
              <nz-tag class="show-more-tag" nz-popover [nzPopoverTitle]="undefined"
                nzPopoverOverlayClassName="show-more-popover" nzPopoverPlacement="bottom"
                [nzPopoverContent]="showMoreContentTemplate">
                {{"show_more_element" | translation: "Mostra altri"}} {{data.item.generalUserRoles.length - 5}}
                <i class="ps-ml-2" nz-icon nzType="down" nzTheme="outline"></i>
                <ng-template #showMoreContentTemplate>
                  <nz-tag *ngFor="let role of data.item.generalUserRoles.slice(5)??[]" purpleStringToColor [word]="role.roleId!"
                    [opacity]="10">{{role.roleName}}</nz-tag>
                </ng-template>
              </nz-tag>
            </ng-container>
          </td>

          <td nzAlign="center">
            <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
              data.expanded?
              ("hide_more_button_customer_table" | translation: "Nascondi dati aggiuntivi"):
              ("show_more_button_customer_table" | translation: "Mostra più dati")
              ' nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default" nzShape="circle"><i
                nz-icon class="fsp-18-0" [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button>

            <button (click)="editEmployee(data.item!)" class="ps-mr-3"
              [nzTooltipTitle]='"edit_employee_button_employee_table" | translation: "Modifica Dipendente"'
              nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon class="fsp-18-0" nzType="edit"></i>
            </button>

            <button nz-popover nzPopoverPlacement="left"
              [nzPopoverTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzPopoverTrigger='click'
              [nzPopoverContent]="userMoreActions"
              [nzTooltipTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzTooltipPlacement="top" nz-tooltip
              class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon nzType="unordered-list"
                class="fsp-18-0" nzTheme="outline"></i></button>

            <ng-template #userMoreActions>
              <div class="ps-d-flex ps-flex-wrap">

                <button (click)="editUser(data.item!)"
                  [nzTooltipTitle]='"edit_button_customer_table" | translation: "Modifica cliente"'
                  nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i
                    nz-icon class="fsp-18-0" nzType="edit"></i></button>

                <button (click)="deleteEmployee(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"delete_button_employee_table" | translation: "Rimuovi Dipendente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="delete"></i>
                </button>

                <button (click)="resetPassword(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"reset_password_button_customer_table" | translation: "Resetta Password Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="redo"></i>
                </button>

                <button *ngIf="!data.item.emailVerified" class="ps-mr-3" (click)="verifyEmail(data.item!)"
                  [nzTooltipTitle]='"verify_user_email_customer_table" | translation: "Verifica email"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="check"></i>
                </button>

              </div>
            </ng-template>
          </td>
        </tr>
        <tr #expandedRow [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">
              <nz-descriptions class="ps-w-100"
                [nzTitle]="'employee_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []" nzBordered
                [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzTitle]="'employee_table_gender' | translation: 'Sesso': []">
                  {{data.item.genderId | gender}}
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'employee_table_birthdate' | translation: 'Data di nascita': []">
                  <span *ngIf="(data.item.birthDate??undefined)!= undefined">
                    {{data.item.birthDate | date:"dd MMM yyyy"}}
                  </span>
                  <span *ngIf="(data.item.birthDate??undefined)== undefined">
                    {{ 'employee_table_not_specify_w' | translation: 'Non specificata': [] }}
                  </span>
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'employee_table_email_verified' | translation: 'Email Verificata': []">
                  <nz-badge [nzStatus]="data.item.emailVerified?'success':'error'"
                    [nzText]="data.item.emailVerified?'Si':'No'">
                  </nz-badge>
                </nz-descriptions-item>

                <nz-descriptions-item
                  [nzTitle]="'employee_table_registration_date' | translation: 'Data di Registrazione': []">
                  {{ data.item.registrationDate | date:'dd MMM yyyy (HH:mm)' }}
                </nz-descriptions-item>

              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container>



<!-- <ng-container *ngIf="dashSvc.dashboardHeight != undefined">
  <purple-table #purpleTable [tableBody]="tableBody" [isBordered]="true" [switchOn]="false"
    [refreshFunction]="refreshFunction" (tableData)="listOfData = $event" [tableColumns]="tabColumns"
    [tableScroll]="{y:dashSvc.dashboardHeight}" [refreshTableRowsNumber]="refreshTableRowsNumber"
    [isSelectRowActive]="false" [checkIfItemIsSelected]="checkIfItemIsSelected" [version]="2"
    [showCustomPagination]="true">
    <ng-template #tableBody>
      <ng-container *ngFor="let data of listOfData; let i = index">
        <tr class="{{i%2==0?'main-purple-table-tr-even': 'main-purple-table-tr-odd'}}">
          <td>{{ data.item.firstName }}</td>
          <td>{{ data.item.lastName }}</td>
          <td>{{ data.item.email }}</td>
          <td>
            <span *ngIf="(data.item.phone??undefined)!= undefined">
              <span *ngIf="(data.item.phonePrefix??undefined)!= undefined">{{data.item.phonePrefix}}</span>
              {{data.item.phone}}
            </span>
            <span *ngIf="(data.item.phone??undefined)== undefined">
              {{ 'customer_table_not_specify' | translation: 'Non specificato': [] }}
            </span>
          </td>

          <td>
            <ng-container *ngFor="let role of data.item.roles.slice(0,5)??[]">
              <nz-tag class="ps-mt-2" purpleStringToColor [word]="role.roleId!" [opacity]="10">
                {{role.roleName}}
              </nz-tag>
            </ng-container>
            <ng-container *ngIf="(data.item.roles.length??0) > 5">
              <nz-tag class="show-more-tag" nz-popover [nzPopoverTitle]="undefined"
                nzPopoverOverlayClassName="show-more-popover" nzPopoverPlacement="bottom"
                [nzPopoverContent]="showMoreContentTemplate">
                {{"show_more_element" | translation: "Mostra altri"}} {{data.item.roles.length - 5}}
                <i class="ps-ml-2" nz-icon nzType="down" nzTheme="outline"></i>
                <ng-template #showMoreContentTemplate>
                  <nz-tag *ngFor="let role of data.item.roles.slice(5)??[]" purpleStringToColor [word]="role.roleId!"
                    [opacity]="10">{{role.roleName}}</nz-tag>
                </ng-template>
              </nz-tag>
            </ng-container>
          </td>

          <td nzAlign="center">
            <button (click)="data.expanded = !data.expanded" [nzTooltipTitle]='
              data.expanded?
              ("hide_more_button_customer_table" | translation: "Nascondi dati aggiuntivi"):
              ("show_more_button_customer_table" | translation: "Mostra più dati")
              ' nzTooltipPlacement="top" class="ps-mr-3" nz-tooltip nz-button nzType="default" nzShape="circle"><i
                nz-icon class="fsp-18-0" [nzType]="data.expanded?'eye-invisible':'eye'"></i>
            </button>

            <button (click)="editEmployee(data.item!)" class="ps-mr-3"
              [nzTooltipTitle]='"edit_employee_button_employee_table" | translation: "Modifica Dipendente"'
              nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
              <i nz-icon class="fsp-18-0" nzType="edit"></i>
            </button>

            <button nz-popover nzPopoverPlacement="left"
              [nzPopoverTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzPopoverTrigger='click'
              [nzPopoverContent]="userMoreActions"
              [nzTooltipTitle]='"edit_user_show_more" | translation: "Altre azioni"' nzTooltipPlacement="top" nz-tooltip
              class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i nz-icon nzType="unordered-list"
                class="fsp-18-0" nzTheme="outline"></i></button>

            <ng-template #userMoreActions>
              <div class="ps-d-flex ps-flex-wrap">

                <button (click)="editUser(data.item!)"
                  [nzTooltipTitle]='"edit_button_customer_table" | translation: "Modifica cliente"'
                  nzTooltipPlacement="top" nz-tooltip class="ps-mr-3" nz-button nzType="default" nzShape="circle"><i
                    nz-icon class="fsp-18-0" nzType="edit"></i></button>

                <button (click)="deleteEmployee(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"delete_button_employee_table" | translation: "Rimuovi Dipendente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="delete"></i>
                </button>

                <button (click)="resetPassword(data.item!)" class="ps-mr-3"
                  [nzTooltipTitle]='"reset_password_button_customer_table" | translation: "Resetta Password Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="redo"></i>
                </button>

                <button (click)="editUserAddresses(data.item!)"
                  [nzTooltipTitle]='"edit_user_addresses_button_customer_table" | translation: "Gestione Indirizzi Utente"'
                  nzTooltipPlacement="top" nz-tooltip nz-button nzType="default" nzShape="circle">
                  <i nz-icon class="fsp-18-0" nzType="home"></i>
                </button>
              </div>
            </ng-template>
          </td>
        </tr>
        <tr #expandedRow [nzExpand]="data.expanded">
          <div class="ps-d-flex ps-w-100 ps-justify-content-center">
            <div
              class="ps-d-flex ps-flex-wrap ps-align-items-start ps-w-100 ps-justify-content-between table-expanded-row-container">
              <nz-descriptions class="ps-w-100"
                [nzTitle]="'employee_table_expanded_row_title' | translation: 'Informazioni aggiuntive': []" nzBordered
                [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <nz-descriptions-item [nzTitle]="'employee_table_gender' | translation: 'Sesso': []">
                  {{data.item.genderId | gender}}
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'employee_table_birthdate' | translation: 'Data di nascita': []">
                  <span *ngIf="(data.item.birthDate??undefined)!= undefined">
                    {{data.item.birthDate | date:"dd MMM yyyy"}}
                  </span>
                  <span *ngIf="(data.item.birthDate??undefined)== undefined">
                    {{ 'employee_table_not_specify_w' | translation: 'Non specificata': [] }}
                  </span>
                </nz-descriptions-item>

                <nz-descriptions-item [nzTitle]="'employee_table_email_verified' | translation: 'Email Verificata': []">
                  <nz-badge [nzStatus]="data.item.emailVerified?'success':'error'"
                    [nzText]="data.item.emailVerified?'Si':'No'">
                  </nz-badge>
                </nz-descriptions-item>

                <nz-descriptions-item
                  [nzTitle]="'employee_table_registration_date' | translation: 'Data di Registrazione': []">
                  {{ data.item.registrationDate | date:'dd MMM yyyy (HH:mm)' }}
                </nz-descriptions-item>

              </nz-descriptions>
            </div>
          </div>
        </tr>
      </ng-container>
    </ng-template>
  </purple-table>
</ng-container> -->
