import { PurpleTableSelectFilter } from "src/core-modules/sdk/api/model/purpleTableSelectFilter";

type KeyValue<T> = { key: string; value: T; conversion?: (input: T) => string };
export class Psf {
  public static buildTableSelectFilterItem<T>(
    tableSelectFilters: PurpleTableSelectFilter[],
    propertyKey: string,
    filterValue: T | undefined,
    conversion: ((input: T) => string) | undefined = undefined
  ): void {
    if (filterValue != undefined) {
      const selectFilter: PurpleTableSelectFilter = {
        filterValue:
          conversion != undefined
            ? conversion(filterValue)
            : typeof filterValue === 'string'
            ? filterValue
            : typeof filterValue === 'boolean'
            ? filterValue
              ? 'true'
              : 'false'
            : filterValue.toString(),
        property: propertyKey,
      };
      tableSelectFilters.push(selectFilter);
    }
  }

  public static buildTableFilters(
    filters: KeyValue<any | undefined>[]
  ): PurpleTableSelectFilter[] {
    const tableSelectFilters: PurpleTableSelectFilter[] = [];

    for (const filter of filters) {
      this.buildTableSelectFilterItem(
        tableSelectFilters,
        filter.key,
        filter.value,
        filter.conversion
      );
    }

    return tableSelectFilters;
  }
}
