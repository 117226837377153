/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Envelope { 
    envelopeId: string;
    envelopeName: string;
    envelopeDescription: string;
    envelopeEmailBlurb: string;
    envelopeEmailSubject: string;
    accountId: string;
    externalEnvelopeId?: string | null;
    certificateId?: string | null;
    envelopeStatusId: string;
    envelopesLogUserId?: string | null;
    isActive?: boolean | null;
    timeStamp?: string | null;
}

