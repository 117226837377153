import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { PurpleApiMakeRequestResponse, PurpleApiProxyService, PurpleApiResponseStatus, PurpleTranslationPipe } from 'purple-lib';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeNotificationService, MailProviderTemplateBase } from 'src/core-modules/sdk/api';

@Component({
  selector: 'app-import-mail-template',
  templateUrl: './import-mail-template.component.html',
  styleUrls: ['./import-mail-template.component.scss']
})
export class ImportMailTemplateComponent implements OnInit {

  constructor(private tsvc: AppTranslationService, private modal: NzModalRef, 
    private backNotSvc: BackOfficeNotificationService, private apiProxySvc: PurpleApiProxyService, private tranPipe: PurpleTranslationPipe) { }

    templateFolders: templateFolderTree[] = [];
    currentFolder: templateFolderTree | undefined;
    selectedTemplate: templateFolderItem | undefined;
  
    isLoading: boolean = false;
    tabIdx: mailTemplateImportType = mailTemplateImportType.Stripo;


  async ngOnInit() {
    await this.reloadTemplateList();
  }


  async reloadTemplateList(refresh: boolean = false) {
    this.isLoading = true;

    var res = await this.apiProxySvc.makeRequestErrorMessage<MailProviderTemplateBase[]>(() =>this.backNotSvc.getMailTemplateListFromProvider(this.tsvc.currentLanguage.value, {
      mailTemplateProvider: mailTemplateImportType.Stripo ? 'stripo' : 'mailchimp',
      refresh: refresh,
      templateType: 0
    }), false, "internal-loader", 500,
      this.tranPipe.transform("import_template_error_response", "Nessun template trovato"), undefined, async (res: PurpleApiMakeRequestResponse<MailProviderTemplateBase[]>) => {

        (res.data ?? []).forEach(el => {
          const fidx = this.templateFolders.findIndex(f => f.folder?.strEq(el.folderName ?? ""));

          if (fidx == -1) {
            this.templateFolders.push({
              folder: el.folderName ?? undefined,
              isOpen: false,
              templates: [{ id: el.id ?? undefined, name: el.name ?? undefined, previewImageUrl: el.previewImageUrl ?? undefined }]
            })
          } else {
            this.templateFolders[fidx].templates.push({ id: el.id ?? undefined, name: el.name ?? undefined, previewImageUrl: el.previewImageUrl ?? undefined })
          }
        });
        console.log("TEMPLATES: ", this.templateFolders)

      })

      if (res.status != PurpleApiResponseStatus.Success) {
        switch (this.tabIdx) {
          case mailTemplateImportType.Stripo:
            await this.onTabChange(mailTemplateImportType.MailChimp);
            break;
          case mailTemplateImportType.MailChimp:
            await this.onTabChange(mailTemplateImportType.Stripo);
            break;
          default:
            break;
        }
      }

    this.isLoading = false;
  }

  async onTabChange(idx: number) {
    this.tabIdx = idx;
    await this.reloadTemplateList();
  }

  setCurrentTemplate(temp: templateFolderItem) {
    this.selectedTemplate = temp;
  }

  async getTemplateHtml() {
    this.isLoading = true;

    await this.apiProxySvc.makeRequestErrorMessage<string>(() => this.backNotSvc.getMailTemplateContentFromProvider(this.tsvc.currentLanguage.value, {
      mailTemplateProvider: mailTemplateImportType.Stripo ? 'stripo' : 'mailchimp',
      mailTemplateId: this.selectedTemplate!.id!,
      refresh: true,
      templateType: 0
    }), false, "internal-loader", 500, undefined, undefined, (res: PurpleApiMakeRequestResponse<string>) => {
      this.isLoading = false;
      this.modal.close(res.data)
    })

    this.isLoading = false;
  }
}

enum mailTemplateImportType {
  Stripo = 0,
  MailChimp = 1
}

export class templateFolderTree {
  folder: string | undefined;
  isOpen: boolean = false;
  templates: templateFolderItem[] = []
}

export interface templateFolderItem {
  id: string | undefined;
  name: string | undefined;
  previewImageUrl: string | undefined;
}