import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { PurpleTableBoxColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton, dashboardLocalSelect } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { StellaService } from 'src/core-modules/sdk/api';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-internal-alerts',
  templateUrl: './internal-alerts.component.html',
  styleUrls: ['./internal-alerts.component.scss']
})
export class InternalAlertsComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) private locale: string, private admSvc: StellaService,
    private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service) { }
    sub: Subject<void> = new Subject();




  anticipatedSelectValues: { label: string; value: any; }[] = [
    {
      label: "Anticipated",
      value: "Anticipated"
    }
  ]

  deliveriesSelectValues: { label: string; value: any; }[] = [
    {
      label: "Deliveries",
      value: "Deliveries"
    }
  ]


  listOfData: Array<any> | undefined | null;
  refreshTableRowsNumber: number = 0;

  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("interal_alerts_table_company", "Company"),
      dbKey: "InternalAlertsCompany",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'InternalAlertsCompany',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_company', 'Company', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_company_placeholder', 'Company', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_type", "B/S"),
      dbKey: "InternalAlertsType",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'select-local',
        fieldControlName: 'InternalAlertsType',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_type', 'B/S', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_type_placeholder', 'B/S', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'B',
                value: 'B'
              },
              {
                label: 'S',
                value: 'S'
              }
            ]
          }
        ]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("internal_alerts_table_year", "Year"),
      dbKey: "InteralAlertsYear",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'date',
        fieldControlName: 'InteralAlertsYear',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_year', 'Year', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_year_placeholder', 'Year', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'pickerMode', value: 'year' }]
      },
      operatorType: "=",
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_product", "Product"),
      dbKey: "InternalAlertsProduct",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'InternalAlertsProduct',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_product', 'Product', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_product_placeholder', 'Product', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_trader", "Trader"),
      dbKey: "InternalAlertsTrader",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'InternalAlertsTrader',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_trader', 'Trader', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_trader_placeholder', 'Trader', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_counterparty", "Counterparty"),
      dbKey: "InternalAlertsCounterparty",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'InternalAlertsCounterparty',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_counterparty', 'Counterparty', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_counterparty_placeholder', 'Counterparty', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("internal_alerts_table_trade_date", "Trade Date"),
      dbKey: "InteralAlertsTradeDate",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'date',
        fieldControlName: 'InteralAlertsTradeDate',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_trade_date', 'Trade Date', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filtr_trade_date_placeholder', 'Trade Date', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) }]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_reference_number", "Reference Number"),
      dbKey: "InternalAlertsReferenceNumber",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'InternalAlertsReferenceNumber',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_reference_number', 'Reference Number', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_reference_number_placeholder', 'Reference Number', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("internal_alerts_table_delivery", "Delivery"),
      dbKey: "InteralAlertsDelivery",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'date',
        fieldControlName: 'InteralAlertsDelivery',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_delivery', 'Delivery', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_delivery_placeholder', 'Delivery', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) }]
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("internal_alerts_table_vintage", "Vintage"),
      dbKey: "InteralAlertsVintage",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'date',
        fieldControlName: 'InteralAlertsVintage',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_vintage', 'Vintage', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_vintage_placeholder', 'Vintage', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'pickerMode', value: 'year' }]
      },
      operatorType: "=",
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_origin", "Origin"),
      dbKey: "InternalAlertsOrigin",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'select-multiple',
        fieldControlName: 'InternalAlertsOrigin',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_origin', 'Origin', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_origin_placeholder', 'Origin', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'ITA',
                value: 'ITA'
              },
              {
                label: 'ESP',
                value: 'ESP'
              }
            ]
          }
        ]
      },
      operatorType: "in"
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_technology", "Technology"),
      dbKey: "InternalAlertsTechnology",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'select-multiple',
        fieldControlName: 'InternalAlertsTechnology',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_technology', 'Technology', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_technology_placeholder', 'Technology', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false,
        fieldOptions: [
          { id: 'isMultiple', value: false },
          { id: 'showArrow', value: true },
          { id: 'enableSearch', value: false },
          {
            id: 'options', value: [
              {
                label: 'WIND',
                value: 'WIND'
              },
              {
                label: 'HYD',
                value: 'HYD'
              }
            ]
          }
        ]
      },
      operatorType: "in"
    },
    {
      name: this.tranPipe.transform("interal_alerts_table_additional", "Additional"),
      dbKey: "InternalAlertsAdditional",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'InternalAlertsAdditional',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_additional', 'Additional', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_additional_placeholder', 'Additional', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "like"
    }, {
      name: this.tranPipe.transform("interal_alerts_table_volume", "Volume"),
      dbKey: "InternalAlertsVolume",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'InternalAlertsVolume',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_volume', 'Volume', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_volume_placeholder', 'Volume', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: true,
        fieldPaddingRight: false
      },
      operatorType: "=",
    }, {
      name: this.tranPipe.transform("interal_alerts_table_price", "Price"),
      dbKey: "InternalAlertsPrice",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'InternalAlertsPrice',
        fieldBootstrapColumn: 6,
        fieldName: this.tranPipe.transform('internal_alerts_table_filter_price', 'Price', []),
        fieldPlaceholder: this.tranPipe.transform('internal_alerts_table_filter_price_placeholder', 'Price', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: true
      },
      operatorType: "=",
    },
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "130px"
    }

  ]



  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {

    return this.admSvc.getCounterPartyTable(args.culture, args)
  }

  ngOnInit() {
    this.dashSvc.dashMainItems.push(
      new dashboardLocalSelect("deliveries-local-slect",
        () => { },
        this.deliveriesSelectValues,
        "default",
        undefined,
        undefined,
        'Deliveries',
        true
      ),
      new dashboardLocalSelect("anticipated-local-slect",
        () => { },
        this.anticipatedSelectValues,
        "default",
        undefined,
        undefined,
        'Anticipated',
        true
      ),
      new dashboardButton("export-button", this.tranPipe.transform('export_button', 'Export', []), () => { }, 'default', 'export', ' dashboard-button', 'left'),
      new dashboardButton("load-button", this.tranPipe.transform('load_button', 'Load', []), () => { }, 'default', 'reload', ' dashboard-button', 'left'),
      new dashboardButton("save-button", this.tranPipe.transform('save_button', 'Save', []), () => { }, 'primary', 'save', ' dashboard-button', 'left'))

  }


  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "stella_internal_alerts";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Internal Alerts', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Check and monitor deliveries, invoices  and contract’s statuses.', []))
  }


}
