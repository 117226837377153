import { Component, OnInit } from '@angular/core';
import { PurpleTableBoxColumn, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton } from 'purple-lib';
import { Subject } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { CounterPartyPurpleTableResponse, StellaService } from 'src/core-modules/sdk/api';
import { EditBrokerComponent } from '../modals/edit-broker/edit-broker.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-brokers',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.scss']
})
export class BrokersComponent implements OnInit {

  constructor(private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, private modal: NzModalService, public dashSvc: DashboardLayout2Service) { }
  sub: Subject<void> = new Subject();

  listOfData: Array<CounterPartyPurpleTableResponse> | undefined | null;
  refreshTableRowsNumber: number = 0;
  tabColumns: PurpleTableBoxColumn[] = [
    {
      name: this.tranPipe.transform("counter_party_table_header_event_name", "Nome"),
      dbKey: "CounterPartyName",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-text',
        fieldControlName: 'CounterPartyName',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('counter_party_table_filter_name', 'Nome', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_name_placeholder', 'Nome', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "like"
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_event_fee", "Fee"),
      dbKey: "CounterPartyFee",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'CounterPartyFee',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('counter_party_table_filter_fee', 'Fee', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_fee_placeholder', 'Fee', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_event_fee_currency", "Fee Currency"),
      dbKey: "CounterPartyFeeCurrency",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'CounterPartyFeeCurrency',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('counter_party_table_filter_fee_currency', 'Fee Currency', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_fee_currency_placeholder', 'Fee Currency', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_event_fee_unit", "Fee Unit"),
      dbKey: "CounterPartyFeeUnit",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'CounterPartyFeeUnit',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('counter_party_table_filter_fee_unit', 'Fee Unit', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_fee_unit_placeholder', 'Fee Unit', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "="
    },
    {
      name: this.tranPipe.transform("counter_party_table_header_event_deals", "Deals"),
      dbKey: "CounterPartyDeals",
      hasReorder: true,
      hasFilter: true,
      filterField:
      {
        fieldType: 'input-number',
        fieldControlName: 'CounterPartyDeals',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('counter_party_table_filter_deals', 'Deals', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_deals_placeholder', 'Deals', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false
      },
      operatorType: "="
    },

    /*
    {
      name: this.tranPipe.transform("counter_party_table_header_event_description", "Descrizione"),
      dbKey: "CounterPartyDescription",
      hasReorder: false,
      hasFilter: true,
      filterField: {
        fieldType: 'textarea',
        fieldControlName: 'CounterPartyDescription',
        fieldBootstrapColumn: 12,
        fieldName: this.tranPipe.transform('counter_party_table_filter_level_description', 'Descrizione', []),
        fieldPlaceholder: this.tranPipe.transform('counter_party_table_filter_level_description_placeholder', 'Descrizione livello', []),
        fieldValue: undefined,
        fieldIsRequired: false,
        fieldIsDisabled: false,
        fieldPaddingLeft: false,
        fieldPaddingRight: false,
        fieldOptions: [{ id: 'rowNumber', value: 4 }]
      },
      operatorType: "like",
    }, */
    {
      name: this.tranPipe.transform("table_header_actions", "Azioni"),
      hasReorder: false,
      hasFilter: false,
      fixedWidth: "160px",
    }
  ]

  refreshFunction = (args: {
    pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
    culture: string
  }) => {
    args.tableFilters = [
      {
        property: "CounterPartyTypeId",
        filterValue: "broker"
      }
    ]
    return this.admSvc.getBrokerTable(args.culture, args)
  }

  ngOnInit() {
    this.dashSvc.dashMainItems.push(new dashboardButton("create-invoice", this.tranPipe.transform('create_invoice', 'Create an invoice', []), () => { }, 'default', 'inbox', ' add-button', 'left'),
      new dashboardButton("add-new-brokers", this.tranPipe.transform('add_new_brokers', 'Add new broker', []), () => this.editBroker(undefined), 'primary', 'plus', 'add-button', 'left'))
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  ngAfterViewInit(): void {
    const pageName = "stella_brokers";
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Brokers', []),
      this.tranPipe.transform(pageName + '_page_subtitle', 'Lista dei brokers', []))
  }


  editBroker(broker: any) {
    this.modal.create<EditBrokerComponent>({
      nzContent: EditBrokerComponent,
      nzTitle: broker == undefined ? this.tranPipe.transform("table_item_edit_modal_title_new", "Aggiungi {0}", ["Broker"]) : this.tranPipe.transform("table_item_edit_modal_title_update", "Modifica {0}", ["Broker"]),
      nzWidth: '800px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: false
    })
  }
}
