import { Injectable, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActivatedRoute, Router } from '@angular/router';
import { PurpleTranslationPipe, dashboardButton, dashboardDateRange, dashboardDropdown, dashboardLocalSelect, dashboardPopover, dashboardTag, dashboardToggle, showMenu } from 'purple-lib';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { dashboardPurpleSelect } from 'purple-lib/lib/classes/dashboardPurpleSelect';
import { dashboardDate } from 'purple-lib/lib/classes/dashboardDate';

@Injectable({
  providedIn: 'root'
})
export class DashboardLayout2Service {
  softwareVersion: string = environment.appVersion;
  dashboardHeight: any | undefined;
  dashboardWidth: any | undefined;
  dashboardHeightNumber: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  isMenuCollapsed: boolean = false;
  showMenu: showMenu[] = [];

  pageTitle: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  pageSubTitle: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  isUserMenuOpen: boolean = false;

  dashMainItems: (dashboardButton | dashboardToggle | dashboardPopover | dashboardLocalSelect | dashboardPurpleSelect | dashboardTag | dashboardDate | dashboardDateRange | dashboardDropdown)[] = [];
  dashBackButtons: (dashboardButton | dashboardToggle | dashboardPopover | dashboardLocalSelect | dashboardPurpleSelect | dashboardTag | dashboardDate | dashboardDateRange)[] = [];
  currentMenu: string | undefined;
  currentSubMenu: string | undefined;


  purpleTableBoxFilterStatus: "open" | "close" = "close";

  constructor(private modal: NzModalService, private mesSvc: NzMessageService, private tsvc: AppTranslationService, private route: ActivatedRoute,
    private router: Router, private tranPipe: PurpleTranslationPipe, private authSvc: AuthenticationService) {

    if (this.showMenu.length == 0) {
      this.showMenu = environment.ACTIVE_MENU.map(m => ({ menu: m.menu, submenu: m.submenu, maxlevel: m.maxlevel } as showMenu))
    }
  }

  addTranslationSelect(position: "back" | "main" = "back") {
    const sel = new dashboardLocalSelect("tran-language", async (id: string | undefined) => {
      if (id != undefined) {
        this.tsvc.changeTranslationLanguage(id);
        this.router.navigate([this.router.url.split('?')[0]], {

          queryParams: {
            translationLanguage: this.tsvc.translationLanguage.value
          },
          relativeTo: this.route
        });
      }
    },
      this.tsvc.availableLanguages.map(m => {
        return {
          label: m.languageName,
          value: m.languageId,
          queryParamsHandling: 'merge'
        }
      }),
      "default",
      this.tsvc.translationLanguage.value,
      this.tranPipe.transform('select_translation_language', 'Lingua Traduzione', []),
      this.tranPipe.transform('select_translation_language_placeholder', 'Seleziona la lingua di traduzione', []),
      true,
      false,
      false,
      "translation-language-select"
    );

    if (position.strEq("back")) {
      this.dashBackButtons?.push(sel);
    } else {
      this.dashMainItems?.push(sel);
    }

  }

  setPageHeader(title?: string | undefined, subtitle?: string | undefined): void {
    setTimeout(() => {
      this.pageTitle.next(title);
      this.pageSubTitle.next(subtitle);
    }, 100);
  }

  setCurrentActiveMenuSubMenu(menu?: string | undefined, subMenu?: string | undefined): void {
    this.currentMenu = menu;
    this.currentSubMenu = subMenu;
  }

  clearPageHeader() {
    this.dashBackButtons = [];
    this.dashMainItems = [];
    this.pageTitle.next(undefined);
    this.pageSubTitle.next(undefined);
  }

  closePurpleTablePopover() {
    const el = this.dashMainItems.find(f => f.id.strEq("purple-table-filter-popover"));

    if (el != undefined) {
      (el as dashboardPopover).closePopover()
    }
  }

  setPurpleTablePopover(template: TemplateRef<any>) {
    this.purpleTableBoxFilterStatus = "close";

    this.dashMainItems.push(
      new dashboardPopover("purple-table-filter-popover",
        this.tranPipe.transform('purple_table_filter_button', 'Filtri', []),
        template,
        undefined,
        'default',
        'down',
        'filter-button',
        undefined,
        'click',
        'bottomRight',
        'purple-table-filters-popover',
        false,
        (isOpen: boolean) => {
          this.purpleTableBoxFilterStatus = isOpen ? "open" : "close"
        }));
  }

  logout(isMobile: boolean): void {
    this.isUserMenuOpen = false;
    this.modal.create(
      {
        nzTitle: isMobile ? this.tranPipe.transform("logout_title_mobile", "Logout") : this.tranPipe.transform("logout_title_desktop", "Vuoi davvero uscire??"),
        nzContent: this.tranPipe.transform("logout_subtitle", "{0} sei sicuro di voler uscire?", [this.authSvc.currentUser?.user?.firstName!]),
        nzWidth: isMobile ? '80%' : '600px',
        nzClassName: 'purple-simple-modal',
        nzCentered: isMobile ? true : false,
        nzClosable: false,
        nzMaskClosable: false,
        nzOkText: this.tranPipe.transform("logout", "logout"),
        nzOnOk: async () => {
          var res = this.authSvc.logout()

          if (res) {
            this.mesSvc.success(this.tranPipe.transform("logout_ok_response", "Logout effettuato correttamente"), {
              nzDuration: environment.MESSAGE_DURATION
            })

            this.router.navigate([this.tsvc.currentLanguage.value, 'login'], {})
          } else {
            this.mesSvc.error(this.tranPipe.transform("logout_error_response", "Errore! non è stato possibile effettuare il logout"), {
              nzDuration: environment.MESSAGE_DURATION
            })
          }
        }
      }
    )
  }
}

