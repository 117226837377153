import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { CounterpartiesComponent } from './counterparties/counterparties.component';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { ExchangeRateComponent } from './exchange-rate/exchange-rate.component';
import { BrokersComponent } from './brokers/brokers.component';
import { InternalAlertsComponent } from './internal-alerts/internal-alerts.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'counterparties',
        pathMatch: "full"
      },
      {
        path: 'counterparties',
        component: CounterpartiesComponent,
        data: { submenu: "counterparties" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'brokers',
        component: BrokersComponent,
        data: { submenu: "brokers" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'exchange-rate',
        component: ExchangeRateComponent,
        data: { submenu: "exchange-rate" },
        canActivate: [ShowMenuGuard]
      },
      {
        path: 'internal-alerts',
        component: InternalAlertsComponent,
        data: { submenu: "internal-alerts" },
        canActivate: [ShowMenuGuard]
      }
    ]
  }
];

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StellaBackOfficeRoutingModule { }
