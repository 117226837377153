import { FormatWidth, KeyValue, TitleCasePipe, getLocaleDateFormat } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getYear } from 'date-fns';
import { PurpleSelectFilter, PurpleTableSearchGroupGroup, PurpleTableSelectFilter, PurpleTranslationPipe, dashboardButton, dashboardLocalSelect, dashboardPopover, dashboardPurpleSelect, dashboardMultiplePurpleSelect, PurpleTableBoxColumn } from 'purple-lib';
import { Subject, takeUntil } from 'rxjs';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { BackOfficeUsersService, DealBaseGrouped, DealBaseGroupedPurpleTableResponse, StellaService } from 'src/core-modules/sdk/api';
import { Nc } from 'src/lib-wish/nc';
import { Psf } from 'src/lib-wish/psf';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ViewDeliveriesComponent } from '../../backoffice/modals/view-deliveries/view-deliveries.component';
import { DealAgreementStatusComponent } from '../deal-ticket/modals/deal-agreement-status/deal-agreement-status.component';
import { NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})

export class PortfolioComponent implements OnInit {
  constructor(@Inject(LOCALE_ID) private locale: string, private tranPipe: PurpleTranslationPipe, private tsvc: AppTranslationService, private route: ActivatedRoute, private backSvc: StellaService,
    private router: Router, public dashSvc: DashboardLayout2Service, private modal: NzModalService, private backUserSvc: BackOfficeUsersService, private titleCasePipe: TitleCasePipe, private drawer: NzDrawerService,
  ) { }

  viewType: number = 0;
  tableHeight: string | undefined;
  sub: Subject<void> = new Subject();
  showTable: boolean = false;

  viewTypes: { label: string; value: any }[] = [
    {
      label: this.tranPipe.transform('portfolio_type_view_table', 'Table view', []),
      value: 0,
    },
    {
      label: this.tranPipe.transform('portfolio_type_view_graph', 'Graph view', []),
      value: 1,
    },
  ];


  portfolioYearOptions: { label: string; value: any }[] = [];
  globalFilters: { currency: string, companies: string[] | undefined, portfolios: string[] | undefined, portfolioYears: number[] | undefined, traders: string[] | undefined } =
    {
      companies: undefined,
      portfolios: ['goo'],
      portfolioYears: [(new Date()).getFullYear()],
      traders: undefined,
      currency: "CHF"
    }

  //////////////////////////////////////////////FILTRI LOCALI/////////////////////////////////////////////////////////////
  //ORIGINS
  originsScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getOriginSelect(args.culture, args);
  };
  originsSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getOriginByNameSelect(args.culture, args);
  };
  originsSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getOriginByIdsSelect(args.culture, args);
  };

  //TECHNOLOGIES
  technologiesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getTechnologiesSelect(args.culture, args);
  };
  technologiesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getTechnologyByNameSelect(args.culture, args);
  };
  technologiesSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getTechnologiesByIdsSelect(args.culture, args);
  };

  //FROM COUNTERPARTIES
  fromCounterpartiesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCompaniesSelect(args.culture, args);
  };
  fromCounterpartiesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCompaniesByNameSelect(args.culture, args);
  };
  fromCounterpartiesSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCompaniesByIdsSelect(args.culture, args);
  };

  //TO COUNTERPARTIES
  toCounterpartiesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if ((this.globalFilters.companies?.length ?? 0) > 0) {
      args.searchFilters = [
        {
          filterValues: this.globalFilters.companies ?? [],
          propertyName: "filterOnlyCounterParties",
          filterOperator: "in"
        }
      ]
    }

    return this.backSvc.getCompaniesSelect(args.culture, args);
  };
  toCounterpartiesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if ((this.globalFilters.companies?.length ?? 0) > 0) {
      args.searchFilters = [
        {
          filterValues: this.globalFilters.companies ?? [],
          propertyName: "filterOnlyCounterParties",
          filterOperator: "in"
        }
      ]
    }
    return this.backSvc.getCompaniesByNameSelect(args.culture, args);
  };
  toCounterpartiesSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCompaniesByIdsSelect(args.culture, args);
  };

  //TRADERS
  tradersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if ((this.globalFilters.companies?.length ?? 0) > 0) {
      args.searchFilters = [
        { propertyName: "traders", filterValues: ['true'] },
        {
          filterValues: this.globalFilters.traders ?? [],
          propertyName: "usersToInclude",
          filterOperator: "in"
        }
      ]
    } else {
      args.searchFilters = [
        { propertyName: "traders", filterValues: ['true'] }
      ];
    }

    return this.backUserSvc.getUsersSelect(args.culture, args)
  }
  tradersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    if ((this.globalFilters.companies?.length ?? 0) > 0) {
      args.searchFilters = [
        { propertyName: "traders", filterValues: ['true'] },
        {
          filterValues: this.globalFilters.traders ?? [],
          propertyName: "usersToInclude",
          filterOperator: "in"
        }
      ]
    } else {
      args.searchFilters = [
        { propertyName: "traders", filterValues: ['true'] }
      ];
    }
    return this.backUserSvc.getUsersByNameSelect(args.culture, args)
  }
  tradersSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByIdsSelect(args.culture, args)
  }

  portfolioTables: portfolioTables = {
    sales: {
      tableColumns: [
        {
          name: this.tranPipe.transform('portfolio_table_header_traders', 'Trader'),
          dbKey: 'TraderInfo',
          hasReorder: false,
          hasFilter: true,
          fixedWidth: "50px",
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'TraderInfo',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('portfolio_table_filters_traders', 'Traders', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_traders_placeholder', 'Traders', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.tradersScrollFunction },
              { id: 'searchFunction', value: this.tradersSearchFunction },
              { id: 'searchByIdFunction', value: this.tradersSearchByIdsFunction }
            ]
          },
          operatorType: "in like",
          splitCharacters: ",:"
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_trade_date', 'Trade date'),
          dbKey: 'TransactionDate ',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'date-range',
            fieldControlName: 'transactionDate',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('portfolio_table_filters_transaction_date', 'Trade date', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_transaction_date_placeholder', 'Trade date', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
              { id: 'disabledDate', value: undefined }
            ]
          },
          operatorType: 'date between'
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_counterparty', 'Counterparty'),
          dbKey: 'ToCounterPartyName',
          hasReorder: true,
          hasFilter: false
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_counterparty', 'Counterparty'),
          dbKey: 'ToCounterPartyId',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'ToCounterPartyId',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_buyer_counterparty', 'Buyer counterparty', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_buyer_counterparty_placeholder', 'Buyer counterparty', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.toCounterpartiesScrollFunction },
              { id: 'searchFunction', value: this.toCounterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.toCounterpartiesSearchByIdsFunction }
            ]
          },
          operatorType: "in",
          hiddenColumn: true
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_counterparty', 'Counterparty'),
          dbKey: 'FromCounterPartyId',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'FromCounterPartyId',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_seller_counterparty', 'Seller counterparty', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_seller_counterparty_placeholder', 'Seller counterparty', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.fromCounterpartiesScrollFunction },
              { id: 'searchFunction', value: this.fromCounterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.fromCounterpartiesSearchByIdsFunction }
            ]
          },
          operatorType: "in",
          hiddenColumn: true
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_reference_number', 'Ref.N'),
          dbKey: 'TransactionReferenceNumber',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'input-text',
            fieldControlName: 'TransactionReferenceNumber',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_transaction_reference_number', 'Reference number', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_transaction_reference_number_placeholder', 'Reference number', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          operatorType: "like"
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_origin', 'Origins'),
          dbKey: 'OriginInfo',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'OriginInfo',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_origins', 'Origini', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_origins_placeholder', 'Origini', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.originsScrollFunction },
              { id: 'searchFunction', value: this.originsSearchFunction },
              { id: 'searchByIdFunction', value: this.originsSearchByIdsFunction }
            ]
          },
          operatorType: "in like"
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_technologies', 'Tech.'),
          dbKey: 'TechnologyInfo',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'TechnologyInfo',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_technologies', 'Technologie', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_technologies_placeholder', 'Technologie', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.technologiesScrollFunction },
              { id: 'searchFunction', value: this.technologiesSearchFunction },
              { id: 'searchByIdFunction', value: this.technologiesSearchByIdsFunction }
            ]
          },
          operatorType: "in like",

        },
        {
          name: this.tranPipe.transform('portfolio_table_header_volume', 'Vol. (Mw)'),
          dbKey: 'transactionVolume',
          hasReorder: true,
          hasFilter: false
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_weighted_avg_price', 'Price'),
          dbKey: 'weightedAvgPrice',
          hasReorder: true,
          hasFilter: false
        },
        {
          name: this.tranPipe.transform('table_header_actions', 'Actions'),
          hasReorder: false,
          hasFilter: false,
          fixedWidth: "130px"
        },
      ],
      filter: undefined,
      filterBoxStatus: "close",
      listOfData: undefined,
      refreshFunction: (args: {
        pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
        tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
        culture: string
      }) => {
        args.tableFilters = this.getGlobalTableFilter('seller');
        return this.backSvc.getPortfolioTable(args.culture, args);
      },
      refreshTableRowsNumber: 0,
      totalItems: 0,
      tableTitle: this.tranPipe.transform('portfolio_sales', 'Sales', [])
    },
    purchases: {
      tableColumns: [
        {
          name: this.tranPipe.transform('portfolio_table_header_traders', 'Trader'),
          dbKey: 'TraderInfo',
          hasReorder: false,
          hasFilter: true,
          fixedWidth: "50px",
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'TraderInfo',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('portfolio_table_filters_traders', 'Traders', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_traders_placeholder', 'Traders', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.tradersScrollFunction },
              { id: 'searchFunction', value: this.tradersSearchFunction },
              { id: 'searchByIdFunction', value: this.tradersSearchByIdsFunction }
            ]
          },
          operatorType: "in like",
          splitCharacters: ",:"
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_trade_date', 'Trade date'),
          dbKey: 'TransactionDate ',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'date-range',
            fieldControlName: 'transactionDate',
            fieldBootstrapColumn: 12,
            fieldName: this.tranPipe.transform('portfolio_table_filters_transaction_date', 'Trade date', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_transaction_date_placeholder', 'Trade date', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'dateFormat', value: getLocaleDateFormat(this.locale, FormatWidth.Medium) },
              { id: 'disabledDate', value: undefined }
            ]
          },
          operatorType: 'date between'
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_counterparty', 'Counterparty'),
          dbKey: 'ToCounterPartyName',
          hasReorder: true,
          hasFilter: false
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_counterparty', 'Counterparty'),
          dbKey: 'ToCounterPartyId',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'ToCounterPartyId',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_buyer_counterparty', 'Buyer counterparty', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_buyer_counterparty_placeholder', 'Buyer counterparty', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.toCounterpartiesScrollFunction },
              { id: 'searchFunction', value: this.toCounterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.toCounterpartiesSearchByIdsFunction }
            ]
          },
          operatorType: "in",
          hiddenColumn: true
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_counterparty', 'Counterparty'),
          dbKey: 'FromCounterPartyId',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'FromCounterPartyId',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_seller_counterparty', 'Seller counterparty', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_seller_counterparty_placeholder', 'Seller counterparty', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.fromCounterpartiesScrollFunction },
              { id: 'searchFunction', value: this.fromCounterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.fromCounterpartiesSearchByIdsFunction }
            ]
          },
          operatorType: "in",
          hiddenColumn: true
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_reference_number', 'Ref.N'),
          dbKey: 'TransactionReferenceNumber',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'input-text',
            fieldControlName: 'TransactionReferenceNumber',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_transaction_reference_number', 'Reference number', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_transaction_reference_number_placeholder', 'Reference number', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true
          },
          operatorType: "like"
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_origin', 'Origins'),
          dbKey: 'OriginInfo',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'OriginInfo',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_origins', 'Origini', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_origins_placeholder', 'Origini', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: true,
            fieldPaddingRight: false,
            fieldOptions: [
              { id: 'scrollFunction', value: this.originsScrollFunction },
              { id: 'searchFunction', value: this.originsSearchFunction },
              { id: 'searchByIdFunction', value: this.originsSearchByIdsFunction }
            ]
          },
          operatorType: "in like"
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_technologies', 'Tech.'),
          dbKey: 'TechnologyInfo',
          hasReorder: true,
          hasFilter: true,
          filterField: {
            fieldType: 'select-multiple',
            fieldControlName: 'TechnologyInfo',
            fieldBootstrapColumn: 6,
            fieldName: this.tranPipe.transform('portfolio_table_filters_technologies', 'Technologie', []),
            fieldPlaceholder: this.tranPipe.transform('portfolio_table_filters_technologies_placeholder', 'Technologie', []),
            fieldValue: undefined,
            fieldIsRequired: false,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: true,
            fieldOptions: [
              { id: 'scrollFunction', value: this.technologiesScrollFunction },
              { id: 'searchFunction', value: this.technologiesSearchFunction },
              { id: 'searchByIdFunction', value: this.technologiesSearchByIdsFunction }
            ]
          },
          operatorType: "in like",

        },
        {
          name: this.tranPipe.transform('portfolio_table_header_volume', 'Vol. (Mw)'),
          dbKey: 'transactionVolume',
          hasReorder: true,
          hasFilter: false
        },
        {
          name: this.tranPipe.transform('portfolio_table_header_weighted_avg_price', 'Price'),
          dbKey: 'weightedAvgPrice',
          hasReorder: true,
          hasFilter: false
        },
        {
          name: this.tranPipe.transform('table_header_actions', 'Actions'),
          hasReorder: false,
          hasFilter: false,
          fixedWidth: "130px"
        }
      ],
      filter: undefined,
      filterBoxStatus: "close",
      listOfData: undefined,
      refreshFunction: (args: {
        pageNumber: number, pageSize: number, orderColumnName?: string | null, orderType?: string | null,
        tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null, tableFilters?: Array<PurpleTableSelectFilter> | null, switchOn?: boolean,
        culture: string
      }) => {
        args.tableFilters = this.getGlobalTableFilter('buyer');
        return this.backSvc.getPortfolioTable(args.culture, args);
      },
      refreshTableRowsNumber: 0,
      totalItems: 0,
      tableTitle: this.tranPipe.transform('portfolio_purchases', 'Purchases', [])
    }
  }

  ngAfterViewInit(): void {
    const pageName = 'stella_frontoffice_portfolio';
    this.dashSvc.setPageHeader(
      this.tranPipe.transform(pageName + '_page_title', 'Portfolio', []),
      this.tranPipe.transform(
        pageName + '_page_subtitle',
        'Text explaning what this page is about',
        []
      )
    );
  }

  ngOnInit() {
    //this.loaderSvc.addRequest("internal-loader")
    this.dashSvc.dashboardHeightNumber
      .pipe(takeUntil(this.sub))
      .subscribe((s: number | undefined) => {
        if (s == undefined || s - 200 < 450) {
          this.tableHeight = '450px';
        } else {
          this.tableHeight = s - 65 + 'px';
        }
      });

    const currentYear = getYear(new Date());
    const min = 2018;
    const max = currentYear + 10;

    this.portfolioYearOptions = Array.from({ length: max - min }, (v, k) => {
      let y = (k + 2018).toString();
      return {
        label: y,
        value: y,
      };
    });

    this.route.queryParams.subscribe(async (params) => {
      this.viewType = Nc.IsNullCast(params['viewType'], 0, (str) => +str);
      this.globalFilters.currency = params['currency'] ?? "CHF";
      this.globalFilters.companies = params['companies'] ? JSON.parse(params['companies']) : undefined;
      this.globalFilters.portfolioYears = params['portfolioYears'] ? JSON.parse(params['portfolioYears']) : [(new Date()).getFullYear().toString()];
      this.globalFilters.portfolios = params['portfolios'] ? JSON.parse(params['portfolios']) : ["goo"];
      this.globalFilters.traders = params['traders'] ? JSON.parse(params['traders']) : undefined;

      this.showTable = true;
      this.dashSvc.dashMainItems = [];
      this.dashSvc.dashMainItems?.push(
        new dashboardButton(
          'view-type',
          this.tranPipe.transform('portfolio_view_type_graph_button', 'Graph view', []),
          () => {
            const idx = this.dashSvc.dashMainItems.findIndex((f) => f.id.strEq('view-type'));

            if (this.viewType == 0) {
              if (idx != -1) {
                (this.dashSvc.dashMainItems[idx] as dashboardButton).buttonText = this.tranPipe.transform('portfolio_view_type_tab_button', 'Tab view', []);
                (this.dashSvc.dashMainItems[idx] as dashboardButton).buttonIcon = 'layout';
              }
              this.viewType = 1;
            } else {
              if (idx != -1) {
                (this.dashSvc.dashMainItems[idx] as dashboardButton).buttonText = this.tranPipe.transform('portfolio_view_type_graph_button', 'Graph view', []);
                (this.dashSvc.dashMainItems[idx] as dashboardButton).buttonIcon = 'line-chart';
              }
              this.viewType = 0;
            }
            this.setRouteParameters();
          },
          undefined,
          'line-chart',
          'type-view-button',
          'left'
        ),
        new dashboardPurpleSelect(
          'currency-select',
          this.globalCurrenciesScrollFunction,
          this.gloablCurrenciesSearchFunction,
          this.gloablCurrenciesSearchByIdFunction,
          (id: any) => {
            this.globalFilters.currency = id;
            this.setRouteParameters(true);
          },
          this.globalFilters.currency,
          undefined,
          undefined,
          this.tranPipe.transform('select_currency', 'Select currency', []),
          false,
          false,
          'currency-header-select'
        ),
        new dashboardMultiplePurpleSelect(
          'portfolio-select',
          this.globalPortfoliosScrollFunction,
          this.globalPortfoliosSearchFunction,
          this.globalPortfoliosSearchByIdsFunction,
          (ids: string[]) => {
            this.globalFilters.portfolios = ids;
            this.setRouteParameters(true);
          },
          this.globalFilters.portfolios,
          undefined,
          undefined,
          this.tranPipe.transform('select_portfolio', 'Select portfolio', []),
          true,
          false,
          'portfolio-header-select'
        ),
        new dashboardMultiplePurpleSelect(
          'trader-select',
          this.gloablTradersScrollFunction,
          this.gloablTradersSearchFunction,
          this.globalTradersSearchByIdsFunction,
          (ids: string[]) => {
            this.globalFilters.traders = ids;
            this.setRouteParameters(true);
          },
          this.globalFilters.traders,
          undefined,
          undefined,
          this.tranPipe.transform('select_trader', 'Select trader', []),
          true,
          false,
          'trader-header-select'
        ),
        new dashboardMultiplePurpleSelect(
          'company-select',
          this.globalCompaniesScrollFunction,
          this.globalCompaniesSearchFunction,
          this.globalCompaniesSearchByIdsFunction,
          (ids: string[]) => {
            this.globalFilters.companies = ids;
            this.setRouteParameters(true);
          },
          this.globalFilters.companies,
          undefined,
          undefined,
          this.tranPipe.transform('select_company', 'Select company', []),
          false,
          false,
          'company-header-select'
        ),
        new dashboardLocalSelect(
          'portfolio-year',
          (year: number[]) => {
            this.globalFilters.portfolioYears = year;
            this.setRouteParameters(true);
          },
          this.portfolioYearOptions,
          "multiple",
          this.globalFilters.portfolioYears,
          undefined,
          this.tranPipe.transform('select_portfolio_year', 'Anno', []),
          true,
          true,
          true,
          'portfolio-year-header-select'
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.dashSvc.clearPageHeader();
    this.sub.next();
    this.sub.complete();
  }

  setPortfolioTablePopover(portfolio: portfolioTableItem, template: TemplateRef<any>) {
    portfolio.filterBoxStatus = "close";
    portfolio.filter =
      new dashboardPopover("purple-table-filter-popover",
        this.tranPipe.transform('purple_table_filter_button', 'Filtri', []),
        template,
        undefined,
        'default',
        'down',
        'filter-button',
        undefined,
        'click',
        'bottomRight',
        'purple-table-filters-popover',
        false,
        (isOpen: boolean) => {
          portfolio.filterBoxStatus = isOpen ? "open" : "close"
        });
  }

  closePortfolioTablePopover(portfolio: portfolioTableItem) {
    const el = portfolio.filter;

    if (el != undefined) {
      (el as dashboardPopover).closePopover()
    }
  }

  setRouteParameters(refresh: boolean = false) {
    console.log('Setto i parametri di route');
    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: {
        viewType: this.viewType,
        currency: this.globalFilters.currency,
        companies: (this.globalFilters.companies?.length ?? 0) == 0 ? undefined : JSON.stringify(this.globalFilters.companies),
        portfolioYears: (this.globalFilters.portfolioYears?.length ?? 0) == 0 ? undefined : JSON.stringify(this.globalFilters.portfolioYears),
        portfolios: (this.globalFilters.portfolios?.length ?? 0) == 0 ? undefined : JSON.stringify(this.globalFilters.portfolios),
        traders: (this.globalFilters.traders?.length ?? 0) == 0 ? undefined : JSON.stringify(this.globalFilters.traders)
      },
      relativeTo: this.route,
    });

    if (refresh) {
      this.portfolioTables.sales.refreshTableRowsNumber++;
      this.portfolioTables.purchases.refreshTableRowsNumber++;
    }
  }

  async duplicateDeal(dealId: string) {

    await this.router.navigate([this.tsvc.currentLanguage.value, 'dashboard', 'stella-frontoffice', 'deal-ticket'],
      {
        queryParams:
        {
          transactionId: dealId
        }
      }
    )
  }


  getTraders(deal: DealBaseGrouped): { initials: string, fullNames: string } {
    const fn = deal.traders.map((m) => this.titleCasePipe.transform(m.traderFullName)).join(', ');
    const ini = deal.traders.map((m) => {
      const tn = m.traderFullName?.split(" ") ?? [];
      if (tn.length > 0) {
        return tn[0].charAt(0) + tn[1].charAt(0)
      } else {
        return tn[0].charAt(0) + tn[0].charAt(1)
      }
    }).join(', ').toUpperCase();

    return { fullNames: fn, initials: ini }
  }

  getGlobalTableFilter(type: 'buyer' | 'seller'): PurpleTableSelectFilter[] {
    const res = Psf.buildTableFilters([
      { key: 'type', value: type },
      { key: 'currency', value: this.globalFilters.currency },
      { key: 'counterParties', value: this.globalFilters.companies?.join(",") },
      { key: 'portfolioYears', value: this.globalFilters.portfolioYears?.join(",") },
      { key: 'portfolios', value: this.globalFilters.portfolios?.join(",") },
      { key: 'traders', value: this.globalFilters.traders?.join(",") }
    ]);
    return res;
  }


  viewDelivery(transactionId: string, dealType: "buyer" | "seller", productId: string, currency: string) {
    var width = window.innerWidth;
    width = this.dashSvc.isMenuCollapsed ? width - 100 : width - 270;

    this.drawer.create<ViewDeliveriesComponent, { transactionId: string, dealType: string, productId: string, currency: string }>({
      nzContent: ViewDeliveriesComponent,
      nzTitle: this.tranPipe.transform("view_deliveries_table_modal", "View all deliveries for deal",),
      nzWidth: width + 'px',
      nzData: {
        transactionId: transactionId,
        dealType: dealType,
        productId: productId,
        currency: currency
      },
      nzMaskClosable: false,
      nzPlacement: 'right',
      nzSize: 'large'
    }).afterClose.subscribe((updated: boolean | undefined) => {
      if (updated != undefined && updated == true) {
        this.portfolioTables.sales.refreshTableRowsNumber++;
        this.portfolioTables.purchases.refreshTableRowsNumber++;
      }
    })
  }


  viewAgreement(deal: DealBaseGrouped) {


    this.modal.create<DealAgreementStatusComponent, { dealTransactionid: string, refNo: string }>({
      nzContent: DealAgreementStatusComponent,
      nzTitle: this.tranPipe.transform("view_agreement_table_modal", "View agreement status",),

      nzData: {
        dealTransactionid: deal.transactionId!,
        refNo: deal.transactionReferenceNumber
      },
      nzWidth: '1000px',
      nzClassName: 'purple-modal',
      nzFooter: null,
      nzMaskClosable: true
    }).afterClose.subscribe((update: boolean) => {

    })
  }
  //////////////////////////////////////////////FILTRI GLOCALI/////////////////////////////////////////////////////////////
  //COMPANIES
  globalCompaniesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCompaniesSelect(args.culture, args);
  };
  globalCompaniesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCompaniesByNameSelect(args.culture, args);
  };
  globalCompaniesSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCompaniesByIdsSelect(args.culture, args);
  };


  //PORTFOLIOS
  globalPortfoliosScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getPortfoliosSelect(args.culture, args);
  };
  globalPortfoliosSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getPortfoliosByNameSelect(args.culture, args);
  };
  globalPortfoliosSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getPortfoliosByIdsSelect(args.culture, args);
  };


  //CURRENCIES
  globalCurrenciesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCurrenciesSelect(args.culture, args);
  };
  gloablCurrenciesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCurrenciesByNameSelect(args.culture, args);
  };
  gloablCurrenciesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    return this.backSvc.getCurrenciesByIdSelect(args.culture, args);
  };


  //TRADERS
  gloablTradersScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "traders", filterValues: ['true'] }
    ];
    return this.backUserSvc.getUsersSelect(args.culture, args)
  }
  gloablTradersSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = [
      { propertyName: "traders", filterValues: ['true'] }
    ];
    return this.backUserSvc.getUsersByNameSelect(args.culture, args)
  }
  globalTradersSearchByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    return this.backUserSvc.getUsersByIdsSelect(args.culture, args)
  }

  portfolioTableOrder: ((a: KeyValue<keyof portfolioTables, portfolioTableItem>, b: KeyValue<keyof portfolioTables, portfolioTableItem>) => number) | undefined =
    (a: KeyValue<keyof portfolioTables, portfolioTableItem>, b: KeyValue<keyof portfolioTables, portfolioTableItem>) => {
      if (a.key.strEq("sales")) {
        return 0
      }

      return 1;
    };
}


interface portfolioTables {
  sales: portfolioTableItem;
  purchases: portfolioTableItem;
}

interface portfolioTableItem {
  //table
  tableColumns: PurpleTableBoxColumn[];
  refreshFunction: (args: {
    pageNumber: number; pageSize: number; orderColumnName?: string | null; orderType?: string | null;
    tableSearchValues?: Array<PurpleTableSearchGroupGroup> | null; tableFilters?: Array<PurpleTableSelectFilter> | null;
    switchOn?: boolean; culture: string;
  }) => any;
  refreshTableRowsNumber: number;
  listOfData: Array<DealBaseGroupedPurpleTableResponse> | undefined | null;
  totalItems: number;

  //filter
  filter: dashboardPopover | undefined;
  filterBoxStatus: "close" | "open";

  //html
  tableTitle: string;
}
