import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Guid, PurpleApiProxyService, PurpleSelectFilter, PurpleTranslationPipe, purpleFormFieldsGroup } from 'purple-lib';
import { DashboardLayout2Service } from 'src/app/dashboard/dashboard-layout-2/dashboard-layout-2.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { StellaService } from 'src/core-modules/sdk/api';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-merge-counterparty',
  templateUrl: './merge-counterparty.component.html',
  styleUrls: ['./merge-counterparty.component.scss']
})
export class MergeCounterpartyComponent implements OnInit {

  constructor(@Inject(NZ_MODAL_DATA) private data: { parentCounterPartyId: string | undefined, childrenCounterPartyId: string[] | undefined, isMerge: boolean }, @Inject(LOCALE_ID) private locale: string, private admSvc: StellaService, private tranPipe: PurpleTranslationPipe, public dashSvc: DashboardLayout2Service,
    private apiProxySvc: PurpleApiProxyService, private tsvc: AppTranslationService, private fb: FormBuilder, private mesSvc: NzMessageService, private modal: NzModalService, private modalRef: NzModalRef) { }

  disableForm: boolean = false;


  purpleFormFieldGroups: purpleFormFieldsGroup[] | undefined;

  validateForm: FormGroup = this.fb.group({});

  isLoading: boolean = false;
  refreshChildScroll = 0;
  refreshParentScroll = 0;
  isNew: boolean = false;
  newObj!: counterPartRelationships;



  counterPartSearchFilter: PurpleSelectFilter[] = [

  ]
  ngOnInit() {

    if (this.data.parentCounterPartyId == undefined) {
      this.newObj =
      {
        parentId: "",
        childIds: []
      }
      this.isNew = true;
    }
    else {
      if (this.data.isMerge || this.data.childrenCounterPartyId == undefined)
        this.newObj =
        {
          parentId: this.data.parentCounterPartyId,
          childIds: []
        }
      else {
        this.newObj =
        {
          parentId: this.data.parentCounterPartyId,
          childIds: this.data.childrenCounterPartyId
        }

      }

      this.counterPartSearchFilter.push({
        propertyName: "CounterPartyId", filterValues: [this.data.parentCounterPartyId],
        filterOperator: "<>"
      })
    }


    var customValidator = Validators.nullValidator;
    const fieldCustomValidators = [
      (control: AbstractControl): ValidationErrors | null => {

        const value = control.value as string[];
        var contained = (this.newObj.parentId != null && value != null) ? value.some(id => id == this.newObj.parentId) : false;

        console.log("childids", value);
        console.log("value", this.newObj.parentId);
        console.log("contained", contained);
        if (contained) {
          console.log("entro qui")
          return { error: true };
        }
        return customValidator(control);
      }
    ];

    this.purpleFormFieldGroups = [
      {
        fieldGroupNumber: 1,
        fielGroupBootstrapColumn: 12,
        fieldGroupPaddingLeft: false,
        fieldGroupPaddingRight: false,
        formFieldFormGroup: this.validateForm,
        formFields: [
          {
            fieldType: 'select',
            fieldControlName: 'ParentCounterParty',
            fieldBootstrapColumn: 12,
            fieldName: this.data.isMerge ? this.tranPipe.transform('merge_counterparty_correct', 'Correct CounterParty', []) : this.tranPipe.transform('merge_counterparty_parent', 'Parent CounterParty', []),
            fieldPlaceholder: this.data.isMerge ? this.tranPipe.transform('merge_counterparty_correct_placeholder', 'Correct CounterParty', []) : this.tranPipe.transform('merge_counterparty_parent_placeholder', 'Parent CounterParty', []),
            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterpartiesScrollFunction },
              { id: 'searchFunction', value: this.counterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterpartiesSearchByIdFunction },
              { id: 'refreshFieldNumber', value: this.refreshParentScroll }

            ],
            fieldValue: this.newObj.parentId,
            fieldIsRequired: true,
            fieldIsDisabled: !this.isNew,
            fieldPaddingLeft: false,
            fieldPaddingRight: false
          },
          {
            fieldType: 'select-multiple',
            fieldControlName: 'ChildCounterParty',
            fieldBootstrapColumn: 12,
            fieldName: this.data.isMerge ? this.tranPipe.transform('wrong_counterparties', 'Wrong Counterparties', []) : this.tranPipe.transform('child_counterparties', 'Child CounterParty', []),
            fieldPlaceholder: this.data.isMerge ? this.tranPipe.transform('wrong_counterparties_placeholder', 'Wrong Counterparties', []) : this.tranPipe.transform('child_counterparties_placeholder', 'Child Counterparty', []),
            fieldCustomClass: undefined,
            fieldOptions: [
              { id: 'scrollFunction', value: this.counterpartiesScrollFunction },
              { id: 'searchFunction', value: this.counterpartiesSearchFunction },
              { id: 'searchByIdFunction', value: this.counterPartiesByIdsFunction },
              { id: 'refreshFieldNumber', value: this.refreshChildScroll }
            ],
            fieldValue: this.newObj.childIds,
            fieldIsRequired: true,
            fieldIsDisabled: false,
            fieldPaddingLeft: false,
            fieldPaddingRight: false,
            fieldCustomValidators: fieldCustomValidators,
            fieldCustomValidatorErrors: [
              {
                errorId: "error",
                text: this.tranPipe.transform("id_cannot_be_selected", "Uno o più id selezionati corrispondono con quello iniziale")
              }
            ]
          }
        ]
      }
    ]


    setTimeout(() => {
      this.validateForm.controls["ParentCounterParty"].valueChanges.subscribe((value: string) => {
        if (this.newObj.parentId != value) {

          this.newObj.parentId = value;

          if (this.newObj.childIds != null) {
            this.validateForm.controls["ChildCounterParty"].updateValueAndValidity()
          }
        }

      });


      this.validateForm.controls["ChildCounterParty"].valueChanges.subscribe((value: string[]) => {


        if (this.newObj.childIds != value) {
          this.newObj.childIds = value;
          this.validateForm.controls["ChildCounterParty"].updateValueAndValidity()
        }

      });


    }, 100);

  }



  //ScrollFunction
  counterpartiesScrollFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.counterPartSearchFilter
    return this.admSvc.getCounterPartiesSelect(args.culture, args)
  }
  //SearchFunction
  counterpartiesSearchFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.counterPartSearchFilter
    return this.admSvc.getCounterPartiesByNameSelect(args.culture, args)
  }
  //InitFunction (SearchByIdFunction)
  counterpartiesSearchByIdFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string, searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.counterPartSearchFilter
    return this.admSvc.getCounterPartyByIdSelect(args.culture, args)
  }


  //InitFunction (SearchByIdFunction)
  counterPartiesByIdsFunction = (args: { pageNumber: number, pageSize: number, culture: string, searchName: string[], searchFilters: PurpleSelectFilter[] }) => {
    args.searchFilters = this.counterPartSearchFilter
    return this.admSvc.getCounterPartiesByIdsSelect(args.culture, args)
  }

  async mergeCounterParties() {

    this.isLoading = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }



    if (this.validateForm.valid) {
      this.newObj.parentId = this.validateForm.controls["ParentCounterParty"].value;
      this.newObj.childIds = this.validateForm.controls["ChildCounterParty"].value;

      if (!this.data.isMerge) {
        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.addCounterPartsRelatioship(this.tsvc.currentLanguage.value,
          {
            parentCounterpartyId: this.newObj.parentId,
            childCounterpartyId: this.newObj.childIds
          }), false, "internal-loader", 500, undefined, undefined, (res) => {

            if (res.data) {
              this.mesSvc.success("Relationships successfully inserted", {
                nzDuration: environment.MESSAGE_DURATION
              });


            }
            else {
              this.mesSvc.error("Relationships not inserted", {
                nzDuration: environment.MESSAGE_DURATION
              });


            }

            this.modalRef.destroy(res.data);


          })
      }
      else {

        await this.apiProxySvc.makeRequestErrorMessage<boolean>(() => this.admSvc.mergeCounterParts(this.tsvc.currentLanguage.value,
          {
            correctCounterpartyId: this.newObj.parentId,
            wrongCounterpartyIds: this.newObj.childIds
          }), false, "internal-loader", 500, undefined, undefined, () => {
            this.mesSvc.success("Successfully merged", {
              nzDuration: environment.MESSAGE_DURATION
            });

            this.modalRef.destroy(true)
          })
      }


    }

    this.isLoading = false
  }





}

export interface counterPartRelationships {
  parentId: string;
  childIds: string[];
}
