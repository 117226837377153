import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PurpleLoaderService, APP_MEDIA_SERVICE, PURPLE_LIB_USER_TOKEN, APP_TRANSLATION_SERVICE, PurpleEditTextModule, PurpleGeneralDirectiveModule, PurpleStorageService, PurpleLoaderModule, PurpleErrorModule, PurpleLanguageIconPipe, PURPLE_LIB_MEDIA_PATH, PurpleApiErrorInterceptor, PurpleApiErrorManagerModule } from 'purple-lib';
import { AppMediaService } from 'src/core-modules/medias/media.service';
import { Configuration, BASE_PATH } from 'src/core-modules/sdk/api';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import it from '@angular/common/locales/it';
import en from '@angular/common/locales/en';
import { NZ_I18N, it_IT } from 'ng-zorro-antd/i18n';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { registerLocaleData } from '@angular/common';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
import { LocalizationModule } from 'src/core-modules/localization/localization.module';
import { IAuthenticatedUser } from 'src/core-modules/authentication/IAuthenticatedUser';
import { AuthenticationGuard } from 'src/core-modules/authentication/authentication.guard';
import { AuthenticationService } from 'src/core-modules/authentication/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationModule } from './authentication/authentication.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { DashboardModule } from './dashboard/dashboard.module';
import { ShowMenuGuard } from 'src/core-modules/show-menu/showMenu.guard';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { ICONS } from './icons';
import { LOCALE_CONFIG } from './locale-config';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';


registerLocaleData(it, "it-it");
registerLocaleData(it, "it");
registerLocaleData(en, "en-us");

export function MSALInstanceFactory(): PublicClientApplication {

  console.log("ms", environment.msauth);
  return new PublicClientApplication({
    auth: environment.msauth
  });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PurpleEditTextModule,
    PurpleGeneralDirectiveModule,
    BrowserAnimationsModule,
    NzIconModule,
    NzMenuModule,
    MsalModule,

    PurpleLoaderModule.forRoot(),
    PurpleErrorModule.forRoot(),
    LocalizationModule,
    AuthenticationModule,
    DashboardModule.forRoot(),
    JwtModule.forRoot({}),

    PurpleApiErrorManagerModule
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    ShowMenuGuard,
    AuthenticationGuard,
    AuthenticationService,
    PurpleLanguageIconPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PurpleApiErrorInterceptor,
      multi: true
    },
    PurpleLoaderService,
    { provide: NZ_ICONS, useValue: ICONS },
    {
      provide: Configuration,
      useFactory: (storageSvc: PurpleStorageService) => new Configuration(
        {
          basePath: environment.API_BASE_PATH,
          accessToken: () => storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token ?? undefined
        }
      ),
      deps: [PurpleStorageService],
      multi: false
    },
    {
      provide: JWT_OPTIONS,
      useFactory: (storageSvc: PurpleStorageService) => storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token ?? "",
      deps: [PurpleStorageService]
    },
    {
      provide: LOCALE_ID,
      deps: [PurpleStorageService],
      useFactory: (storageSvc: PurpleStorageService) => storageSvc.get<string>("Language") ?? environment.DEFAULT_LANGUAGE
    },
    {
      provide: NZ_I18N,
      useFactory: (localId: string) => LOCALE_CONFIG[localId] || it_IT,
      deps: [LOCALE_ID]
    },
    {
      provide: APP_TRANSLATION_SERVICE,
      deps: [AppTranslationService],
      useExisting: AppTranslationService
    },
    {
      provide: APP_MEDIA_SERVICE,
      deps: [AppMediaService],
      useExisting: AppMediaService
    },
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: PURPLE_LIB_MEDIA_PATH, useValue: environment.API_BASE_PATH },
    {
      provide: PURPLE_LIB_USER_TOKEN,
      useFactory: (storageSvc: PurpleStorageService) => storageSvc.get<IAuthenticatedUser>("CurrentUser")?.token ?? undefined,
      deps: [PurpleStorageService],
      multi: false
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
