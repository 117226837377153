/**
 * Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TemplatizeDocumentResponse } from './templatizeDocumentResponse';
import { TemplatizeRecipientResponse } from './templatizeRecipientResponse';
import { TemplatizeTabResponse } from './templatizeTabResponse';
import { PRPTemplatesEnvelope } from './pRPTemplatesEnvelope';


export interface TemplatizeEnvelopeResponse { 
    envelopeTemplate?: PRPTemplatesEnvelope;
    documentTemplates?: Array<TemplatizeDocumentResponse> | null;
    recipientTemplates?: Array<TemplatizeRecipientResponse> | null;
    recipientTabs?: Array<TemplatizeTabResponse> | null;
}

